export const hotDealAttributeModel = {
    label_color: {
        type: String,
    },
    label_text: {
        type: String,
    },
    label_text_color: {
        type: String,
        isOptional: true,
    },
    kp_label_color: {
        type: String,
    },
    kp_label_text: {
        type: String,
    },
    kp_label_text_color: {
        type: String,
        isOptional: true,
    },
    start_date: {
        type: Date,
        keepRaw: true,
    },
    end_date: {
        type: Date,
        keepRaw: true,
    },
    action_code: {
        type: String,
        isOptional: true,
    },
    show_timer: {
        type: Object,
        isOptional: true,
    },
    link_text: {
        type: String,
        isOptional: true,
    },
    link_url: {
        type: String,
        isOptional: true,
    },
};
