export const BIG = 'big';
export const SMALL = 'small';

export const SIZES = { BIG, SMALL };

export const BLACK = 'black';
export const GREEN = 'green';
export const WHITE = 'white';
export const BLUE = 'blue';
export const RED = 'red';

export const COLORS = {
    BLACK,
    GREEN,
    WHITE,
    RED,
    BLUE,
};
