export const types = {
    SET_I18N_LOCALE: 'SET_I18N_LOCALE',
};

const mutations = {
    [types.SET_I18N_LOCALE](state, locale) {
        state.locale = locale;
    },
};

export default mutations;
