export const LOCALE_PL = 'pl-PL';
export const LOCALE_SK = 'sk-SK';
export const LOCALE_CZ = 'cs-CZ';
export const LOCALE_RO = 'ro-RO';
export const LOCALE_HU = 'hu-HU';
export const LOCALE_BG = 'bg-BG';
export const LOCALE_GR = 'el-GR';
export const LOCALE_LT = 'lt-LT';
export const LOCALE_DE = 'de-DE';
export const LOCALE_FR = 'fr-FR';
export const LOCALE_IT = 'it-IT';
export const LOCALE_HR = 'hr-HR';
export const LOCALE_UA = 'uk-UA';
export const LOCALE_UA_RU = 'ru-UA';
export const LOCALE_LV = 'lv-LV';
export const LOCALE_SI = 'sl-SI';
export const LOCALE_AT = 'de-AT';
export const LOCALE_EE = 'et-EE';
