<template>
    <div class="messages">
        <BaseAlertRow
            v-for="{
                id,
                type,
                additionalText,
                text,
                action,
                hasIcon,
            } in messages"
            :id="id"
            :key="`message-${id}`"
            :type="type"
            :action-handler="action.handler"
            :action-url="action.url"
            :action-text="action.label"
            :has-icon="hasIcon"
            class="messages-alert"
        >
            {{ text }}
            <template v-if="!!additionalText" #additional-text>
                {{ additionalText }}
            </template>
        </BaseAlertRow>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseAlertRow from '@atoms/BaseAlertRow/BaseAlertRow';

const {
    mapState: mapStateMessages,
    mapActions: mapActionsMessages,
} = createNamespacedHelpers('messages');

export default {
    name: 'Messages',
    components: {
        BaseAlertRow,
    },

    computed: {
        ...mapStateMessages(['messages']),
    },

    methods: {
        ...mapActionsMessages(['removeMessage']),
    },
};
</script>

<style lang="scss" scoped>
.messages {
    @apply fixed z-9 w-full bottom-0;

    .messages-alert {
        @apply mt-3;
    }
}

@screen mobile-and-tablet-only {
    .messages {
        .messages-alert {
            @apply rounded-b-0;
        }
    }
}

@screen md {
    .messages {
        @apply left-1/2 mb-5 text-center w-auto;
        transform: translateX(-50%);
        max-width: theme('customVariables.messages.maxWidth');

        .messages-alert {
            @apply w-auto inline-flex;
            min-width: theme('customVariables.messages.minWidth');
            max-width: theme('customVariables.messages.maxWidth');
        }
    }
}
</style>
