import bgBGOgImage from '@static/img/og-logo-BG.jpg';
import chCHOgImage from '@static/img/og-logo-CH.jpg';
import csCZOgImage from '@static/img/og-logo-CZ.jpg';
import deATOgImage from '@static/img/og-logo-AT.jpg';
import deDEOgImage from '@static/img/og-logo-DE.jpg';
import elGROgImage from '@static/img/og-logo-GR.jpg';
import enGBOgImage from '@static/img/og-logo-GB.jpg';
import esESOgImage from '@static/img/og-logo-ES.jpg';
import frFROgImage from '@static/img/og-logo-FR.jpg';
import hrHROgImage from '@static/img/og-logo-HR.jpg';
import huHUOgImage from '@static/img/og-logo-HU.jpg';
import itITOgImage from '@static/img/og-logo-IT.jpg';
import ltLTOgImage from '@static/img/og-logo-LT.jpg';
import lvLVOgImage from '@static/img/og-logo-LV.jpg';
import plPLOgImage from '@static/img/og-logo-PL.jpg';
import roROOgImage from '@static/img/og-logo-RO.jpg';
import ruUAOgImage from '@static/img/og-logo-RU.jpg';
import skSKOgImage from '@static/img/og-logo-SK.jpg';
import slSIOgImage from '@static/img/og-logo-SI.jpg';
import svSEOgImage from '@static/img/og-logo-SE.jpg';
import ukUAOgImage from '@static/img/og-logo-UA.jpg';

import defaultOgImage from '@static/img/og-logo-Default.jpg';

export const DEFAULT_OG_IMAGE = defaultOgImage;

export const bg_BG_OgImage = 'bgBGOgImage';
export const ch_CH_OgImage = 'chCHOgImage';
export const cs_CZ_OgImage = 'csCZOgImage';
export const de_AT_OgImage = 'deATOgImage';
export const de_DE_OgImage = 'deDEOgImage';
export const el_GR_OgImage = 'elGROgImage';
export const en_GB_OgImage = 'enGBOgImage';
export const es_ES_OgImage = 'esESOgImage';
export const fr_FR_OgImage = 'frFROgImage';
export const hr_HR_OgImage = 'hrHROgImage';
export const hu_HU_OgImage = 'huHUOgImage';
export const it_IT_OgImage = 'itITOgImage';
export const lt_LT_OgImage = 'ltLTOgImage';
export const lv_LV_OgImage = 'lvLVTOgImage';
export const pl_PL_OgImage = 'plPLOgImage';
export const ro_RO_OgImage = 'roROOgImage';
export const ru_UA_OgImage = 'ruUAOgImage';
export const sk_SK_OgImage = 'skSKOgImage';
export const sl_SI_OgImage = 'slSIOgImage';
export const sv_SE_OgImage = 'svSEOgImage';
export const uk_UA_OgImage = 'ukUAOgImage';

export const OG_IMAGES = {
    [bg_BG_OgImage]: bgBGOgImage,
    [ch_CH_OgImage]: chCHOgImage,
    [cs_CZ_OgImage]: csCZOgImage,
    [de_AT_OgImage]: deATOgImage,
    [de_DE_OgImage]: deDEOgImage,
    [el_GR_OgImage]: elGROgImage,
    [en_GB_OgImage]: enGBOgImage,
    [es_ES_OgImage]: esESOgImage,
    [fr_FR_OgImage]: frFROgImage,
    [hr_HR_OgImage]: hrHROgImage,
    [hu_HU_OgImage]: huHUOgImage,
    [it_IT_OgImage]: itITOgImage,
    [lt_LT_OgImage]: ltLTOgImage,
    [lv_LV_OgImage]: lvLVOgImage,
    [pl_PL_OgImage]: plPLOgImage,
    [ro_RO_OgImage]: roROOgImage,
    [ru_UA_OgImage]: ruUAOgImage,
    [sk_SK_OgImage]: skSKOgImage,
    [sl_SI_OgImage]: slSIOgImage,
    [sv_SE_OgImage]: svSEOgImage,
    [uk_UA_OgImage]: ukUAOgImage,
};
