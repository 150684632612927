export const setDataInStore = ({
    store,
    route,
    sortOptions,
    page,
    totalPages,
    products = [],
    navigation,
    total,
}) => {
    store.dispatch('sort/setOrderOptions', sortOptions);

    store.dispatch('pagination/setPagination', {
        currentPage: page,
        totalPages,
    });

    const skus = products.map(({ sku }) => sku).filter(value => value);

    const productsAnalytics = products.map(({ analytics }) => analytics);

    store.dispatch('category/setCategory', {
        skus,
        navigation,
        total,
        productsAnalytics,
    });

    store.dispatch('category/setLastUrl', route.fullPath);

    return { skus };
};
