import RequestUserPermission from '@modules/notifications/RequestUserPermission';
import { TRY_GET_USER_PERMISSION_TIMEOUT } from '@configs/web-push-notifications';

export default ({ app: { $errorHandler, $analytics, $cookies, $storage } }) => {
    const requestUserPermission = new RequestUserPermission({
        $errorHandler,
        $analytics,
        $cookies,
        $storage,
    });

    const tryGetUserPermissionAfterTimeout = () => {
        setTimeout(async () => {
            await requestUserPermission.tryGetUserPermission();
        }, TRY_GET_USER_PERMISSION_TIMEOUT);
    };

    tryGetUserPermissionAfterTimeout();
};
