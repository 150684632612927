import { DateTime } from 'luxon';

const TIME_COMPARE_RESOLUTION = 'minute';

const timeDiff = (time1, time2) => time1.valueOf() - time2.valueOf();

export default class DateHelper {
    constructor(timezone, dateFormat) {
        this.timezone = timezone;
        this.dateFormat = dateFormat;
    }

    /**
     * @typedef {import('luxon').DateTime} DateTime
     */

    /**
     * @param {string} dateString
     */
    createDateFromStringInTimezone(dateString) {
        return DateTime.fromFormat(dateString, this.dateFormat, {
            zone: this.timezone,
        });
    }

    createCurrentDateInTimezone() {
        return DateTime.now().setZone(this.timezone);
    }

    /**
     * @param {string} dateString
     */
    isValid(dateString) {
        if (dateString === '') {
            return false;
        }

        return DateTime.fromFormat(dateString, this.dateFormat).isValid;
    }

    /**
     * @param {DateTime} start
     * @param {DateTime} end
     * @param {DateTime} current
     */
    static isCurrentDateBetweenPassed(start, end, current) {
        const currentValue = current.startOf(TIME_COMPARE_RESOLUTION).valueOf();

        return currentValue >= start.valueOf() && currentValue <= end.valueOf();
    }

    /**
     * @param {DateTime} inputDate
     * @param {DateTime} current
     */
    static isCurrentDateAfterPassed(inputDate, current) {
        const currentValue = current.startOf(TIME_COMPARE_RESOLUTION).valueOf();

        return currentValue >= inputDate.valueOf();
    }

    /**
     * @param {DateTime} end
     * @param {DateTime} current
     */
    static timeDiffCurrentToEnd(end, current) {
        return timeDiff(end, current.startOf(TIME_COMPARE_RESOLUTION));
    }

    /**
     * @param {string} dateString
     * @param {string} format
     * @param {string} timezone
     */
    static getDateFromFormat(dateString, format, timezone = 'UTC') {
        return DateTime.fromFormat(dateString, format, { zone: timezone });
    }

    /**
     * @param {DateTime} date
     * @param {number} hours
     */
    static minusHours(date, hours) {
        return date.minus({ hours });
    }

    /**
     * @param {DateTime} date
     */
    static toSeconds(date) {
        return date.toSeconds();
    }

    /**
     * @param {DateTime} date
     */
    static toISO(date) {
        return date.toISO({ suppressMilliseconds: true });
    }

    /**
     * @param {DateTime} date
     */
    static toJSDate(date) {
        return date.toJSDate();
    }

    /**
     * @param {DateTime} date
     * @param {string} timezone
     */
    static setTimezone(date, timezone) {
        return date.setZone(timezone);
    }
}
