export const CREATED_AT_KEY = 'order_created_at';
export const ORDER_NUMBER_KEY = 'order_number';
export const STATUS_KEY = 'order_status';
export const DETAILS_KEY = 'order_details';
export const SHIPPING_ADDRESSES_KEY = 'ext_shipping_addresses';
export const BILLING_ADDRESS_KEY = 'billing_address';
export const SELECTED_PAYMENT_METHOD_KEY = 'selected_payment_method';
export const ITEMS_KEY = 'items';
export const PRICES_KEY = 'prices';
export const EMAIL_KEY = 'email';
export const INPOST_POINT_KEY = 'inpost_point';
export const STORE_PICKUP_PLACE_KEY = 'store_pickup_place';
export const PICKUP_POINT_KEY = 'dpd_parcel_shop_point';
export const SHIPPING_POINT_KEY = 'shipping_point';
export const APPLIED_COUPON_CODE_KEY = 'coupon_code';
export const PAYMENT_ADDITIONAL_INFO_KEY = 'additional_info';
export const BANK_ACCOUNT_NUMBER_KEY = 'bank_account_number';
export const SELECTED_PAYMENT_METHOD_CODE_KEY = 'code';
export const SELECTED_PAYMENT_METHOD_TITLE_KEY = 'title';
export const SELECTED_SHIPPING_METHOD_KEY = 'selected_shipping_method';
export const SELECTED_SHIPPING_METHOD_CARRIER_CODE_KEY = 'carrier_code';
export const SELECTED_SHIPPING_METHOD_CARRIER_TITLE_KEY = 'carrier_title';
export const TRACKING_IDENTIFIER = 'tracking_identifier';
export const ITEM_SKU_PRODUCT_KEY = 'product';

export const PRICE_GRAND_TOTAL = 'grand_total';
export const PRICE_SUBTOTAL_INCLUDING_TAX = 'subtotal_including_tax';
export const PRICE_FORMATTED_GRAND_TOTAL = 'formatted_grand_total';
export const PRICE_FORMATTED_SUBTOTAL_INCLUDING_TAX =
    'formatted_subtotal_including_tax';
export const PRICE_FORMATTED_SUBTOTAL_WITH_DISCOUNT_EXCLUDING_TAX =
    'formatted_subtotal_with_discount_excluding_tax';
export const PRICE_DISCOUNT_PERCENT = 'discount_percent';
export const PRICE_DISCOUNT = 'discount';
export const PRICE_DUTY = 'duty';

export const ITEM_PRICE_DISCOUNT_PERCENT = 'discount_percent';
export const ITEM_PRICE_ORIGINAL_PRICE = 'original_price';
export const ITEM_PRICE_FORMATTED_ORIGINAL_PRICE = 'formatted_original_price';
export const ITEM_PRICE_FINAL_PRICE = 'final_price';
export const ITEM_PRICE_FORMATTED_FINAL_PRICE = 'formatted_final_price';
export const ITEM_PRICE_ITEM_ORIGINAL_PRICE = 'item_original_price';
export const ITEM_PRICE_FORMATTED_ITEM_ORIGINAL_PRICE =
    'formatted_item_original_price';
export const ITEM_PRICE_FINAL_ITEM_PRICE = 'final_item_price';
export const ITEM_PRICE_FORMATTED_FINAL_ITEM_PRICE =
    'formatted_final_item_price';
export const ITEM_PRICE_DISCOUNT_PERCENT_BY_SPECIAL_PRICE =
    'discount_percent_by_special_price';

export const ORDER_GRAND_TOTAL = 'order_grand_total';
export const ORDER_ITEMS = 'order_items';
export const ORDER_DISCOUNT = 'order_discount';
export const FORMATTED_GRAND_TOTAL = 'formatted_grand_total';
export const FORMATTED_GRAND_TOTAL_WITHOUT_SHIPPING =
    'formatted_grand_total_without_shipping';
export const ORDER_GRAND_TOTAL_WITHOUT_SHIPPING =
    'order_grand_total_without_shipping';
