import { MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME } from '@configs/megatron-client';

import normalizeProduct from '@normalizers/models/product';

const normalizer = async (response, config, helpers) => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    response.data.items = data.items.map(productItem =>
        normalizeProduct(
            { ...productItem, attributes: data.attributes },
            helpers,
            config
        )
    );

    return response;
};

export const getProducts = {
    operationName: MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME,
    normalizer,
};
