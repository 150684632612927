export const PRODUCT_EXCEPTION = 'product-exception';
export const PRODUCT = 'product';
export const REGULAR_PRICE = 'regular-price';
export const FINAL_PRICE = 'final-price';
export const BRAND_NAME = 'brand-name';
export const PRODUCT_NAME = 'product-name';
export const PRODUCT_SIZES = 'product-sizes';
export const GO_TO_CART_POPUP = 'go-to-cart-popup';
export const GO_TO_CART_BUTTON = 'go-to-cart-button';
export const PRODUCT_GALLERY = 'product-gallery';
export const PRODUCT_PRICE = 'product-price';
export const SIZE_TABLE_BUTTON = 'size-table-button';
export const ADD_TO_CART_BUTTON = 'add-to-cart-button';
export const FREE_SHIPPING_AND_RETURN = 'free-shipping-and-return';
export const PRODUCT_DATA = 'product-data';
export const REVIEWS_SECTION = 'reviews-section';
export const COMMENTS = 'comments';
export const ADD_TO_WISHLIST = 'add-to-wishlist';
export const PRODUCT_MAIN_PICTURE = 'product-main-picture';
export const ZOOM_PICTURE = 'zoom-picture';
export const OPEN_GALLERY_SLIDER = 'open-gallery-slider';
export const CLOSE_MODAL = 'close-modal';
export const PRODUCT_SIZE_TABLE_CONTENT = 'product-size-table-content';
export const CLOSE_SIDE_MODAL = 'close-side-modal';
export const OVERLAY = 'overlay-loader';
export const MODAL_ADD_TO_CART_ERROR = 'modal-add-to-cart-error';
export const CUSTOMER_CONSENTS_BUTTON = 'customer-consents-button';
export const CUSTOMER_NECESSARY_CONSENTS_BUTTON =
    'customer-necessary-consents-button';
export const PRODUCT_LIST_ITEM = 'product-list-item';
export const NAVIGATION_ITEM_LINK = 'navigation-item-link';
export const NAVIGATION_NESTED_ITEM_LINK = 'navigation-nested-item-link';
export const NAVIGATION_SHOW_ALL_LINK = 'navigation-show-all-link';
export const HEADER_MENU_CATEGORY_ITEM = 'header-menu-category-item';
export const HEADER_MENU_CATEGORY_DISCLOSURE_ITEM =
    'header-menu-category-disclosure-item';
export const HEADER_MENU_SIDE_MODAL = 'header-menu-side-modal';
export const HAMBURGER_MENU_BUTTON = 'hamburger-menu-button';
export const PRODUCT_SIZE = 'product-size';
export const AGREEMENTS_BUTTON = 'agreements-button';
