export const REVIEWS_PAGINATION_SCROLL_DURATION = 500;

export const DEFAULT_REVIEWS_LIST_SIZE = 10;
export const PRODUCT_PAGE_REVIEWS_LIST_SIZE = 5;
export const DATE_SORT = 'desc';

export const REVIEW_STATUS_PUBLISHED = 'APPROVED';
export const REVIEW_STATUS_REJECTED = 'NOT_APPROVED';
export const REVIEW_STATUS_IN_REVIEW = 'PENDING';

export const PSB_HOW_OPINIONS_WORK = 'psb_how_opinions_work';

export const REVIEW_EMPTY_NAME = 'unnamed';
export const LOW_RATING_LEVEL = 2;
