import {
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST,
} from '@configs/megatron-client';
import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { UNAUTHENTICATED } from '@types/GraphQLErrorCodes';

import { getFirstErrorCode } from '@assets/megatron-client/graphql-errors-helper';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.customer.${action}`,
});

export default ({ megatronClient, $errorHandler, helpers: { $store } }) => ({
    async generateAuthToken(email, password) {
        const response = tokens => {
            const {
                access_token: accessToken = null,
                refresh_token: refreshToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthToken');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/generateThrudToken',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        email,
                        password,
                    },
                }
            );

            if (!error) {
                return response(data?.generateThrudToken);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async generateAuthTokenWithFacebook(token, referrerToken = undefined) {
        const response = tokens => {
            const {
                access_token: accessToken = null,
                refresh_token: refreshToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
                isRegistration = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                isRegistration,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthTokenWithFacebook');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/generateThrudTokenWithFacebook',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        token,
                        referrerToken,
                    },
                }
            );

            if (!error) {
                return response(data?.generateThrudTokenWithFacebook);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async generateAuthTokenWithGoogle(token, referrerToken = undefined) {
        const response = tokens => {
            const {
                access_token: accessToken = null,
                refresh_token: refreshToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
                isRegistration = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                isRegistration,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthTokenWithGoogle');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/generateThrudTokenWithGoogle',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        token,
                        referrerToken,
                    },
                }
            );

            if (!error) {
                return response(data?.generateThrudTokenWithGoogle);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async getCustomer() {
        const ERROR_TAGS = getErrorTags('getCustomer');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/customer',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.extCustomer || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },

    async getCustomerEmail() {
        const ERROR_TAGS = getErrorTags('getCustomerEmail');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/customerEmail',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.extCustomer?.email || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },

    async revokeAuthToken() {
        const ERROR_TAGS = getErrorTags('revokeAuthToken');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/revokeThrudToken',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.revokeThrudToken?.result || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return false;
    },

    async refreshAuthToken(refreshToken) {
        const response = (tokens = {}, success = false) => {
            const {
                access_token: accessToken = null,
                refresh_token: newRefreshToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken: newRefreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                success,
            };
        };
        const ERROR_TAGS = getErrorTags('refreshAuthToken');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/refreshThrudToken',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        refreshToken,
                    },
                }
            );

            if (!error) {
                return response(data?.refreshThrudToken, true);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            if (![UNAUTHENTICATED].includes(firstErrorCode)) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        data,
                        error,
                        status,
                    }
                );
            }
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async generateAuthTokenWithMagicLink(magicLink) {
        const response = (tokens, success = false) => {
            const {
                access_token: accessToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token: refreshToken = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
                isRegistration = null,
            } = tokens || {};

            return {
                accessToken,
                accessTokenExpirationISO,
                refreshToken,
                refreshTokenExpirationISO,
                isRegistration,
                success,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthTokenWithMagicLink');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/generateThrudTokenWithMagicLink',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        magicLink,
                    },
                }
            );

            if (!error) {
                return response(data?.generateThrudTokenWithMagicLink, true);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    magicLink,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                magicLink,
            });
        }

        return response();
    },

    async createCustomer(registerData) {
        const ERROR_TAGS = getErrorTags('createCustomer');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/createCustomer',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: registerData,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return false;
        }
    },

    async wishlistProducts(skus = []) {
        const ERROR_TAGS = getErrorTags('wishlistProducts');

        try {
            if (!skus.length) {
                return [];
            }

            const { data, error, status } = await megatronClient.get(
                'v1/wishlistProducts',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        skus,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.wishlistProducts || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                skus,
            });
        }

        return [];
    },

    async addWishlistProducts(skus = []) {
        const response = isAdded => ({ isAdded });
        const ERROR_TAGS = getErrorTags('addWishlistProducts');

        try {
            if (!skus.length) {
                return response(false);
            }

            const { data, error, status } = await megatronClient.post(
                'v1/addWishlistProducts',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        skus,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(!data.addWishlistProducts.hasErrors);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                skus,
            });
        }

        return response(false);
    },

    async deleteWishlistProducts(skus = []) {
        const response = isDeleted => ({ isDeleted });
        const ERROR_TAGS = getErrorTags('deleteWishlistProducts');

        try {
            if (!skus.length) {
                return response(false);
            }

            const { data, error, status } = await megatronClient.post(
                'v1/deleteWishlistProducts',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        skus,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(!data.deleteWishlistProducts.hasErrors);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                skus,
            });
        }

        return response(false);
    },

    async getCustomerWishlist(pageSize, currentPage = 1) {
        const response = customerWishlist => ({ customerWishlist });
        const ERROR_TAGS = getErrorTags('getCustomerWishlist');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/customerWishlist',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        pageSize,
                        currentPage,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(
                    data?.extCustomer?.paginatedWishlist?.items || []
                );
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                pageSize,
                currentPage,
            });
        }

        return response([]);
    },

    async getCustomerWishlistCount() {
        const ERROR_TAGS = getErrorTags('getCustomerWishlistCount');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/customerWishlistCount',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.extCustomer?.paginatedWishlist?.items_count || 0;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return 0;
    },

    async initiateResetPassword(email) {
        const ERROR_TAGS = getErrorTags('initiateResetPassword');

        let url = 'V1/customers/password';

        const storeCode = $store.getters['config/storeCode'];

        if (storeCode !== 'default') {
            url = `${storeCode}/${url}`;
        }

        try {
            const { data, error, status } = await megatronClient.put(url, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST,
                payload: {
                    email,
                    template: 'email_reset',
                },
            });

            if (!error || status === NOT_FOUND_HTTP_CODE) {
                return typeof data === 'boolean' ? data : false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                email,
            });
        }

        return false;
    },

    async validateResetPasswordLinkToken(customerId, token) {
        const ERROR_TAGS = getErrorTags('validateResetPasswordToken');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/validateResetPasswordLinkToken',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        customerId,
                        token,
                    },
                }
            );

            if (!error) {
                return data?.validateResetPasswordLinkToken?.email || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerId,
                token,
            });
        }

        return null;
    },

    async setNewPassword(resetToken, newPassword) {
        const ERROR_TAGS = getErrorTags('setNewPassword');

        let url = 'V1/customers/resetPassword';

        const storeCode = $store.getters['config/storeCode'];

        if (storeCode !== 'default') {
            url = `${storeCode}/${url}`;
        }

        try {
            const { data, error, status } = await megatronClient.post(url, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST,
                payload: {
                    email: '',
                    resetToken,
                    newPassword,
                },
            });

            if (!error) {
                return typeof data === 'boolean' ? data : false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return false;
    },

    async updateCustomer(customerInput) {
        const response = (customer, errorCode, errorMessages) => ({
            customer: customer || null,
            errorCode: errorCode || null,
            errorMessages: errorMessages || [],
        });
        const ERROR_TAGS = getErrorTags('updateCustomer');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/updateCustomer',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        customerInput,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(data?.extUpdateCustomer?.customer);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            if (![UNAUTHENTICATED].includes(firstErrorCode)) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        customerInput,
                        data,
                        error,
                        status,
                    }
                );
            }

            const errorMessages = error.data.errors.map(
                ({ message }) => message
            );

            return response(null, firstErrorCode, errorMessages);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerInput,
            });

            return response();
        }
    },

    async changeCustomerPassword(currentPassword, newPassword) {
        const response = (result, errorCode) => ({
            result: result || false,
            errorCode: errorCode || null,
        });
        const ERROR_TAGS = getErrorTags('changeCustomerPassword');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/changeCustomerPassword',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        currentPassword,
                        newPassword,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(true);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            if (![UNAUTHENTICATED].includes(firstErrorCode)) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        data,
                        error,
                        status,
                    }
                );
            }

            return response(false, firstErrorCode);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return response(false);
        }
    },

    async getCustomerOrdersExtData(currentPage, pageSize, field, order) {
        const response = (hasError, orders, ordersCount) => ({
            hasError: hasError || false,
            orders: orders || [],
            ordersCount: ordersCount || 0,
        });
        const ERROR_TAGS = getErrorTags('getCustomerOrdersExt');
        const payload = { currentPage, pageSize, field, order };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getCustomerOrdersExt',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                const { items, itemsCount } = data?.customerOrdersExt || {};

                return response(false, items, itemsCount);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                payload,
            });
        }

        return response(true);
    },

    async getCustomerOrderExtData(orderNumber) {
        const ERROR_TAGS = getErrorTags('getCustomerOrderExtData');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getCustomerOrderExtData',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        orderNumber,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.customerOrderExt || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    orderNumber,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                orderNumber,
            });
        }

        return null;
    },

    async createCustomerAddress(customerAddressInput = {}) {
        const ERROR_TAGS = getErrorTags('createCustomerAddress');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/createCustomerAddress',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        customerAddressInput,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.createCustomerAddress || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    customerAddressInput,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerAddressInput,
            });
        }

        return null;
    },

    async deleteCustomerAddress(addressId) {
        const ERROR_TAGS = getErrorTags('deleteCustomerAddress');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/deleteCustomerAddress',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        addressId,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    addressId,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                addressId,
            });
        }

        return false;
    },

    async updateCustomerAddress(customerAddressInput, addressId) {
        const ERROR_TAGS = getErrorTags('updateCustomerAddress');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/updateCustomerAddress',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        customerAddressInput,
                        addressId,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    customerAddressInput,
                    addressId,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerAddressInput,
                addressId,
            });
        }

        return false;
    },

    async getLatestUnpaidOrders() {
        const ERROR_TAGS = getErrorTags('getLatestUnpaidOrders');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getLatestUnpaidOrders',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.latestUnpaidOrders?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return [];
    },

    async getCustomerOrderItemsToReview(currentPage, pageSize) {
        const ERROR_TAGS = getErrorTags('getCustomerOrderItemsToReview');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getCustomerOrderItemsToReview',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        pageSize,
                        currentPage,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.customerOrderItemsToReview || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    pageSize,
                    currentPage,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                pageSize,
                currentPage,
            });
        }

        return null;
    },

    async getCustomerOrderItemsReviewed(currentPage, pageSize) {
        const ERROR_TAGS = getErrorTags('getCustomerOrderItemsReviewed');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getCustomerOrderItemsReviewed',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        pageSize,
                        currentPage,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.customerOrderItemsReviewed || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    pageSize,
                    currentPage,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                pageSize,
                currentPage,
            });
        }

        return null;
    },

    async getCustomerIsSubscribedToNewsletter() {
        const ERROR_TAGS = getErrorTags('getCustomerIsSubscribedToNewsletter');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/customerIsSubscribedToNewsletter',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.customerIsSubscribedToNewsletter || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return false;
        }
    },

    async updateCustomerNonSensitiveData(payload) {
        const ERROR_TAGS = getErrorTags('updateCustomerNonSensitiveData');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/updateCustomerNonSensitive',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data.updateCustomerNonSensitive?.customer || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },
});
