import { MODIVO_FOOTER_UTM } from '@configs/modivo';

export const getModivoUrlByDomain = modivoDomain =>
    modivoDomain ? `https://${modivoDomain}` : '';

export const getFooterModivoUrl = modivoDomain => {
    const modivoUrl = getModivoUrlByDomain(modivoDomain);

    return modivoUrl ? `${modivoUrl}?${MODIVO_FOOTER_UTM}` : '';
};
