<template>
    <div class="footer-simple">
        <ContainerContent>
            <StaticHtmlLinkHandler>
                <StaticBlock
                    v-if="footerSimpleStaticBlocks.footerSimpleTop"
                    :content="
                        footerSimpleStaticBlocks.footerSimpleTop.content || ''
                    "
                />
            </StaticHtmlLinkHandler>
        </ContainerContent>
    </div>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';
import { createNamespacedHelpers } from 'vuex';
import { PSB_FOOTER_SIMPLE_TOP } from '@configs/footer';
import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'FooterSimple',

    components: {
        StaticHtmlLinkHandler,
        ContainerContent,
        StaticBlock,
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),

        footerSimpleStaticBlocks() {
            return {
                footerSimpleTop: this.staticBlockById(PSB_FOOTER_SIMPLE_TOP),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-simple {
    @apply bg-gray8 border-solid border-border border-b-1 border-t-1 w-full py-3 px-1;

    &:deep() {
        .link-list {
            @apply flex flex-wrap justify-center;
        }

        .link {
            @apply leading-s text-s inline-block mb-3 text-text mx-12p my-2;

            &:hover {
                @apply underline;
            }
        }
    }
}
</style>
