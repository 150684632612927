export default {
    getProductReviewTotalCount: state => sku => {
        return state.productsReviewsTotalCount[sku];
    },
    getProductsReviewTotalCountBySkus: (_, getters) => skus => {
        const totalCounts = {};

        skus.forEach(sku => {
            totalCounts[sku] = getters.getProductReviewTotalCount(sku);
        });

        return totalCounts;
    },
    getProductReviewAverageRating: state => {
        return state.productsReviewsAverageRating;
    },
};
