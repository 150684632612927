import NotFoundException from '@exceptions/NotFoundException';

export default class ProductNotFoundException extends NotFoundException {
    name = 'ProductNotFoundException';

    constructor(urlKey) {
        super(`Product with urlKey ${urlKey} not found`);

        this.urlKey = urlKey;
    }
}
