import { LOCALE_WITH_EUR_PRICE } from '@configs/duty';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

export const getSelectLocales = (locale, showDutyInformation = false) => {
    const selectLocales = [DEFAULT_LOCALE];

    if (showDutyInformation) {
        selectLocales.push(LOCALE_WITH_EUR_PRICE);
    }

    return selectLocales;
};

export const getLocaleISOCode = (locales, localeCode) => {
    const localeObj = locales.find(
        localesItem => localesItem.code === localeCode
    );

    return localeObj?.iso;
};

export const convertIsoToLocale = locale => locale?.replace('-', '_');

export const convertLocaleToIso = locale => locale?.replace('_', '-');

export const getCountryCodeFromLocale = locale => {
    return (!!locale?.length && locale.slice(3, 5).toLowerCase()) || '';
};
