export const THEME_DARK = 'dark';
export const THEME_GRAY = 'gray';
export const THEME_LIGHT_GRAY = 'light-gray';

export const THEMES = {
    THEME_DARK,
    THEME_GRAY,
    THEME_LIGHT_GRAY,
};

export const POSITION_TOP = 'top';
export const POSITION_BOTTOM = 'bottom';
export const POSITION_LEFT = 'left';
export const POSITION_RIGHT = 'right';
export const POSITION_BOTTOM_LEFT = 'bottom-left';
export const POSITION_BOTTOM_RIGHT = 'bottom-right';
export const POSITION_TOP_LEFT = 'top-left';
export const POSITION_TOP_RIGHT = 'top-right';

export const POSITIONS = {
    POSITION_TOP,
    POSITION_BOTTOM,
    POSITION_LEFT,
    POSITION_RIGHT,
    POSITION_BOTTOM_LEFT,
    POSITION_BOTTOM_RIGHT,
    POSITION_TOP_LEFT,
    POSITION_TOP_RIGHT,
};
