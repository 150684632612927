import { PLN } from '@localeConfig/currency-units';

const getFaqBlocksIds = () =>
    Array.from(
        { length: 15 },
        (_, index) => `psb_invite_friend_faq_${index + 1}`
    );

export const INVITE_FRIEND_FAQ_BLOCKS_IDS = getFaqBlocksIds();

export const BENEFITS_MAP_BY_STORE_CODE = {
    default: {
        value: 100,
        currency: PLN.symbol,
    },
};

export const MINIMUM_ORDER_AMOUNT_REQUIRED_BY_STORE_CODE = {
    default: {
        value: 400,
        currency: PLN.symbol,
    },
};

const COUPON_FOR_REGISTRATION = 'voucher_for_registration';
const COUPON_FOR_A_FRIENDS_PURCHASE = 'voucher_for_a_friends_purchase';

export const COUPONS_NAMES_BY_TYPE = {
    [COUPON_FOR_REGISTRATION]: 'Discount for registration',
    [COUPON_FOR_A_FRIENDS_PURCHASE]: 'Discount for a friend purchase',
};

export const DAYS_TO_EXPIRE_COUPON = 60;
export const STATUS_EXPIRED = 'expired';
export const STATUS_PENDING = 'pending';
const STATUS_ACTIVE = 'active';
const STATUS_USED = 'used';

export const INVITE_FRIEND_FAQ_SECTION_ID = 'invite-friend-faq-section';

export const COUPONS_STATUSES = {
    [STATUS_EXPIRED]: 'Expired',
    [STATUS_ACTIVE]: 'Active',
    [STATUS_PENDING]: 'Pending',
    [STATUS_USED]: 'Used',
};

export const PROVIDERS_FOR_SHARE_INVITE_LINK = {
    MESSENGER: 'messenger',
    WHATSAPP: 'whatsapp',
    EMAIL: 'email',
};
