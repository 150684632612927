import { types } from './mutations';

const actions = {
    setUrlKey({ commit }, urlKey) {
        commit(types.SET_URL_KEY, urlKey);
    },

    setIsProductReviewsVisible({ commit }, isLoaded) {
        commit(types.SET_IS_PRODUCT_REVIEWS_VISIBLE, isLoaded);
    },

    setProductsReviewsTotalCount({ commit }, count) {
        commit(types.SET_PRODUCTS_REVIEWS_TOTAL_COUNT, count);
    },

    setProductsReviewsAverageRating({ commit }, rating) {
        commit(types.SET_PRODUCTS_REVIEWS_AVERAGE_RATING, rating);
    },

    setModalAddProductReviewOpened({ commit }, isModalAddProductReviewOpened) {
        commit(
            types.SET_MODAL_ADD_PRODUCT_REVIEW_OPENED,
            isModalAddProductReviewOpened
        );
    },

    setRecommendedProductsByKey({ state, commit }, { key, products }) {
        if (state.recommendedProducts[key] || !products) {
            return false;
        }

        commit(types.SET_RECOMMENDED_PRODUCTS_BY_KEY, { key, products });

        return true;
    },

    removeRecommendedProductsByKey({ commit }, key) {
        commit(types.REMOVE_RECOMMENDED_PRODUCTS_BY_KEY, key);
    },

    setListingCategoryId({ commit }, listingCategory) {
        commit(types.SET_LISTING_CATEGORY_ID, listingCategory);
    },

    setColorVariants({ commit }, colorVariants) {
        commit(types.SET_COLOR_VARIANTS, colorVariants);
    },
};

export default actions;
