<template>
    <ContainerContent class="footer-order-question">
        <BaseParagraph class="paragraph">
            {{ $t('Do you have a question about your order?') }}
        </BaseParagraph>
        <BaseLinkStatic
            v-if="isHelpCenterLinkVisible"
            :path="$tPath('help-center')"
            class="link"
            target="_blank"
        >
            <template #icon>
                <SvgIcon width="24px" height="24px">
                    <QuestionMarkCircled />
                </SvgIcon>
            </template>
            {{ $t('Help center') }}
        </BaseLinkStatic>
        <BaseLinkStatic :path="$tPath('contact')" class="link" target="_blank">
            <template #icon>
                <SvgIcon width="24px" height="24px">
                    <HelpCenter />
                </SvgIcon>
            </template>
            {{ $t('Contact') }}
        </BaseLinkStatic>
    </ContainerContent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { LOCALES_WITH_HIDDEN_HELP_CENTER_LINK } from '@configs/checkout';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import QuestionMarkCircled from '@static/icons/24px/question-mark-circled.svg?inline';
import HelpCenter from '@static/icons/24px/help-center.svg?inline';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'FooterOrderQuestion',

    components: {
        ContainerContent,
        SvgIcon,
        BaseLinkStatic,
        QuestionMarkCircled,
        HelpCenter,
        BaseParagraph,
    },

    computed: {
        ...mapConfigGetters(['locale']),

        isHelpCenterLinkVisible() {
            return !LOCALES_WITH_HIDDEN_HELP_CENTER_LINK.includes(this.locale);
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-order-question {
    @apply py-4 flex items-center flex-wrap justify-center;

    .paragraph {
        @apply px-12p text-r leading-r mb-2 w-full text-center text-text2;
    }

    .link {
        @apply leading-s text-s px-12p h-7 flex items-center;
    }

    @screen md {
        .paragraph {
            @apply mb-0 w-auto;
        }
    }

    @screen lg {
        @apply py-5;
    }
}
</style>
