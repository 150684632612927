import {
    FIRSTNAME,
    LASTNAME,
    COMPANY,
    STREET,
    HOUSE_NUMBER,
    CITY,
    POSTCODE,
    COUNTRY_CODE,
    TELEPHONE,
    VAT_ID,
} from '@configs/mage-customer-attributes';

export const COMPLEX_PASSWORD_RULE_TYPE = 'complexPassword';
export const PHONE_NUMBER_RULE_TYPE = 'phoneNumber';
export const SOFT_PHONE_NUMBER_RULE_TYPE = 'softPhoneNumber';
export const IBAN_NUMBER_RULE_TYPE = 'ibanNumber';
export const POSTCODE_RULE_TYPE = 'postcode';
export const NIP_RULE_TYPE = 'nip';
export const NIP_CONTROL_SUM_RULE_TYPE = 'nipControlSum';
export const REQUIRED_RULE_TYPE = 'required';
export const EMAIL_RULE_TYPE = 'email';
export const CONFIRMED_RULE_TYPE = 'confirmed';
export const MAX_TYPE = 'max';
export const MIN_TYPE = 'min';
export const NUMERIC_TYPE = 'numeric';
export const EMAIL_CHARACTERS_TYPE = 'emailCharacters';
export const NAME_CHARACTERS_TYPE = 'nameCharacters';

export const REQUIRED_RULE = { [REQUIRED_RULE_TYPE]: true };
export const EMAIL_RULE = { [EMAIL_RULE_TYPE]: true };
export const CONFIRMED_RULE = { [CONFIRMED_RULE_TYPE]: true };
export const NUMERIC_RULE = { [NUMERIC_TYPE]: true };
export const EMAIL_CHARACTERS_RULE = { [EMAIL_CHARACTERS_TYPE]: true };
export const NAME_CHARACTERS_RULE = { [NAME_CHARACTERS_TYPE]: true };

export const MIN_2_RULE = { [MIN_TYPE]: 2 };
export const MIN_4_RULE = { [MIN_TYPE]: 4 };
export const MAX_6_RULE = { [MAX_TYPE]: 6 };
export const MAX_10_RULE = { [MAX_TYPE]: 10 };
export const MAX_20_RULE = { [MAX_TYPE]: 20 };
export const MAX_30_RULE = { [MAX_TYPE]: 30 };
export const MAX_35_RULE = { [MAX_TYPE]: 35 };
export const MAX_50_RULE = { [MAX_TYPE]: 50 };
export const MAX_60_RULE = { [MAX_TYPE]: 60 };
export const MAX_100_RULE = { [MAX_TYPE]: 100 };
export const MAX_255_RULE = { [MAX_TYPE]: 255 };

export const EMAIL_VALIDATION_RULES = {
    ...REQUIRED_RULE,
    ...MAX_100_RULE,
    ...EMAIL_RULE,
    ...EMAIL_CHARACTERS_RULE,
};
export const COMPANY_VALIDATION_RULES = {
    ...REQUIRED_RULE,
    ...MAX_35_RULE,
};
export const FIRST_NAME_VALIDATION_RULES = {
    ...NAME_CHARACTERS_RULE,
    ...MIN_2_RULE,
    ...REQUIRED_RULE,
    ...MAX_30_RULE,
};
export const LAST_NAME_VALIDATION_RULES = {
    ...NAME_CHARACTERS_RULE,
    ...MIN_2_RULE,
    ...REQUIRED_RULE,
    ...MAX_60_RULE,
};
export const COMPANY_FIRST_NAME_VALIDATION_RULES = {
    ...NAME_CHARACTERS_RULE,
    ...MIN_2_RULE,
    ...MAX_30_RULE,
};
export const COMPANY_LAST_NAME_VALIDATION_RULES = {
    ...NAME_CHARACTERS_RULE,
    ...MIN_2_RULE,
    ...MAX_60_RULE,
};
export const TELEPHONE_VALIDATION_RULES = {
    [PHONE_NUMBER_RULE_TYPE]: true,
    ...REQUIRED_RULE,
};
export const SOFT_TELEPHONE_VALIDATION_RULES = {
    [SOFT_PHONE_NUMBER_RULE_TYPE]: true,
    ...REQUIRED_RULE,
};
export const STREET_VALIDATION_RULES = {
    ...REQUIRED_RULE,
    ...MAX_30_RULE,
};
export const HOUSE_VALIDATION_RULES = {
    ...REQUIRED_RULE,
    ...MAX_10_RULE,
};
export const POSTCODE_VALIDATION_RULES = {
    [POSTCODE_RULE_TYPE]: true,
    ...REQUIRED_RULE,
    ...MAX_10_RULE,
};
export const CITY_VALIDATION_RULES = {
    ...REQUIRED_RULE,
    ...MAX_100_RULE,
};
export const VAT_ID_VALIDATION_RULES = {
    [NIP_RULE_TYPE]: true,
    [NIP_CONTROL_SUM_RULE_TYPE]: true,
    ...REQUIRED_RULE,
    ...MAX_20_RULE,
};
export const COUNTRY_CODE_VALIDATION_RULES = {
    ...REQUIRED_RULE,
};

export const PRIVATE_ADDRESS_VALIDATION_RULES = {
    [COUNTRY_CODE]: COUNTRY_CODE_VALIDATION_RULES,
    [STREET]: STREET_VALIDATION_RULES,
    [HOUSE_NUMBER]: HOUSE_VALIDATION_RULES,
    [TELEPHONE]: TELEPHONE_VALIDATION_RULES,
    [POSTCODE]: POSTCODE_VALIDATION_RULES,
    [CITY]: CITY_VALIDATION_RULES,
    [FIRSTNAME]: FIRST_NAME_VALIDATION_RULES,
    [LASTNAME]: LAST_NAME_VALIDATION_RULES,
};

export const COMPANY_ADDRESS_VALIDATION_RULES = {
    ...PRIVATE_ADDRESS_VALIDATION_RULES,
    [COMPANY]: COMPANY_VALIDATION_RULES,
};

export const COMPANY_INVOICE_ADDRESS_VALIDATION_RULES = {
    ...COMPANY_ADDRESS_VALIDATION_RULES,
    [VAT_ID]: VAT_ID_VALIDATION_RULES,
};

export const ESIZEME_PIN_RULE = {
    ...MIN_4_RULE,
    ...MAX_6_RULE,
    ...NUMERIC_RULE,
    ...REQUIRED_RULE,
};
