export const FACEBOOK_API_SCRIPT =
    'https://connect.facebook.net/%languageCode%/sdk.js';
export const FACEBOOK_API_VERSION = 'v17.0';
export const FACEBOOK_APP_ID = '299785941673256';
export const FACEBOOK_LOGIN_STATUS_CONNECTED = 'connected';

export const FACEBOOK_PARAMS_INIT = {
    appId: FACEBOOK_APP_ID,
    status: false,
    xfbml: false,
    version: FACEBOOK_API_VERSION,
};

export const RE_REQUEST_AUTH_TYPE = 'rerequest';

export const EMAIL_SCOPE = 'email';
const PUBLIC_PROFILE_SCOPE = 'public_profile';

export const STATUS_GRANTED = 'granted';

export const FACEBOOK_PARAMS_RE_REQUEST = {
    auth_type: RE_REQUEST_AUTH_TYPE,
    scope: EMAIL_SCOPE,
    return_scopes: true,
};

const SCOPE = [EMAIL_SCOPE, PUBLIC_PROFILE_SCOPE].join(',');

export const FACEBOOK_PARAMS_LOGIN = {
    scope: SCOPE,
    return_scopes: true,
};

export const FACEBOOK_LANGUAGE_DEFAULT = 'en_US';
