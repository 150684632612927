export const types = {
    SET_ESIZEME_STORE_CONFIG: 'SET_ESIZEME_STORE_CONFIG',
    SET_GUEST_SCANS: 'SET_GUEST_SCANS',
    SET_CUSTOMER_SCANS: 'SET_CUSTOMER_SCANS',
    SET_SELECTED_GUEST_SCAN: 'SET_SELECTED_GUEST_SCAN',
    SET_SELECTED_CUSTOMER_SCAN: 'SET_SELECTED_CUSTOMER_SCAN',
    SET_ARE_CUSTOMER_SCANS_LOADING: 'SET_ARE_CUSTOMER_SCANS_LOADING',
};

export default {
    [types.SET_ESIZEME_STORE_CONFIG](state, storeConfig) {
        state.esizemeStoreConfig = storeConfig;
    },

    [types.SET_GUEST_SCANS](state, scans) {
        state.guestScans = scans;
    },

    [types.SET_CUSTOMER_SCANS](state, scans) {
        state.customerScans = scans;
    },

    [types.SET_SELECTED_GUEST_SCAN](state, scan) {
        state.selectedGuestScan = scan;
    },

    [types.SET_SELECTED_CUSTOMER_SCAN](state, scan) {
        state.selectedCustomerScan = scan;
    },

    [types.SET_ARE_CUSTOMER_SCANS_LOADING](state, areCustomerScansLoading) {
        state.areCustomerScansLoading = areCustomerScansLoading;
    },
};
