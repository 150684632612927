export const bg_BG = 'bg_BG';
export const cs_CZ = 'cs_CZ';
export const de_AT = 'de_AT';
export const de_CH = 'de_CH';
export const de_DE = 'de_DE';
export const el_GR = 'el_GR';
export const en_GB = 'en_GB';
export const es_ES = 'es_ES';
export const fr_CH = 'fr_CH';
export const fr_FR = 'fr_FR';
export const hr_HR = 'hr_HR';
export const hu_HU = 'hu_HU';
export const it_CH = 'it_CH';
export const it_IT = 'it_IT';
export const lt_LT = 'lt_LT';
export const lv_LV = 'lv_LV';
export const pl_PL = 'pl_PL';
export const ro_RO = 'ro_RO';
export const ru_RU = 'ru_RU';
export const ru_UA = 'ru_UA';
export const sk_SK = 'sk_SK';
export const sl_SI = 'sl_SI';
export const sv_SE = 'sv_SE';
export const uk_UA = 'uk_UA';

export const LOCALES = [
    bg_BG,
    cs_CZ,
    de_AT,
    de_CH,
    de_DE,
    el_GR,
    en_GB,
    es_ES,
    fr_CH,
    fr_FR,
    hr_HR,
    hu_HU,
    it_CH,
    it_IT,
    lt_LT,
    lv_LV,
    pl_PL,
    ro_RO,
    ru_RU,
    ru_UA,
    sk_SK,
    sl_SI,
    sv_SE,
    uk_UA,
];
