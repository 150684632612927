import { MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME } from '@configs/megatron-client';

import normalizeProduct from '@normalizers/models/product';

const normalizer = async (response, config, helpers) => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    response.data.products = await Promise.all(
        data.products.map(productItem =>
            normalizeProduct(
                { ...productItem, attributes: data.attributes },
                helpers,
                config
            )
        )
    );

    return response;
};

export const getSponsoredProducts = {
    operationName: MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME,
    normalizer,
};
