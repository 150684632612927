import {
    ERROR_PAGE_TAG_NAME,
    ERROR_CORRELATION_ID_TAG_NAME,
} from '@types/Errors';

export default async function setErrorHandlerPageContext(
    $errorHandler,
    routeName,
    correlationId
) {
    await $errorHandler.setGlobalContext({
        [ERROR_PAGE_TAG_NAME]: routeName,
        [ERROR_CORRELATION_ID_TAG_NAME]: correlationId,
    });
}
