

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => {
                        try {
                            document.querySelector(':focus-visible');

                            return true;
                        } catch {
                            return false;
                        }
                    };

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "focus-visible"
        ], function (require) {
            const polyfill = require("focus-visible");

            resolve();
        });
    });
}