import { isObject } from '@modules/async-services/src/assets/object';

export const validateServicesConfig = serviceConfig => {
    if (!isObject(serviceConfig)) {
        return false;
    }

    const values = Object.values(serviceConfig);

    return (
        !!values.length &&
        values.every(value => {
            return typeof value === 'function';
        })
    );
};
