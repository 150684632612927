import bgBGEob from '@static/logo/bg_BG-eobuwie-logo.svg?inline';
import chCHEob from '@static/logo/ch_CH-eobuwie-logo.svg?inline';
import csCZEob from '@static/logo/cs_CZ-eobuwie-logo.svg?inline';
import deATEob from '@static/logo/de_AT-eobuwie-logo.svg?inline';
import deDEEob from '@static/logo/de_DE-eobuwie-logo.svg?inline';
import enGBEob from '@static/logo/en_GB-eobuwie-logo.svg?inline';
import elGREob from '@static/logo/el_GR-eobuwie-logo.svg?inline';
import esESEob from '@static/logo/es_ES-eobuwie-logo.svg?inline';
import frFREob from '@static/logo/fr_FR-eobuwie-logo.svg?inline';
import hrHREob from '@static/logo/hr_HR-eobuwie-logo.svg?inline';
import huHUEob from '@static/logo/hu_HU-eobuwie-logo.svg?inline';
import itITEob from '@static/logo/it_IT-eobuwie-logo.svg?inline';
import ltLTEob from '@static/logo/lt_LT-eobuwie-logo.svg?inline';
import lvLVEob from '@static/logo/lv_LV-eobuwie-logo.svg?inline';
import plPLEob from '@static/logo/pl_PL-eobuwie-logo.svg?inline';
import roROEob from '@static/logo/ro_RO-eobuwie-logo.svg?inline';
import ruUAEob from '@static/logo/ru_UA-eobuwie-logo.svg?inline';
import skSKEob from '@static/logo/sk_SK-eobuwie-logo.svg?inline';
import slSIEob from '@static/logo/sl_SI-eobuwie-logo.svg?inline';
import svSEEob from '@static/logo/sv_SE-eobuwie-logo.svg?inline';
import ukUAEob from '@static/logo/uk_UA-eobuwie-logo.svg?inline';

export const bg_BG_LOGO = 'bgBGEob';
export const ch_CH_LOGO = 'chCHEob';
export const cs_CZ_LOGO = 'csCZEob';
export const de_AT_LOGO = 'deATEob';
export const de_DE_LOGO = 'deDEEob';
export const en_GB_LOGO = 'enGBEob';
export const el_GR_LOGO = 'elGREob';
export const es_ES_LOGO = 'esESEob';
export const fr_FR_LOGO = 'frFREob';
export const hr_HR_LOGO = 'hrHREob';
export const hu_HU_LOGO = 'huHUEob';
export const it_IT_LOGO = 'itITEob';
export const lt_LT_LOGO = 'ltLTEob';
export const lv_LV_LOGO = 'lvLVEob';
export const pl_PL_LOGO = 'plPLEob';
export const ro_RO_LOGO = 'roROEob';
export const ru_UA_LOGO = 'ruUAEob';
export const sk_SK_LOGO = 'skSKEob';
export const sl_SI_LOGO = 'slSIEob';
export const sv_SE_LOGO = 'svSEEob';
export const uk_UA_LOGO = 'ukUAEob';

export const LOGOS = {
    [bg_BG_LOGO]: bgBGEob,
    [ch_CH_LOGO]: chCHEob,
    [cs_CZ_LOGO]: csCZEob,
    [de_AT_LOGO]: deATEob,
    [de_DE_LOGO]: deDEEob,
    [en_GB_LOGO]: enGBEob,
    [el_GR_LOGO]: elGREob,
    [es_ES_LOGO]: esESEob,
    [fr_FR_LOGO]: frFREob,
    [hr_HR_LOGO]: hrHREob,
    [hu_HU_LOGO]: huHUEob,
    [it_IT_LOGO]: itITEob,
    [lt_LT_LOGO]: ltLTEob,
    [lv_LV_LOGO]: lvLVEob,
    [pl_PL_LOGO]: plPLEob,
    [ro_RO_LOGO]: roROEob,
    [ru_UA_LOGO]: ruUAEob,
    [sk_SK_LOGO]: skSKEob,
    [sl_SI_LOGO]: slSIEob,
    [sv_SE_LOGO]: svSEEob,
    [uk_UA_LOGO]: ukUAEob,
};

export const DEFAULT_LOGO = pl_PL_LOGO;
