import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';

export const areProductsEmpty = ({ error, products, total }) => {
    if (products?.length === 0 && total !== 0) {
        return error({
            statusCode: NOT_FOUND_HTTP_CODE,
            message: 'Page was not found',
        });
    }
};
