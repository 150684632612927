export const FIRST_AND_LAST_NAME_RULE_NAME = 'rmaFirstAndLastName';

const FIRST_AND_LAST_NAME_VALIDATION_RULE = {
    pattern: /^((?!\d).)*$/,
    length: 65,
};

export const validate = value => {
    const { pattern, length } = FIRST_AND_LAST_NAME_VALIDATION_RULE;

    if (value.length > length) {
        return false;
    }

    return pattern.test(value);
};

export const getFirstAndLastNameRuleName = i18n => {
    const { length } = FIRST_AND_LAST_NAME_VALIDATION_RULE;

    return {
        validate,
        message: (_, values) => {
            const { _value_ } = values;

            if (_value_.length > length) {
                return i18n.t('__validations.max', { length });
            }

            return i18n.t('__validations.__rma.noDigitsInName');
        },
    };
};

export default i18n => ({
    name: FIRST_AND_LAST_NAME_RULE_NAME,
    validation: getFirstAndLastNameRuleName(i18n),
});
