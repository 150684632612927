import {
    STORE_CODES_WHITELIST,
    LOYALTY_FRIENDS_AND_FAMILY_TEST_COOKIE_NAME,
} from '@loyalty-club/config';

export const isLoyaltyClubEnabled = ($abTests, storeCode, $cookies) => {
    if (!STORE_CODES_WHITELIST.includes(storeCode)) {
        return false;
    }

    if ($cookies?.get(LOYALTY_FRIENDS_AND_FAMILY_TEST_COOKIE_NAME)) {
        return true;
    }

    try {
        const variant = $abTests.getVariant('dev_eob_loyalty_club');

        const allowedStoreCodes = variant.split('_');

        return allowedStoreCodes.includes(storeCode);
    } catch {
        return false;
    }
};
