import { types } from './mutations';

const actions = {
    closeAvailabilityNotificationModal({ commit }) {
        commit(types.SET_AVAILABILITY_NOTIFICATION_MODAL, false);
    },

    openAvailabilityNotificationModal({ commit }) {
        commit(types.SET_AVAILABILITY_NOTIFICATION_MODAL, true);
    },

    openAddProductToCartErrorModal({ commit }, cartItemData) {
        commit(types.SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL, cartItemData);
    },

    resetAddProductToCartErrorModal({ commit }) {
        commit(types.SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL);
    },

    setShouldOpenSniperLinkModal({ commit }, shouldOpenSniperLinkModal) {
        commit(
            types.SET_SHOULD_OPEN_SNIPER_LINK_MODAL,
            shouldOpenSniperLinkModal
        );
    },

    setIsSizeModalOpen({ commit }, isSizeModalOpen) {
        commit(types.SET_IS_SIZE_MODAL_OPEN, isSizeModalOpen);
    },
};

export default actions;
