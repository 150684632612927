import { NAME as CMS_BLOCKS_SERVICE_NAME } from '@async-services/cmsBlocks/meta';
import { NAME as NEWSLETTER_SERVICE_NAME } from '@async-services/newsletter/meta';
import { NAME as NEWSLETTER_SMS_SERVICE_NAME } from '@async-services/newsletterSms/meta';
import { NAME as SEOMATIC_SERVICE_NAME } from '@async-services/seomatic/meta';
import { NAME as CHECKOUT_SERVICE_NAME } from '@async-services/checkout/meta';

export const ASYNC_NEWSLETTER_SERVICE_NAME = 'newsletter';
export const ASYNC_NEWSLETTER_SMS_SERVICE_NAME = 'newsletter-sms';

export default {
    [NEWSLETTER_SERVICE_NAME]: () =>
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "async-service-newsletter" */ '@async-services/newsletter/service'
        ),
    [NEWSLETTER_SMS_SERVICE_NAME]: () =>
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "async-service-newsletter-sms" */ '@async-services/newsletterSms/service'
        ),
    [CMS_BLOCKS_SERVICE_NAME]: () =>
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "async-service-cms-blocks" */ '@async-services/cmsBlocks/service'
        ),
    [SEOMATIC_SERVICE_NAME]: () =>
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "async-service-seomatic" */ '@async-services/seomatic/service'
        ),
    [CHECKOUT_SERVICE_NAME]: () =>
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "async-service-checkout" */ '@async-services/checkout/service'
        ),
};
