<template>
    <p class="base-paragraph" :class="additionalClasses">
        <slot />
    </p>
</template>

<script>
import { SIZES } from '@types/Paragraph';

import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    name: 'BaseParagraph',

    props: {
        size: {
            type: String,
            default: SIZES.NORMAL,
            validator: checkIfExistsInValuesMap(SIZES, true),
        },
    },

    computed: {
        additionalClasses() {
            const { size } = this;

            if (!size || size !== SIZES.NORMAL) {
                return [size];
            }

            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
.base-paragraph {
    @apply text-left font-book text-s leading-s text-text;

    &.big {
        @apply text-r leading-r;
    }
}
</style>
