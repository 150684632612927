import { getMissingError } from '../assets/errors.js';
import { copyObjectDeep } from '../assets/object.js';
import Filter from './Filter.js';

class FilterNestedMultiselect extends Filter {
  options;
  constructor(filterAttributes, params, addFilter, removeFilter) {
    super(filterAttributes, addFilter, removeFilter);
    const { options } = filterAttributes;
    if (!options) {
      throw getMissingError("options");
    }
    const mutableOptions = copyObjectDeep(options);
    mutableOptions.forEach(FilterNestedMultiselect.recurrentApplyParent());
    this.options = mutableOptions;
    if (Object.keys(params).length) {
      this.selectedFilterParams = copyObjectDeep(params);
      this.initialParamsKeys = Object.keys(params);
    }
  }
  toggleOption(option) {
    if (this.selectedFilterParams[option.code]) {
      this.removeSelectedOption(option);
      return;
    }
    if (option.parentOption && this.selectedFilterParams[option.parentOption.code]) {
      this.removeSelectedOption(option.parentOption);
    }
    if (option.options.length) {
      option.options.forEach(this.removeSelectedOption.bind(this));
    }
    this.addSelectedOption(option);
  }
  updateFilter(filterAttributes) {
    const mutableOptions = copyObjectDeep(filterAttributes.options);
    mutableOptions.forEach(FilterNestedMultiselect.recurrentApplyParent());
    this.options = mutableOptions;
    const getCodeCallback = ({
      code,
      options
    }) => [code, ...options.flatMap(getCodeCallback)];
    const optionsCodes = this.options.flatMap(getCodeCallback);
    this.selectedFilterParams = Object.values(this.selectedFilterParams).reduce(
      (acc, value) => {
        if (optionsCodes.includes(value.code)) {
          acc[value.code] = value;
        }
        return acc;
      },
      {}
    );
  }
  addSelectedOption(option) {
    const { code, url, label, rootOption } = option;
    this.selectedFilterParams = {
      ...this.selectedFilterParams,
      [code]: {
        url,
        code,
        label: `${rootOption?.label}:${label}`
      }
    };
  }
  removeSelectedOption(option) {
    const { [option.code]: _unused, ...rest } = this.selectedFilterParams;
    this.selectedFilterParams = rest;
  }
  static recurrentApplyParent(parentOption = void 0, rootOption = void 0) {
    let rootOptionToPass = rootOption;
    return function(option) {
      if (parentOption && rootOption) {
        option.parentOption = parentOption;
        option.rootOption = rootOption;
      } else {
        rootOptionToPass = {
          code: option.code,
          label: option.label
        };
      }
      option.options.forEach(
        FilterNestedMultiselect.recurrentApplyParent(
          {
            code: option.code,
            label: option.label,
            parentOption: option.parentOption
          },
          rootOptionToPass
        )
      );
    };
  }
}

export { FilterNestedMultiselect as default };
