import { PRODUCT_PAGE_NAME } from '@router/names';

export const checkEarlyReturn = ({ from, store, route }) => {
    const { lastUrl, productsSkus } = store.state.category;

    const shouldReturnEarly =
        from?.name === PRODUCT_PAGE_NAME &&
        productsSkus.length &&
        lastUrl === route.fullPath;

    return { shouldReturnEarly };
};
