import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

export default megatronClient => ({
    async getStoreData(cmsBlocksIds, pageBuilderBlocksIds, rootCategoryId) {
        const { data } = await megatronClient.get('v1/storeData', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                cmsBlocksIds,
                pageBuilderBlocksIds,
                rootCategoryId,
            },
        });

        return data || {};
    },

    async queryParser(url, storeCode) {
        const { data } = await megatronClient.post('v1/queryParser', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                url,
                storeCode,
            },
        });

        return data?.queryParser?.url;
    },
});
