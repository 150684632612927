import {
    ACCOUNT_PAGE,
    CHECKOUT_PAGE,
    CONTACT_PAGE,
    CUSTOMER_REGISTRATION_PAGE,
    CUSTOMER_REGISTRATION_PAGE_FROM_REF_PAGE,
    NEWSLETTER_REGISTRATION,
    PRODUCT_STOCK_ALERT,
    CUSTOMER_REGISTRATION_THROUGH_SOCIAL_POPUP,
    WISHLIST,
    LOYALTY_CLUB,
    CHECKOUT_THANK_YOU_PAGE_REGISTRATION,
    CHECKOUT_THANK_YOU_PAGE_LOYALTY_CLUB,
} from '@configs/gdpr';

import gdprMutations from './mutations';
import gdprActions from './actions';

export const state = () => ({
    [ACCOUNT_PAGE]: [],
    [CHECKOUT_PAGE]: [],
    [CONTACT_PAGE]: [],
    [CUSTOMER_REGISTRATION_PAGE]: [],
    [CUSTOMER_REGISTRATION_PAGE_FROM_REF_PAGE]: [],
    [NEWSLETTER_REGISTRATION]: [],
    [PRODUCT_STOCK_ALERT]: [],
    [CUSTOMER_REGISTRATION_THROUGH_SOCIAL_POPUP]: [],
    [WISHLIST]: [],
    [LOYALTY_CLUB]: [],
    [CHECKOUT_THANK_YOU_PAGE_REGISTRATION]: [],
    [CHECKOUT_THANK_YOU_PAGE_LOYALTY_CLUB]: [],
    isFetching: false,
});

export const mutations = gdprMutations;
export const actions = gdprActions;
