import { EOBUWIE_PL_APP_HOST } from '@configs/app-host';
import { META_ROBOTS_NOINDEX_FOLLOW } from '@configs/seo';

export default (context, inject) => {
    inject('getMetaTagRobotsContent', defaultRobotsContent => {
        let host = '';

        if (process.server) {
            const { req } = context;

            host = req.headers.host;
        } else {
            host = window.location.host;
        }

        if (host.includes(EOBUWIE_PL_APP_HOST)) {
            return META_ROBOTS_NOINDEX_FOLLOW;
        }

        return defaultRobotsContent;
    });
};
