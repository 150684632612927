<template>
    <div class="register-benefits">
        <BaseHeading v-if="withHeading" class="title">
            {{ $t('It is worth having an account at eobuwie') }}
        </BaseHeading>
        <BulletPoint
            v-for="({ icon, text }, index) in benefits"
            :key="`benefit-${index}`"
            :size="bulletSize"
        >
            <template #icon="{ iconSize }">
                <Icon :icon="icon" :width="iconSize" :height="iconSize" />
            </template>

            {{ text }}
        </BulletPoint>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import { Icon } from '@eobuwie-ui/components/Icon/v1';
import {
    BulletPoint,
    BULLET_POINT_SIZES,
} from '@eobuwie-ui/components/BulletPoint/v1';
import { Clock, Bag, Book } from '@eobuwie-ui/icons/v1/other';
import { Gift } from '@eobuwie-ui/icons/v2/other';

import { isLoyaltyClubEnabled } from '@loyalty-club/assets';
import {
    getPossiblePointsByTypes,
    POINTS_TYPES as LOYALTY_POINTS_TYPES,
} from '@loyalty-club/helpers/possible-points';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'RegisterBenefits',

    components: {
        Icon,
        BulletPoint,
        BaseHeading,
    },

    props: {
        withHeading: {
            type: Boolean,
            default: false,
        },

        isLarge: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loyaltyPointsOnStart: null,
        };
    },

    computed: {
        ...mapConfigGetters(['storeCode']),

        bulletSize() {
            const { S, M } = BULLET_POINT_SIZES;

            return this.isLarge ? M : S;
        },

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        benefits() {
            let firstBenefit = this.$t('Check your order history easily');

            if (this.isLoyaltyClubEnabled) {
                firstBenefit = this.$tLoyalty(
                    'Receive {pointsOnStart} points to start in the eobuwie Club',
                    {
                        pointsOnStart: this.loyaltyPointsOnStart,
                    }
                );
            }

            return [
                {
                    text: firstBenefit,
                    icon: this.isLoyaltyClubEnabled ? Gift : Book,
                },
                {
                    text: this.$t('Create an account in just 10 seconds'),
                    icon: Clock,
                },
                {
                    text: this.$t('Shop up to 2x faster'),
                    icon: Bag,
                },
            ];
        },
    },

    async created() {
        if (!this.isLoyaltyClubEnabled) {
            return;
        }

        const { POINTS_FOR_JOINING_PROGRAM } = LOYALTY_POINTS_TYPES;
        const {
            [POINTS_FOR_JOINING_PROGRAM]: pointsOnStart,
        } = await getPossiblePointsByTypes({
            asyncServices: this.$asyncServices,
            storeCode: this.storeCode,
            types: [POINTS_FOR_JOINING_PROGRAM],
        });

        this.loyaltyPointsOnStart = pointsOnStart;
    },
};
</script>

<style scoped lang="scss">
.register-benefits {
    .title {
        @apply mb-3 text-ui-18 block;
    }
}
</style>
