<script>
import { createNamespacedHelpers } from 'vuex';

import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';

import { CONFIG, PAGE } from '@analytics-types/Events';

import { getDeviceType } from '@assets/device';

import { insiderScripts } from '@modules/insider/assets/insider';

import WithCustomer from '@layouts/mixins/WithCustomer';

const codeComponentMap = new Map();

codeComponentMap.set(404, 'ErrorPage404');

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapState: mapCustomerState } = createNamespacedHelpers('customer');

export default {
    name: 'Error',

    components: {
        ErrorPage404: () => import('@organisms/ErrorPage/ErrorPage404'),
        ErrorPageDefault: () => import('@organisms/ErrorPage/ErrorPageDefault'),
    },

    mixins: [WithCustomer],

    layout: () => 'default',

    props: {
        error: {
            type: Object,
            default: () => ({
                statusCode: 404,
            }),
        },
    },

    data() {
        return {
            pageName: 'ErrorPage',
            meta: {
                robots: META_ROBOTS_INDEX_FOLLOW,
            },
        };
    },

    head() {
        const {
            meta: { robots },
        } = this;

        return {
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: this.$getMetaTagRobotsContent(robots),
                },
            ],
        };
    },

    computed: {
        ...mapConfigGetters(['locale', 'currency']),

        ...mapCustomerState(['fetchingCustomerInProgress']),
    },

    watch: {
        fetchingCustomerInProgress: {
            handler(fetchingInProgress) {
                if (!fetchingInProgress) {
                    const errorRoute = {
                        name: this.pageName,
                        fullPath: this.$route.fullPath,
                    };

                    this.$analytics.emit(CONFIG, {
                        pageLocation: document.location.href,
                        pageTitle: this.$metaInfo?.title || document.title,
                        pageReferrer: this.$getPageReferrer(),
                        ignoreReferrer: undefined,
                    });

                    this.$analytics.emit(PAGE, {
                        route: errorRoute,
                        locale: this.locale,
                        currency: this.currency,
                        deviceType: getDeviceType(),
                    });
                }
            },

            immediate: true,
        },
    },

    mounted() {
        insiderScripts();
    },

    render(createElement) {
        const ErrorPage =
            codeComponentMap.get(this.error.statusCode) || 'ErrorPageDefault';

        return createElement(ErrorPage, {}, []);
    },
};
</script>
