import navigationMutations from './mutations';
import navigationActions from './actions';
import navigationGetters from './getters';

export const state = () => ({
    defaultCategoryId: '2',
    categories: [],
    rootCategoryPimSlug: '',
    isDesktopMenuOpen: false,
    isSearchInputActive: false,
});

export const mutations = navigationMutations;
export const actions = navigationActions;
export const getters = navigationGetters;
