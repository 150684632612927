export const manageCacheForBot = (process, req, res) => {
    if (process.server) {
        res.setHeader('Vary', 'googlebot');

        const cachedListing = req.headers.googlebot === 'prerenderer';

        if (cachedListing) {
            res.setHeader('Cache-Control', 'public, max-age=120');
        }

        return cachedListing;
    }

    return false;
};
