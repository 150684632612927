import Exception from '@exceptions/Exception';

export default class CmsPageException extends Exception {
    name = 'CmsPageException';

    constructor(identifier, error) {
        super();
        this.identifier = identifier;
        this.error = error;
        this.message = `Getting CMS page with identifier ${identifier} error.`;
    }
}
