import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.gdpr.${action}`,
});

export default ({ megatronClient, $errorHandler, helpers }) => ({
    async getGdprAgreementsData(viewId, customerEmail) {
        const ERROR_TAGS = getErrorTags('getGdprAgreementsData');

        try {
            const { abTests, $store, $cookies } = helpers;

            const isLoyaltyClub = isLoyaltyClubEnabled(
                abTests,
                $store.getters['config/storeCode'],
                $cookies
            );

            const agreementsFieldName = isLoyaltyClub
                ? 'gdprAgreementsV2'
                : 'gdprAgreements';
            const agreementsForEmailEndpoint = isLoyaltyClub
                ? 'v2/gdprAgreementsForEmail'
                : 'v1/gdprAgreementsForEmail';
            const agreementsEndpoint = isLoyaltyClub
                ? 'v2/gdprAgreements'
                : 'v1/gdprAgreements';

            const { data, error, status } = await megatronClient.get(
                customerEmail ? agreementsForEmailEndpoint : agreementsEndpoint,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        viewId,
                        ...(customerEmail && { customerEmail }),
                    },
                }
            );

            if (!error) {
                return data?.[agreementsFieldName]?.agreements || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    viewId,
                    customerEmail,
                    data,
                    error,
                    status,
                }
            );

            return [];
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                viewId,
                customerEmail,
            });

            return [];
        }
    },

    async createGdprAgreementDecision(agreementsData) {
        const ERROR_TAGS = getErrorTags('createGdprAgreementDecision');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/createGdprAgreementDecision',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: agreementsData,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.createGdprAgreementDecision?.success || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    agreementsData,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                agreementsData,
            });

            return false;
        }
    },

    async getGdprCustomerAgreements() {
        const response = (agreements, error) => ({
            agreements: agreements || [],
            error: error || null,
        });
        const ERROR_TAGS = getErrorTags('getGdprCustomerAgreements');

        try {
            const { abTests, $store, $cookies } = helpers;

            const isLoyaltyClub = isLoyaltyClubEnabled(
                abTests,
                $store.getters['config/storeCode'],
                $cookies
            );

            const gdprFieldName = isLoyaltyClub
                ? 'gdprCustomerAgreementsV2'
                : 'gdprCustomerAgreements';
            const customerAgreementsEndpoint = isLoyaltyClub
                ? 'v2/gdprCustomerAgreements'
                : 'v1/gdprCustomerAgreements';

            const { data, error, status } = await megatronClient.get(
                customerAgreementsEndpoint,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(data?.[gdprFieldName]);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return response([], error);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return response([], err);
        }
    },
});
