var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-top-bar"},[_c('div',{staticClass:"top-bar-content"},[(_vm.isLoyaltyClubEnabled && _vm.loyaltyClubLink.path)?_c('BaseLink',{staticClass:"link loyalty-link",attrs:{"path":_vm.loyaltyClubLink.path,"title":_vm.loyaltyClubLink.title}},[_c('div',{staticClass:"link-content"},[_c('span',[_vm._v(_vm._s(_vm.loyaltyClubLink.content))]),_vm._v(" "),_c('Badge',{staticClass:"link-badge",attrs:{"variant":_vm.BADGE_NEW}},[_vm._v("\n                    "+_vm._s(_vm.$t('New'))+"\n                ")])],1)]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"header-helpful-links"},[_vm._l((_vm.links),function({
                    title,
                    path,
                    content,
                    component,
                    isOpenedInNewTab,
                }){return _c('li',{key:path,staticClass:"link-item"},[_c(component,{tag:"component",staticClass:"link",attrs:{"path":path,"title":title,"target":isOpenedInNewTab ? '_blank' : null}},[_vm._v("\n                    "+_vm._s(content)+"\n                ")])],1)}),_vm._v(" "),_c('LanguageSwitcher',{attrs:{"is-dropdown":true}})],2)],1),_vm._v(" "),_c('Divider',{staticClass:"top-bar-divider"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }