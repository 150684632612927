import Vue from 'vue';
import VueI18n from 'vue-i18n';
import fetch from 'isomorphic-fetch';

import { DEFAULT_LOCALE_CODE } from '@configs/locale';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import en_GB from '@i18n/lang/en_GB';

import { removeHostPrefix } from '@assets/removeHostPrefix';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

Vue.use(VueI18n);

const FALLBACK_LOCALE = {
    code: 'en',
    file: 'en_GB.js',
    iso: 'en-GB',
    domain: null,
};

async function getMessages(locales, locale, translationUrl, appVersion) {
    if (!translationUrl) {
        return {};
    }

    const localeObj = locales.find(localeItem => localeItem.code === locale);

    if (!localeObj) {
        return {};
    }

    const [langCode] = localeObj.file.split('.');
    const response = await fetch(
        `${translationUrl}/${langCode}?v=${appVersion}`
    );
    const result = await response.json();

    return result;
}

function tPathKey(translationKey) {
    return this.t(`__paths.${translationKey}`);
}

function tLabel(translationKey) {
    return this.t(`__labels.${translationKey}`);
}

function tPath(translationKey) {
    return `/${tPathKey.call(this, translationKey)}`;
}

function getTranslationUrl(isServer, endpoint) {
    if (isServer || typeof window === 'undefined') {
        return endpoint;
    }

    return `${window.location.origin}${endpoint}`;
}

const createI18nInstance = async ({
    locale = null,
    locales,
    translationUrl,
    errorHandler,
    appVersion,
}) => {
    const messages = {
        [FALLBACK_LOCALE.code]: en_GB(),
    };

    if (locale !== FALLBACK_LOCALE.code) {
        try {
            const localeMessages = await getMessages(
                locales,
                locale,
                translationUrl,
                appVersion
            );

            if (Object.keys(localeMessages).length) {
                messages[locale] = localeMessages;
            }
        } catch (error) {
            errorHandler.captureDomainError(
                CORE_ERROR_DOMAIN,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'i18n',
                },
                {
                    locale,
                    translationUrl,
                }
            );
        }
    }

    const i18n = new VueI18n({
        locale: locale || FALLBACK_LOCALE.code,
        fallbackLocale: FALLBACK_LOCALE.code,
        messages,
    });

    i18n.locales = locales;
    i18n.tPathKey = tPathKey.bind(i18n);
    i18n.tPath = tPath.bind(i18n);
    i18n.tLabel = tLabel.bind(i18n);

    return i18n;
};

export default async ({ app, env, $config, req }, inject) => {
    const { locales } = $config;

    const host = process.server ? req.headers.host : window.location.host;

    const hostWithoutPrefix = removeHostPrefix(host);

    const domainLocaleConfig = locales.find(config => {
        return (
            config.domain &&
            removeHostPrefix(config.domain) === hostWithoutPrefix
        );
    });

    const locale =
        domainLocaleConfig && domainLocaleConfig.code
            ? domainLocaleConfig.code
            : DEFAULT_LOCALE_CODE;

    const translationUrl = getTranslationUrl(
        process.server,
        $config.translationEndpoint
    );

    const i18n = await createI18nInstance({
        locale,
        locales,
        translationUrl,
        errorHandler: app.$errorHandler,
        appVersion: env.appVersion,
    });

    app.i18n = i18n;
    app.$t = i18n.t.bind(i18n);
    app.$tLabel = i18n.tLabel.bind(i18n);
    app.$tPath = i18n.tPath.bind(i18n);
    app.$tPathKey = i18n.tPathKey.bind(i18n);

    inject('tPath', app.$tPath);
    inject('tLabel', app.$tLabel);
    inject('tPathKey', app.$tPathKey);
};
