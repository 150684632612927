import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME, UNKNOWN_ERROR } from '@types/Errors';

import { getErrorCodes } from '@assets/megatron-client/graphql-errors-helper';

import { PAYMENTS_ERROR_DOMAIN } from '@errors/feature-domain-names';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.checkout.${action}`,
});

export default ({ megatronClient, $errorHandler }) => ({
    async getAvailableCountries() {
        const ERROR_TAGS = getErrorTags('getAvailableCountries');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/availableCountries',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                }
            );

            if (!error) {
                return data?.countries || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return [];
    },

    async placeOrder(cartId) {
        const response = (order, errors) => ({
            order: order || null,
            errors: errors || [],
        });
        const ERROR_TAGS = getErrorTags('placeOrder');
        const payload = { cartId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/placeOrder',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                        shouldAddXInstanceIdHeader: true,
                    },
                }
            );

            if (!error) {
                return response(data?.placeOrder);
            }

            const errors = getErrorCodes(error.data.errors, {
                onlyFirstError: false,
                withCategory: true,
                withMessage: true,
            });

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    errors,
                    data,
                    error,
                    status,
                }
            );

            return response(null, errors);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });

            return response();
        }
    },

    async getClientType(orderHash) {
        const ERROR_TAGS = getErrorTags('getClientType');
        const payload = { orderHash };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getClientType',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.clientType || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getSuccessPageData(orderHash) {
        const ERROR_TAGS = getErrorTags('getSuccessPageData');
        const payload = { orderHash };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getSuccessPageData',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                const { thanksPageData = null, clientType = null } = data || {};

                if (!thanksPageData || !clientType) {
                    return null;
                }

                return {
                    ...thanksPageData,
                    ...clientType,
                };
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getRepaymentOrderDetails(orderHash) {
        const ERROR_TAGS = getErrorTags('getRepaymentOrderDetails');
        const payload = { orderHash };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getRepaymentOrderDetails',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.thanksPageData || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getOrderRepayLink(orderHash, redirectUrl) {
        const ERROR_TAGS = getErrorTags('getOrderRepayLink');
        const payload = { orderHash, redirectUrl };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getOrderRepayLink',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.repayLink || {};
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return {};
    },

    async getRepayLinkByPaymentMethod(orderHash, paymentMethod, redirectUrl) {
        const ERROR_TAGS = getErrorTags('getRepayLinkByPaymentMethod');
        const payload = { orderHash, paymentMethod, redirectUrl };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getRepayLinkByPaymentMethod',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.repayLinkByPaymentMethod || {};
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return {};
    },

    async getOrderRepayOptions(orderHash) {
        const ERROR_TAGS = getErrorTags('getOrderRepayOptions');
        const payload = { orderHash };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getOrderRepayOptions',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.repayPaymentMethods?.paymentMethod || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getPaymentGatewayData(paymentDataForGateway) {
        const ERROR_TAGS = getErrorTags('getPaymentGatewayData');
        const {
            paymentMethodCode,
            orderNumber,
            redirectUrl,
            additionalData,
        } = paymentDataForGateway;

        const payload = {
            paymentMethodCode,
            orderNumber,
            redirectUrl,
        };

        if (additionalData.length) {
            payload.input = {
                additionalData,
            };
        }

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/paymentGatewayData',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.paymentGateway || null;
            }

            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                err,
                ERROR_TAGS,
                { payload }
            );
        }

        return null;
    },

    async getInpostCities() {
        const ERROR_TAGS = getErrorTags('inpostCities');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/inpostCities',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                }
            );

            if (!error) {
                return data?.inpostCities || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return [];
    },

    async payuPlaceOrderByBlik(orderHash, authorizationCode) {
        const ERROR_TAGS = getErrorTags('payuPlaceOrderByBlik');
        const payload = { orderHash, authorizationCode };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/payuPlaceOrderByBlik',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.payUplaceOrderByBlik || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getInpostPoints(city) {
        const ERROR_TAGS = getErrorTags('getInpostPoints');
        const payload = {
            city,
        };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/inpostPoints',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.inpostPoints?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getInpostPoint(pointName) {
        const ERROR_TAGS = getErrorTags('getInpostPoint');
        const payload = {
            name: pointName,
        };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/inpostPoint',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                const [point = null] = data?.inpostPoints?.items || [];

                return point;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getPickupLapostePoints(city, zipCode, address) {
        const ERROR_TAGS = getErrorTags('getPickupLapostePoints');
        const payload = {
            city,
            zipCode,
            address,
        };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/laPosteshippingPoints',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.laPosteshippingPoints?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getStorePickupPlacesByCart(cartId) {
        const ERROR_TAGS = getErrorTags('getStorePickupPlacesByCart');
        const payload = {
            cartId,
        };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getStorePickupPlacesByCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.getStorePickupPlacesByCart || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getPickupPointsCities(code) {
        const ERROR_TAGS = getErrorTags('getPickupPointsCities');
        const payload = {
            code,
        };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/shippingPointsCities',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.shippingPointsCities || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getPickupPointsDpdParcelShopCities() {
        const ERROR_TAGS = getErrorTags('getPickupPointsDpdParcelShopCities');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/pickupPointsCities',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                }
            );

            if (!error) {
                return data?.dpdParcelShopCities || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return [];
    },

    async getPickupPoints(city, code) {
        const ERROR_TAGS = getErrorTags('getPickupPoints');
        const payload = { city, code };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/shippingPoints',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.shippingPoints?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getUnsavedPickupPoints(methodCode, city, zipCode, address) {
        const ERROR_TAGS = getErrorTags('getUnsavedPickupPoints');
        const payload = {
            methodCode,
            city,
            zipCode,
            address,
        };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/asyncShippingPoints',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.asyncShippingPoints?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getPickupPointsDpdParcelShop(city) {
        const ERROR_TAGS = getErrorTags('getPickupPointsDpdParcelShop');
        const payload = { city };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/pickupPoints',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.dpdParcelShopPoints?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getShippingPoint(shippingPointId) {
        const ERROR_TAGS = getErrorTags('getShippingPoint');
        const payload = { shippingPointId };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/shippingPoint',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.shippingPoints?.items[0] || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getPickupPointDpdParcelShop(parcelshopId) {
        const ERROR_TAGS = getErrorTags('getPickupPointDpdParcelShop');
        const payload = { parcelshopId };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/pickupPoint',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                const pickupPoints = data?.dpdParcelShopPoints?.items || [];

                return pickupPoints[0] || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async setElectronicInvoiceAgreement(cartId, electronicInvoiceAgreement) {
        const ERROR_TAGS = getErrorTags('setElectronicInvoiceAgreement');
        const payload = { cartId, electronicInvoiceAgreement };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setElectronicInvoiceOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setElectronicInvoiceOnCart?.result || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return false;
    },

    async resetPaymentMethodOnCart(cartId) {
        const ERROR_TAGS = getErrorTags('resetPaymentMethodOnCart');
        const payload = { cartId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/resetPaymentMethodOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.resetPaymentMethodOnCart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },
});
