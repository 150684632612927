import { getFullName } from '@assets/customer';
import { COMPANY_CUSTOMER, PRIVATE_PERSON_CUSTOMER } from '@types/Customer';
import {
    ADDRESS_IS_ACTIVE,
    ADDRESS_TITLE,
    BUY_AS,
    BUY_AS_COMPANY,
    BUY_AS_PRIVATE_PERSON,
    CITY,
    COMPANY,
    COUNTRY_CODE,
    FIRSTNAME,
    HOUSE_NUMBER,
    LASTNAME,
    POSTCODE,
    SAVE_IN_ADDRESS_BOOK,
    DEFAULT_SHIPPING_ADDRESS,
    DEFAULT_BILLING_ADDRESS,
    STREET,
    TELEPHONE,
    VAT_ID,
} from '@configs/mage-customer-attributes';

export default class AbstractAddress {
    constructor(address = {}) {
        this.set(address);

        this.NEW_ADDRESS_TITLE_PLACEHOLDER = 'My new address';
    }

    get fullName() {
        return getFullName(this.firstName, this.lastName);
    }

    get streetWithNumber() {
        return `${this.street} ${this.houseNumber}`.trim();
    }

    get postcodeWithCity() {
        return `${this.postcode} ${this.city}`;
    }

    // @todo PWA-326 Replace it with real country name
    get country() {
        return this.countryCode;
    }

    get isCompany() {
        return this.customerType === COMPANY_CUSTOMER;
    }

    setSaveInAddressBook(saveInAddressBook = false) {
        this.saveInAddressBook = saveInAddressBook;
    }

    set({
        customerType = PRIVATE_PERSON_CUSTOMER,
        company = '',
        firstName = '',
        lastName = '',
        telephone = '',
        street = '',
        houseNumber = '',
        postcode = '',
        city = '',
        countryCode = '',
        vatId = '',
        saveInAddressBook = false,
    } = {}) {
        this.customerType = customerType;
        this.company = company;
        this.firstName = firstName;
        this.lastName = lastName;
        this.telephone = telephone;
        this.street = street;
        this.houseNumber = houseNumber;
        this.postcode = postcode;
        this.city = city;
        this.countryCode = countryCode;
        this.vatId = vatId;
        this.saveInAddressBook = saveInAddressBook;
    }

    setTelephone(telephone) {
        this.telephone = telephone;
    }

    setCountryCode(countryCode) {
        this.countryCode = countryCode;
    }

    toCustomerAddressInput() {
        const { customerType } = this;
        const customerAddressInput = {
            [COUNTRY_CODE]: this.countryCode,
            [STREET]: [this.street],
            [HOUSE_NUMBER]: this.houseNumber,
            [ADDRESS_TITLE]: this.NEW_ADDRESS_TITLE_PLACEHOLDER,
            [ADDRESS_IS_ACTIVE]: true,
            [TELEPHONE]: this.telephone,
            [POSTCODE]: this.postcode,
            [CITY]: this.city,
            [FIRSTNAME]: this.firstName,
            [LASTNAME]: this.lastName,
            [BUY_AS]: AbstractAddress.mapToMagentoCustomerType(customerType),
            [DEFAULT_BILLING_ADDRESS]: this.isDefaultBilling,
            [DEFAULT_SHIPPING_ADDRESS]: this.isDefaultShipping,
        };

        if (customerType === COMPANY_CUSTOMER) {
            customerAddressInput[COMPANY] = this.company;
            customerAddressInput[VAT_ID] = this.vatId;
        }

        return customerAddressInput;
    }

    toCartAddressInput() {
        const { customerType } = this;

        const cartAddressInput = {
            [CITY]: this.city,
            [COUNTRY_CODE]: this.countryCode,
            [FIRSTNAME]: this.firstName,
            [LASTNAME]: this.lastName,
            [POSTCODE]: this.postcode,
            [ADDRESS_IS_ACTIVE]: true,
            [ADDRESS_TITLE]: this.NEW_ADDRESS_TITLE_PLACEHOLDER,
            [SAVE_IN_ADDRESS_BOOK]: this.saveInAddressBook,
            [STREET]: [this.street],
            [TELEPHONE]: this.telephone,
            [HOUSE_NUMBER]: this.houseNumber,
            [BUY_AS]: AbstractAddress.mapToMagentoCustomerType(customerType),
        };

        if (customerType === COMPANY_CUSTOMER) {
            cartAddressInput[COMPANY] = this.company;
            cartAddressInput[VAT_ID] = this.vatId;
        }

        return cartAddressInput;
    }

    static mapToMagentoCustomerType(customerType) {
        return !customerType || customerType === PRIVATE_PERSON_CUSTOMER
            ? BUY_AS_PRIVATE_PERSON
            : BUY_AS_COMPANY;
    }
}
