import LOYALTY_CLUB_SERVICES_NAMESPACE from '@loyalty-club/async-services/namespace';

export const NAME = `${LOYALTY_CLUB_SERVICES_NAMESPACE}/loyalty`;

export const GET_MEMBER_DATA = 'GET_MEMBER_DATA';
export const GET_POINTS_BALANCE = 'GET_POINTS_BALANCE';
export const GET_POINTS_HISTORY = 'GET_POINTS_HISTORY';
export const GET_POINTS_PENDING = 'GET_POINTS_PENDING';
export const GET_ORDER_CALCULATION = 'GET_ORDER_CALCULATION';
export const GET_AVAILABLE_VOUCHERS = 'GET_AVAILABLE_VOUCHERS';
export const GET_VOUCHERS_TO_GENERATE = 'GET_VOUCHERS_TO_GENERATE';
export const GET_VOUCHER_BY_ID = 'GET_VOUCHER_BY_ID';
export const GENERATE_VOUCHER = 'GENERATE_VOUCHER';
export const GET_ORDER_COMPENSATION = 'GET_ORDER_COMPENSATION';

export const ASYNC_LOYALTY_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_MEMBER_DATA,
        GET_POINTS_BALANCE,
        GET_POINTS_HISTORY,
        GET_POINTS_PENDING,
        GET_ORDER_CALCULATION,
        GET_AVAILABLE_VOUCHERS,
        GET_VOUCHERS_TO_GENERATE,
        GET_VOUCHER_BY_ID,
        GENERATE_VOUCHER,
        GET_ORDER_COMPENSATION,
    },
};
