import { v4 as uuidv4 } from 'uuid';
import { STORE_MODULE_NAME as LOYALTY_STORE_MODULE_NAME } from '@loyalty-club/store/meta';
import {
    hasLoyaltyClubStoreModule,
    registerLoyaltyStoreModule,
} from '@loyalty-club/helpers/store';
import { memberStatuses } from '@loyalty-club/config';

export const TOPICS = {
    LOYALTY_CLUB_SIGN_IN: 'LOYALTY_CLUB_SIGN_IN',
};

const GET_MEMBER_DATA_RETRY_TIMEOUT = 2000;
const GET_MEMBER_DATA_RETRY_ATTEMPTS = 3;

export default class PubSub {
    constructor(store) {
        this.subscribers = {
            [TOPICS.LOYALTY_CLUB_SIGN_IN]: {
                [uuidv4()]: async () => {
                    if (!hasLoyaltyClubStoreModule(store)) {
                        await registerLoyaltyStoreModule(store);
                    }

                    const fetchMemberDataWithRetrying = async () => {
                        const memberData = await store.dispatch(
                            `${LOYALTY_STORE_MODULE_NAME}/getLoyaltyMemberData`
                        );

                        if (
                            !memberData ||
                            memberData.status === memberStatuses.LEFT
                        ) {
                            const retry = async attempts => {
                                if (attempts <= 0) {
                                    return;
                                }

                                const data = await store.dispatch(
                                    `${LOYALTY_STORE_MODULE_NAME}/getLoyaltyMemberData`
                                );

                                if (
                                    data &&
                                    data.status !== memberStatuses.LEFT
                                ) {
                                    return;
                                }

                                return new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve(retry(attempts - 1));
                                    }, GET_MEMBER_DATA_RETRY_TIMEOUT);
                                });
                            };

                            return retry(GET_MEMBER_DATA_RETRY_ATTEMPTS);
                        }
                    };

                    await fetchMemberDataWithRetrying();
                },
            },
        };
    }

    publish(topic, payload = {}) {
        if (!this.subscribers[topic]) {
            return;
        }

        Object.values(this.subscribers[topic]).forEach(subscriber =>
            subscriber(payload)
        );
    }

    subscribe(topic, subscriber) {
        if (!this.subscribers[topic]) {
            return null;
        }

        const subscriberId = uuidv4();

        this.subscribers[topic][subscriberId] = subscriber;

        return subscriberId;
    }

    unsubscribe(subscriberId) {
        if (!subscriberId) {
            return;
        }

        Object.values(this.subscribers).forEach(
            subscribers => delete subscribers[subscriberId]
        );
    }
}
