export const types = {
    ADD_HTML_CLASSES: 'ADD_HTML_CLASSES',
    REMOVE_HTML_CLASSES: 'REMOVE_HTML_CLASSES',
};

const mutations = {
    [types.ADD_HTML_CLASSES](state, classNames) {
        state.htmlClasses.push(...classNames);
        state.htmlClasses = [...new Set(state.htmlClasses)];
    },

    [types.REMOVE_HTML_CLASSES](state, classNames) {
        state.htmlClasses = state.htmlClasses.filter(
            item => !classNames.includes(item)
        );
    },
};

export default mutations;
