import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.order.${action}`,
});

const normalizeErrors = errors =>
    Array.isArray(errors) && errors.length
        ? new Error(errors.map(err => err.message).join('|'))
        : null;

export default ({ megatronClient, $errorHandler }) => ({
    async getOrderNumber(orderHash) {
        const ERROR_TAGS = getErrorTags('getOrderNumber');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getOrderNumber',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        orderHash,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            const { thanksPageData, errors = [] } = data || {};

            const normalizedError = error || normalizeErrors(errors);

            if (!normalizedError) {
                return thanksPageData?.order_number;
            }

            $errorHandler.captureError(
                new Error(normalizedError?.message || 'UNKNOWN_ERROR', {
                    cause: normalizedError,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                    orderHash,
                }
            );

            throw normalizedError;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                orderHash,
            });

            throw err;
        }
    },

    async getOrderPaymentStatus(orderHash) {
        const ERROR_TAGS = getErrorTags('getOrderPaymentStatus');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getOrderPaymentStatus',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        orderHash,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            const { orderPaymentStatus, errors = [] } = data || {};

            const normalizedError = error || normalizeErrors(errors);

            if (!normalizedError) {
                return orderPaymentStatus;
            }

            $errorHandler.captureError(
                new Error(normalizedError?.message || 'UNKNOWN_ERROR', {
                    cause: normalizedError,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                    orderHash,
                }
            );

            throw normalizedError;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                orderHash,
            });

            throw err;
        }
    },

    async getOrderStatus(orderHash) {
        const ERROR_TAGS = getErrorTags('getOrderStatus');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getOrderStatus',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        orderHash,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            const { orderStatus, errors = [] } = data || {};

            const normalizedError = error || normalizeErrors(errors);

            if (!normalizedError) {
                return orderStatus;
            }

            $errorHandler.captureError(
                new Error(normalizedError?.message || 'UNKNOWN_ERROR', {
                    cause: normalizedError,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                    orderHash,
                }
            );

            throw normalizedError;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                orderHash,
            });

            throw err;
        }
    },

    async cancelOrder(orderHash) {
        const ERROR_TAGS = getErrorTags('cancelOrder');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/cancelOrder',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: orderHash,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.cancelOrder?.success || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                    orderHash,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                orderHash,
            });

            return false;
        }
    },

    async cancelPayUPayment(orderHash) {
        const ERROR_TAGS = getErrorTags('cancelPayUPayment');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/cancelPayUPayment',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        orderHash,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.payUCancelPayment || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                    orderHash,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                orderHash,
            });

            return false;
        }
    },
});
