import Shield from '@static/icons/24px/shield.svg?inline';
import Wallet from '@static/icons/24px/wallet.svg?inline';
import Truck from '@static/icons/24px/truck.svg?inline';

export const PSB_SHOPPING_DELIVERY_TIME = 'psb_shopping_delivery_time';
export const PSB_FAST_SHOPPING_DELIVERY_TIME =
    'psb_fast_shopping_delivery_time';
export const PSB_SHOPPING_SAFE_SHOPPING = 'psb_shopping_safe_shopping';
export const PSB_SHOPPING_PAYMENT_DELIVERY = 'psb_shopping_payment_delivery';
export const PSB_SHOPPING_SHIPPING_RETURN = 'psb_shopping_shipping_return';

export const PSB_PAYMENT_WIDGET = 'psb_payment_widget';

export const SHOPPING_CMS_BLOCKS_IDS = [
    PSB_SHOPPING_DELIVERY_TIME,
    PSB_SHOPPING_SAFE_SHOPPING,
    PSB_FAST_SHOPPING_DELIVERY_TIME,
    PSB_SHOPPING_PAYMENT_DELIVERY,
    PSB_SHOPPING_SHIPPING_RETURN,
];

export const SHOPPING_ADDITIONAL_INFO_MAP = [
    {
        id: 'shippingReturn',
        icon: Truck,
        blockId: PSB_SHOPPING_SHIPPING_RETURN,
    },
    {
        id: 'safeShopping',
        icon: Shield,
        blockId: PSB_SHOPPING_SAFE_SHOPPING,
    },
    {
        id: 'paymentDelivery',
        icon: Wallet,
        blockId: PSB_SHOPPING_PAYMENT_DELIVERY,
    },
];
