<template>
    <ContainerContent
        class="container-with-sidebar"
        :class="{
            _full: fullWidth,
            _nogutters: noGutters,
            'is-sidebar-visible-on-mobile': isSidebarVisibleOnMobile,
            'narrow-columns': areColumnsNarrow,
        }"
    >
        <div class="sidebar">
            <!-- @slot sidebar slot -->
            <slot name="sidebar" />
        </div>
        <div class="content">
            <!-- @slot content slot -->
            <slot />
        </div>
    </ContainerContent>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'ContainerContentWithSidebar',
    components: {
        ContainerContent,
    },

    props: {
        /**
         * Changes max-width of element to 100% and apply 0 paddings.
         */
        fullWidth: {
            type: Boolean,
            default: false,
        },

        /**
         * Sets negative margins to counteract containers padding.
         */
        noGutters: {
            type: Boolean,
            default: false,
        },

        /**
         * Sets sidebar visibility on mobile.
         */
        isSidebarVisibleOnMobile: {
            type: Boolean,
            default: false,
        },

        /**
         * Changes width of sidebar and content column to 1/5 and 5/6.
         */
        areColumnsNarrow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.container-with-sidebar {
    &.is-sidebar-visible-on-mobile {
        .sidebar {
            @apply block z-3;
        }
    }

    .sidebar {
        @apply hidden;
    }

    .sidebar,
    .content {
        @apply w-full;
    }

    @screen lg {
        .sidebar {
            @apply block w-1/5;
        }

        .content {
            @apply w-4/5;
        }

        &.narrow-columns {
            .sidebar {
                @apply w-1/6;
            }

            .content {
                @apply w-5/6;
            }
        }
    }
}
</style>
