import {
    VALID_ORDER_DIRS,
    DEFAULT_ORDER_DIR,
} from '@configs/defaults/query/sort';
import { types } from './mutations';

const actions = {
    setSortFromQuery({ commit }, { orderCode = '', orderDir = null }) {
        commit(types.SET_ORDER_CODE, orderCode);

        const isOrderDirValid = VALID_ORDER_DIRS.indexOf(orderDir) !== -1;

        const fallbackOrder = orderCode ? DEFAULT_ORDER_DIR : '';
        const dirToApply = isOrderDirValid ? orderDir : fallbackOrder;

        commit(types.SET_ORDER_DIR, dirToApply);

        commit(types.SET_SELECTED_SORT, {
            code: orderCode,
            order: dirToApply,
        });
    },

    setDefaultOrderCode({ commit }, defaultOrderCode) {
        commit(types.SET_DEFAULT_ORDER_CODE, defaultOrderCode);
    },

    setOrderCode({ commit }, orderCode) {
        commit(types.SET_ORDER_CODE, orderCode);
    },

    setOrderOptions({ commit }, orderOptions) {
        commit(types.SET_ORDER_OPTIONS, orderOptions);
    },

    setSelectedSort({ commit }, selectedSort) {
        commit(types.SET_SELECTED_SORT, selectedSort);
    },

    resetSort({ commit }) {
        commit(types.SET_ORDER_CODE, null);
        commit(types.SET_ORDER_DIR, DEFAULT_ORDER_DIR);
    },
};

export default actions;
