import validationMessages from 'vee-validate/dist/locale/en.json';
import en_GB from '../translations/en_GB.json';
import en_GB_labels from '../translations/labels/en_GB.json';
import en_GB_validations from '../translations/validations/en_GB.json';
import en_GB_paths from '../translations/paths/en_GB.json';

export default () => {
    const validations = validationMessages.messages;

    return {
        __validations: {
            ...validations,
            ...en_GB_validations,
        },
        __labels: {
            ...en_GB_labels,
        },
        __paths: {
            ...en_GB_paths,
        },
        ...en_GB,
    };
};
