import CustomerAddress from '@models/Customer/CustomerAddress';
import { getFullName } from '@assets/customer';

import {
    ADDRESSES,
    EMAIL,
    FIRSTNAME,
    LASTNAME,
} from '@configs/mage-customer-attributes';
import { ADDRESS_TYPE_BILLING } from '@types/Checkout';

export default class Customer {
    constructor(magentoCustomer = {}) {
        this.customer = Customer.mapMagentoCustomer(magentoCustomer);
    }

    get() {
        return this.customer;
    }

    get email() {
        return this.customer.email;
    }

    get firstName() {
        return this.customer.firstName;
    }

    get lastName() {
        return this.customer.lastName;
    }

    get fullName() {
        const { firstName, lastName } = this.customer;

        return getFullName(firstName, lastName);
    }

    get addresses() {
        return this.customer.addresses.filter(
            address => address.isActive === true
        );
    }

    get defaultBillingAddress() {
        const { addresses } = this;

        if (addresses.length === 0) {
            return null;
        }

        return (
            addresses.find(address => address.isDefaultBilling === true) || null
        );
    }

    get defaultShippingAddress() {
        const { addresses } = this;

        if (addresses.length === 0) {
            return null;
        }

        return (
            addresses.find(address => address.isDefaultShipping === true) ||
            null
        );
    }

    get latestAddress() {
        const { addresses = [] } = this;
        const addressesCount = addresses.length;

        return this.hasAnyAddress ? addresses[addressesCount - 1] : null;
    }

    get additionalAddresses() {
        return this.addresses.filter(
            address =>
                address.isDefaultBilling === false &&
                address.isDefaultShipping === false
        );
    }

    get hasDefaultBillingAddress() {
        return this.defaultBillingAddress !== null;
    }

    get hasDefaultShippingAddress() {
        return this.defaultShippingAddress !== null;
    }

    get hasAnyAddress() {
        return this.addresses.length > 0;
    }

    hasDefaultAddress(addressType) {
        return addressType === ADDRESS_TYPE_BILLING
            ? this.hasDefaultBillingAddress
            : this.hasDefaultShippingAddress;
    }

    getDefaultAddress(addressType) {
        return addressType === ADDRESS_TYPE_BILLING
            ? this.defaultBillingAddress
            : this.defaultShippingAddress;
    }

    getAddressById(customerAddressId) {
        return (
            this.addresses.find(address => address.id === customerAddressId) ||
            null
        );
    }

    static mapMagentoCustomer(magentoCustomer) {
        const {
            [EMAIL]: email = '',
            [FIRSTNAME]: firstName = '',
            [LASTNAME]: lastName = '',
            [ADDRESSES]: addresses = [],
        } = magentoCustomer;

        return {
            email,
            firstName,
            lastName,
            addresses: Customer.mapMagentoAddresses(addresses),
        };
    }

    static mapMagentoAddresses(magentoCustomerAddresses = []) {
        return magentoCustomerAddresses.map(magentoCustomerAddress => {
            const customerAddress = new CustomerAddress();

            customerAddress.copyFromMagentoCustomerAddress(
                magentoCustomerAddress
            );

            return customerAddress;
        });
    }
}
