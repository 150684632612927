import { megatronNormalizersRegistry } from '@normalizers/requests/megatron-registry';

export const getMegatronNormalizer = (app, store) => {
    const {
        $createProductPath: createProductPath,
        $createCategoryPath: createCategoryPath,
        $imaginator: imaginator,
        $getLocaleConfigByKey: getLocaleConfigByKey,
        $t: t,
    } = app;

    const helpers = {
        imaginator,
        createProductPath,
        createCategoryPath,
        getLocaleConfigByKey,
        t,
    };

    return (response, operationName) => {
        if (!operationName) {
            return response;
        }

        const normalizer = megatronNormalizersRegistry[operationName];

        if (!normalizer) {
            return response;
        }

        const {
            locale,
            base_currency_code: currency,
            timezone: storeViewTimezone,
        } = store.state.config.storeView;

        return normalizer(
            response,
            {
                locale,
                currency,
                storeViewTimezone,
            },
            helpers
        );
    };
};
