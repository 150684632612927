import { createNamespacedHelpers } from 'vuex';

import { GUEST_CART_ID_KEY, USER_CART_ID_KEY } from '@configs/storage';

import { CHECKOUT_PAGE_NAME, CHECKOUT_CART_PAGE_NAME } from '@router/names';

const {
    mapActions: mapCustomerActions,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');

const {
    mapActions: mapCartActions,
    mapGetters: mapCartGetters,
} = createNamespacedHelpers('cart');
const { mapActions: mapEsizemeActions } = createNamespacedHelpers('esizeme');

export default {
    computed: {
        ...mapCustomerGetters(['isLoggedIn']),
        ...mapCartGetters(['cartFetched', 'checkoutCartDataFetched']),
    },

    async mounted() {
        if (!this.isLoggedIn) {
            await this.getCustomerData();
        }

        if (this.isLoggedIn) {
            this.loadEsizemeCustomerScans();
        }

        const storageCartKey = this.isLoggedIn
            ? USER_CART_ID_KEY
            : GUEST_CART_ID_KEY;
        const cartId = this.$storage.getItem(storageCartKey)?.id;

        if (cartId) {
            this.setCartId(cartId);

            // @todo: PWA-1247 - change this condition and use Deffered
            if (
                ![CHECKOUT_PAGE_NAME, CHECKOUT_CART_PAGE_NAME].includes(
                    this.$route.name
                ) &&
                !this.cartFetched
            ) {
                await this.getCart({ cartId });
            }
        }

        if (!this.isLoggedIn) {
            this.initLocalWishlist();
        }

        this.setFetchingCustomerInProgressFlag(false);
    },
    methods: {
        ...mapCustomerActions([
            'getCustomerData',
            'setFetchingCustomerInProgressFlag',
            'initLocalWishlist',
        ]),
        ...mapCartActions(['setCartId', 'getCart']),
        ...mapEsizemeActions(['loadEsizemeCustomerScans']),
    },
};
