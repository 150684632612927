import { isBotUserAgent } from './bot-detect';
import {
    isMobileOperatingSystemAndroid,
    isMobileOperatingSystemIOS,
    isMobileOperatingSystemHuawei,
} from './system-detect';

export default (context, inject) => {
    let userAgent = '';

    if (typeof context.req !== 'undefined') {
        userAgent = context.req.headers?.['user-agent'] || '';
    } else if (typeof window !== 'undefined') {
        userAgent = window?.navigator?.userAgent || '';
    }

    context.$ua = {
        userAgent,
        isBot: isBotUserAgent(userAgent),
        isAndroid: isMobileOperatingSystemAndroid(userAgent),
        isIOS: isMobileOperatingSystemIOS(userAgent),
        isHuaweiOS: isMobileOperatingSystemHuawei(userAgent),
    };

    inject('ua', context.$ua);
};
