export const withRetryHandling = (
    callback,
    { delay = 500, maxRetries = 3 } = {}
) => {
    const retry = async (attempt = 1) => {
        const response = await callback();

        if (response?.error) {
            if (attempt >= maxRetries) {
                return response;
            }

            return new Promise(resolve => {
                setTimeout(() => resolve(retry(attempt + 1)), delay);
            });
        }

        return response;
    };

    return retry();
};
