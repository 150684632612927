<template>
    <div>
        <ModalAuthorization
            v-if="isAuthorizationModalOpen"
            @close-modal="closeModal(MODAL_AUTHORIZATION_NAME)"
        />
        <InviteFriendInfoModal
            v-if="isInviteFriendInfoModalOpen"
            :title="$t('Only new users can use the referral link')"
            :description="inviteFriendInfoDescription"
            :button-text="$t('Recommend eobuwie')"
            @button-click="goToInviteFriendPage()"
            @close-modal="closeModal(MODAL_INVITE_FRIEND_INFO)"
            @mounted="onInviteFriendInfoModalMounted()"
        />
        <InviteFriendThankYouModal
            v-if="isInviteFriendThankYouModalOpen"
            @close-modal="closeModal(MODAL_INVITE_FRIEND_THANK_YOU)"
        />
        <AddEsizemeScanModal
            v-if="isAddEsizemeScanModalOpen"
            :is-open="isAddEsizemeScanModalOpen"
            @close="closeModal(MODAL_ADD_ESIZEME_SCAN)"
        />
        <ModalAfterRegistrationBySocialMedia
            v-if="
                isModalAfterRegistrationBySocialMediaOpen &&
                !isInviteFriendThankYouModalOpen
            "
            @close-modal="closeModal(MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA)"
        />
        <MagicLinkAuth
            v-if="isMagicLinkAuthModalOpen && isMagicLinkEnabled"
            @close="closeModal(MODAL_MAGIC_LINK_AUTH)"
        />
        <AccountVerificationModal
            v-if="isAccountVerificationModalOpen"
            @close="closeModal(MODAL_ACCOUNT_VERIFICATION)"
        />

        <NewsletterTriggerTooltip v-if="shouldOpenNewsletterTriggerTooltip" />
        <ModalNewsletter
            v-if="shouldOpenModalNewsletter"
            :customer-email="customerEmail"
            @close="closeModal(MODAL_NEWSLETTER_NAME)"
        />

        <ModalSniperLink
            v-if="isModalSniperLinkOpen"
            @close="closeSniperLinkModal()"
        />
        <JoinToLoyaltyClubModal
            v-if="isLoyaltyClubEnabled"
            :show="isJoinToLoyaltyClubModalOpen"
            @close="closeModal(JOIN_TO_LOYALTY_CLUB_MODAL)"
        />
        <NewsletterConfirmationModal
            :show="
                isModalNewsletterConfirmationOpen &&
                shouldOpenModalNewsletterConfirmation &&
                !isJoinToLoyaltyClubModalOpen
            "
            @close="closeModal(MODAL_NEWSLETTER_CONFIRMATION)"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { MODULE_NAME } from '@analytics-module/modules/client/meta';

import {
    INVITATION_PROGRAM_EARLIER_LOGGED_MODAL_VIEW,
    INVITATION_PROGRAM_EARLIER_LOGGED_MODAL_RECOMMEND_CLICK,
    INVITATION_PROGRAM_EARLIER_LOGGED_MODAL_CLOSE_MODAL_CLICK,
} from '@analytics-module/modules/client/types/Events';

import {
    MODAL_AUTHORIZATION_NAME,
    MODAL_INVITE_FRIEND_INFO,
    MODAL_INVITE_FRIEND_THANK_YOU,
    MODAL_ADD_ESIZEME_SCAN,
    MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA,
    MODAL_MAGIC_LINK_AUTH,
    MODAL_NEWSLETTER_NAME,
    SHOULD_OPEN_MODAL_ON_CLICK,
    MODAL_ACCOUNT_VERIFICATION,
    NEWSLETTER_RELEASER_AUTO,
    MODAL_NEWSLETTER_RELEASER,
    MODAL_SNIPER_LINK,
    JOIN_TO_LOYALTY_CLUB_MODAL,
    MODAL_NEWSLETTER_CONFIRMATION,
    MODAL_HEADER_MENU,
} from '@configs/modals';
import { BENEFITS_MAP_BY_STORE_CODE } from '@configs/invite-friend';
import {
    GTM_CATEGORIES_STORAGE_KEY,
    PAGE_VIEWS_KEY,
    MODAL_NEWSLETTER_VIEWS_KEY,
    MODAL_NEWSLETTER_LAST_VIEW_EXPIRATION_KEY,
    STORAGE_TYPE_SESSION,
} from '@configs/storage';
import {
    NUMBER_OF_PAGE_VIEWS_TO_SHOW_NEWSLETTER,
    NEWSLETTER_MODAL_VIEWS_LIMIT,
    MODAL_NEWSLETTER_EXCLUDED_ROUTES,
    isMobileNewsletterTooltipTestOn,
} from '@configs/newsletter';
import { FILTERS_MODAL, SORT_MODAL } from '@search/configs/modals';

import { CUSTOMER_ACCOUNT_INVITE_FIEND_NAME } from '@router/names';

import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import { incrementPagesViewsCountInStorage } from '@assets/session';

const { mapState: mapAvatarState } = createNamespacedHelpers('avatar');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const {
    mapState: mapCustomerState,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');
const {
    mapState: mapModalState,
    mapActions: mapModalsActions,
} = createNamespacedHelpers('modals');
const { mapState: mapNavigationState } = createNamespacedHelpers('navigation');

export default {
    name: 'GlobalModals',

    components: {
        ModalAuthorization: () => ({
            component: import(
                /* webpackChunkName: "modal-authorization" */
                '@organisms/ModalAuthorization/ModalAuthorization'
            ),
        }),

        InviteFriendInfoModal: () => ({
            component: import(
                /* webpackChunkName: "invite-friend-info-modal" */
                '@organisms/InviteFriendInfoModal/InviteFriendInfoModal'
            ),
        }),

        InviteFriendThankYouModal: () => ({
            component: import(
                /* webpackChunkName: "invite-friend-thank-you-modal" */
                '@organisms/InviteFriendThankYouModal/InviteFriendThankYouModal'
            ),
        }),

        AddEsizemeScanModal: () => ({
            component: import(
                /* webpackChunkName: "add-esizeme-scan-modal" */
                '@organisms/AddEsizemeScanModal/AddEsizemeScanModal'
            ),
        }),

        ModalAfterRegistrationBySocialMedia: () => ({
            component: import(
                /* webpackChunkName: "modal-after-registration-by-social-media" */
                '@molecules/ModalAfterRegistrationBySocialMedia/ModalAfterRegistrationBySocialMedia'
            ),
        }),

        MagicLinkAuth: () => ({
            component: import(
                /* webpackChunkName: "MagicLinkAuth" */
                '@molecules/MagicLinkAuth/MagicLinkAuth'
            ),
        }),

        ModalNewsletter: () => ({
            component: import(
                /* webpackChunkName: "modal-newsletter" */
                '@organisms/ModalNewsletter/ModalNewsletter'
            ),
        }),

        AccountVerificationModal: () => ({
            component: import(
                /* webpackChunkName: "AccountVerificationModal" */
                '@organisms/AccountVerificationModal/AccountVerificationModal'
            ),
        }),

        ModalSniperLink: () => ({
            component: import(
                /* webpackChunkName: "ModalSniperLink" */
                '@molecules/ModalSniperLink/ModalSniperLink'
            ),
        }),

        JoinToLoyaltyClubModal: () => ({
            component: import(
                /* webpackChunkName: "JoinToLoyaltyClubModal" */
                '@loyalty-club-organisms/JoinToLoyaltyClubModal/JoinToLoyaltyClubModal'
            ),
        }),

        NewsletterConfirmationModal: () => ({
            component: import(
                /* webpackChunkName: "newsletter-confirmation-modal" */
                '@molecules/NewsletterConfirmationModal/NewsletterConfirmationModal'
            ),
        }),

        NewsletterTriggerTooltip: () => ({
            /* webpackChunkName: "newsletter-trigger-tooltip" */
            component: import(
                '@molecules/NewsletterTriggerTooltip/NewsletterTriggerTooltip'
            ),
        }),
    },

    data() {
        return {
            modalOpeningTimeout: null,
            isModalNewsletterEnabled: false,
        };
    },

    computed: {
        ...mapAvatarState(['isInviteFriendFeatureEnabled']),
        ...mapConfigGetters([
            'storeCode',
            'isMagicLinkEnabled',
            'isAccountVerificationEnabled',
        ]),

        ...mapCustomerState([
            'fetchingCustomerInProgress',
            'isCustomerSubscribedToNewsletter',
            'customerData',
            'shouldOpenModalNewsletterConfirmation',
        ]),

        ...mapCustomerGetters(['isLoggedIn']),
        ...mapModalState(['isSizeModalOpen']),
        ...mapNavigationState(['isSearchInputActive']),

        ...mapState(['isMobile']),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        isAccountVerificationModalOpen() {
            return (
                this.isAccountVerificationEnabled &&
                this.$modals.isOpen(MODAL_ACCOUNT_VERIFICATION) &&
                !this.isJoinToLoyaltyClubModalOpen
            );
        },

        inviteFriendInfoDescription() {
            const { currency = '', value = '' } =
                BENEFITS_MAP_BY_STORE_CODE[this.storeCode] || {};

            return this.$t(
                // eslint-disable-next-line max-len
                'Nothing lost. You can get a {value} {currency} discount on shopping if you recommend eobuwie to others!',
                {
                    currency,
                    value,
                }
            );
        },

        isAuthorizationModalOpen() {
            return this.$modals.isOpen(MODAL_AUTHORIZATION_NAME);
        },

        isInviteFriendInfoModalOpen() {
            return (
                this.isInviteFriendFeatureEnabled &&
                this.$modals.isOpen(MODAL_INVITE_FRIEND_INFO)
            );
        },

        isInviteFriendThankYouModalOpen() {
            return (
                this.isInviteFriendFeatureEnabled &&
                this.$modals.isOpen(MODAL_INVITE_FRIEND_THANK_YOU)
            );
        },

        isAddEsizemeScanModalOpen() {
            return this.$modals.isOpen(MODAL_ADD_ESIZEME_SCAN);
        },

        isModalAfterRegistrationBySocialMediaOpen() {
            return this.$modals.isOpen(
                MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA
            );
        },

        isMagicLinkAuthModalOpen() {
            return this.$modals.isOpen(MODAL_MAGIC_LINK_AUTH);
        },

        isModalNewsletterOpen() {
            return this.$modals.isOpen(MODAL_NEWSLETTER_NAME);
        },

        shouldOpenNewsletterModalOnClick() {
            return (
                this.$modals.getConfig(MODAL_NEWSLETTER_NAME)[
                    SHOULD_OPEN_MODAL_ON_CLICK
                ] || false
            );
        },

        isModalSniperLinkOpen() {
            return this.$modals.isOpen(MODAL_SNIPER_LINK);
        },

        isJoinToLoyaltyClubModalOpen() {
            if (!this.isLoyaltyClubEnabled) {
                return false;
            }

            return this.$modals.isOpen(JOIN_TO_LOYALTY_CLUB_MODAL);
        },

        isModalNewsletterConfirmationOpen() {
            return this.$modals.isOpen(MODAL_NEWSLETTER_CONFIRMATION);
        },

        isSortFilterPanelActive() {
            return (
                this.$modals.isOpen(FILTERS_MODAL) ||
                this.$modals.isOpen(SORT_MODAL)
            );
        },

        shouldBlockNewsletterModal() {
            return (
                this.isAuthorizationModalOpen ||
                this.isMagicLinkAuthModalOpen ||
                this.isSizeModalOpen ||
                this.isSearchInputActive ||
                this.isSortFilterPanelActive ||
                this.shouldOpenModalNewsletterConfirmation ||
                this.isHeaderMenuOpen
            );
        },

        customerEmail() {
            return this.customerData?.email || '';
        },

        shouldOpenNewsletterTriggerTooltip() {
            return (
                this.isModalNewsletterEnabled &&
                isMobileNewsletterTooltipTestOn(this.$abTests) &&
                this.isMobile
            );
        },

        shouldOpenModalNewsletter() {
            return (
                this.isModalNewsletterOpen &&
                (this.shouldOpenNewsletterModalOnClick ||
                    (this.isModalNewsletterEnabled &&
                        (!isMobileNewsletterTooltipTestOn(this.$abTests) ||
                            !this.isMobile)))
            );
        },

        isHeaderMenuOpen() {
            return this.$modals.isOpen(MODAL_HEADER_MENU);
        },
    },

    watch: {
        $route: {
            async handler() {
                incrementPagesViewsCountInStorage(this.$storage);
                this.clearTimeoutForOpenNewsletterModal();
                await this.modalNewsletterInit();
            },
        },

        fetchingCustomerInProgress: {
            async handler(inProgress) {
                if (!inProgress && !this.shouldBlockNewsletterModal) {
                    await this.modalNewsletterInit();
                }
            },
        },

        shouldBlockNewsletterModal(shouldBlock) {
            if (shouldBlock) {
                this.clearTimeoutForOpenNewsletterModal();
            }
        },

        isModalAfterRegistrationBySocialMediaOpen(isOpen) {
            if (isOpen) {
                this.clearTimeoutForOpenNewsletterModal();
            }
        },
    },

    beforeCreate() {
        this.MODAL_AUTHORIZATION_NAME = MODAL_AUTHORIZATION_NAME;
        this.MODAL_INVITE_FRIEND_INFO = MODAL_INVITE_FRIEND_INFO;
        this.MODAL_INVITE_FRIEND_THANK_YOU = MODAL_INVITE_FRIEND_THANK_YOU;
        this.MODAL_ADD_ESIZEME_SCAN = MODAL_ADD_ESIZEME_SCAN;
        this.MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA = MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA;
        this.MODAL_MAGIC_LINK_AUTH = MODAL_MAGIC_LINK_AUTH;
        this.MODAL_NEWSLETTER_TIMEOUT = 5000;
        this.MODAL_ACCOUNT_VERIFICATION = MODAL_ACCOUNT_VERIFICATION;
        this.JOIN_TO_LOYALTY_CLUB_MODAL = JOIN_TO_LOYALTY_CLUB_MODAL;
        this.MODAL_NEWSLETTER_NAME = MODAL_NEWSLETTER_NAME;
        this.MODAL_NEWSLETTER_CONFIRMATION = MODAL_NEWSLETTER_CONFIRMATION;
        this.isMobileNewsletterTooltipTestOn = isMobileNewsletterTooltipTestOn;
    },

    beforeDestroy() {
        this.clearTimeoutForOpenNewsletterModal();
    },

    async mounted() {
        incrementPagesViewsCountInStorage(this.$storage);
        await this.modalNewsletterInit();

        if (this.isMagicLinkEnabled) {
            const magicLink = this.$route.query.magiclink || null;

            if (magicLink) {
                this.$modals.open(MODAL_MAGIC_LINK_AUTH);
            }
        }

        if (this.isAccountVerificationEnabled) {
            const accountVerificationToken =
                this.$route.query.accountVerificationToken || null;

            if (accountVerificationToken) {
                this.$modals.open(MODAL_ACCOUNT_VERIFICATION);
            }
        }
    },

    methods: {
        ...mapModalsActions(['setShouldOpenSniperLinkModal']),

        onInviteFriendInfoModalMounted() {
            this.$analytics.moduleEmit(
                MODULE_NAME,
                INVITATION_PROGRAM_EARLIER_LOGGED_MODAL_VIEW
            );
        },

        closeModal(modalName) {
            this.$modals.close(modalName);

            if (this.shouldOpenModalNewsletterConfirmation) {
                this.$modals.open(MODAL_NEWSLETTER_CONFIRMATION);
            }

            if (modalName === MODAL_INVITE_FRIEND_INFO) {
                this.$analytics.moduleEmit(
                    MODULE_NAME,
                    INVITATION_PROGRAM_EARLIER_LOGGED_MODAL_CLOSE_MODAL_CLICK
                );
            }
        },

        goToInviteFriendPage() {
            this.$router.push({ name: CUSTOMER_ACCOUNT_INVITE_FIEND_NAME });

            this.$analytics.moduleEmit(
                MODULE_NAME,
                INVITATION_PROGRAM_EARLIER_LOGGED_MODAL_RECOMMEND_CLICK
            );

            this.$modals.close(MODAL_INVITE_FRIEND_INFO);
        },

        getModalNewsletterViews() {
            return this.$storage.getItem(MODAL_NEWSLETTER_VIEWS_KEY) || 0;
        },

        setTimeoutForOpenNewsletterModal() {
            if (this.modalOpeningTimeout) {
                return;
            }

            this.modalOpeningTimeout = setTimeout(() => {
                this.$modals.open(MODAL_NEWSLETTER_NAME, {
                    [MODAL_NEWSLETTER_RELEASER]: NEWSLETTER_RELEASER_AUTO,
                });
            }, this.MODAL_NEWSLETTER_TIMEOUT);
        },

        clearTimeoutForOpenNewsletterModal() {
            if (!this.modalOpeningTimeout) {
                return;
            }

            clearTimeout(this.modalOpeningTimeout);
            this.modalOpeningTimeout = null;
        },

        async modalNewsletterInit() {
            if (!this.fetchingCustomerInProgress) {
                this.isModalNewsletterEnabled = await this.setIsModalNewsletterEnabled();
            }

            if (this.isModalNewsletterEnabled) {
                this.setTimeoutForOpenNewsletterModal();
            }
        },

        async setIsModalNewsletterEnabled() {
            if (this.isMagicLinkAuthModalOpen) {
                return false;
            }

            const isExcludedRoute = MODAL_NEWSLETTER_EXCLUDED_ROUTES.includes(
                this.$route.name
            );

            if (isExcludedRoute) {
                return false;
            }

            const modalLastViewExpirationDate = this.$storage.getItem(
                MODAL_NEWSLETTER_LAST_VIEW_EXPIRATION_KEY
            );

            if (modalLastViewExpirationDate > new Date().getTime()) {
                return false;
            }

            const isModalViewsLimitExceeded =
                this.getModalNewsletterViews() > NEWSLETTER_MODAL_VIEWS_LIMIT;

            if (isModalViewsLimitExceeded) {
                return false;
            }

            const areRequiredPageViewsExceeded =
                this.$storage?.getItem(PAGE_VIEWS_KEY, STORAGE_TYPE_SESSION) <
                NUMBER_OF_PAGE_VIEWS_TO_SHOW_NEWSLETTER;

            if (areRequiredPageViewsExceeded) {
                return false;
            }

            const consentsCategoriesFromStorage =
                this.$storage?.getItem(GTM_CATEGORIES_STORAGE_KEY) || [];

            if (!consentsCategoriesFromStorage.length) {
                return false;
            }

            const areAllConsentsAccepted = consentsCategoriesFromStorage.every(
                ({ isActive }) => isActive
            );

            if (!areAllConsentsAccepted) {
                return false;
            }

            if (this.isHeaderMenuOpen) {
                return false;
            }

            return !this.isCustomerSubscribedToNewsletter;
        },

        closeSniperLinkModal() {
            this.closeModal(MODAL_SNIPER_LINK);
            this.setShouldOpenSniperLinkModal(false);
        },
    },
};
</script>
