<template>
    <div
        :data-test-id="testId"
        :style="{ background: bgColor }"
        :class="type"
        class="base-alert-row"
    >
        <SvgIcon v-if="hasIcon" width="24px" height="24px" class="icon">
            <component :is="iconType" />
        </SvgIcon>
        <div>
            <slot />
            <div v-if="hasAdditionalTextSlot" class="additional-text">
                <slot name="additional-text" />
            </div>
            <template v-if="hasAction">
                <TextButton
                    v-if="actionHandler"
                    type="button"
                    class="action"
                    @click.native="actionHandler(id)"
                >
                    {{ actionText }}
                </TextButton>
                <a v-else :href="actionUrl" class="action">
                    {{ actionText }}
                </a>
            </template>
        </div>
    </div>
</template>

<script>
import TYPES, { ERROR } from '@types/Alerts';
import { PRODUCT_EXCEPTION } from '@types/AutomaticTestIDs';

import Check from '@static/icons/24px/check.svg?inline';
import ExclamationMark from '@static/icons/24px/exclamation-mark.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import TextButton from '@atoms/TextButton/TextButton';

const ICON_MAP = {
    success: Check,
    info: ExclamationMark,
    warning: ExclamationMark,
    error: ExclamationMark,
    notification: ExclamationMark,
};

export default {
    name: 'BaseAlertRow',

    components: {
        SvgIcon,
        TextButton,
    },

    props: {
        hasIcon: {
            type: Boolean,
            default: false,
        },

        id: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            required: true,
            validator: value => Object.values(TYPES).includes(value),
        },

        bgColor: {
            type: String,
            default: '',
        },

        actionHandler: {
            type: Function,
            default: null,
        },

        actionUrl: {
            type: String,
            default: '',
        },

        actionText: {
            type: String,
            default: '',
        },
    },

    computed: {
        hasAdditionalTextSlot() {
            return !!this.$slots['additional-text'];
        },

        iconType() {
            return ICON_MAP[this.type];
        },

        hasAction() {
            return this.actionText && (this.actionHandler || this.actionUrl);
        },

        testId() {
            return this.type === ERROR ? PRODUCT_EXCEPTION : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.base-alert-row {
    @apply flex px-3 py-20p w-full text-light text-left text-s font-bold leading-s rounded-4;

    &.error {
        @apply bg-red5;
    }

    &.success {
        @apply bg-success;
    }

    &.warning {
        @apply bg-yellow3 text-text2;
    }

    &.info {
        @apply bg-gray3;
    }

    &.notification {
        @apply bg-ui-swap-gray900;
    }

    .icon {
        @apply w-4 h-4 mr-2 shrink-0;
        color: inherit;
    }

    .additional-text {
        @apply font-book mt-2;
    }

    .action {
        @apply text-r leading-r block mt-2 font-bold no-underline;
    }
}
</style>
