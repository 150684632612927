export const PSB_FOOTER_CUSTOMER_SERVICE_MENU =
    'psb_footer_customer_service_menu';
export const PSB_FOOTER_REGULATIONS_MENU = 'psb_footer_regulations_menu';
export const PSB_FOOTER_COMPANY_MENU = 'psb_footer_company_menu';
export const PSB_FOOTER_GUIDES_MENU = 'psb_footer_guides_menu';
export const PSB_FOOTER_FOLLOW_US = 'psb_footer_follow_us';

export const PSB_FOOTER_COOKIES = 'psb_footer_cookies';
export const PSB_FOOTER_COOKIES_SCROLL = 'psb_footer_cookies_scroll';
export const PSB_FOOTER_COOKIES_POLICY = 'psb_footer_cookies_policy';
export const PSB_FOOTER_COOKIES_PERSONAL_DATA =
    'psb_footer_cookies_personal_data';
export const PSB_FOOTER_COOKIES_ALERT = 'psb_footer_cookies_alert';

export const PSB_FOOTER_SIMPLE_TOP = 'psb_footer_simple_top';

export const COOKIES_CMS_BLOCKS_IDS = [
    PSB_FOOTER_COOKIES,
    PSB_FOOTER_COOKIES_SCROLL,
    PSB_FOOTER_COOKIES_POLICY,
    PSB_FOOTER_COOKIES_PERSONAL_DATA,
    PSB_FOOTER_COOKIES_ALERT,
];

export const FOOTER_CMS_BLOCKS_IDS = [
    PSB_FOOTER_CUSTOMER_SERVICE_MENU,
    PSB_FOOTER_REGULATIONS_MENU,
    PSB_FOOTER_COMPANY_MENU,
    PSB_FOOTER_GUIDES_MENU,
    PSB_FOOTER_FOLLOW_US,
];

export const FOOTER_SIMPLE_CMS_BLOCKS_IDS = [PSB_FOOTER_SIMPLE_TOP];
