import { LOCALE } from '@jest-helpers/config';

export const categoriesMock = [
    {
        id: '100',
        translations: {
            [LOCALE]: {
                label: 'Odzież',
                locale: LOCALE,
                url: 'kobiety/odzież',
            },
        },
    },
];

export const tree = productsCount => {
    return {
        items: [
            {
                children: [
                    {
                        children: [
                            {
                                children: [],
                                id: '1000',
                                products_count: productsCount,
                                translations: {
                                    [LOCALE]: {
                                        label: 'T-shirty',
                                        locale: LOCALE,
                                        url: 'kobiety/odziez/t-shirty',
                                    },
                                },
                            },
                        ],
                        id: '100',
                        products_count: productsCount,
                        translations: {
                            [LOCALE]: {
                                label: 'Odzież',
                                locale: LOCALE,
                                url: 'kobiety/odziez',
                            },
                        },
                    },
                ],
                id: '10',
                products_count: productsCount,
                translations: {
                    [LOCALE]: {
                        label: 'Kobiety',
                        locale: LOCALE,
                        url: 'kobiety',
                    },
                },
            },
        ],
    };
};
