import { FilterPrefixes } from '../../types/filter-prefixes.js';
import { FilterSeparators } from '../../types/filter-separators.js';
import { FilterTypes } from '../../types/filter-types.js';

const getFilterValue = (filterValueObject) => {
  const value = JSON.parse(JSON.stringify(filterValueObject));
  delete value.attribute;
  const [[filterType, filterValue]] = Object.entries(value).filter(([, i]) => i);
  if (filterType === FilterTypes.RANGE) {
    return [
      FilterPrefixes.RANGE,
      ...filterValue.split(FilterSeparators.FILTER_VALUE_SEPARATOR)
    ];
  }
  return filterValue;
};
var handlerGetters = {
  areFiltersChanged: (state) => {
    const initialEntries = Object.entries(state.initialState);
    const currentEntries = Object.entries(state.selectedParams);
    if (initialEntries.length !== currentEntries.length) {
      return true;
    }
    const filtersChanged = currentEntries.some(([key, params]) => {
      const initialParams = state.initialState[key];
      if (!initialParams) {
        return true;
      }
      if (Object.keys(params.selected).length !== Object.keys(initialParams.selected).length) {
        return true;
      }
      return Object.values(params.selected).some(
        ({ code, url }) => initialParams.selected?.[code]?.url !== url
      );
    });
    const esizemeChanged = state.initialEsizemeScan.id !== state.selectedEsizemeScan.id;
    return filtersChanged || esizemeChanged;
  },
  areExtraFiltersChanged: (state) => {
    const initialEntries = Object.entries(state.initialExtraState);
    const currentEntries = Object.entries(state.selectedExtraParams);
    if (initialEntries.length !== currentEntries.length) {
      return true;
    }
    return currentEntries.some(([key, params]) => {
      const initialParams = state.initialExtraState[key];
      if (!initialParams) {
        return true;
      }
      if (Object.keys(params.selected).length !== Object.keys(initialParams.selected).length) {
        return true;
      }
      return Object.values(params.selected).some(
        ({ code, url }) => initialParams.selected?.[code]?.url !== url
      );
    });
  },
  selectedFiltersCount: (state) => Object.values(state.selectedParams).reduce(
    (acc, { selected }) => {
      return acc + Object.keys(selected).length;
    },
    Object.keys(state.selectedEsizemeScan).length ? 1 : 0
  ),
  selectedExtraFiltersCount: (state) => Object.values(state.selectedExtraParams).reduce((acc, { selected }) => {
    return acc + Object.keys(selected).length;
  }, 0),
  filters: (state) => {
    return Object.values(state.filters).map((filter) => {
      if (filter.type === FilterTypes.GROUP) {
        const selectedCount2 = Object.values(filter.filters).reduce((acc, { code }) => {
          return acc + Object.keys(state.selectedParams?.[code]?.selected || {}).length;
        }, 0);
        return {
          ...filter,
          selectedCount: selectedCount2
        };
      }
      const selectedCount = Object.keys(
        state.selectedParams?.[filter.code]?.selected || {}
      ).length;
      return {
        ...filter,
        selectedCount
      };
    });
  },
  searchQueryFilters: (state) => state.searchQueryFilters,
  selectedParams: (state) => state.selectedParams,
  selectedExtraParams: (state) => state.selectedExtraParams,
  localizedFilters: (state, getters) => {
    const filters = {};
    Object.entries(state.searchQueryLocalized).forEach(([filterName, filterValue]) => {
      if (!getters.availableCodes.includes(filterName) && filterName !== "discount") {
        filters[filterName] = getFilterValue(filterValue);
      }
    });
    return {
      ...filters
    };
  },
  selectedFilters: (state) => {
    const selected = Object.values(state.selectedParams).reduce((acc, filterParams) => {
      const filter = state.availableFilters[filterParams.code];
      if (filter.type === FilterTypes.RANGE) {
        let { min, max } = filterParams.selected;
        if (!min) {
          min = {};
          min.url = filter.min.toString();
        }
        if (!max) {
          max = {};
          max.url = filter.max.toString();
        }
        acc[filterParams.url] = [FilterPrefixes.RANGE, min.url, max.url];
        return acc;
      }
      const urls = Object.values(filterParams.selected).map(({ url }) => url);
      const selectedNode = acc[filterParams.url];
      if (selectedNode) {
        acc[filterParams.url] = [...selectedNode, ...urls];
      } else {
        acc[filterParams.url] = urls;
      }
      return acc;
    }, {});
    const esizeme = state.selectedEsizemeScan;
    if (Object.keys(esizeme).length) {
      const key = esizeme.gender === "MALE" ? "true_size_male" : "true_size_female";
      selected[key] = [
        FilterPrefixes.RANGE,
        ...esizeme.size.split(FilterSeparators.FILTER_VALUE_SEPARATOR)
      ];
    }
    return selected;
  },
  selectedCodes: (state) => {
    const selected = Object.values(state.selectedParams).reduce((acc, filterParams) => {
      const filter = state.availableFilters[filterParams.code];
      if (filter.type === FilterTypes.RANGE) {
        let { min, max } = filterParams.selected;
        if (!min) {
          min = {};
          min.url = filter.min.toString();
        }
        if (!max) {
          max = {};
          max.url = filter.max.toString();
        }
        acc[filterParams.code] = [min.url, max.url];
        return acc;
      }
      const codes = Object.values(filterParams.selected).map(({ code }) => code);
      const selectedNode = acc[filterParams.code];
      if (selectedNode) {
        acc[filterParams.code] = [...selectedNode, ...codes];
      } else {
        acc[filterParams.code] = codes;
      }
      return acc;
    }, {});
    const esizeme = state.selectedEsizemeScan;
    if (Object.keys(esizeme).length) {
      selected.esizeme = [...esizeme.size.split(FilterSeparators.FILTER_VALUE_SEPARATOR)];
    }
    return selected;
  },
  selectedLabels: (state) => {
    const selected = Object.values(state.selectedParams).reduce((acc, filterParams) => {
      const filter = state.availableFilters[filterParams.code];
      if (filter.type === FilterTypes.RANGE) {
        const range = [];
        const { min, max } = filterParams.selected;
        if (!min) {
          range[0] = filter.min;
        } else {
          range[0] = min.url;
        }
        if (!max) {
          range[1] = filter.max;
        } else {
          range[1] = max.url;
        }
        acc[filterParams.code] = [range.join("-")];
        return acc;
      }
      if (filter.type === FilterTypes.NESTED_MULTISELECT) {
        const groups = {};
        Object.values(filterParams.selected).forEach(({ label }) => {
          const [groupLabel, nestedLabel] = label.split(":");
          if (!groups[groupLabel]) {
            groups[groupLabel] = [];
          }
          groups[groupLabel].push(nestedLabel);
        });
        const labels2 = Object.entries(groups).map(
          ([groupLabel, nestedLabels]) => `${groupLabel}: ${nestedLabels.join(", ")}`
        );
        const selectedNode2 = acc[filterParams.code];
        if (selectedNode2) {
          acc[filterParams.code] = [...selectedNode2, ...labels2];
        } else {
          acc[filterParams.code] = labels2;
        }
        return acc;
      }
      const labels = Object.values(filterParams.selected).map(({ label }) => label);
      const selectedNode = acc[filterParams.code];
      if (selectedNode) {
        acc[filterParams.code] = [...selectedNode, ...labels];
      } else {
        acc[filterParams.code] = labels;
      }
      return acc;
    }, {});
    const esizeme = state.selectedEsizemeScan;
    if (Object.keys(esizeme).length) {
      selected.esizeme = [esizeme.name];
    }
    return selected;
  },
  selectedExtraLabels: (state) => {
    const selected = {};
    Object.values(state.selectedExtraParams).forEach((filterParams) => {
      selected[filterParams.code] = Object.values(filterParams.selected).map(
        ({ label }) => label
      );
    });
    return selected;
  },
  selectedTags: (state) => {
    const selected = Object.values(state.selectedParams).reduce((acc, filterParams) => {
      const tags = Object.values(filterParams.selected).map(({ code, label }) => ({
        tagCode: code,
        tagLabel: label
      }));
      const selectedNode = acc[filterParams.code];
      if (selectedNode) {
        acc[filterParams.code] = {
          ...selectedNode,
          tags: [...selectedNode.tags, ...tags]
        };
      } else {
        acc[filterParams.code] = {
          label: filterParams.label,
          type: state.availableFilters[filterParams.code].type,
          tags
        };
      }
      return acc;
    }, {});
    const esizeme = state.selectedEsizemeScan;
    if (Object.keys(esizeme).length) {
      selected.esizeme = {
        label: "Esizeme",
        type: FilterTypes.ESIZEME,
        tags: [{ tagCode: "esizeme", tagLabel: esizeme.name }]
      };
    }
    return selected;
  },
  selectedExtraTags: (state) => {
    return Object.values(state.selectedExtraParams).reduce((acc, filterParams) => {
      const tags = Object.values(filterParams.selected).map(({ code, label }) => ({
        tagCode: code,
        tagLabel: label
      }));
      const selectedNode = acc[filterParams.code];
      if (selectedNode) {
        acc[filterParams.code] = {
          ...selectedNode,
          tags: [...selectedNode.tags, ...tags]
        };
      } else {
        acc[filterParams.code] = {
          label: filterParams.label,
          type: state.searchQueryLabels[filterParams.code].type,
          tags
        };
      }
      return acc;
    }, {});
  },
  availableCodes: (state) => Object.keys(state.availableFilters),
  availableFilters: (state) => state.availableFilters,
  urlFilters: (state) => state.urlFilters,
  leftoverFilters: (state) => {
    return Object.fromEntries(
      Object.entries(state.urlFilters).filter(([key]) => {
        if (key.includes("true_size")) {
          return false;
        }
        return !Object.values(state.availableFilters).some((filter) => {
          return filter.url === key;
        });
      })
    );
  },
  selectedExtraFilters: (state) => {
    return Object.values(state.selectedExtraParams).reduce((acc, filterParams) => {
      if (filterParams.selected.range) {
        const [min, max] = filterParams.selected.range.url.split("-");
        acc[filterParams.url] = [FilterPrefixes.RANGE, min, max];
        return acc;
      }
      const urls = Object.values(filterParams.selected).map(({ url }) => url);
      const selectedNode = acc[filterParams.url];
      if (selectedNode) {
        acc[filterParams.url] = [...selectedNode, ...urls];
      } else {
        acc[filterParams.url] = urls;
      }
      return acc;
    }, {});
  },
  selectedEsizemeScan: (state) => state.selectedEsizemeScan,
  esizemeRangeBoundaries: (state) => {
    if (state.esizemeRangeBoundaries.length) {
      return {
        below: state.esizemeRangeBoundaries[0],
        above: state.esizemeRangeBoundaries[1]
      };
    }
    return {
      below: 0,
      above: 0
    };
  },
  isEsizemeFilterChanged: (state) => {
    return state.initialEsizemeScan.id !== state.selectedEsizemeScan.id;
  }
};

export { handlerGetters as default };
