<template>
    <ContentContainer class="checkout-footer-links">
        <Grid cols="12">
            <Column cols="12">
                <StaticHtmlLinkHandler>
                    <StaticBlock :content="footerSimpleTopStaticBlockContent" />
                </StaticHtmlLinkHandler>
            </Column>
        </Grid>
    </ContentContainer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PSB_FOOTER_SIMPLE_TOP } from '@configs/footer';

import { ContentContainer } from '@eobuwie-ui/components/ContentContainer/v1';
import { Column, Grid } from '@eobuwie-ui/components/Grid/v1';

import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';

import StaticBlock from '@atoms/StaticBlock/StaticBlock';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'CheckoutFooterLinks',

    components: {
        Column,
        ContentContainer,
        Grid,
        StaticHtmlLinkHandler,
        StaticBlock,
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),

        footerSimpleTopStaticBlockContent() {
            return this.staticBlockById(PSB_FOOTER_SIMPLE_TOP)?.content || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.checkout-footer-links {
    @apply pt-ui-6 border-t-ui-1 border-ui-swap-gray200;

    .grid {
        @apply px-ui-4;
    }

    .column {
        @apply flex justify-center;
    }

    @screen ui-tablet {
        .grid {
            @apply px-ui-0;
        }
    }

    @screen ui-desktop {
        @apply border-t-ui-0 pt-ui-0;
    }

    &:deep() {
        .link-list {
            @apply flex flex-wrap justify-center gap-x-ui-6 gap-y-ui-4;
        }

        .link {
            @apply block font-ui-body-s leading-ui-20;

            &:hover {
                @apply underline;
            }
        }
    }
}
</style>
