export const CMS_BLOCK_ID = 'psb_smart_banner';

export const VIEWS_LIMIT = 15;

export const COOKIE_NAME_VIEWS_COUNT = 'smart-banner-views-count';
export const COOKIE_NAME_RECENTLY_VIEWED = 'smart-banner-recently-viewed';
export const COOKIE_EXPIRE_TIME_ALL_VIEWS = 90 * 24 * 60 * 60;
export const COOKIE_EXPIRE_TIME_RECENT_VIEW = 60 * 60;

export const ONE_LINK_SCRIPT_URL =
    'https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js';
