import { IBAN_COUNTRIES_SPECIFICATION } from '@core-configs/iban-countries-specification';

export const BANK_ACCOUNT_LENGTH_VALIDATION_RULE_NAME =
    'bankAccountNumberLength';
export const BANK_ACCOUNT_STARTS_WITH_DIGITS_VALIDATION_RULE_NAME =
    'bankAccountStartsWithDigits';

const IBAN_CONFIG_NOT_FOUND_VALUE = -1;

export const calculateBankAccountNumberLength = ibanCountryCode => {
    const { ibanLength, countryCode } =
        IBAN_COUNTRIES_SPECIFICATION[ibanCountryCode] || {};

    if (ibanLength && countryCode) {
        return ibanLength - countryCode.length;
    }

    return IBAN_CONFIG_NOT_FOUND_VALUE;
};

export const validate = (bankAccountNumber, [ibanCountryCode]) => {
    const bankAccountNumberLength = calculateBankAccountNumberLength(
        ibanCountryCode
    );

    if (bankAccountNumberLength === IBAN_CONFIG_NOT_FOUND_VALUE) {
        return false;
    }

    return (
        bankAccountNumber.replace(/\s/g, '').length === bankAccountNumberLength
    );
};

const getBankAccountLengthValidationRule = i18n => {
    return {
        validate,

        message: (_, params) => {
            // params is an object with key '0'
            const ibanCountryCode = params['0'];

            const bankAccountNumberRequiredLength = calculateBankAccountNumberLength(
                ibanCountryCode
            );

            if (
                bankAccountNumberRequiredLength === IBAN_CONFIG_NOT_FOUND_VALUE
            ) {
                return '';
            }

            return i18n.t('__validations.__rma.bankAccountInvalidLength', {
                length: bankAccountNumberRequiredLength,
            });
        },
    };
};

const getBankAccountStartsWithDigitsValidationRule = i18n => {
    return {
        // bank account number must start with digits only
        validate: bankAccountNumber => /^\d/.test(bankAccountNumber),

        message: () =>
            i18n.t('__validations.__rma.bankAccountMustStartWithDigits'),
    };
};

export default i18n => [
    {
        name: BANK_ACCOUNT_LENGTH_VALIDATION_RULE_NAME,
        validation: getBankAccountLengthValidationRule(i18n),
    },
    {
        name: BANK_ACCOUNT_STARTS_WITH_DIGITS_VALIDATION_RULE_NAME,
        validation: getBankAccountStartsWithDigitsValidationRule(i18n),
    },
];
