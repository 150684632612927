import RMA_SERVICES_NAMESPACE from '@rma/async-services/namespace';

export const NAME = `${RMA_SERVICES_NAMESPACE}/carriers`;

export const CHECK_DELIVERY_ADDRESS = 'CHECK_DELIVERY_ADDRESS';

export const ASYNC_CARRIERS_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        CHECK_DELIVERY_ADDRESS,
    },
};
