import setErrorHandlerPageContext from '@assets/error';

export default function ({ app, route, $correlation }) {
    if (process.server) {
        return;
    }

    setErrorHandlerPageContext(
        app.$errorHandler,
        route.name,
        $correlation.correlationId
    );
}
