const types = {
  SET_SELECTED_PARAMS: "SET_SELECTED_PARAMS",
  SET_AVAILABLE_FILTERS: "SET_AVAILABLE_FILTERS",
  SET_URL_FILTERS: "SET_URL_FILTERS",
  SET_FILTERS: "SET_FITLERS",
  SET_INITIAL_STATE: "SET_INITIAL_STATE",
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  RESTORE_FILTERS: "RESTORE_FILTERS",
  SET_ESIZEME_RANGE_BOUNDARIES: "SET_ESIZEME_RANGE_BOUNDARIES",
  SET_SELECTED_ESIZEME_SCAN: "SET_SELECTED_ESIZEME_SCAN",
  SET_INITIAL_ESIZEME_SCAN: "SET_INITIAL_ESIZEME_SCAN",
  SET_SELECTED_EXTRA_PARAMS: "SET_SELECTED_EXTRA_PARAMS",
  SET_INITIAL_EXTRA_STATE: "SET_INITIAL_EXTRA_STATE"
};
var handlerMutations = {
  [types.SET_SELECTED_PARAMS](state, selectedParams) {
    state.selectedParams = selectedParams;
  },
  [types.SET_INITIAL_STATE](state, selectedParams) {
    state.initialState = selectedParams;
  },
  [types.SET_FILTERS](state, filters) {
    state.filters = filters;
  },
  [types.SET_AVAILABLE_FILTERS](state, filters) {
    state.availableFilters = filters;
  },
  [types.SET_URL_FILTERS](state, urlFilters) {
    state.urlFilters = urlFilters;
  },
  [types.RESTORE_FILTERS](state) {
    state.selectedParams = state.initialState;
  },
  [types.SET_SELECTED_ESIZEME_SCAN](state, esizemeScan) {
    state.selectedEsizemeScan = esizemeScan;
  },
  [types.SET_INITIAL_ESIZEME_SCAN](state, esizemeScan) {
    state.initialEsizemeScan = esizemeScan;
  },
  [types.SET_ESIZEME_RANGE_BOUNDARIES](state, rangeBoundaries) {
    state.esizemeRangeBoundaries = rangeBoundaries;
  },
  [types.SET_SEARCH_QUERY](state, { filters, localized, labels }) {
    state.searchQueryLabels = labels;
    state.searchQueryLocalized = localized;
    state.searchQueryFilters = filters;
  },
  [types.SET_SELECTED_EXTRA_PARAMS](state, extraParams) {
    state.selectedExtraParams = extraParams;
  },
  [types.SET_INITIAL_EXTRA_STATE](state, extraParams) {
    state.initialExtraState = extraParams;
  }
};

export { handlerMutations as default, types };
