import { getMissingError } from '../assets/errors.js';

class Filter {
  selected;
  code;
  type;
  url;
  label;
  initialParamsKeys;
  selectedFilterParams;
  addFilter;
  removeFilter;
  constructor(filterAttributes, addFilter, removeFilter) {
    const { code, label, url, selected, type } = filterAttributes;
    ["code", "label", "url", "selected", "type"].forEach((key) => {
      if (typeof filterAttributes[key] === "undefined") {
        throw getMissingError(key);
      }
    });
    this.addFilter = addFilter;
    this.removeFilter = removeFilter;
    this.code = code;
    this.selected = selected;
    this.label = label;
    this.url = url;
    this.type = type;
    this.initialParamsKeys = [];
    this.selectedFilterParams = {};
  }
  get selectedCount() {
    return Object.keys(this.selectedFilterParams).length;
  }
  get isFilterChanged() {
    const paramsKeys = Object.keys(this.selectedFilterParams);
    if (paramsKeys.length !== this.initialParamsKeys.length) {
      return true;
    }
    return !paramsKeys.every((key) => this.initialParamsKeys.includes(key));
  }
  applyFilter() {
    if (Object.keys(this.selectedFilterParams).length) {
      this.addFilter(this);
    } else {
      this.removeFilter(this);
    }
  }
  clearFilter() {
    this.selectedFilterParams = {};
  }
}

export { Filter as default };
