export default async function ({ app, params, route, error }) {
    const { name } = route;

    if (app.i18n.tPathKey(name) !== params[name]) {
        return error({
            message: 'Page does not exist',
            statusCode: 404,
        });
    }
}
