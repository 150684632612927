import { getMissingError, getUnknownFilterTypeError } from '../assets/errors.js';
import { FilterTypes } from '../types/filter-types.js';

class FilterGroup {
  filters;
  code;
  type;
  label;
  constructor(filters, filterAttributes) {
    ["code", "label", "type"].forEach((key) => {
      if (typeof filterAttributes[key] === "undefined") {
        throw getMissingError(key);
      }
    });
    const { code, label, type } = filterAttributes;
    this.type = type;
    this.label = label;
    this.code = code;
    this.filters = {};
    if (!filters) {
      throw getMissingError("filters");
    }
    filters.forEach((filter) => {
      this.filters = {
        ...this.filters,
        [filter.code]: filter
      };
    });
  }
  get selectedCount() {
    return Object.values(this.filters).reduce((acc, filter) => {
      return acc + filter.selectedCount;
    }, 0);
  }
  get isFilterChanged() {
    return Object.values(this.filters).some((filter) => {
      return filter.isFilterChanged;
    });
  }
  updateFilter(filterData, rawFilterData) {
    const newFilterCodes = Object.keys(filterData.filters);
    const oldFilterCodes = Object.keys(this.filters);
    const filtersToRemove = oldFilterCodes.filter((code) => !newFilterCodes.includes(code));
    const filtersToAdd = newFilterCodes.filter((code) => !oldFilterCodes.includes(code));
    const filtersToUpdate = newFilterCodes.filter((code) => oldFilterCodes.includes(code));
    filtersToRemove.forEach((code) => {
      const { [code]: toRemove, ...rest } = this.filters;
      this.filters = rest;
    });
    filtersToAdd.forEach((code) => {
      this.filters = {
        ...this.filters,
        [code]: filterData.filters[code]
      };
    });
    filtersToUpdate.forEach((code) => {
      const filterUpdateData = rawFilterData.filters.find(
        ({ code: rawCode }) => rawCode === code
      );
      if (filterUpdateData) {
        const filter = this.filters[code];
        switch (filter.type) {
          case FilterTypes.MULTISELECT: {
            filter.updateFilter(
              filterUpdateData
            );
            break;
          }
          case FilterTypes.NESTED_MULTISELECT: {
            filter.updateFilter(
              filterUpdateData
            );
            break;
          }
          case FilterTypes.RANGE: {
            filter.updateFilter(
              filterUpdateData
            );
            break;
          }
          case FilterTypes.TOGGLE: {
            filter.updateFilter(
              filterUpdateData
            );
            break;
          }
          default: {
            throw getUnknownFilterTypeError(filter);
          }
        }
      }
    });
  }
  applyFilter() {
    Object.values(this.filters).forEach((filter) => filter.applyFilter());
  }
  clearFilter() {
    Object.values(this.filters).forEach((filter) => filter.clearFilter());
  }
}

export { FilterGroup as default };
