const GENERAL_TRANSLATIONS_KEY = 'translations';
const VALIDATION_TRANSLATIONS_KEY = '__validations';
const PATH_TRANSLATIONS_KEY = '__paths';
const LABEL_TRANSLATIONS_KEY = '__labels';

module.exports = {
    GENERAL_TRANSLATIONS_KEY,
    VALIDATION_TRANSLATIONS_KEY,
    PATH_TRANSLATIONS_KEY,
    LABEL_TRANSLATIONS_KEY,
};
