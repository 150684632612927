<template>
    <div class="invite-friend-message">
        <SvgIcon class="icon" :width="ICON_SIZE" :height="ICON_SIZE">
            <Present />
        </SvgIcon>
        <div class="content">
            <BaseParagraph class="text">{{ text }}</BaseParagraph>
            <BaseHeading tag="h5" theme="h5" class="heading">
                {{ heading }}
            </BaseHeading>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { BENEFITS_MAP_BY_STORE_CODE } from '@configs/invite-friend';

import Present from '@static/icons/48px/present.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'InviteFriendMessage',

    components: {
        SvgIcon,
        Present,
        BaseParagraph,
        BaseHeading,
    },

    props: {
        firstRowText: {
            type: String,
            default: '',
        },

        secondRowText: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapConfigGetters(['storeCode']),

        text() {
            return (
                this.firstRowText ||
                this.$t('You have an invitation to eobuwie')
            );
        },

        heading() {
            const { currency = '', value = '' } =
                BENEFITS_MAP_BY_STORE_CODE[this.storeCode] || {};

            const defaultHeading = this.$t(
                'Create an account and get {value} {currency}!',
                {
                    currency,
                    value,
                }
            );

            return this.secondRowText || defaultHeading;
        },
    },

    beforeCreate() {
        this.ICON_SIZE = 48;
    },
};
</script>

<style scoped lang="scss">
.invite-friend-message {
    @apply flex items-center px-3 py-12p bg-green1;

    .icon {
        @apply mr-3 text-light;
    }

    .text {
        @apply text-light text-r leading-r;
    }

    .heading {
        @apply text-light text-m leading-m;
    }

    @screen lg {
        @apply py-3;

        .icon {
            @apply mr-20p;
        }
    }
}
</style>
