const separator = '-';

export const formatBirthdate = (day, month, year) =>
    [year, month, day].join(separator);

export const parseBirthdate = birthdate => {
    const [year, month, day] = birthdate.split(separator);

    return {
        day,
        month,
        year,
    };
};
