<template>
    <component
        :is="element"
        :key="isLoading"
        :type="to ? null : type"
        :class="['base-button', theme, size, color]"
        :target="$attrs.target"
        :disabled="$attrs.disabled"
        v-bind="attrs"
        v-on="$listeners"
    >
        <span v-if="isLoading" class="button-content hidden">
            <slot />
        </span>
        <slot v-else />

        <SvgIcon
            v-if="isLoading"
            :width="iconSize"
            :height="iconSize"
            class="icon"
        >
            <component :is="loaderIcon" />
        </SvgIcon>
    </component>
</template>

<script>
import { COLORS, SIZE_SMALL } from '@types/Button';

import LoaderBig from '@static/icons/24px/loader.svg?inline';
import LoaderSmall from '@static/icons/20px/loader.svg?inline';

import Button from '@mixins/Button';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

export default {
    name: 'BaseButton',

    components: {
        LoaderBig,
        LoaderSmall,
        SvgIcon,
    },

    mixins: [Button],

    inheritAttrs: false,

    props: {
        color: {
            type: String,
            default: COLORS.COLOR_BLACK,
            validator: value => Object.values(COLORS).includes(value),
        },

        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        element() {
            if (!this.to) {
                return 'button';
            }

            return this.isHttpLink ? 'a' : 'nuxt-link';
        },

        isHttpLink() {
            return (
                typeof this.to === 'string' &&
                (this.to.startsWith('#') ||
                    this.to.match(/^https?:\/\//) !== null)
            );
        },

        attrs() {
            const attrs = { ...this.$attrs };

            if (this.isHttpLink) {
                attrs.href = this.to;
            } else {
                attrs.to = this.to;
            }

            return attrs;
        },

        iconSize() {
            return this.size === SIZE_SMALL ? '20px' : '24px';
        },

        loaderIcon() {
            return this.size === SIZE_SMALL ? LoaderSmall : LoaderBig;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/focus-visible.scss';

@mixin boxShadow($width, $color: false) {
    @if $color {
        box-shadow: inset 0 0 0 $width $color;
    } @else {
        // if color is absent then it's inherited from text color
        box-shadow: inset 0 0 0 $width;
    }
}

@mixin boxShadowBase($color: false) {
    @include boxShadow(2px, $color);
}

@mixin boxShadowHover($color: false) {
    @include boxShadow(3px, $color);
}

@mixin hoverAndActive() {
    &:hover,
    &:active,
    &.is-active {
        @content;
    }
}

@mixin textColor($text, $textHover, $textDisabled) {
    color: $text;

    @include hoverAndActive {
        color: $textHover;
    }

    &[disabled] {
        color: $textDisabled;
    }
}

@mixin backgroundColor($bg, $bgHover, $bgDisabled) {
    background: $bg;

    @include hoverAndActive {
        background: $bgHover;
    }

    &[disabled] {
        background: $bgDisabled;
    }
}

@mixin border($border, $borderHover, $borderDisabled) {
    @include boxShadowBase($border);
    box-shadow: inset 0 0 0 2px $border;

    @include hoverAndActive {
        @include boxShadowHover($borderHover);
    }

    &[disabled] {
        @include boxShadowBase($borderDisabled);
    }
}

.base-button {
    @apply inline-flex items-center justify-center px-4 font-bold relative min-h-xxl;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    @include focusOutlineNone();

    &:active {
        @apply outline-none;
    }

    &[disabled] {
        @apply pointer-events-none;
    }

    .button-content {
        @apply flex;
    }

    .hidden {
        @apply invisible;
    }

    .icon {
        @apply absolute;
        color: currentColor;
    }

    &.small {
        @apply text-s leading-s min-h-xl;
    }

    &.primary {
        @include textColor(
            #{theme('colors.light')},
            #{theme('colors.light')},
            #{theme('colors.light')}
        );
    }

    &.secondary {
        @include boxShadowBase();

        @include hoverAndActive {
            @include boxShadowHover();
        }
    }

    &.tertiary {
        @include boxShadowBase(theme('colors.gray5'));

        @include hoverAndActive {
            @include boxShadowHover(theme('colors.gray4'));
        }

        &[disabled] {
            @include boxShadowBase();
        }
    }

    &.secondary,
    &.tertiary {
        @include hoverAndActive {
            background: theme('colors.gray8');
        }
    }

    &.pure {
        @include hoverAndActive {
            background: theme('colors.gray6');
        }
    }

    &.black {
        &.primary {
            @include backgroundColor(
                theme('colors.gray2'),
                theme('colors.gray1'),
                theme('colors.gray4')
            );
        }

        &.secondary,
        &.tertiary,
        &.pure {
            @include textColor(
                theme('colors.gray2'),
                theme('colors.gray1'),
                theme('colors.gray4')
            );
        }
    }

    &.green {
        &.primary {
            @include backgroundColor(
                theme('colors.green2'),
                theme('colors.green1'),
                theme('colors.green4')
            );
        }

        &.secondary,
        &.tertiary,
        &.pure {
            @include textColor(
                theme('colors.green2'),
                theme('colors.green1'),
                theme('colors.green4')
            );
        }
    }

    &.red {
        &.primary {
            @include backgroundColor(
                theme('colors.red2'),
                theme('colors.red1'),
                theme('colors.red2')
            );

            @include textColor(
                theme('colors.gray2'),
                theme('colors.light'),
                theme('colors.light')
            );
        }

        &.secondary,
        &.tertiary,
        &.pure {
            @include textColor(
                theme('colors.red1'),
                theme('colors.red1'),
                theme('colors.red2')
            );
        }
    }

    &.blue {
        &.primary {
            @include backgroundColor(
                theme('colors.blue2'),
                theme('colors.blue3'),
                theme('colors.blue4')
            );
        }

        &.secondary,
        &.tertiary,
        &.pure {
            @include textColor(
                theme('colors.blue2'),
                theme('colors.blue3'),
                theme('colors.blue4')
            );
        }
    }

    &.blue3 {
        &.primary {
            @include backgroundColor(
                theme('colors.blue3'),
                theme('colors.blue2'),
                theme('colors.blue4')
            );
        }

        &.secondary,
        &.tertiary,
        &.pure {
            @include textColor(
                theme('colors.blue3'),
                theme('colors.blue2'),
                theme('colors.blue4')
            );
        }
    }

    &.white {
        &.primary {
            @include backgroundColor(
                theme('colors.light'),
                theme('colors.gray8'),
                theme('colors.gray3')
            );

            @include textColor(
                theme('colors.gray2'),
                theme('colors.gray1'),
                theme('colors.gray1')
            );
        }

        &.secondary,
        &.tertiary,
        &.pure {
            @include textColor(
                theme('colors.light'),
                theme('colors.gray8'),
                theme('colors.gray3')
            );

            @include hoverAndActive {
                background: theme('colors.gray1');
            }
        }
    }

    &.white-blue {
        &.primary {
            @include backgroundColor(
                theme('colors.light'),
                theme('colors.blue4'),
                theme('colors.gray5')
            );

            @include textColor(
                theme('colors.blue2'),
                theme('colors.blue2'),
                theme('colors.light')
            );
        }

        &.secondary,
        &.tertiary,
        &.pure {
            @include textColor(
                theme('colors.light'),
                theme('colors.blue2'),
                theme('colors.gray5')
            );
        }

        &.secondary,
        &.tertiary {
            @include border(
                theme('colors.light'),
                theme('colors.light'),
                theme('colors.gray5')
            );

            @include hoverAndActive {
                background: theme('colors.blue4');
            }
        }
    }

    &.gray {
        &.primary {
            @include backgroundColor(
                theme('colors.gray3'),
                theme('colors.gray2'),
                theme('colors.gray4')
            );
        }
    }
}
</style>
