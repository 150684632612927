<template>
    <div class="navigation-content">
        <div class="nav-limiter" :style="navLimiterStyle">
            <section
                v-for="(content, index) in categories"
                :key="content.id"
                class="link-container"
                :class="linkContainerClass(content.columns)"
            >
                <h3 class="category-title">{{ content.title }}</h3>
                <div
                    class="navigation-links-container"
                    :class="navigationLinksContainerClass(content.columns)"
                    :style="
                        getContainerMaxHeight(
                            content.categoryLinks.length,
                            content.columns
                        )
                    "
                >
                    <NavigationNestedItem
                        v-for="link in content.categoryLinks"
                        :key="link.path"
                        :category="link"
                        class="nav-item"
                    />

                    <NavigationNestedItem
                        v-if="content.showAll"
                        :category="content.showAll"
                        :data-test-id="NAVIGATION_SHOW_ALL_LINK"
                        class="nav-item"
                    >
                        <span class="view-all">
                            {{ $t('View all') }}
                        </span>
                    </NavigationNestedItem>
                </div>
                <Divider
                    v-if="
                        index !== categories.length && !!categories[index + 1]
                    "
                    :variant="DIVIDER_VARIANT"
                    class="nav-divider"
                />
            </section>
        </div>
    </div>
</template>

<script>
import { NAVIGATION_SHOW_ALL_LINK } from '@types/AutomaticTestIDs';

import NavigationNestedItem from '@header-atoms/NavigationItem/NavigationNestedItem';

import { Divider, DIVIDER_VARIANTS } from '@eobuwie-ui/components/Divider/v1';

const ITEMS_PER_COLUMN = 10;

export default {
    name: 'NavigationContent',

    components: {
        NavigationNestedItem,
        Divider,
    },

    props: {
        categories: {
            type: Array,
            required: true,
        },
    },

    computed: {
        columns() {
            let columns = 0;

            this.categories.forEach(category => {
                columns += Math.ceil(
                    category.categoryLinks.length / ITEMS_PER_COLUMN
                );
            });

            return columns;
        },

        navLimiterStyle() {
            return {
                'grid-template-columns': `repeat(${this.columns}, minmax(0, 1fr))`,
            };
        },
    },

    beforeCreate() {
        this.DIVIDER_VARIANT = DIVIDER_VARIANTS.M;
        this.NAVIGATION_SHOW_ALL_LINK = NAVIGATION_SHOW_ALL_LINK;
    },

    methods: {
        getContainerMaxHeight(numberOfLinks, columns) {
            if (numberOfLinks <= 10) {
                return null;
            }

            return {
                maxHeight: `${Math.ceil(numberOfLinks / columns) * 52 - 4}px`,
            };
        },

        navigationLinksContainerClass(columns) {
            return {
                'one-column': columns === 1,
                'double-column': columns === 2,
                'three-column': columns > 2,
            };
        },

        linkContainerClass(columns) {
            return {
                [`col-span-${columns}`]: true,
            };
        },
    },
};
</script>

<style scoped lang="scss">
.navigation-content {
    .nav-limiter {
        @apply flex flex-col;
    }

    .category-title {
        @apply font-ui-header-m-bold lowercase truncate pb-ui-4 px-ui-4;

        &:first-letter {
            @apply uppercase;
        }
    }

    .view-all {
        @apply font-ui-body-m-bold;
    }

    .nav-divider {
        @apply my-ui-7;
    }

    @screen lg {
        .nav-limiter {
            @apply px-ui-7 max-w-[1440px] mx-auto py-ui-5;
            @apply max-h-[600px] overflow-y-auto scroll-ui;
            @apply grid;
        }

        .navigation-links-container {
            @apply flex flex-col flex-wrap gap-y-ui-1;
        }

        .one-column {
            .nav-item {
                @apply w-full;
            }
        }

        .double-column {
            .nav-item {
                @apply w-1/2;
            }
        }

        .three-column {
            .nav-item {
                @apply w-1/3;
            }
        }

        .nav-divider {
            @apply hidden;
        }

        .category-title {
            @apply px-0 pb-ui-2;
        }

        .link-container {
            @apply flex flex-col gap-y-ui-1 pr-ui-2;
        }
    }
}
</style>
