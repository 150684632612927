var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isStaticBlocksAvailable)?_c('footer',{staticClass:"base-footer"},[_c('LazyHydrate',{attrs:{"when-visible":true}},[_c('FooterTop',{attrs:{"static-blocks":_vm.staticBlocks}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":true}},[_c('FooterBottom')],1),_vm._v(" "),(_vm.isNotBot && !_vm.isFetchingConsentsData)?[(_vm.isConsentsModalConfigured)?_c('ConsentsDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
                agreeConsents,
                categories,
                closeModal,
                isModalOpen,
                openModal,
                providers,
                setStoredCategories,
                isTrustedPartnersModalOpen,
                isCookieSettingsModalOpen,
                openTrustedPartnersModal,
                closeTrustedPartnersModal,
                openCookieSettingsModal,
                closeCookieSettingsModal,
            }){return [_c('LoadCookieModal',{attrs:{"categories":categories,"is-consents-modal-open":isModalOpen,"is-trusted-partners-modal-open":isTrustedPartnersModalOpen,"is-cookie-settings-modal-open":isCookieSettingsModalOpen,"providers":providers},on:{"agree-all-consents":function($event){return agreeConsents(true)},"agree-necessary-consents":function($event){return agreeConsents()},"open-consents-modal":function($event){return openModal()},"close-consents-modal":function($event){return closeModal()},"open-trusted-partners-modal":function($event){return openTrustedPartnersModal()},"close-trusted-partners-modal":function($event){return closeTrustedPartnersModal()},"open-cookie-settings-modal":function($event){return openCookieSettingsModal()},"close-cookie-settings-modal":function($event){return closeCookieSettingsModal()},"set-stored-categories":function($event){return setStoredCategories($event)}}})]}}],null,false,3625047561)}):_c('CheckCookieExist',{attrs:{"cookie-name":_vm.cookiesModalCookieName},scopedSlots:_vm._u([{key:"default",fn:function({ isCookieExist }){return [(_vm.displayCookiesModal(isCookieExist))?_c('CookiesApprovals'):_vm._e()]}}],null,false,1459768178)})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }