import { RECENTLY_VIEWED_PRODUCTS_KEY } from '@configs/storage';
import { RECENTLY_VIEWED_PRODUCTS_LIMIT } from '@configs/recently-viewed-products';

export default $storage => ({
    add(sku) {
        const products = this.get();
        const productsNew = products.filter(productSku => productSku !== sku);

        productsNew.unshift(sku);
        this.set(productsNew.slice(0, RECENTLY_VIEWED_PRODUCTS_LIMIT));
    },
    get() {
        return $storage.getItem(RECENTLY_VIEWED_PRODUCTS_KEY) || [];
    },
    set(products) {
        $storage.setItem(RECENTLY_VIEWED_PRODUCTS_KEY, products);
    },
});
