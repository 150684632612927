import { SEARCH_QUERY_INPUT_CATEGORIES } from '@configs/graphql-keys/search-query-input';
import {
    REQUEST_CURRENT_PAGE,
    REQUEST_PAGE_SIZE,
} from '@configs/graphql-keys/pagination';

export const createProductsRequestParams = ({
    pagination,
    filters,
    sort,
    categoryUrl,
    locale,
    currency,
}) => {
    const { currentPage, pageSize } = pagination;
    const { orderCode, orderDir } = sort;

    const params = {};

    params[REQUEST_CURRENT_PAGE] = currentPage;
    params[REQUEST_PAGE_SIZE] = pageSize;
    params.locale = locale;
    params.currency = currency;

    if (orderCode) {
        params.sortType = orderCode;
        params.sortOrder = orderDir;
    }

    if (categoryUrl) {
        params[SEARCH_QUERY_INPUT_CATEGORIES] = [categoryUrl];
    }

    if (Object.keys(filters).length) {
        params.filters = filters;
    }

    return params;
};
