import { ORDER_ITEM_SKU_PRODUCT_KEY } from '@configs/mage-order-attributes';
import { ITEM_SKU_PRODUCT_KEY } from '@configs/mage-order-based-on-cart-attributes';

import { FORMAT_DDMMYYYY_DOT } from '@types/DateFormat';
import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

import { formatFullDate } from '@assets/date';

export const formatCreatedDate = (order, locale) => {
    return {
        ...order,
        created_at: formatFullDate(
            order.created_at,
            locale,
            FORMAT_DDMMYYYY_DOT
        ),
    };
};

export const getIndexesFromOrderItems = orderItems => {
    return orderItems
        .map(
            ({
                [ORDER_ITEM_SKU_PRODUCT_KEY]: productParent,
                [ITEM_SKU_PRODUCT_KEY]: product,
            }) => {
                const sku = productParent?.sku || product?.sku || '';
                const [index] = sku.split('-');

                return index;
            }
        )
        .filter(index => !!index);
};

export const getProductDataMapForOrderItems = async (items, appContext) => {
    const { locale, currency } = appContext;

    const indexes = getIndexesFromOrderItems(items);

    if (!indexes.length) {
        return {};
    }

    const {
        items: searchProducts,
    } = await appContext.$services.product.getByIndexes({
        indexes,
        limit: indexes.length,
        locale,
        selectLocales: [DEFAULT_LOCALE],
        currency,
    });

    return Object.fromEntries(
        searchProducts.map(product => [product.id, product])
    );
};
