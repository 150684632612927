import { ASYNC_LOYALTY_POINTS_ESTIMATOR_SERVICE_CONFIG } from '@loyalty-club/async-services/points-estimator/meta';

const POSSIBLE_POINTS_NAMES = {
    POINTS_FOR_JOINING_PROGRAM: 'POINTS_FOR_JOINING_PROGRAM',
    POINTS_FOR_BIRTH_DATE_PROVIDED: 'POINTS_FOR_BIRTH_DATE_PROVIDED',
    BIRTHDAY_GIFT: 'BIRTHDAY_GIFT',
    MONEY_TO_POINTS_CONVERSION_BASIC: 'MONEY_TO_POINTS_CONVERSION_BASIC',
    ADDITIONAL_POINTS_FOR_PRODUCT_PARAMETER:
        'ADDITIONAL_POINTS_FOR_PRODUCT_PARAMETER',
    ADDITIONAL_POINTS_FOR_ORDER_PARAMETER:
        'ADDITIONAL_POINTS_FOR_ORDER_PARAMETER',
};

const getPossiblePointsList = ({ asyncServices, storeCode }) => {
    return asyncServices.use(
        ASYNC_LOYALTY_POINTS_ESTIMATOR_SERVICE_CONFIG.NAME,
        ASYNC_LOYALTY_POINTS_ESTIMATOR_SERVICE_CONFIG.METHODS
            .GET_POSSIBLE_POINTS,
        {
            storeCode,
        }
    );
};

const pointsReaderForMobileAppOrder = pointsList => {
    const pointsForMobileApp = pointsList.find(
        ({ name, params: { propertyName, propertyValue } = {} }) =>
            name ===
                POSSIBLE_POINTS_NAMES.ADDITIONAL_POINTS_FOR_ORDER_PARAMETER &&
            propertyValue === 'Mobile App' &&
            propertyName === 'channel'
    );

    if (!pointsForMobileApp) {
        return null;
    }

    return pointsForMobileApp.params.additionalPoints;
};

const getSimplePointsReaderByName = providedName => {
    return pointsList => {
        const pointsObject = pointsList.find(
            ({ name }) => name === providedName
        );

        return pointsObject?.params?.points || null;
    };
};

export const POINTS_TYPES = {
    POINTS_FOR_JOINING_PROGRAM: 'POINTS_FOR_JOINING_PROGRAM',
    POINTS_FOR_BIRTH_DATE_PROVIDED: 'POINTS_FOR_BIRTH_DATE_PROVIDED',
    BIRTHDAY_GIFT: 'BIRTHDAY_GIFT',
    ADDITIONAL_POINTS_FOR_MOBILE_APP_ORDER:
        'ADDITIONAL_POINTS_FOR_MOBILE_APP_ORDER',
};

const POINTS_READER_FN_BY_TYPE = {
    [POINTS_TYPES.POINTS_FOR_JOINING_PROGRAM]: getSimplePointsReaderByName(
        POSSIBLE_POINTS_NAMES.POINTS_FOR_JOINING_PROGRAM
    ),
    [POINTS_TYPES.POINTS_FOR_BIRTH_DATE_PROVIDED]: getSimplePointsReaderByName(
        POSSIBLE_POINTS_NAMES.POINTS_FOR_BIRTH_DATE_PROVIDED
    ),
    [POINTS_TYPES.BIRTHDAY_GIFT]: getSimplePointsReaderByName(
        POSSIBLE_POINTS_NAMES.BIRTHDAY_GIFT
    ),
    [POINTS_TYPES.ADDITIONAL_POINTS_FOR_MOBILE_APP_ORDER]: pointsReaderForMobileAppOrder,
};

export const getPossiblePointsByTypes = async ({
    asyncServices,
    storeCode,
    types,
}) => {
    if (!Array.isArray(types) || !storeCode || !asyncServices) {
        return {};
    }

    const pointsList = await getPossiblePointsList({
        asyncServices,
        storeCode,
    });

    if (!pointsList.length) {
        return {};
    }

    const availableTypes = types.filter(type =>
        Object.values(POINTS_TYPES).includes(type)
    );

    return availableTypes.reduce((acc, type) => {
        const reader = POINTS_READER_FN_BY_TYPE[type];

        if (!reader) {
            acc[type] = null;

            return acc;
        }

        acc[type] = reader(pointsList) || null;

        return acc;
    }, {});
};
