import { PAYMENT_TYPES } from '@types/PaymentTypes';

const COD_LABEL = 'Pay on delivery';

const ONLINE_METHOD_NOT_AVAILABLE_TEXT =
    'This method is not available for online payments.';
const COD_METHOD_NOT_AVAILABLE_TEXT =
    'This method is not available for payment on delivery.';

export const PAYMENT_TYPES_CONFIG = {
    [PAYMENT_TYPES.ONLINE]: {
        label: '',
        methodNotAvailableText: ONLINE_METHOD_NOT_AVAILABLE_TEXT,
    },
    [PAYMENT_TYPES.COD]: {
        label: COD_LABEL,
        methodNotAvailableText: COD_METHOD_NOT_AVAILABLE_TEXT,
    },
};
