import customerMutations from './mutations';
import customerActions from './actions';
import customerGetters from './getters';

export const state = () => ({
    fetchingCustomerInProgress: true,
    customerData: null,
    wishlist: [],
    accessToken: null,
    refreshToken: null,
    accessTokenExpirationISO: null,
    refreshTokenExpirationISO: null,
    isCustomerSubscribedToNewsletter: false,
    shouldOpenModalNewsletterConfirmation: false,
    shouldShowNewsletterConfirmationSection: false,
});

export const mutations = customerMutations;
export const actions = customerActions;
export const getters = customerGetters;
