<template>
    <div class="expand-collapse">
        <div
            class="header"
            :class="{
                'is-expanded-on-desktop': isExpandedOnDesktop,
            }"
            @click="changeState()"
        >
            <TextButton class="heading-button">
                <BaseHeading theme="h5">
                    <slot name="header" />
                </BaseHeading>
            </TextButton>
            <template v-if="showExpandIcon">
                <template v-if="!isExpanded">
                    <slot name="icon-plus" />
                </template>
                <template v-else>
                    <slot name="icon-minus" />
                </template>
            </template>
        </div>
        <div
            class="content"
            :class="{
                'is-expanded': isExpanded,
                'is-expanded-on-desktop': isExpandedOnDesktop,
            }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import TextButton from '@atoms/TextButton/TextButton';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'ExpandCollapse',

    components: {
        BaseHeading,
        TextButton,
    },

    props: {
        isExpandedOnDesktop: {
            type: Boolean,
            default: true,
        },

        isExpandedDefault: {
            type: Boolean,
            default: false,
        },

        showExpandIcon: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isExpanded: this.isExpandedDefault,
        };
    },

    methods: {
        changeState() {
            this.isExpanded = !this.isExpanded;
            this.$emit('expand-change', this.isExpanded);
        },
    },
};
</script>

<style lang="scss" scoped>
.expand-collapse {
    @apply flex flex-col w-auto;

    .header {
        @apply flex flex-row justify-between cursor-pointer items-center;
    }

    .heading-button {
        @apply font-semibold;
    }

    .content {
        @apply hidden mt-4;

        &.is-expanded {
            @apply block;
        }
    }

    @screen lg {
        .header {
            .icon {
                @apply hidden;
            }

            &.is-expanded-on-desktop {
                @apply cursor-default;

                .icon {
                    @apply hidden;
                }

                .heading-button {
                    &:hover,
                    &:focus {
                        @apply no-underline;
                    }
                }

                &:deep() {
                    .text-button {
                        @apply cursor-pointer;
                    }

                    &.is-expanded-on-desktop {
                        .text-button {
                            @apply cursor-default;
                        }
                    }
                }
            }
        }

        .content {
            &.is-expanded-on-desktop {
                @apply block;
            }
        }
    }
}
</style>
