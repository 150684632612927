import { ApiVersion, Channel, Endpoint, SearchQuery } from '@eobuwie/search';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { getSearchClientAndEndpointParameter } from '@assets/get-megatron-endpoint-parameter-search';

const ERROR_TAGS = {
    [ERROR_ACTION_TAG_NAME]: 'services.attributes.getAttributeAggregation',
};

export default ({
    megatronClient,
    httpClient,
    $errorHandler,
    helpers: { abTests },
}) => ({
    async getAttributeAggregation({ locale, currency, aggregations }) {
        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.SEARCH_WEB_FILTERS,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setLimit(0)
                .setAggregations(aggregations)
                .build();

            const {
                client,
                endpointParameter,
            } = getSearchClientAndEndpointParameter(
                megatronClient,
                httpClient,
                abTests
            );

            const { data, error, status } = await client.get(searchQueryUrl, {
                ...endpointParameter,
            });

            if (error) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        searchQueryUrl,
                        data,
                        error,
                        status,
                    }
                );

                return {};
            }

            return data;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return {};
        }
    },
});
