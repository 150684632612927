import { MODULE_NAME } from '@header/store/meta';
import headerModule from '@header/store';

export default async function ({ store }) {
    if (store.hasModule(MODULE_NAME)) {
        return;
    }

    store.registerModule(MODULE_NAME, headerModule);
}
