import DataLayerProduct from '@models/Analytics/DataLayerProduct';

export default class DataLayerProducts {
    constructor({
        products = [],
        productsReviewsTotalCount = {},
        routePath = '',
        selectedShippingMethod = null,
        selectedPaymentMethod = '',
        selectedPaymentType = '',
        promotedType = '',
    }) {
        this.products = products;
        this.productsReviewsTotalCount = productsReviewsTotalCount;
        this.routePath = routePath;
        this.selectedShippingMethod = selectedShippingMethod;
        this.selectedPaymentMethod = selectedPaymentMethod;
        this.selectedPaymentType = selectedPaymentType;
        this.promotedType = promotedType;
    }

    build() {
        return this.products.map((item, index) =>
            this.buildProduct(
                item,
                index + 1,
                this.selectedShippingMethod,
                this.selectedPaymentMethod,
                this.selectedPaymentType,
                this.promotedType
            )
        );
    }

    buildProduct(
        item,
        position,
        shippingMethod,
        selectedPaymentMethod,
        selectedPaymentType,
        promotedType
    ) {
        const {
            quantity,
            skuVariant,
            prices: { final_item_price: discountedPrice } = {},
        } = item;

        const productReviewsTotalCount = {
            totalCount: this.productsReviewsTotalCount[item.sku],
        };

        const product = item.product || item;

        const productData = new DataLayerProduct({
            product,
            reviews: productReviewsTotalCount,
            position: item?.position || position,
            quantity,
            variantId: skuVariant,
            shippingMethod,
            discountedPrice,
            selectedPaymentMethod,
            selectedPaymentType,
            promotedType,
        }).build();

        if (productData) {
            return {
                ...productData,
                list: promotedType || this.routePath,
            };
        }

        return null;
    }
}
