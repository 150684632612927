import {
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    COLOR_ARRAY_ATTRIBUTE_NAME,
    PRODUCT_CARD_SIZE_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';
import {
    SAFE_SIZE_LENGTH,
    SAFE_SIZE_MAX_SIZE,
    MANUFACTURER_TABLE_ATTRIBUTE_KEY,
    MANUFACTURER_TABLE_VALUE_KEY,
    MANUAL_TABLE_KEYS,
    MANUFACTURER_TABLE_SIZE,
    MANUFACTURER_TABLE_ATTRIBUTES_KEYS,
} from '@configs/size-tables';
import { GOOD_PRICE_ENABLED_LOCALES } from '@configs/product';

import { stringifyCategoryUrl } from '@assets/path';
import { getProductImage } from '@assets/images';
import {
    IMAGE_TYPE_PRODUCT_276w_368h,
    IMAGE_TYPE_PRODUCT_528w_704h,
} from '@types/Image';
import { BADGE_VARIANTS_MAP } from '@types/Badge';

export const getProductVariantLabel = (productVariant, variantDimension) => {
    const { size, values: productVariantValues } = productVariant;

    return productVariantValues?.[variantDimension]?.value?.label || size;
};

const getCategoryAndAttribute = (product, productAttributeCode) => {
    const {
        category: { label: categoryName = '', url: categoryUrl = '' },
        values = {},
    } = product || {};
    const productAttribute = values[productAttributeCode] || {};

    return {
        categoryName,
        categoryUrl,
        productAttribute,
    };
};

export const getChosenSizeLabelFromVariant = (
    productVariant,
    variantDimension
) => {
    const { size, values: productVariantValues } = productVariant;

    return (
        productVariantValues?.[variantDimension]?.value?.label ||
        productVariantValues?.[PRODUCT_CARD_SIZE_ATTRIBUTE_NAME]?.value
            ?.label ||
        size
    );
};

export const getChosenSizeLabel = (product, chosenSize) => {
    const productVariant = product.variants?.[chosenSize];

    if (!productVariant) {
        return '';
    }

    const { variant_dimension: variantDimension = null } = product.family;

    return getChosenSizeLabelFromVariant(productVariant, variantDimension);
};

export const getCategoryUrlsWithColorFilter = (
    product,
    $createCategoryPath
) => {
    const {
        categoryName,
        categoryUrl,
        productAttribute,
    } = getCategoryAndAttribute(product, COLOR_ARRAY_ATTRIBUTE_NAME);
    const {
        attribute: { url: attributeUrl = null } = {},
        value: colors = null,
    } = productAttribute || {};

    if (!attributeUrl || !colors) {
        return [];
    }

    return Object.values(colors).map(({ url: valueUrl, label }) => ({
        label: `${categoryName} ${label}`,
        url: $createCategoryPath(
            stringifyCategoryUrl({
                categoryUrl,
                filters: {
                    [attributeUrl]: [valueUrl],
                },
            })
        ),
    }));
};

export const getCategoryUrlWithBrandFilter = (product, $createCategoryPath) => {
    const {
        categoryName,
        categoryUrl,
        productAttribute,
    } = getCategoryAndAttribute(product, BRAND_WITH_COLLECTION_ATTRIBUTE_NAME);

    if (!productAttribute) {
        return null;
    }

    const [brandValue = null] = Object.values(productAttribute.value || {});

    if (brandValue === null) {
        return null;
    }

    const { url, label } = brandValue;

    return {
        label: `${categoryName} ${label}`,
        path: $createCategoryPath(
            stringifyCategoryUrl({
                categoryUrl,
                filters: {
                    [productAttribute.attribute.url]: [url],
                },
            })
        ),
    };
};

export const getCategoriesWithBrandFilter = ({
    categories,
    brandAttribute,
    brandUrl,
    brandName,
    locale,
    $createCategoryPath,
}) => {
    const SEO_CATEGORY_VARIABLE_REGEXP = /(\(\([^)]+\)\))/gm;

    return categories
        .map(category => {
            const { header, url: categoryUrl, label: categoryLabel } =
                category.translations[locale] || {};

            if (!header || !categoryUrl || !categoryLabel) {
                return null;
            }

            const { url: brandAttributeUrl } = brandAttribute;

            let [headerMain] = header.split('{');

            headerMain = headerMain.replaceAll(
                SEO_CATEGORY_VARIABLE_REGEXP,
                categoryLabel
            );

            const label = `${headerMain.trim()} ${brandName}`;

            const url = $createCategoryPath(
                stringifyCategoryUrl({
                    categoryUrl,
                    filters: {
                        [brandAttributeUrl]: [brandUrl],
                    },
                })
            );

            return {
                url,
                label,
            };
        })
        .filter(category => !!category);
};

const getManufacturerTable = (product, tLabel) => {
    const attribute = product.values[MANUFACTURER_TABLE_ATTRIBUTE_KEY];

    if (!attribute) {
        return {};
    }

    const { [MANUFACTURER_TABLE_VALUE_KEY]: manufacturerTableValues = [] } =
        attribute?.value?.values || {};

    if (
        !Array.isArray(manufacturerTableValues) ||
        !manufacturerTableValues?.length
    ) {
        return {};
    }

    const manufacturerTable = {};

    const SIZE_VALUES_CHARACTERS_REGEXP = /[.,]/gm;

    manufacturerTableValues.forEach(value => {
        const { values } = value;

        if (!values) {
            return;
        }

        const sizeValues = values[MANUFACTURER_TABLE_SIZE]?.replaceAll(
            SIZE_VALUES_CHARACTERS_REGEXP,
            '_'
        );

        if (!sizeValues) {
            return;
        }

        MANUFACTURER_TABLE_ATTRIBUTES_KEYS.forEach(key => {
            const manufacturerValues = values[key];

            if (!manufacturerValues) {
                return;
            }

            manufacturerTable[key] = {
                ...manufacturerTable[key],
                [sizeValues]: {
                    value: manufacturerValues || '-',
                    // @todo PWA-823 - remove labels
                    label: tLabel(`${key}`) || '',
                },
            };
        });
    });

    if (!Object.keys(manufacturerTable).length) {
        return {};
    }

    return manufacturerTable;
};

const getManualTable = (product, tLabel) => {
    const { variants, attributes } = product;

    if (!variants || !attributes) {
        return;
    }

    const manualTable = {};

    Object.values(variants).forEach(({ values, size }) => {
        MANUAL_TABLE_KEYS.forEach(attribute => {
            const propertyValue = values[attribute];
            const propertyAttribute = attributes[attribute];

            if (!propertyValue || !propertyAttribute) {
                return false;
            }

            const { formatted, value } = propertyValue;
            const { code, label } = propertyAttribute;

            manualTable[code] = {
                ...manualTable[code],
                [size]: { formatted, label, value },
            };
        });
    });

    if (!Object.keys(manualTable).length) {
        return getManufacturerTable(product, tLabel);
    }

    return manualTable;
};

const addSizeToSizeTable = (safeSizeValues, attribute, sizeTable, size) => {
    if (!safeSizeValues) {
        return;
    }

    const { label, code } = attribute;

    const { value } = safeSizeValues;

    sizeTable[code] = {
        ...sizeTable[code],
        [size]: {
            label,
            value: Math.round(value) / 10,
        },
    };
};

export const getSizeTable = (product, tLabel) => {
    const { variants, attributes } = product;

    if (!variants) {
        return {};
    }

    const safeSizeTable = {};

    Object.values(variants).forEach(({ size, values }) => {
        Object.entries(values).forEach(([code, value]) => {
            if (![SAFE_SIZE_LENGTH, SAFE_SIZE_MAX_SIZE].includes(code)) {
                return;
            }

            const attribute = attributes[code];

            addSizeToSizeTable(value, attribute, safeSizeTable, size);
        });
    });

    if (!Object.keys(safeSizeTable).length) {
        return getManualTable(product, tLabel);
    }

    return safeSizeTable;
};

export const getVariantKey = (variants, variantId) =>
    Object.entries(variants || {}).find(
        ([, { id }]) => id === variantId
    )?.[0] || '';

export const formatPromoBadge = promoSticker => {
    if (!promoSticker) {
        return null;
    }

    return {
        backgroundColor: promoSticker.bgColor,
        color: promoSticker.textColor,
        label: promoSticker.formattedContent,
    };
};

export const getProductDesignSystem = ({
    product,
    locale,
    isCentsEnabled,
    isRegularPriceEnabled,
    isOmnibusEnabled,
    isCurrencyBefore,
}) => {
    const {
        sku: id,
        brandName: brand,
        badges,
        nameDisplay: name,
        color,
        isSponsored,
        promoSticker,
        images,
        price,
    } = product;

    const { discount, promotional, regular, omnibus } = price;

    const regularAmount = regular?.amount || null;
    const promotionalAmount = promotional?.amount || null;
    const omnibusAmount = omnibus?.amount || null;

    const isDiscountFromPrice = isOmnibusEnabled
        ? omnibusAmount > promotionalAmount
        : regularAmount > promotionalAmount;

    const isDiscounted = discount ? isDiscountFromPrice : false;

    const arePricesTheSame = isRegularPriceEnabled
        ? promotionalAmount === regularAmount &&
          promotionalAmount === omnibusAmount
        : promotionalAmount === omnibusAmount;

    const isCatalogPromotion = regularAmount !== promotionalAmount;

    const isPricesOnGoodPriceEnabled =
        GOOD_PRICE_ENABLED_LOCALES.includes(locale) && isCatalogPromotion;

    const regularPrice =
        isRegularPriceEnabled && regularAmount > promotionalAmount
            ? regular?.formatted
            : null;

    const imageDimensions = [
        IMAGE_TYPE_PRODUCT_276w_368h,
        IMAGE_TYPE_PRODUCT_528w_704h,
    ];

    const fallback = IMAGE_TYPE_PRODUCT_528w_704h;

    return {
        id,
        images: images.map((_, index) => {
            const image = getProductImage(
                product,
                index,
                imageDimensions,
                index > 0 ? null : fallback
            );

            image.src = image.url;
            delete image.url;

            return image;
        }),

        badges: Object.values(badges).map(({ code, label }) => ({
            type: BADGE_VARIANTS_MAP[code],
            label,
        })),

        brand,
        name,
        url: product.url,
        color,
        price: {
            final: promotional.formatted,
            regular: regularPrice,
            minimal:
                isOmnibusEnabled &&
                omnibusAmount &&
                !arePricesTheSame &&
                (isPricesOnGoodPriceEnabled ||
                    omnibusAmount > promotionalAmount)
                    ? price.omnibus?.formatted || ''
                    : null,

            isDiscounted,
            isCentsEnabled,
            isCurrencyBefore,
        },

        promoBadge: formatPromoBadge(promoSticker),
        isSponsored,
    };
};

export default {
    getChosenSizeLabelFromVariant,
};
