<template>
    <div v-if="content" v-html="content" />
</template>

<script>
export default {
    name: 'StaticBlock',

    props: {
        content: {
            type: String,
            required: true,
        },
    },
};
</script>
