import { VALID_QUERY_ORDER_KEYS } from '@configs/query-keys/sort';
import { VALID_PAGINATION_QUERY_KEYS } from '@configs/query-keys/pagination';
import { VALID_QUERY_SEARCH_KEYS } from '@configs/query-keys/search';

const validQueryKey = (keySet, key) => keySet.indexOf(key) !== -1;

export default {
    testSearchParams(key) {
        return validQueryKey(VALID_QUERY_SEARCH_KEYS, key);
    },
    testSortParams(key) {
        return validQueryKey(VALID_QUERY_ORDER_KEYS, key);
    },
    testPaginationParams(key) {
        return validQueryKey(VALID_PAGINATION_QUERY_KEYS, key);
    },
    groupQueries(routeQuery) {
        return Object.keys(routeQuery).reduce(
            (groupedConfig, key) => {
                const grouped = groupedConfig;
                const value = routeQuery[key];

                if (value) {
                    if (this.testSearchParams(key)) {
                        const { search } = grouped;

                        grouped.search = {
                            ...search,
                            [key]: value,
                        };

                        return grouped;
                    }

                    if (this.testSortParams(key)) {
                        const { sort } = grouped;

                        grouped.sort = {
                            ...sort,
                            [key]: value,
                        };

                        return grouped;
                    }

                    if (this.testPaginationParams(key)) {
                        const { pagination } = grouped;

                        grouped.pagination = {
                            ...pagination,
                            [key]: value,
                        };

                        return grouped;
                    }
                }

                return grouped;
            },
            {
                search: {},
                sort: {},
                pagination: {},
            }
        );
    },
};
