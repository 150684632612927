import { GOOGLE_API_SCRIPT, GOOGLE_CLIENT_INIT_PARAMS } from '@configs/google';

import loadScript from '@assets/loadScript';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export default ($config, store, $errorHandler, app) => ({
    async init() {
        if (!window.google?.accounts?.oauth2) {
            await loadScript(GOOGLE_API_SCRIPT);
        }

        return window.google.accounts.oauth2;
    },

    getClientInitParams() {
        return {
            ...GOOGLE_CLIENT_INIT_PARAMS,
            client_id: $config.google?.loginClientId,
        };
    },

    errorCallback(data) {
        store.dispatch('messages/addErrorMessage', {
            text: app.$t('Some error occured during logging in with Google'),
        });

        $errorHandler.captureError(
            new Error('Login Google error'),
            {
                [ERROR_ACTION_TAG_NAME]: 'google.oauth2.initTokenClient',
            },
            {
                message: data.message,
            }
        );
    },

    async signIn() {
        try {
            const oauth2 = await this.init();

            return await new Promise(resolve => {
                const client = oauth2.initTokenClient({
                    ...this.getClientInitParams(),
                    callback: ({ access_token }) => {
                        return resolve(access_token);
                    },
                    error_callback: data => {
                        this.errorCallback(data);

                        return resolve(null);
                    },
                });

                client.requestAccessToken();
            });
        } catch {
            return null;
        }
    },
});
