import { FilterTypes } from '../types/filter-types.js';
import { FilterSeparators } from '../types/filter-separators.js';

class FilterEsizeme {
  code;
  type;
  label;
  url;
  rangeAbove;
  rangeBelow;
  selectedScan;
  setFilter;
  constructor(selectedScan, rangeBoundaries, setFilter) {
    this.code = "esizeme";
    this.url = "true_size";
    this.label = "Esizeme";
    this.type = FilterTypes.ESIZEME;
    this.rangeBelow = rangeBoundaries.below;
    this.rangeAbove = rangeBoundaries.above;
    this.selectedScan = {};
    this.setFilter = setFilter;
    if (Object.keys(selectedScan).length) {
      this.selectedScan = selectedScan;
    }
  }
  toggleScan(scan) {
    if (this.selectedScan?.id === scan.id) {
      this.selectedScan = {};
      return;
    }
    const scanSize = parseFloat(scan.size);
    this.selectedScan = {
      gender: scan.gender,
      name: scan.name,
      id: scan.id,
      size: [scanSize - this.rangeBelow, scanSize + this.rangeAbove].join(
        FilterSeparators.FILTER_VALUE_SEPARATOR
      )
    };
  }
  applyFilter() {
    this.setFilter(this);
  }
  clearFilter() {
    this.selectedScan = {};
  }
  static MALE = "MALE";
  static FEMALE = "FEMALE";
  static DEFAULT_ID = "default";
  static DEFAULT_LABEL = "ESIZE.ME";
  static TRUE_SIZE_FEMALE = "true_size_female";
  static TRUE_SIZE_MALE = "true_size_male";
}

export { FilterEsizeme as default };
