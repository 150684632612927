import { normalizeDateString } from '@assets/date';

const RECOMMENDATION_TYPE_BEST = 'best_size';
const RECOMMENDATION_TYPE_SECOND = 'second_size';

export const transformEsizemeScan = scan => {
    const {
        created_at: createdAt = '',
        updated_at: updatedAt = '',
        valid_until: validUntil = '',
        foot_profile_url: footProfileUrl = '',
        phone_number: phoneNumber = '',
        ...rest
    } = scan || {};

    return {
        ...rest,
        createdAt,
        updatedAt,
        validUntil,
        footProfileUrl,
        phoneNumber,
    };
};

export const sortScans = scans =>
    Array.from(scans).sort(
        (
            { updatedAt: scan1Updated, createdAt: scan1Created },
            { updatedAt: scan2Updated, createdAt: scan2Created }
        ) => {
            const scan1Date = scan1Updated || scan1Created;
            const scan2Date = scan2Updated || scan2Created;

            return -1 * scan1Date.localeCompare(scan2Date);
        }
    );

export const checkEsizemeRecommendation = (
    sizeRecommendation,
    sizeLabel,
    type = RECOMMENDATION_TYPE_BEST
) => {
    if (Object.keys(sizeRecommendation).length === 0 || !sizeLabel) {
        return false;
    }

    const { display_size: displaySize, custom_size: customSize } =
        sizeRecommendation[type] || {};

    return displaySize === sizeLabel || customSize === sizeLabel;
};

export const checkIfRecommendationIsBest = (sizeRecommendation, sizeLabel) => {
    return checkEsizemeRecommendation(sizeRecommendation, sizeLabel);
};

export const checkIfRecommendationIsAcceptable = (
    sizeRecommendation,
    sizeLabel
) => {
    return checkEsizemeRecommendation(
        sizeRecommendation,
        sizeLabel,
        RECOMMENDATION_TYPE_SECOND
    );
};

export const isScanDateValid = scan => {
    const { validUntil } = scan;

    if (!validUntil) {
        return true;
    }

    return new Date(normalizeDateString(validUntil)) > new Date();
};

export const mergeScanLists = (scanList1, scanList2) => [
    ...new Map(
        [...scanList1, ...scanList2].map(scan => [scan.id, scan])
    ).values(),
];
