<template>
    <component :is="name" stroke="none" class="main-logo" />
</template>

<script>
import { LOGOS } from '@localeConfig/logos';

export default {
    name: 'MainLogo',

    components: {
        ...LOGOS,
    },

    props: {
        name: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.main-logo {
    @apply h-4;

    max-width: theme('customVariables.mainLogo.maxWidth.default');

    @media (min-width: 360px) {
        max-width: theme('customVariables.mainLogo.maxWidth.360');
    }

    @screen md {
        height: theme('customVariables.mainLogo.height.md');
        max-width: theme('customVariables.mainLogo.maxWidth.md');
    }

    @screen lg {
        height: theme('customVariables.mainLogo.height.lg');
        max-width: theme('customVariables.mainLogo.maxWidth.lg');
    }

    @screen xl {
        max-width: theme('customVariables.mainLogo.maxWidth.xl');
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: theme('customVariables.mainLogo.maxWidth.ie');
    }
}
</style>
