import {
    WEB_EOB_PDP_1,
    WEB_EOB_PDP_2,
    WEB_EOB_PDP_UNAVBL_1,
    WEB_EOB_PRECART_1,
    WEB_EOB_CART_1,
    WEB_EOB_CATEGORY_1,
    WEB_EOB_EMPTY_CART_1,
    WEB_EOB_SEARCH_RESULTS_1,
    WEB_EOB_ERROR_PAGE_1,
    WEB_EOB_PDP_PRODUCT_NOTIFICATION,
} from '@types/Synerise';

export const createDefaultSyneriseConfig = () => ({
    apiKey: '',
    trackerKey: '',
    trackerDomain: '',
    scriptUrl: '',
    [WEB_EOB_PDP_1]: '',
    [WEB_EOB_PDP_2]: '',
    [WEB_EOB_PDP_UNAVBL_1]: '',
    [WEB_EOB_PRECART_1]: '',
    [WEB_EOB_CART_1]: '',
    [WEB_EOB_CATEGORY_1]: '',
    [WEB_EOB_EMPTY_CART_1]: '',
    [WEB_EOB_SEARCH_RESULTS_1]: '',
    [WEB_EOB_ERROR_PAGE_1]: '',
    [WEB_EOB_PDP_PRODUCT_NOTIFICATION]: '',
});

const state = () => ({
    syneriseConfig: {
        ...createDefaultSyneriseConfig(),
    },
    syneriseLoaded: false,
});

export default state;
