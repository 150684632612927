<template>
    <div class="download-app">
        <BaseHeading>
            {{ $t('Download our application') }}
        </BaseHeading>

        <ContainerContent
            v-if="appLinksMap.length"
            :full-width="true"
            class="container buttons-container"
        >
            <ButtonSocialMedia
                v-for="({ link, icon, title }, linkId) in appLinksMap"
                :key="linkId"
                :href="link"
                :variant="BUTTON_VARIANT_BASIC"
                target="_blank"
                class="app-button auto-width"
            >
                <template #icon>
                    <Icon :icon="icon" />
                </template>
                {{ $t(title) }}
            </ButtonSocialMedia>
        </ContainerContent>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { GooglePlay, Apple } from '@eobuwie-ui/icons/v2/social-media';

import {
    ButtonSocialMedia,
    BUTTON_SOCIAL_MEDIA_VARIANTS,
} from '@eobuwie-ui/components/ButtonSocialMedia/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

export default {
    name: 'DownloadApp',

    components: {
        Icon,
        BaseHeading,
        ContainerContent,
        ButtonSocialMedia,
    },

    props: {
        androidAppUrl: {
            type: String,
            default: '',
        },

        iosAppUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        appLinksMap() {
            return [
                {
                    link: this.androidAppUrl,
                    icon: GooglePlay,
                    title: 'Google Play',
                },
                {
                    link: this.iosAppUrl,
                    icon: Apple,
                    title: 'App Store',
                },
            ].filter(({ link }) => link);
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT_BASIC = BUTTON_SOCIAL_MEDIA_VARIANTS.BASIC;
    },
};
</script>

<style lang="scss" scoped>
.download-app {
    @apply flex flex-row flex-wrap justify-center;

    .app-button {
        @apply mx-2 mt-3;

        &.auto-width {
            @apply w-auto;
        }
    }

    @screen xs {
        .app-button {
            @apply mx-0;

            &:last-child {
                @apply ml-3;
            }

            &:first-child {
                @apply ml-0;
            }
        }
    }

    @screen md {
        @apply justify-start;

        .buttons-container {
            @apply justify-start;
        }
    }
}
</style>
