export default () => ({
    url: '',
    productsSkus: [],
    productsAnalytics: [],
    navigation: {},
    filteredProductsCount: 0,
    query: '',
    currentProduct: null,
    lastUrl: '',
    isContentLoading: false,
});
