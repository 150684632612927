import CustomAddress from '@models/Customer/CustomAddress';
import { ADDRESS_TYPES } from '@types/Checkout';
import {
    CHECKOUT_LOCAL_STORAGE_KEY,
    STORAGE_EXPIRES_DATA_KEY,
} from '@configs/storage';

// 7 days
const ADDRESS_EXPIRATION_TIME_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

export default class CheckoutLocalStorage {
    constructor($storage) {
        this.$storage = $storage;
        this.data = {};

        const { [STORAGE_EXPIRES_DATA_KEY]: savedData = null } =
            this.$storage.getItem(CHECKOUT_LOCAL_STORAGE_KEY) || {};

        ADDRESS_TYPES.forEach(addressType => {
            const { customAddress = null, customerAddressId = null } =
                savedData?.[addressType] || {};

            this.data[addressType] = {
                customAddress: customAddress
                    ? new CustomAddress(customAddress)
                    : null,
                customerAddressId,
            };
        });

        this.data.isInvoiceSelected = savedData?.isInvoiceSelected || false;
    }

    getIsInvoiceSelected() {
        return this.data.isInvoiceSelected;
    }

    setIsInvoiceSelected(isInvoiceSelected = false) {
        this.data.isInvoiceSelected = isInvoiceSelected;
    }

    getCustomerAddressId(addressType) {
        return this.data[addressType].customerAddressId;
    }

    setCustomerAddressId(customerAddressId, addressType) {
        this.data[addressType] = {
            customAddress: null,
            customerAddressId,
        };
    }

    getCustomAddress(addressType) {
        return this.data[addressType].customAddress;
    }

    setCustomAddress(customAddress, addressType) {
        this.data[addressType] = {
            customAddress,
            customerAddressId: null,
        };
    }

    clearAddressId(addressType) {
        this.data[addressType].customerAddressId = null;
    }

    clearAddress(addressType) {
        this.data[addressType] = {
            customAddress: null,
            customerAddressId: null,
        };
    }

    clear() {
        this.$storage.removeItem(CHECKOUT_LOCAL_STORAGE_KEY);
    }

    save() {
        this.$storage.setItem(
            CHECKOUT_LOCAL_STORAGE_KEY,
            this.data,
            new Date().getTime() + ADDRESS_EXPIRATION_TIME_IN_MILLISECONDS
        );
    }
}
