export const types = {
    SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS:
        'SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS',
    SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
    ADD_TO_WISHLIST: 'ADD_TO_WISHLIST',
    REMOVE_FROM_WISHLIST: 'REMOVE_FROM_WISHLIST',
    SET_WISHLIST: 'SET_WISHLIST',
    INCREMENT_CUSTOMER_WISHLIST_COUNT: 'INCREMENT_CUSTOMER_WISHLIST_COUNT',
    DECREMENT_CUSTOMER_WISHLIST_COUNT: 'DECREMENT_CUSTOMER_WISHLIST_COUNT',
    SET_CUSTOMER_WISHLIST_COUNT: 'SET_CUSTOMER_WISHLIST_COUNT',
    UPDATE_CUSTOMER_DATA: 'UPDATE_CUSTOMER_DATA',
    SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
    SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
    SET_ACCESS_TOKEN_EXPIRATION_ISO: 'SET_ACCESS_TOKEN_EXPIRATION_ISO',
    SET_REFRESH_TOKEN_EXPIRATION_ISO: 'SET_REFRESH_TOKEN_EXPIRATION_ISO',
    SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER:
        'SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER',
    SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION:
        'SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION',
    SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION:
        'SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION',
};

const mutations = {
    [types.SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS](state, fetchingInProgress) {
        state.fetchingCustomerInProgress = fetchingInProgress;
    },
    [types.SET_CUSTOMER_DATA](state, customerData) {
        state.customerData = customerData;
    },
    [types.ADD_TO_WISHLIST](state, items = []) {
        state.wishlist = [...items, ...new Set(state.wishlist)];
    },
    [types.REMOVE_FROM_WISHLIST](state, sku) {
        state.wishlist = state.wishlist.filter(item => item !== sku);
    },
    [types.SET_WISHLIST](state, wishlist = []) {
        state.wishlist = [...new Set(wishlist)];
    },
    [types.INCREMENT_CUSTOMER_WISHLIST_COUNT](state) {
        state.customerData.paginatedWishlist.items_count += 1;
    },
    [types.DECREMENT_CUSTOMER_WISHLIST_COUNT](state) {
        state.customerData.paginatedWishlist.items_count -= 1;
    },

    [types.SET_CUSTOMER_WISHLIST_COUNT](state, wishlistCount) {
        state.customerData.paginatedWishlist.items_count = wishlistCount;
    },

    [types.UPDATE_CUSTOMER_DATA](state, updatedData) {
        state.customerData = {
            ...state.customerData,
            ...updatedData,
        };
    },

    [types.SET_ACCESS_TOKEN](state, accessToken) {
        state.accessToken = accessToken;
    },

    [types.SET_REFRESH_TOKEN](state, refreshToken) {
        state.refreshToken = refreshToken;
    },

    [types.SET_ACCESS_TOKEN_EXPIRATION_ISO](state, expiration) {
        state.accessTokenExpirationISO = expiration;
    },

    [types.SET_REFRESH_TOKEN_EXPIRATION_ISO](state, expiration) {
        state.refreshTokenExpirationISO = expiration;
    },

    [types.SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER](
        state,
        isCustomerSubscribedToNewsletter
    ) {
        state.isCustomerSubscribedToNewsletter = isCustomerSubscribedToNewsletter;
    },

    [types.SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION](
        state,
        shouldOpenModalNewsletterConfirmation
    ) {
        state.shouldOpenModalNewsletterConfirmation = shouldOpenModalNewsletterConfirmation;
    },

    [types.SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION](
        state,
        shouldShowNewsletterConfirmationSection
    ) {
        state.shouldShowNewsletterConfirmationSection = shouldShowNewsletterConfirmationSection;
    },
};

export default mutations;
