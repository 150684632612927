export const types = {
    SET_CMS_BLOCKS: 'SET_CMS_BLOCKS',
};

export default {
    [types.SET_CMS_BLOCKS](state, cmsBlocksItems) {
        cmsBlocksItems.forEach(block => {
            if (block?.identifier) {
                state[block.identifier] = block;
            }
        });
    },
};
