import { ApiVersion, Channel, Endpoint, SearchQuery } from '@eobuwie/search';
import { SYNERISE_UUID_COOKIE_NAME } from '@configs/storage';
import { HTTP_CLIENT_ENDPOINT_NAME_SEARCH_SERVICE } from '@configs/http-client';

import {
    getEngineVariant,
    getSearchServiceQueryUrl,
    getGoogleClientId,
    JESSICA,
} from '@search/assets/search-service';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const AUTOCOMPLETE_RESULTS_LIMIT = 8;
const ERROR_TAGS = {
    [ERROR_ACTION_TAG_NAME]: 'services.searchService.getAutocomplete',
};

const emptyAutocompleteResponse = () => ({
    phrases: [],
});

const emptyAutocompleteRedirectResponse = () => ({
    slug: null,
});

export default ({
    httpClient,
    $errorHandler,
    helpers: { abTests, $cookies, $avatar },
}) => ({
    async getPocAutocomplete(query, locale, currency) {
        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.AUTOCOMPLETE,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setLocale(locale)
                .setLimit(AUTOCOMPLETE_RESULTS_LIMIT)
                .setCurrency(currency)
                .setQuery(query)
                .build();

            const engineVariant = getEngineVariant(abTests);

            if (engineVariant === JESSICA) {
                return emptyAutocompleteResponse();
            }

            const searchServiceQueryUrl = getSearchServiceQueryUrl(
                searchQueryUrl,
                engineVariant
            );

            const syneriseUuid = $cookies.get(SYNERISE_UUID_COOKIE_NAME);
            const googleClientId = getGoogleClientId($cookies);

            const { data, error, status } = await httpClient.get(
                `autocomplete${searchServiceQueryUrl}`,
                {
                    endpoint: HTTP_CLIENT_ENDPOINT_NAME_SEARCH_SERVICE,
                    headers: {
                        ...$avatar.getInstanceIdHeader(),
                        ...(syneriseUuid && {
                            'x-synerise-uuid': syneriseUuid,
                        }),
                        ...(googleClientId && {
                            'x-google-client-id': googleClientId,
                        }),
                    },
                }
            );

            if (!error) {
                return data;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            return emptyAutocompleteResponse();
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return emptyAutocompleteResponse();
        }
    },

    async getAutocompleteRedirect(query, locale, currency) {
        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.AUTOCOMPLETE,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setLocale(locale)
                .setCurrency(currency)
                .setQuery(query)
                .build();

            const searchServiceQueryUrl = getSearchServiceQueryUrl(
                searchQueryUrl,
                null
            );

            const { data, error, status } = await httpClient.get(
                `autocomplete/redirect${searchServiceQueryUrl}`,
                {
                    endpoint: HTTP_CLIENT_ENDPOINT_NAME_SEARCH_SERVICE,
                    headers: {
                        ...$avatar.getInstanceIdHeader(),
                    },
                }
            );

            if (!error) {
                return data;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return emptyAutocompleteRedirectResponse();
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return emptyAutocompleteRedirectResponse();
        }
    },
});
