<template>
    <MarketingBar
        :background-color="MARKETING_BAR_DEFAULT_BACKGROUND"
        :icon-color="MARKETING_BAR_DEFAULT_COLOR"
        :color="MARKETING_BAR_DEFAULT_COLOR"
        :text-desktop="$t('You are in the safe shopping area')"
        :text-mobile="$t('You are in the safe shopping area')"
        icon="LockFilled"
    />
</template>

<script>
import {
    MARKETING_BAR_DEFAULT_BACKGROUND,
    MARKETING_BAR_DEFAULT_COLOR,
} from '@configs/marketing-bar';

import MarketingBar from '@marketing-bar-components/molecules/MarketingBar/MarketingBar';

export default {
    name: 'MarketingBarCart',

    components: {
        MarketingBar,
    },

    beforeCreate() {
        this.MARKETING_BAR_DEFAULT_BACKGROUND = MARKETING_BAR_DEFAULT_BACKGROUND;
        this.MARKETING_BAR_DEFAULT_COLOR = MARKETING_BAR_DEFAULT_COLOR;
    },
};
</script>
