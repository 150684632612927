import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { transformEsizemeScan } from '@assets/esizeme';
import { getErrorCodes } from '@assets/megatron-client/graphql-errors-helper';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.esizeme.${action}`,
});

export default (megatronClient, $errorHandler) => ({
    async getStoreConfig() {
        const response = (isValid, config = {}) => {
            const {
                esizeme_api_enabled: isEsizemeApiEnabled = false,
                esizeme_customer_account_enabled: isEsizemeCustomerAccountEnabled = false,
                esizeme_category_pages_enabled: isEsizemeCategoryPagesEnabled = false,
                esizeme_category_pages_range_below: esizemeCategoryPagesRangeBelow = 0,
                esizeme_category_pages_range_above: esizemeCategoryPagesRangeAbove = 0,
                esizeme_product_page_enabled: isEsizemeProductPageEnabled = false,
                esizeme_top_bar_enabled: isEsizemeTopBarEnabled = false,
            } = config || {};

            return {
                isValid,
                config: {
                    isEsizemeApiEnabled,
                    isEsizemeCustomerAccountEnabled,
                    isEsizemeCategoryPagesEnabled,
                    esizemeCategoryPagesRangeBelow,
                    esizemeCategoryPagesRangeAbove,
                    isEsizemeProductPageEnabled,
                    isEsizemeTopBarEnabled,
                },
            };
        };
        const ERROR_TAGS = getErrorTags('getStoreConfig');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getEsizemeStoreConfig',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                }
            );

            if (!error) {
                return response(true, data?.storeConfig);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return response(false);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return response(false);
        }
    },

    async getCustomerScans() {
        const ERROR_TAGS = getErrorTags('getCustomerScans');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getCustomerEsizemeScans',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return (data?.customer?.esizeme_scans?.items || []).map(scan =>
                    transformEsizemeScan(scan)
                );
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return [];
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return [];
        }
    },

    async sendCustomerAccessPin(phoneNumber) {
        const ERROR_TAGS = getErrorTags('sendCustomerAccessPin');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/esizemeSendCustomerAccessPin',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        phoneNumber,
                    },
                }
            );

            if (!error) {
                return data?.esizemeSendCustomerAccessPin || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return false;
        }
    },

    async getPhoneNumberScans(phoneNumber, pin) {
        const response = (scans, error) => ({
            scans: scans || [],
            error: error || null,
        });
        const ERROR_TAGS = getErrorTags('getPhoneNumberScans');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/esizemeGetPhoneNumberScans',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        phoneNumber,
                        pin,
                    },
                }
            );

            if (!error) {
                return response(
                    (data?.esizemeGetPhoneNumberScans?.items || []).map(scan =>
                        transformEsizemeScan(scan)
                    )
                );
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return response([], error);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                phoneNumber,
                pin,
            });

            return response([], err);
        }
    },

    async assignScansToCustomer(ids) {
        const ERROR_TAGS = getErrorTags('assignScansToCustomer');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/esizemeAssignScansToCustomer',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        ids,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.esizemeAssignScansToCustomer || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return false;
        }
    },

    async unassignScanToCustomer(scanId) {
        const ERROR_TAGS = getErrorTags('unassignScanToCustomer');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/esizemeUnassignScansToCustomer',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        input: [scanId],
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.esizemeUnassignScansToCustomer || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return false;
        }
    },

    async getRecommendationForProductAndScan(scanId, sku) {
        const ERROR_TAGS = getErrorTags('getRecommendationForProductAndScan');
        const response = (recommendation, errorCode) => ({
            recommendation: recommendation || null,
            errorCode: errorCode || null,
        });

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/esizemeGetSizeRecommendation',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        scanId,
                        sku,
                    },
                }
            );
            const firstErrorCode = getErrorCodes(data?.errors || []);

            if (!error) {
                return response(
                    data?.esizemeGetSizeRecommendation,
                    firstErrorCode
                );
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                    firstErrorCode,
                }
            );

            return response();
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                scanId,
                sku,
            });

            return response();
        }
    },

    async getSizeRecommendationWidget(scanId, sku, lang) {
        const ERROR_TAGS = getErrorTags('getSizeRecommendationWidget');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/esizemeGetSizeRecommendationWidget',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        scanId,
                        sku,
                        lang,
                    },
                }
            );

            if (!error) {
                return data?.esizemeGetSizeRecommendationWidget?.html || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return null;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                scanId,
                sku,
            });

            return null;
        }
    },
});
