import { render, staticRenderFns } from "./BaseLinkStatic.vue?vue&type=template&id=4cc9c725&scoped=true"
import script from "./BaseLinkStatic.vue?vue&type=script&lang=js"
export * from "./BaseLinkStatic.vue?vue&type=script&lang=js"
import style0 from "./BaseLinkStatic.vue?vue&type=style&index=0&id=4cc9c725&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc9c725",
  null
  
)

export default component.exports