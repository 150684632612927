export const X_LOCALE = 'x-locale';

export const X_CHANNEL = 'x-channel';
export const X_DEVICE = 'x-device';
export const CONTENT_TYPE = 'Content-Type';

export const METHOD_POST = 'POST';
export const NO_REFERRER = 'no-referrer';

export const HEADERS_CONFIG = {
    [X_CHANNEL]: 'EOBUWIE',
    [X_DEVICE]: 'DESKTOP',
    [CONTENT_TYPE]: 'application/json',
};
