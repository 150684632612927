import AbstractAddress from '@models/Customer/AbstractAddress';

export default class CustomAddress extends AbstractAddress {
    constructor(customAddress = {}) {
        super(customAddress);

        this.email = '';
        this.isInvoiceSelected = false;

        this.set(customAddress);
    }

    set(customAddress = {}) {
        super.set(customAddress);

        const { email = '', isInvoiceSelected = false } = customAddress || {};

        this.email = email;
        this.isInvoiceSelected = isInvoiceSelected;
    }

    setIsInvoiceSelected(isInvoiceSelected) {
        this.isInvoiceSelected = isInvoiceSelected;
    }

    copyFromCustomerAddress(customerAddress) {
        Object.entries(customerAddress).forEach(([key, value]) => {
            if (typeof this[key] !== 'undefined') {
                this[key] = typeof value === 'boolean' ? value : value || '';
            }
        });
    }

    copyEmailFromCustomer({ email }) {
        this.email = email;
    }

    copyFromCustomer({ email = '', firstName = '', lastName = '' }) {
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
    }
}
