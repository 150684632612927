import CatalogProduct from '@search/models/CatalogProduct';

export default function normalizeProduct(
    product,
    {
        createProductPath,
        createCategoryPath,
        imaginator,
        getLocaleConfigByKey,
        t,
    },
    { locale, currency, storeViewTimezone }
) {
    const normalizedProduct = { ...product };

    const productModel = new CatalogProduct(
        normalizedProduct,
        locale,
        currency,
        createProductPath,
        createCategoryPath,
        imaginator,
        getLocaleConfigByKey,
        t,
        storeViewTimezone
    );

    return productModel.buildDetails();
}
