import {
    PATH_SEGMENTS_SEPARATOR,
    PATH_FILTER_NAME_VALUE_SEPARATOR,
    PATH_FILTER_VALUES_SEPARATOR,
} from '@configs/query-keys/filters';

import { capitalizeFirstLetter } from '@assets/string-utility';

export const filtersToParamArray = filters => {
    if (!filters) {
        return [];
    }

    return (
        Object.entries(filters)
            // todo SEARCH-2398 maybe we should handle it differently
            .filter(
                ([filterName, filterValue]) => filterName && filterValue?.length
            )
            .map(([filterName, filterValues]) => {
                const filterValueArray = [filterValues].flat();

                const filterValue = filterValueArray.join(
                    PATH_FILTER_VALUES_SEPARATOR
                );

                return [filterName, filterValue]
                    .map(encodeURIComponent)
                    .join(PATH_FILTER_NAME_VALUE_SEPARATOR);
            })
    );
};

export const parseCategoryUrl = url => {
    const result = {
        categoryUrl: [],
        filters: {},
    };

    url.split(PATH_SEGMENTS_SEPARATOR).forEach(item => {
        const [paramName, paramValue] = item.split(
            PATH_FILTER_NAME_VALUE_SEPARATOR
        );

        if (!paramName) {
            return;
        }

        if (paramValue) {
            result.filters[paramName] = paramValue.split(
                PATH_FILTER_VALUES_SEPARATOR
            );
        } else {
            result.categoryUrl.push(paramName);
        }
    });

    result.categoryUrl = result.categoryUrl.join(PATH_SEGMENTS_SEPARATOR);

    return result;
};

export const stringifyCategoryUrl = paramsObject => {
    const { categoryUrl, filters } = paramsObject;
    const params = filtersToParamArray(filters);

    params.unshift(categoryUrl);

    return params.join(PATH_SEGMENTS_SEPARATOR);
};

export const stringifySearchUrl = paramsObject => {
    const { query, filters } = paramsObject;
    const params = filtersToParamArray(filters);

    params.unshift(query);

    return params.join(PATH_SEGMENTS_SEPARATOR);
};

export const mapCategoryUrlToBreadcrumbsForAnalytics = url => {
    if (!url) {
        return '';
    }

    return url
        .replaceAll('-', ' ')
        .split('/')
        .filter(value => value)
        .map(value => capitalizeFirstLetter(value))
        .join('|');
};

export const createPagePath = (path, pagePrefix = '', pageSuffix = '') =>
    pagePrefix
        ? `/${pagePrefix}/${path}${pageSuffix}`
        : `/${path}${pageSuffix}`;
