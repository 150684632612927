<template>
    <div class="header-info-box">
        <BaseTooltip
            :is-mobile="isMobile"
            :is-enabled="isTooltipEnabled"
            :position="POSITION_BOTTOM"
            :tabindex="isTooltipEnabled ? 0 : -1"
            padding="11px"
            tooltip-class="box-tooltip"
            tooltip-container-class="box-tooltip-container"
        >
            <div v-if="!!$slots.icon" class="icon">
                <slot name="icon" />
            </div>
            <div class="content">
                <div class="title">
                    <slot />
                </div>
                <div v-if="isTooltipEnabled" class="text">
                    {{ $t('View details').toLowerCase() }}
                </div>
            </div>

            <template #tooltipContent>
                <StaticBlock
                    class="box-tooltip-content"
                    :content="tooltipContent"
                />
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
import { POSITION_BOTTOM } from '@types/Tooltip';

import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

export default {
    name: 'HeaderInfoBox',

    components: {
        StaticBlock,
        BaseTooltip,
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },

        tooltipContent: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            POSITION_BOTTOM,
        };
    },

    computed: {
        isTooltipEnabled() {
            return !this.isMobile && !!this.tooltipContent;
        },
    },
};
</script>

<style lang="scss" scoped>
.header-info-box {
    @apply text-text3;

    &:deep() {
        .box-tooltip {
            @apply flex items-center justify-start;
        }

        .box-tooltip-container {
            width: theme('customVariables.tooltip.width');
        }
    }

    .icon {
        @apply mr-2;

        &:deep(.svg) {
            @apply text-text3;
        }
    }

    .title {
        @apply text-s leading-s;
    }

    .text {
        @apply text-xs leading-s;
    }

    .box-tooltip-content {
        &:deep() {
            p {
                &:not(:last-child) {
                    @apply mb-20p;
                }
            }
        }
    }
}
</style>
