<template>
    <div class="header-actions">
        <AccountTooltip />

        <WishlistTooltip />

        <MinicartTooltip />
    </div>
</template>

<script>
import AccountTooltip from '@header-molecules/AccountTooltip/AccountTooltip';
import WishlistTooltip from '@header-molecules/WishlistTooltip/WishlistTooltip';
import MinicartTooltip from '@header-molecules/MinicartTooltip/MinicartTooltip';

export default {
    name: 'HeaderActions',

    components: {
        AccountTooltip,
        MinicartTooltip,
        WishlistTooltip,
    },
};
</script>

<style scoped lang="scss">
.header-actions {
    @apply flex items-center relative;

    @screen ui-desktopPlus {
        @apply gap-x-ui-6;
    }
}
</style>
