import DateHelper from '@models/DateHelper/DateHelper';

const normalizeFieldValue = (fieldValue, { type }, { dateHelper } = {}) => {
    const defaultValue = null;

    if (
        typeof fieldValue === 'string' &&
        type === Date &&
        dateHelper instanceof DateHelper
    ) {
        if (!dateHelper.isValid(fieldValue)) {
            return defaultValue;
        }

        return dateHelper?.createDateFromStringInTimezone(fieldValue);
    }

    if (typeof fieldValue === 'number' && type === Number) {
        const number = parseInt(fieldValue, 10);

        return Number.isNaN(number) ? defaultValue : number;
    }

    if (type === Object) {
        return fieldValue || defaultValue;
    }

    return fieldValue?.trim() || defaultValue;
};

export const getNormalizedAttributeModelValues = (
    attributeModel,
    attributeValues,
    normalizerOptions,
    attributeValueCallback = null
) => {
    return Object.fromEntries(
        Object.entries(attributeModel).flatMap(([key, model]) => {
            let attributeValue = attributeValues[key];

            if (typeof attributeValueCallback === 'function') {
                attributeValue = attributeValueCallback(
                    attributeValue,
                    key,
                    model,
                    attributeValues
                );
            }

            const value = normalizeFieldValue(
                attributeValue,
                model,
                normalizerOptions
            );

            const entries = [[key, value]];

            if (model.keepRaw === true) {
                entries.push([`${key}_raw`, attributeValue]);
            }

            return entries;
        })
    );
};
