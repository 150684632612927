var handlerState = () => ({
  urlFilters: {},
  filters: {},
  availableFilters: {},
  selectedParams: {},
  selectedExtraParams: {},
  initialState: {},
  initialExtraState: {},
  searchQueryFilters: {},
  searchQueryLocalized: {},
  searchQueryLabels: {},
  selectedEsizemeScan: {},
  initialEsizemeScan: {},
  esizemeRangeBoundaries: []
});

export { handlerState as default };
