import Filter from './Filter.js';
import { copyObjectDeep } from '../assets/object.js';
import { getMissingError } from '../assets/errors.js';

class FilterToggle extends Filter {
  option;
  constructor(filterAttributes, params, addFilter, removeFilter) {
    super(filterAttributes, addFilter, removeFilter);
    const { option } = filterAttributes;
    if (!option) {
      throw getMissingError("option");
    }
    this.option = option;
    if (Object.keys(params).length) {
      this.selectedFilterParams = copyObjectDeep(params);
      this.initialParamsKeys = Object.keys(params);
    }
  }
  get isSelected() {
    return !!this.selectedFilterParams[this.option.code];
  }
  toggleFilter() {
    if (this.isSelected) {
      this.clearFilter();
      return;
    }
    this.setSelected();
  }
  updateFilter(filterAttributes) {
    this.option = filterAttributes.option;
    if (this.option.code !== Object.keys(this.selectedFilterParams)[0]) {
      this.selectedFilterParams = {};
    }
  }
  setSelected() {
    const { code, url, label } = this.option;
    this.selectedFilterParams = { [code]: { code, url, label } };
  }
}

export { FilterToggle as default };
