export const NAME = 'newsletter';

export const CONFIRM_SUBSCRIPTION = 'confirmSubscription';
export const UNSUBSCRIBE = 'unsubscribe';
export const UNSUBSCRIBE_BY_CODE = 'unsubscribeByCode';
export const SUBSCRIBE = 'subscribe';
export const RESEND_NEWSLETTER_SUBSCRIPTION_CONFIRMATION_EMAIL =
    'resendNewsletterSubscriptionConfirmation';

export const ASYNC_NEWSLETTER_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        CONFIRM_SUBSCRIPTION,
        UNSUBSCRIBE,
        UNSUBSCRIBE_BY_CODE,
        SUBSCRIBE,
        RESEND_NEWSLETTER_SUBSCRIPTION_CONFIRMATION_EMAIL,
    },
};
