import {
    ERROR_PAGE_TAG_NAME,
    ERROR_LOCALE_TAG_NAME,
    ERROR_STORE_MODULE_TAG_NAME,
    ERROR_CORRELATION_ID_TAG_NAME,
    ERROR_FEATURE_DOMAIN_TAG_NAME,
    ERROR_K8S_TAG_NAME,
} from '@types/Errors';

import { isObject } from '@assets/object';

const TYPE_ERROR = 'error';
const TYPE_MESSAGE = 'message';

const DEFAULT_FEATURE_DOMAIN_TAG_VALUE = 'monolith';

const DOMAIN_SCOPE_PROPERTIES_LIMIT = 20;

const createDomainMessage = (domainName, message) =>
    `[ErrorHandler] domain "${domainName}": ${message}`;

const checkParamsType = (tags, extras) => {
    if (!isObject(tags)) {
        throw new Error('Tags must be an object');
    }

    if (!isObject(extras)) {
        throw new Error('Extras must be an object');
    }
};

const checkParamsLength = (
    existingTags,
    existingExtras,
    newTags,
    newExtras
) => {
    const tagsSumLength =
        Object.keys(existingTags).length + Object.keys(newTags).length;

    if (tagsSumLength > DOMAIN_SCOPE_PROPERTIES_LIMIT) {
        throw new Error('Tags limit exceeded.');
    }

    const extrasSumLength =
        Object.keys(existingExtras).length + Object.keys(newExtras).length;

    if (extrasSumLength > DOMAIN_SCOPE_PROPERTIES_LIMIT) {
        throw new Error('Extras limit exceeded.');
    }
};

export default ({
    env,
    $sentry,
    $sentryLoad,
    $sentryReady,
    routeName,
    locale,
    correlationId,
}) => {
    let sentryClient = null;
    let sentryLoadPromise = null;
    let sentryReadyPromise = null;
    let setGlobalContextOnInit = false;

    const domainScopes = {};

    const logErrorHandlerErrorMessage = message => {
        if (env.isDev) {
            console.error(message);
        }
    };

    return {
        async initSentry() {
            if (sentryClient) {
                return;
            }

            if ($sentry) {
                sentryClient = $sentry;
            } else {
                if (!sentryLoadPromise) {
                    sentryLoadPromise = $sentryLoad();
                }

                await sentryLoadPromise;

                if (!sentryReadyPromise) {
                    sentryReadyPromise = $sentryReady();
                }

                sentryClient = await sentryReadyPromise;
            }

            if (!setGlobalContextOnInit) {
                setGlobalContextOnInit = true;

                sentryLoadPromise = null;
                sentryReadyPromise = null;

                await this.setGlobalContext({
                    [ERROR_PAGE_TAG_NAME]: routeName,
                    [ERROR_LOCALE_TAG_NAME]: locale,
                    [ERROR_CORRELATION_ID_TAG_NAME]: correlationId,
                    [ERROR_FEATURE_DOMAIN_TAG_NAME]: DEFAULT_FEATURE_DOMAIN_TAG_VALUE,
                    [ERROR_K8S_TAG_NAME]: env.isK8s,
                });
            }
        },

        async setGlobalContext(tags = {}, extras = {}) {
            await this.initSentry();

            sentryClient.configureScope(scope => {
                scope.setTags(tags);
                scope.setExtras(extras);
            });
        },

        async captureStoreError(moduleName, error, tags = {}, extras = {}) {
            await this.captureError(
                error,
                {
                    [ERROR_STORE_MODULE_TAG_NAME]: moduleName,
                    ...tags,
                },
                extras
            );
        },

        async captureStoreMessage(
            moduleName,
            message = '',
            tags = {},
            extras = {}
        ) {
            await this.captureMessage(
                message,
                {
                    [ERROR_STORE_MODULE_TAG_NAME]: moduleName,
                    ...tags,
                },
                extras
            );
        },

        async captureError(error, tags = {}, extras = {}) {
            await this.capture(TYPE_ERROR, error, tags, extras);
        },

        async captureMessage(message = '', tags = {}, extras = {}) {
            await this.capture(TYPE_MESSAGE, message, tags, extras);
        },

        async capture(type, data, tags = {}, extras = {}) {
            await this.initSentry();

            sentryClient.withScope(scope => {
                if (Object.keys(tags)) {
                    scope.setTags(tags);
                }

                if (Object.keys(extras)) {
                    scope.setExtras(extras);
                }

                if (type === TYPE_ERROR && data instanceof Error) {
                    sentryClient.captureException(data);
                } else if (type === TYPE_MESSAGE) {
                    sentryClient.captureMessage(data);
                }
            });
        },

        registerFeatureDomain(domainName, domainTags = {}, domainExtras = {}) {
            if (domainScopes[domainName]) {
                logErrorHandlerErrorMessage(
                    createDomainMessage(
                        domainName,
                        `Scope for domain "${domainName}" already exists.`
                    )
                );

                return;
            }

            try {
                checkParamsType(domainTags, domainExtras);
            } catch (error) {
                logErrorHandlerErrorMessage(
                    createDomainMessage(domainName, error)
                );

                return;
            }

            domainScopes[domainName] = {
                tags: {
                    [ERROR_FEATURE_DOMAIN_TAG_NAME]: domainName,
                    ...domainTags,
                },
                extras: {
                    ...domainExtras,
                },
            };
        },

        setDomainContext(domainName, domainTags = {}, domainExtras = {}) {
            if (!domainScopes[domainName]) {
                logErrorHandlerErrorMessage(
                    createDomainMessage(
                        domainName,
                        `Scope for domain "${domainName}" does not exist.`
                    )
                );

                return;
            }

            try {
                checkParamsType(domainTags, domainExtras);
            } catch (error) {
                logErrorHandlerErrorMessage(
                    createDomainMessage(domainName, error)
                );

                return;
            }

            const { tags = {}, extras = {} } = domainScopes[domainName];

            try {
                checkParamsLength(tags, extras, domainTags, domainExtras);
            } catch (error) {
                logErrorHandlerErrorMessage(
                    createDomainMessage(domainName, error)
                );

                return;
            }

            domainScopes[domainName] = {
                tags: {
                    ...tags,
                    ...domainTags,
                },
                extras: {
                    ...extras,
                    ...domainExtras,
                },
            };
        },

        async captureDomainError(domainName, error, tags = {}, extras = {}) {
            if (!domainScopes[domainName]) {
                logErrorHandlerErrorMessage(
                    createDomainMessage(
                        domainName,
                        `Domain "${domainName}" is not registered.`
                    )
                );

                return;
            }

            try {
                checkParamsType(tags, extras);
            } catch (err) {
                logErrorHandlerErrorMessage(
                    createDomainMessage(domainName, err)
                );

                return;
            }

            const {
                tags: domainTags = {},
                extras: domainExtras = {},
            } = domainScopes[domainName];

            await this.captureError(
                error,
                { ...domainTags, ...tags },
                { ...domainExtras, ...extras }
            );
        },
    };
};
