<template>
    <div class="base-tooltip" v-bind="$attrs" v-on="listeners">
        <WithClickOutsideDetection
            :handler="isTooltipVisible ? 'closeTooltip' : null"
        >
            <div class="base-tooltip-wrapper" :class="tooltipClass">
                <slot />
                <div
                    v-if="isTooltipVisible"
                    ref="tooltipContainer"
                    class="tooltip-container"
                    :class="[positionResponsive, theme, tooltipContainerClass]"
                    :style="tooltipPadding"
                >
                    <div class="tooltip-content">
                        <slot
                            name="tooltipContent"
                            :close-tooltip="closeTooltip"
                        />
                    </div>
                </div>
            </div>
        </WithClickOutsideDetection>
    </div>
</template>

<script>
import { THEMES, POSITIONS } from '@types/Tooltip';

import { PADDING_DIRECTIONS } from '@configs/tooltip';

import { checkIfExistsInValuesMap } from '@assets/props';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

const TAG_LINK = 'a';
const TOOLTIP_CONTENT_CLASS = 'tooltip-content';
const TOOLTIP_PADDING = '25px';

export default {
    name: 'BaseTooltip',

    components: {
        WithClickOutsideDetection,
    },

    inheritAttrs: false,

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },

        position: {
            type: String,
            default: POSITIONS.POSITION_TOP,
            validator: checkIfExistsInValuesMap(POSITIONS),
        },

        positionMobile: {
            type: String,
            default: '',
            validator: checkIfExistsInValuesMap(POSITIONS, true),
        },

        isEnabled: {
            type: Boolean,
            default: true,
        },

        isEnabledOnMobile: {
            type: Boolean,
            default: true,
        },

        padding: {
            type: String,
            default: TOOLTIP_PADDING,
        },

        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        tooltipClass: {
            type: String,
            default: '',
        },

        tooltipContainerClass: {
            type: String,
            default: '',
        },

        autoPosition: {
            type: String,
            default: null,
        },

        autoPositionRoot: {
            type: process.server ? Object : HTMLElement,
            default: null,
        },
    },

    data() {
        return {
            isTooltipVisible: false,
            autoPositionHorizontal: '',
        };
    },

    computed: {
        listeners() {
            const {
                click,
                mouseenter,
                mouseleave,
                focus,
                ...listeners
            } = this.$listeners;
            const {
                isMobile,
                isEnabledOnMobile,
                showTooltip,
                closeTooltip,
            } = this;

            if (!isMobile) {
                listeners.mouseenter = showTooltip;
                listeners.focus = showTooltip;
                listeners.mouseleave = closeTooltip;
            } else if (isEnabledOnMobile) {
                listeners.click = event => {
                    if (this.isTooltipVisible) {
                        const clickTarget = event.target;

                        if (
                            clickTarget.tagName.toLowerCase() !== TAG_LINK ||
                            !clickTarget.closest(`.${TOOLTIP_CONTENT_CLASS}`)
                        ) {
                            event.preventDefault();
                            closeTooltip();
                        }
                    } else {
                        event.preventDefault();
                        showTooltip();
                    }
                };
            }

            return listeners;
        },

        tooltipPadding() {
            return `padding-${PADDING_DIRECTIONS[this.positionResponsive]}: ${
                this.padding
            }`;
        },

        positionResponsive() {
            if (this.autoPosition) {
                return [this.autoPosition, this.autoPositionHorizontal]
                    .filter(position => position)
                    .join('-');
            }

            if (this.positionMobile !== '' && this.isMobile) {
                return this.positionMobile;
            }

            return this.position;
        },
    },

    watch: {
        isMobile(flag) {
            if (flag && !this.isEnabledOnMobile) {
                this.closeTooltip();
            }
        },
    },

    methods: {
        async showTooltip() {
            if (this.isEnabled && (!this.isMobile || this.isEnabledOnMobile)) {
                this.isTooltipVisible = true;

                if (this.autoPosition) {
                    this.autoPositionHorizontal = '';

                    await this.$nextTick();

                    this.autoPositionHorizontal = this.calculateHorizontalPosition();
                }

                this.$emit('tooltip-showed');
            }
        },

        closeTooltip() {
            this.isTooltipVisible = false;
        },

        calculateHorizontalPosition() {
            const { autoPositionRoot } = this;

            const root =
                autoPositionRoot instanceof HTMLElement
                    ? autoPositionRoot
                    : this.$el.parentElement;

            const { tooltipContainer } = this.$refs;

            const {
                left: rootLeft,
                width: rootWidth,
            } = root.getBoundingClientRect();
            const {
                left: tooltipLeft,
                width: tooltipWidth,
            } = tooltipContainer.getBoundingClientRect();

            const rootRight = rootLeft + rootWidth;
            const tooltipRight = tooltipLeft + tooltipWidth;

            const hasCrossedLeftEdge = rootLeft > tooltipLeft;
            const hasCrossedRightEdge = rootRight < tooltipRight;

            if (hasCrossedLeftEdge !== hasCrossedRightEdge) {
                return hasCrossedRightEdge ? 'left' : 'right';
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@theme/resources/mixin/focus-visible.scss';

$tooltip-arrow-size: math.div(12px, 1.4142);
$half-tooltip-arrow-size: math.div($tooltip-arrow-size, 2);
$border-color: $tailwindcss-colors-light;

@mixin arrow-border-color($first-border-direction, $second-border-direction) {
    border-#{$first-border-direction}: 1px solid #{$border-color};
    border-#{$second-border-direction}: 1px solid #{$border-color};
}

.base-tooltip {
    @include focusVisibleStyles();

    &:hover,
    &:focus,
    &:focus-within {
        .tooltip-content {
            @apply block;
        }
    }
}

.base-tooltip-wrapper {
    @apply table relative;

    .tooltip-container {
        @apply absolute text-s leading-s table z-4;

        &.dark {
            .tooltip-content {
                @apply bg-gray2 text-light;

                &:deep() {
                    a {
                        @apply text-blue4;
                    }
                }

                &::after {
                    @apply bg-gray2;
                }
            }
        }

        &.gray {
            .tooltip-content {
                @apply bg-gray3 text-light;

                &:deep() {
                    a {
                        @apply text-blue4;
                    }
                }

                &::after {
                    @apply bg-gray3;
                }
            }
        }

        &.light-gray {
            .tooltip-content {
                @apply bg-gray5 text-text;

                &:deep() {
                    a {
                        @apply text-blue1;
                    }
                }

                &::after {
                    @apply bg-gray5;
                }
            }
        }

        &.top,
        &.bottom {
            @apply left-1/2;
            transform: translateX(-50%);

            .tooltip-content {
                &::after {
                    left: calc(50% - #{$half-tooltip-arrow-size});
                }
            }
        }

        &.left,
        &.right {
            @apply top-1/2;
            transform: translateY(-50%);

            .tooltip-content {
                &::after {
                    top: calc(50% - #{$half-tooltip-arrow-size});
                }
            }
        }

        &.top,
        &.top-left,
        &.top-right {
            @apply bottom-full;
        }

        &.bottom,
        &.bottom-left,
        &.bottom-right {
            @apply top-full;
        }

        &.top {
            .tooltip-content {
                &::after {
                    @apply border-b-1 border-r-1;
                    @include arrow-border-color(bottom, right);
                    bottom: -$half-tooltip-arrow-size;
                }
            }
        }

        &.bottom {
            .tooltip-content {
                &::after {
                    @apply border-t-1 border-l-1;
                    @include arrow-border-color(top, left);
                    top: -$half-tooltip-arrow-size;
                }
            }
        }

        &.left {
            @apply right-full;

            .tooltip-content {
                &::after {
                    @apply border-t-1 border-r-1;
                    @include arrow-border-color(top, right);
                    right: -$half-tooltip-arrow-size;
                    top: calc(50% - #{$half-tooltip-arrow-size});
                }
            }
        }

        &.right {
            @apply left-full;

            .tooltip-content {
                &::after {
                    @apply border-b-1 border-l-1;
                    @include arrow-border-color(bottom, left);
                    left: -$half-tooltip-arrow-size;
                }
            }
        }

        &.bottom-left,
        &.bottom-right {
            .tooltip-content {
                &::after {
                    @apply border-t-1 border-l-1;
                    @include arrow-border-color(top, left);
                    top: -$half-tooltip-arrow-size;
                }
            }
        }

        &.top-left,
        &.top-right {
            .tooltip-content {
                &::after {
                    @apply border-b-1 border-r-1;
                    @include arrow-border-color(bottom, right);
                    bottom: -$half-tooltip-arrow-size;
                }
            }
        }

        &.bottom-right,
        &.top-right {
            left: -$half-tooltip-arrow-size;

            .tooltip-content {
                &::after {
                    @apply left-12;
                }
            }
        }

        &.bottom-left,
        &.top-left {
            right: -$half-tooltip-arrow-size;

            .tooltip-content {
                &::after {
                    @apply right-12;
                }
            }
        }
    }

    .tooltip-content {
        @apply hidden relative px-3 py-12p border-1 border-solid border-light rounded-4;

        a {
            @apply font-bold;
        }

        &::after {
            @apply absolute border-1 border-solid border-transparent rounded-1;
            width: $tooltip-arrow-size;
            height: $tooltip-arrow-size;
            content: '';
            transform: rotate(45deg);
        }
    }
}
</style>
