import {
    PRODUCT_PAGE_NAME,
    CUSTOMER_ACCOUNT_ORDERS_NAME,
    CUSTOMER_ACCOUNT_ORDER_VIEW_NAME,
} from '@router/names';

import {
    SEARCH_RESULTS_PAGE_NAME,
    CATALOG_PAGE_NAME,
} from '@search/routing/names';

// eslint-disable-next-line no-unused-vars
export default ({ app, store }, inject) => {
    inject('absoluteUrl', path => {
        return `${store.state.config.baseUrl}${path}`;
    });

    inject('createPagePath', routePageConfig => {
        return routePageConfig;
    });

    inject('createCategoryPath', (url, query = {}) => {
        const {
            route: { fullPath },
        } = app.router.resolve({
            name: CATALOG_PAGE_NAME,
            params: {
                url,
            },
            query,
        });

        return fullPath.replace(/%2F/g, '/'); // @todo maybe there is a better way to do this;
    });

    inject('createProductPath', (urlKey, query = {}) => {
        const {
            route: { fullPath },
        } = app.router.resolve({
            name: PRODUCT_PAGE_NAME,
            params: {
                urlKey,
            },
            query,
        });

        return fullPath;
    });

    inject('createSearchPath', (url, category) => {
        const normalizedUrl = url.trimEnd();
        const [query] = normalizedUrl.replace(/%2F/g, '/').split('/');

        const {
            route: { fullPath },
        } = app.router.resolve({
            name: SEARCH_RESULTS_PAGE_NAME,
            params: {
                url: normalizedUrl,
            },
            query: {
                q: query,
                category,
            },
        });

        return fullPath.replace(/%2F/g, '/'); // @todo maybe there is a better way to do this;
    });

    inject('createCustomerOrdersPath', () => {
        const {
            route: { path },
        } = app.router.resolve({
            name: CUSTOMER_ACCOUNT_ORDERS_NAME,
        });

        return path;
    });

    inject('createCustomerOrderViewPath', orderId => {
        const {
            route: { path },
        } = app.router.resolve({
            name: CUSTOMER_ACCOUNT_ORDER_VIEW_NAME,
            params: {
                orderId,
            },
        });

        return path;
    });

    inject('createPagePathByName', routeName => {
        const {
            route: { path },
        } = app.router.resolve({
            name: routeName,
        });

        return path;
    });
};
