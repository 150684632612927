export const DEFAULT_STORE_CODE = 'default';
export const CZ_STORE_CODE = 'cz';
export const SK_STORE_CODE = 'sk';
export const RO_STORE_CODE = 'ro';
export const HU_STORE_CODE = 'hu';
export const UA_UK_STORE_CODE = 'ua_uk';
export const UA_RU_STORE_CODE = 'ua_ru';
export const RU_STORE_CODE = 'ru';
export const BG_STORE_CODE = 'bg';
export const DE_STORE_CODE = 'de';
export const GB_STORE_CODE = 'gb';
export const LT_STORE_CODE = 'lt';
export const GR_STORE_CODE = 'gr';
export const SE_STORE_CODE = 'se';
export const IT_STORE_CODE = 'it';
export const ES_STORE_CODE = 'es';
export const FR_STORE_CODE = 'fr';
export const CH_STORE_CODE = 'ch';
export const HR_STORE_CODE = 'hr';
export const AT_STORE_CODE = 'at';

export const STORE_CODES = [
    DEFAULT_STORE_CODE,
    CZ_STORE_CODE,
    SK_STORE_CODE,
    RO_STORE_CODE,
    HU_STORE_CODE,
    UA_UK_STORE_CODE,
    UA_RU_STORE_CODE,
    RU_STORE_CODE,
    BG_STORE_CODE,
    DE_STORE_CODE,
    GB_STORE_CODE,
    LT_STORE_CODE,
    GR_STORE_CODE,
    SE_STORE_CODE,
    IT_STORE_CODE,
    ES_STORE_CODE,
    FR_STORE_CODE,
    CH_STORE_CODE,
    HR_STORE_CODE,
    AT_STORE_CODE,
];
