export function sortCategoriesTreeAlphabetically(data, locale) {
    const { tree: { items = [] } = {} } = data || {};

    function compareLabel(a, b) {
        const aLabel = a.translations[locale]?.label?.toLowerCase() || '';
        const bLabel = b.translations[locale]?.label?.toLowerCase() || '';

        return aLabel.localeCompare(bLabel);
    }

    function nestedLoop(nodeItems = []) {
        nodeItems.forEach(child => {
            if (child.children?.length) {
                child.children.sort(compareLabel);

                nestedLoop(child.children);
            }
        });
    }

    items.sort(compareLabel);
    nestedLoop(items);
}
