/* eslint-disable max-len */

export const mockCategoryNavigationTree1 = {
    tree: {
        items: [
            {
                products_count: 13579,
                children: [
                    {
                        products_count: 1536,
                        children: [],
                        is_leaf: false,
                        id: '2446',
                        translations: {
                            pl_PL: {
                                label: 'Klapki i sandały',
                                url: 'meskie/klapki-i-sandaly',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Klapki i sandały męskie - zobacz modne propozycje {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Klapki i sandały męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Klapki i sandały męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                    {
                        products_count: 8808,
                        children: [
                            {
                                products_count: 8015,
                                children: [],
                                is_leaf: true,
                                id: '2516',
                                translations: {
                                    pl_PL: {
                                        label: 'Codzienne',
                                        url: 'meskie/polbuty/codzienne',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Buty codzienne męskie - wybierz swój wymarzony model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 5693,
                                children: [],
                                is_leaf: true,
                                id: '2517',
                                translations: {
                                    pl_PL: {
                                        label: 'Sneakersy',
                                        url: 'meskie/polbuty/sneakersy',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 800,
                                children: [],
                                is_leaf: true,
                                id: '2569',
                                translations: {
                                    pl_PL: {
                                        label: 'Trampki',
                                        url: 'meskie/polbuty/trampki',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Tenisówki i trampki męskie - uzupełnij swoją miejską stylizację {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 475,
                                children: [],
                                is_leaf: true,
                                id: '2603',
                                translations: {
                                    pl_PL: {
                                        label: 'Wizytowe',
                                        url: 'meskie/polbuty/wizytowe',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Półbuty męskie - zobacz wizytowe buty znanych marek {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 217,
                                children: [],
                                is_leaf: true,
                                id: '2611',
                                translations: {
                                    pl_PL: {
                                        label: 'Mokasyny',
                                        url: 'meskie/polbuty/mokasyny',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Mokasyny męskie - zobacz najlepsze markowe propozycje {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 481,
                                children: [],
                                is_leaf: true,
                                id: '2630',
                                translations: {
                                    pl_PL: {
                                        label: 'Buty Trekkingowe i Trapery',
                                        url:
                                            'meskie/polbuty/buty-trekkingowe-i-trapery',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Trapery i buty trekkingowe męskie - wybierz profesjonalny model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 7,
                                children: [],
                                is_leaf: true,
                                id: '2790',
                                translations: {
                                    pl_PL: {
                                        label: 'Glany',
                                        url: 'meskie/polbuty/glany',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Glany męskie niskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 110,
                                children: [],
                                is_leaf: true,
                                id: '3114',
                                translations: {
                                    pl_PL: {
                                        label: 'Espadryle',
                                        url: 'meskie/polbuty/espadryle',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Espadryle męskie - postaw na wygodne letnie buty {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                        ],
                        is_leaf: false,
                        id: '2515',
                        translations: {
                            pl_PL: {
                                label: 'Półbuty',
                                url: 'meskie/polbuty',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Półbuty męskie: eleganckie, sportowe, skórzane {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                    {
                        products_count: 2044,
                        children: [],
                        is_leaf: false,
                        id: '2534',
                        translations: {
                            pl_PL: {
                                label: 'Kozaki i inne',
                                url: 'meskie/kozaki-i-inne',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Kozaki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Kozaki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Kozaki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                    {
                        products_count: 3723,
                        children: [],
                        is_leaf: false,
                        id: '2570',
                        translations: {
                            pl_PL: {
                                label: 'Sportowe',
                                url: 'meskie/sportowe',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Buty sportowe męskie - sprawdzone buty {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Buty sportowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Buty sportowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                ],
                is_leaf: false,
                id: '2445',
                translations: {
                    pl_PL: {
                        label: 'Męskie',
                        url: 'meskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Modne buty męskie – wybierz stylowo {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 36287,
                children: [],
                is_leaf: false,
                id: '2439',
                translations: {
                    pl_PL: {
                        label: 'Damskie',
                        url: 'damskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty damskie, modne obuwie dla kobiet {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} , modne obuwie dla kobiet w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 16676,
                children: [],
                is_leaf: false,
                id: '2458',
                translations: {
                    pl_PL: {
                        label: 'Dziecięce',
                        url: 'dzieciece',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty dziecięce – wybierz model dla swojego malucha {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 9134,
                children: [],
                is_leaf: false,
                id: '2497',
                translations: {
                    pl_PL: {
                        label: 'Torebki',
                        url: 'torebki',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Torebki damskie – postaw na modne modele {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Torebki {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Torebki {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 15280,
                children: [],
                is_leaf: false,
                id: '2541',
                translations: {
                    pl_PL: {
                        label: 'Akcesoria',
                        url: 'akcesoria',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 13027,
                children: [],
                is_leaf: false,
                id: '3155',
                translations: {
                    pl_PL: {
                        label: 'Sport',
                        url: 'sport',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty sportowe: lifestyle, fitness, bieganie - damskie, męskie, dziecięce {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 1292,
                children: [],
                is_leaf: true,
                id: '3350',
                translations: {
                    pl_PL: {
                        label: 'Unisex',
                        url: 'unisex',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Zobacz ofertę butów i akcesoriów unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 12,
                children: [],
                is_leaf: false,
                id: '5000',
                translations: {
                    pl_PL: {
                        label: 'Odzież',
                        url: 'odziez',
                        locale: 'pl_PL',
                        description: null,
                        meta_title: null,
                        meta_description: null,
                        header: null,
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
        ],
    },
    bread_crumbs: {
        items: [
            {
                id: '2',
                translations: {
                    pl_PL: {
                        label: 'eobuwie',
                        url: 'eobuwie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Zobacz ofertę sklepu internetowego eobuwie.pl {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                id: '2445',
                translations: {
                    pl_PL: {
                        label: 'Męskie',
                        url: 'meskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Modne buty męskie – wybierz stylowo {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                id: '2515',
                translations: {
                    pl_PL: {
                        label: 'Półbuty',
                        url: 'meskie/polbuty',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Półbuty męskie: eleganckie, sportowe, skórzane {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
        ],
    },
    categories: [
        {
            id: '2515',
            translations: {
                pl_PL: {
                    label: 'Półbuty',
                    url: 'meskie/polbuty',
                    locale: 'pl_PL',
                    description: null,
                    meta_title:
                        'Półbuty męskie: eleganckie, sportowe, skórzane {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                    meta_description:
                        'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                    header:
                        'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                    canonical_url: null,
                    meta_robots: 'index, follow',
                },
            },
        },
    ],
    hreflangs: [
        { locale: 'de_AT', slug: 'herrenschuhe/halbschuhe' },
        { locale: 'lt_LT', slug: 'vyrams/batai' },
        { locale: 'pl_PL', slug: 'meskie/polbuty' },
        { locale: 'ru_UA', slug: 'muzskaa/tufli' },
        { locale: 'sl_SI', slug: 'moski/nizki-cevlji' },
        { locale: 'uk_UA', slug: 'choloviche/tufli' },
    ],
};

export const expectedResult1 = [
    {
        products_count: 8015,
        is_leaf: true,
        id: '2516',
        translations: {
            pl_PL: {
                label: 'Codzienne',
                url: 'meskie/polbuty/codzienne',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Buty codzienne męskie - wybierz swój wymarzony model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 8015,
        levelOriginal: 3,
    },
    {
        products_count: 5693,
        is_leaf: true,
        id: '2517',
        translations: {
            pl_PL: {
                label: 'Sneakersy',
                url: 'meskie/polbuty/sneakersy',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 5693,
        levelOriginal: 3,
    },
    {
        products_count: 800,
        is_leaf: true,
        id: '2569',
        translations: {
            pl_PL: {
                label: 'Trampki',
                url: 'meskie/polbuty/trampki',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Tenisówki i trampki męskie - uzupełnij swoją miejską stylizację {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 800,
        levelOriginal: 3,
    },
    {
        products_count: 475,
        is_leaf: true,
        id: '2603',
        translations: {
            pl_PL: {
                label: 'Wizytowe',
                url: 'meskie/polbuty/wizytowe',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Półbuty męskie - zobacz wizytowe buty znanych marek {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 475,
        levelOriginal: 3,
    },
    {
        products_count: 217,
        is_leaf: true,
        id: '2611',
        translations: {
            pl_PL: {
                label: 'Mokasyny',
                url: 'meskie/polbuty/mokasyny',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Mokasyny męskie - zobacz najlepsze markowe propozycje {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 217,
        levelOriginal: 3,
    },
    {
        products_count: 481,
        is_leaf: true,
        id: '2630',
        translations: {
            pl_PL: {
                label: 'Buty Trekkingowe i Trapery',
                url: 'meskie/polbuty/buty-trekkingowe-i-trapery',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Trapery i buty trekkingowe męskie - wybierz profesjonalny model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 481,
        levelOriginal: 3,
    },
    {
        products_count: 7,
        is_leaf: true,
        id: '2790',
        translations: {
            pl_PL: {
                label: 'Glany',
                url: 'meskie/polbuty/glany',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Glany męskie niskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 7,
        levelOriginal: 3,
    },
    {
        products_count: 110,
        is_leaf: true,
        id: '3114',
        translations: {
            pl_PL: {
                label: 'Espadryle',
                url: 'meskie/polbuty/espadryle',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Espadryle męskie - postaw na wygodne letnie buty {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 110,
        levelOriginal: 3,
    },
];

export const mockCategoryNavigationTree2 = {
    tree: {
        items: [
            {
                products_count: 13579,
                children: [
                    {
                        products_count: 1536,
                        children: [],
                        is_leaf: false,
                        id: '2446',
                        translations: {
                            pl_PL: {
                                label: 'Klapki i sandały',
                                url: 'meskie/klapki-i-sandaly',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Klapki i sandały męskie - zobacz modne propozycje {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Klapki i sandały męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Klapki i sandały męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                    {
                        products_count: 8808,
                        children: [
                            {
                                products_count: 8015,
                                children: [],
                                is_leaf: true,
                                id: '2516',
                                translations: {
                                    pl_PL: {
                                        label: 'Codzienne',
                                        url: 'meskie/polbuty/codzienne',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Buty codzienne męskie - wybierz swój wymarzony model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 5693,
                                children: [],
                                is_leaf: true,
                                id: '2517',
                                translations: {
                                    pl_PL: {
                                        label: 'Sneakersy',
                                        url: 'meskie/polbuty/sneakersy',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 800,
                                children: [],
                                is_leaf: true,
                                id: '2569',
                                translations: {
                                    pl_PL: {
                                        label: 'Trampki',
                                        url: 'meskie/polbuty/trampki',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Tenisówki i trampki męskie - uzupełnij swoją miejską stylizację {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 475,
                                children: [],
                                is_leaf: true,
                                id: '2603',
                                translations: {
                                    pl_PL: {
                                        label: 'Wizytowe',
                                        url: 'meskie/polbuty/wizytowe',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Półbuty męskie - zobacz wizytowe buty znanych marek {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 217,
                                children: [],
                                is_leaf: true,
                                id: '2611',
                                translations: {
                                    pl_PL: {
                                        label: 'Mokasyny',
                                        url: 'meskie/polbuty/mokasyny',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Mokasyny męskie - zobacz najlepsze markowe propozycje {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 481,
                                children: [],
                                is_leaf: true,
                                id: '2630',
                                translations: {
                                    pl_PL: {
                                        label: 'Buty Trekkingowe i Trapery',
                                        url:
                                            'meskie/polbuty/buty-trekkingowe-i-trapery',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Trapery i buty trekkingowe męskie - wybierz profesjonalny model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 7,
                                children: [],
                                is_leaf: true,
                                id: '2790',
                                translations: {
                                    pl_PL: {
                                        label: 'Glany',
                                        url: 'meskie/polbuty/glany',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Glany męskie niskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                            {
                                products_count: 110,
                                children: [],
                                is_leaf: true,
                                id: '3114',
                                translations: {
                                    pl_PL: {
                                        label: 'Espadryle',
                                        url: 'meskie/polbuty/espadryle',
                                        locale: 'pl_PL',
                                        description: null,
                                        meta_title:
                                            'Espadryle męskie - postaw na wygodne letnie buty {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                        meta_description:
                                            'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                        header:
                                            'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                        canonical_url: null,
                                        meta_robots: 'index, follow',
                                    },
                                },
                            },
                        ],
                        is_leaf: false,
                        id: '2515',
                        translations: {
                            pl_PL: {
                                label: 'Półbuty',
                                url: 'meskie/polbuty',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Półbuty męskie: eleganckie, sportowe, skórzane {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                    {
                        products_count: 2044,
                        children: [],
                        is_leaf: false,
                        id: '2534',
                        translations: {
                            pl_PL: {
                                label: 'Kozaki i inne',
                                url: 'meskie/kozaki-i-inne',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Kozaki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Kozaki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Kozaki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                    {
                        products_count: 3723,
                        children: [],
                        is_leaf: false,
                        id: '2570',
                        translations: {
                            pl_PL: {
                                label: 'Sportowe',
                                url: 'meskie/sportowe',
                                locale: 'pl_PL',
                                description: null,
                                meta_title:
                                    'Buty sportowe męskie - sprawdzone buty {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                                meta_description:
                                    'Buty sportowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                                header:
                                    'Buty sportowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                                canonical_url: null,
                                meta_robots: 'index, follow',
                            },
                        },
                    },
                ],
                is_leaf: false,
                id: '2445',
                translations: {
                    pl_PL: {
                        label: 'Męskie',
                        url: 'meskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Modne buty męskie – wybierz stylowo {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 36287,
                children: [],
                is_leaf: false,
                id: '2439',
                translations: {
                    pl_PL: {
                        label: 'Damskie',
                        url: 'damskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty damskie, modne obuwie dla kobiet {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} , modne obuwie dla kobiet w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 16676,
                children: [],
                is_leaf: false,
                id: '2458',
                translations: {
                    pl_PL: {
                        label: 'Dziecięce',
                        url: 'dzieciece',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty dziecięce – wybierz model dla swojego malucha {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 9134,
                children: [],
                is_leaf: false,
                id: '2497',
                translations: {
                    pl_PL: {
                        label: 'Torebki',
                        url: 'torebki',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Torebki damskie – postaw na modne modele {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Torebki {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Torebki {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 15280,
                children: [],
                is_leaf: false,
                id: '2541',
                translations: {
                    pl_PL: {
                        label: 'Akcesoria',
                        url: 'akcesoria',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 13027,
                children: [],
                is_leaf: false,
                id: '3155',
                translations: {
                    pl_PL: {
                        label: 'Sport',
                        url: 'sport',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty sportowe: lifestyle, fitness, bieganie - damskie, męskie, dziecięce {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 1292,
                children: [],
                is_leaf: true,
                id: '3350',
                translations: {
                    pl_PL: {
                        label: 'Unisex',
                        url: 'unisex',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Zobacz ofertę butów i akcesoriów unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 12,
                children: [],
                is_leaf: false,
                id: '5000',
                translations: {
                    pl_PL: {
                        label: 'Odzież',
                        url: 'odziez',
                        locale: 'pl_PL',
                        description: null,
                        meta_title: null,
                        meta_description: null,
                        header: null,
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
        ],
    },
    bread_crumbs: {
        items: [
            {
                id: '2',
                translations: {
                    pl_PL: {
                        label: 'eobuwie',
                        url: 'eobuwie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Zobacz ofertę sklepu internetowego eobuwie.pl {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                id: '2445',
                translations: {
                    pl_PL: {
                        label: 'Męskie',
                        url: 'meskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Modne buty męskie – wybierz stylowo {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                id: '2515',
                translations: {
                    pl_PL: {
                        label: 'Półbuty',
                        url: 'meskie/polbuty',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Półbuty męskie: eleganckie, sportowe, skórzane {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Półbuty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                id: '2517',
                translations: {
                    pl_PL: {
                        label: 'Sneakersy',
                        url: 'meskie/polbuty/sneakersy',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
        ],
    },
    categories: [
        {
            id: '2517',
            translations: {
                pl_PL: {
                    label: 'Sneakersy',
                    url: 'meskie/polbuty/sneakersy',
                    locale: 'pl_PL',
                    description: null,
                    meta_title:
                        'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                    meta_description:
                        'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                    header:
                        'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                    canonical_url: null,
                    meta_robots: 'index, follow',
                },
            },
        },
    ],
    hreflangs: [
        { locale: 'de_AT', slug: 'herrenschuhe/halbschuhe/sneakers' },
        { locale: 'lt_LT', slug: 'vyrams/batai/laisvalaikio-batai' },
        { locale: 'pl_PL', slug: 'meskie/polbuty/sneakersy' },
        { locale: 'ru_UA', slug: 'muzskaa/tufli/snikersy' },
        { locale: 'sl_SI', slug: 'moski/nizki-cevlji/superge' },
        { locale: 'uk_UA', slug: 'choloviche/tufli/snikercy' },
    ],
};

export const expectedResult2 = [
    {
        products_count: 8015,
        is_leaf: true,
        id: '2516',
        translations: {
            pl_PL: {
                label: 'Codzienne',
                url: 'meskie/polbuty/codzienne',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Buty codzienne męskie - wybierz swój wymarzony model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Półbuty codzienne męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 8015,
        levelOriginal: 3,
    },
    {
        products_count: 5693,
        is_leaf: true,
        id: '2517',
        translations: {
            pl_PL: {
                label: 'Sneakersy',
                url: 'meskie/polbuty/sneakersy',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Sneakersy męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 5693,
        levelOriginal: 3,
    },
    {
        products_count: 800,
        is_leaf: true,
        id: '2569',
        translations: {
            pl_PL: {
                label: 'Trampki',
                url: 'meskie/polbuty/trampki',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Tenisówki i trampki męskie - uzupełnij swoją miejską stylizację {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Trampki męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 800,
        levelOriginal: 3,
    },
    {
        products_count: 475,
        is_leaf: true,
        id: '2603',
        translations: {
            pl_PL: {
                label: 'Wizytowe',
                url: 'meskie/polbuty/wizytowe',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Półbuty męskie - zobacz wizytowe buty znanych marek {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Półbuty wizytowe męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 475,
        levelOriginal: 3,
    },
    {
        products_count: 217,
        is_leaf: true,
        id: '2611',
        translations: {
            pl_PL: {
                label: 'Mokasyny',
                url: 'meskie/polbuty/mokasyny',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Mokasyny męskie - zobacz najlepsze markowe propozycje {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Mokasyny męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 217,
        levelOriginal: 3,
    },
    {
        products_count: 481,
        is_leaf: true,
        id: '2630',
        translations: {
            pl_PL: {
                label: 'Buty Trekkingowe i Trapery',
                url: 'meskie/polbuty/buty-trekkingowe-i-trapery',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Trapery i buty trekkingowe męskie - wybierz profesjonalny model {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Buty trekkingowe męskie i trapery {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 481,
        levelOriginal: 3,
    },
    {
        products_count: 7,
        is_leaf: true,
        id: '2790',
        translations: {
            pl_PL: {
                label: 'Glany',
                url: 'meskie/polbuty/glany',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Glany męskie niskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Glany męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 7,
        levelOriginal: 3,
    },
    {
        products_count: 110,
        is_leaf: true,
        id: '3114',
        translations: {
            pl_PL: {
                label: 'Espadryle',
                url: 'meskie/polbuty/espadryle',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Espadryle męskie - postaw na wygodne letnie buty {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Espadryle męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 110,
        levelOriginal: 3,
    },
];

export const mockCategoryNavigationTreeSearch = {
    tree: {
        items: [
            {
                products_count: 431,
                children: [],
                is_leaf: false,
                id: '2445',
                translations: {
                    pl_PL: {
                        label: 'Męskie',
                        url: 'meskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Modne buty męskie – wybierz stylowo {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 517,
                children: [],
                is_leaf: false,
                id: '2439',
                translations: {
                    pl_PL: {
                        label: 'Damskie',
                        url: 'damskie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty damskie, modne obuwie dla kobiet {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} , modne obuwie dla kobiet w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 413,
                children: [],
                is_leaf: false,
                id: '2458',
                translations: {
                    pl_PL: {
                        label: 'Dziecięce',
                        url: 'dzieciece',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty dziecięce – wybierz model dla swojego malucha {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 191,
                children: [],
                is_leaf: false,
                id: '2541',
                translations: {
                    pl_PL: {
                        label: 'Akcesoria',
                        url: 'akcesoria',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 1242,
                children: [],
                is_leaf: false,
                id: '3155',
                translations: {
                    pl_PL: {
                        label: 'Sport',
                        url: 'sport',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty sportowe: lifestyle, fitness, bieganie - damskie, męskie, dziecięce {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
            {
                products_count: 183,
                children: [],
                is_leaf: true,
                id: '3350',
                translations: {
                    pl_PL: {
                        label: 'Unisex',
                        url: 'unisex',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Zobacz ofertę butów i akcesoriów unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
        ],
    },
    bread_crumbs: {
        items: [
            {
                id: '2',
                translations: {
                    pl_PL: {
                        label: 'eobuwie',
                        url: 'eobuwie',
                        locale: 'pl_PL',
                        description: null,
                        meta_title:
                            'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                        meta_description:
                            'Zobacz ofertę sklepu internetowego eobuwie.pl {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                        header:
                            'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                        canonical_url: null,
                        meta_robots: 'index, follow',
                    },
                },
            },
        ],
    },
    categories: [
        {
            id: '2',
            translations: {
                pl_PL: {
                    label: 'eobuwie',
                    url: 'eobuwie',
                    locale: 'pl_PL',
                    description: null,
                    meta_title:
                        'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                    meta_description:
                        'Zobacz ofertę sklepu internetowego eobuwie.pl {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                    header:
                        'Oferta eobuwie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"ksztalt_oprawek","prefix":" - kształt oprawek "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_torebki","prefix":" - "}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"rodzaj_torebki","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"wykonanie_paska","prefix":" - "}{"attribute":"rodzaj_mechanizmu","prefix":" - mechanizm "}{"attribute":"szklo","prefix":" - szkło "}{"attribute":"ksztalt_koperty","prefix":" - kształt koperty "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                    canonical_url: null,
                    meta_robots: 'index, follow',
                },
            },
        },
    ],
    hreflangs: [
        { locale: 'de_AT', slug: 'eschuhe' },
        { locale: 'lt_LT', slug: 'eavalyne' },
        { locale: 'pl_PL', slug: 'eobuwie' },
        { locale: 'ru_UA', slug: 'eobuv' },
        { locale: 'sl_SI', slug: 'eobutev' },
        { locale: 'uk_UA', slug: 'evzuttya' },
    ],
};

export const expectedResultSearch = [
    {
        products_count: 431,
        is_leaf: false,
        id: '2445',
        translations: {
            pl_PL: {
                label: 'Męskie',
                url: 'meskie',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Modne buty męskie – wybierz stylowo {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Buty męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"sezon","prefix":" - sezon "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 431,
    },
    {
        products_count: 517,
        is_leaf: false,
        id: '2439',
        translations: {
            pl_PL: {
                label: 'Damskie',
                url: 'damskie',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Buty damskie, modne obuwie dla kobiet {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} , modne obuwie dla kobiet w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Buty damskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 517,
    },
    {
        products_count: 413,
        is_leaf: false,
        id: '2458',
        translations: {
            pl_PL: {
                label: 'Dziecięce',
                url: 'dzieciece',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Buty dziecięce – wybierz model dla swojego malucha {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Buty dla dzieci {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"przeznaczenie"}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 413,
    },
    {
        products_count: 191,
        is_leaf: false,
        id: '2541',
        translations: {
            pl_PL: {
                label: 'Akcesoria',
                url: 'akcesoria',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Akcesoria i dodatki damskie, męskie {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 191,
    },
    {
        products_count: 1242,
        is_leaf: false,
        id: '3155',
        translations: {
            pl_PL: {
                label: 'Sport',
                url: 'sport',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Buty sportowe: lifestyle, fitness, bieganie - damskie, męskie, dziecięce {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Buty sportowe {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"styl","prefix":" - "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 1242,
    },
    {
        products_count: 183,
        is_leaf: true,
        id: '3350',
        translations: {
            pl_PL: {
                label: 'Unisex',
                url: 'unisex',
                locale: 'pl_PL',
                description: null,
                meta_title:
                    'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} | eobuwie.pl',
                meta_description:
                    'Zobacz ofertę butów i akcesoriów unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}{"attribute":"page_number","prefix":" - Strona "} w sklepie internetowym eobuwie.pl ⭐ Ponad 700 marek ✔ 100 000 modeli butów, torebek i akcesoriów ✔ Darmowa dostawa i zwrot',
                header:
                    'Buty i akcesoria Unisex {"attribute":"product_badge","prefix":" - "}{"attribute":"manufacturer_with_collection"}{"attribute":"grouped_size","prefix":"- rozmiar: "}{"attribute":"kolor","prefix":" - kolor "}{"attribute":"fason","prefix":" - "}{"attribute":"wykonanie","prefix":" - "}{"attribute":"rodzaj_zapiecia","prefix":" - "}{"attribute":"width_of_shoe_model","prefix":" - "}{"attribute":"cholewka","prefix":" - "}{"attribute":"typ_noska","prefix":" - typ noska "}{"attribute":"rodzaj_obcasa","prefix":" - "}{"attribute":"nawierzchnia","prefix":" - nawierzchnia "}{"attribute":"sport_dodatkowa","prefix":" - "}{"attribute":"przeznaczenie"}{"attribute":"rozmiar_plecaka","prefix":" - "}{"attribute":"wysokosc_obcasa","prefix":" - wysokość obcasa "}{"attribute":"styl","prefix":" - "}{"attribute":"wzor","prefix":"- "}{"attribute":"sezon","prefix":" - sezon "}{"attribute":"przekatna_ekranu","prefix":" - przekątna ekranu "}{"attribute":"technologie_entity","prefix":" - technologia "}{"attribute":"kolekcja","prefix":" - kolekcja "}',
                canonical_url: null,
                meta_robots: 'index, follow',
            },
        },
        level: 1,
        productsCount: 183,
    },
];
