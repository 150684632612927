export const USER_BAD_INPUT = 'BAD_USER_INPUT';
export const NO_SUCH_ENTITY = 'NO_SUCH_ENTITY';
export const FORBIDDEN = 'FORBIDDEN';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const PRODUCT_IS_OUT_OF_STOCK = 'PRODUCT_IS_OUT_OF_STOCK';
export const PRODUCT_IS_LOW_STOCK = 'PRODUCT_IS_LOW_STOCK';
export const ALREADY_EXISTS = 'ALREADY_EXISTS';
export const NO_SUCH_PRODUCT = 'NO_SUCH_PRODUCT';
export const EXTERNAL_SERVICE_UNAVAILABLE = 'EXTERNAL_SERVICE_UNAVAILABLE';
export const UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY';
