import {
    FACEBOOK_PARAMS_INIT,
    FACEBOOK_PARAMS_LOGIN,
    FACEBOOK_API_SCRIPT,
    FACEBOOK_LANGUAGE_DEFAULT,
    FACEBOOK_LOGIN_STATUS_CONNECTED,
    EMAIL_SCOPE,
    STATUS_GRANTED,
    FACEBOOK_PARAMS_RE_REQUEST,
} from '@configs/facebook';
import loadScript from '@assets/loadScript';

export default store => ({
    languageCode: null,
    isInitDone: false,

    getScriptUrl() {
        return FACEBOOK_API_SCRIPT.replace('%languageCode%', this.languageCode);
    },

    async getFB() {
        if (window.FB) {
            return window.FB;
        }

        await loadScript(this.getScriptUrl());

        return window.FB;
    },

    async init() {
        if (this.isInitDone) {
            return this.getFB();
        }

        this.languageCode =
            store.getters['config/locale'] || FACEBOOK_LANGUAGE_DEFAULT;

        return new Promise(resolve => {
            window.fbAsyncInit = async () => {
                const FB = await this.getFB();

                FB.init(FACEBOOK_PARAMS_INIT);

                this.isInitDone = true;

                return resolve(FB);
            };

            this.getFB();
        });
    },

    async getPermissions() {
        const FB = await this.init();

        return new Promise(resolve => {
            FB.api('/me/permissions', response => {
                return resolve(response);
            });
        });
    },

    async getAuthorizeState() {
        const FB = await this.init();

        const loginStatus = await new Promise(resolve => {
            FB.getLoginStatus(data => {
                return resolve(data);
            }, true);
        });

        if (!loginStatus.authResponse) {
            return loginStatus;
        }

        const permissions = await this.getPermissions();

        const isEmailPermissionGranted = this.checkIsEmailPermissionGranted(
            permissions
        );
        const accessToken = this.getTokenFromResponse(loginStatus);
        const { status } = loginStatus;

        return {
            accessToken,
            isEmailPermissionGranted,
            status,
        };
    },

    getTokenFromResponse(response) {
        try {
            const {
                authResponse: { accessToken },
            } = response;

            return accessToken;
        } catch {
            return null;
        }
    },

    checkIsEmailPermissionGranted(permissions) {
        try {
            if (!permissions) {
                return false;
            }

            const emailPermissionGranted = permissions.data?.find(
                ({ permission, status }) =>
                    permission === EMAIL_SCOPE && status === STATUS_GRANTED
            );

            return !!emailPermissionGranted;
        } catch {
            return false;
        }
    },

    async authorize(isDeclinedPermission = false) {
        const FB = await this.init();

        const options = isDeclinedPermission
            ? FACEBOOK_PARAMS_RE_REQUEST
            : FACEBOOK_PARAMS_LOGIN;

        const loginResponse = await new Promise(resolve => {
            FB.login(response => {
                if (response?.status === FACEBOOK_LOGIN_STATUS_CONNECTED) {
                    return resolve(response);
                }

                return resolve(null);
            }, options);
        });

        return {
            accessToken: this.getTokenFromResponse(loginResponse),
            isEmailPermissionGranted:
                loginResponse?.authResponse?.grantedScopes?.includes(
                    EMAIL_SCOPE
                ) || false,
            status: loginResponse?.status,
        };
    },

    async login() {
        const authorizeState = await this.getAuthorizeState();
        const {
            status = null,
            isEmailPermissionGranted = false,
            accessToken = null,
        } = authorizeState || {};

        if (
            status === FACEBOOK_LOGIN_STATUS_CONNECTED &&
            isEmailPermissionGranted
        ) {
            return {
                accessToken,
                isEmailPermissionGranted,
            };
        }

        const isDeclinedPermission =
            status === FACEBOOK_LOGIN_STATUS_CONNECTED &&
            !isEmailPermissionGranted;

        return this.authorize(isDeclinedPermission);
    },
});
