import { REDIRECT_HTTP_CODE } from '@configs/http-codes';
import { stringifyCategoryUrl } from '@assets/path';

export const redirectCheckCategoryUrl = ({
    $createCategoryPath,
    redirect,
    requestQuery,
    urlFilters,
    url,
    locale,
    category,
}) => {
    const categoryUrl = category?.translations?.[locale]?.url || '';

    if (categoryUrl && url !== categoryUrl) {
        const categoryUrlWithFilters = stringifyCategoryUrl({
            categoryUrl,
            filters: urlFilters,
        });

        return redirect(
            REDIRECT_HTTP_CODE,
            $createCategoryPath(categoryUrlWithFilters, requestQuery)
        );
    }
};
