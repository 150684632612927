import { CHECKOUT_PAGE_NAME, CHECKOUT_CART_PAGE_NAME } from '@router/names';

export const MARKETING_BAR_DEFAULT_BACKGROUND = '#dff1df';
export const MARKETING_BAR_DEFAULT_COLOR = '#222222';

export const MARKETING_BAR_CART_WHITELIST = [
    CHECKOUT_PAGE_NAME,
    CHECKOUT_CART_PAGE_NAME,
];

export const CMS_MARKETING_BAR_GLOBAL_ID = 'psb_marketing_bar_config';

export const MARKETING_BAR_ALLOWED_ICONS = [
    'Sun',
    'Tag',
    'LockKey',
    'LockFilled',
    'Newsletter',
];
