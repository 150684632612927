import { RECENTLY_VIEWED_PRODUCTS_LIMIT } from '@configs/recently-viewed-products';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

import { types } from './mutations';

const getProductsFromAPI = async ({ app, currency, locale, skuArray = [] }) =>
    (
        await app.$services.product.getByIndexes({
            indexes: skuArray,
            limit: RECENTLY_VIEWED_PRODUCTS_LIMIT,
            page: 1,
            locale,
            selectLocales: [DEFAULT_LOCALE],
            currency,
        })
    ).items;

const actions = {
    addProduct({ dispatch, state }, product) {
        const products = state.products.filter(productItem => {
            return productItem.sku !== product.sku;
        });

        products.unshift(product);

        dispatch(
            'setProducts',
            products.slice(0, RECENTLY_VIEWED_PRODUCTS_LIMIT)
        );

        this.app.$recentlyViewedProducts.add(product.sku);
    },

    setProducts({ commit }, products) {
        commit(types.SET_PRODUCTS, products);
    },

    clearProducts({ commit }) {
        commit(types.SET_PRODUCTS, []);
    },

    async initStore({ rootGetters, commit, dispatch, state }) {
        const { app } = this;

        const skuArray = app.$recentlyViewedProducts.get();
        const skuArrayStore = state.products.map(product => product.sku);
        const locale = rootGetters['config/locale'];
        const currency = rootGetters['config/currency'];

        const missingSku = skuArray.filter(sku => !skuArrayStore.includes(sku));

        if (missingSku.length > 0) {
            const products = [
                ...state.products,
                ...(await getProductsFromAPI({
                    app,
                    currency,
                    locale,
                    skuArray: missingSku,
                })),
            ];

            products.sort((product1, product2) => {
                return (
                    skuArray.indexOf(product1.sku) -
                    skuArray.indexOf(product2.sku)
                );
            });

            app.$recentlyViewedProducts.set(
                products.map(product => product.sku)
            );

            dispatch('setProducts', products);
        }

        commit(types.SET_LOADED, true);
    },
};

export default actions;
