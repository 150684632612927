var FilterTypes = /* @__PURE__ */ ((FilterTypes2) => {
  FilterTypes2["MULTISELECT"] = "multiselect";
  FilterTypes2["NESTED_MULTISELECT"] = "nestedmultiselect";
  FilterTypes2["RANGE"] = "range";
  FilterTypes2["TOGGLE"] = "toggle";
  FilterTypes2["GROUP"] = "group";
  FilterTypes2["ESIZEME"] = "esizeme";
  return FilterTypes2;
})(FilterTypes || {});

export { FilterTypes };
