<template>
    <div class="social-icons">
        <BaseHeading>{{ $t('Follow us') }}</BaseHeading>

        <div v-if="socialMediaLinksMap.length" class="icons">
            <ButtonSocialMedia
                v-for="({ link, icon }, linkId) in socialMediaLinksMap"
                :key="linkId"
                :href="link"
                :variant="BUTTON_VARIANT"
                target="_blank"
            >
                <template #icon>
                    <Icon :icon="icon" />
                </template>
            </ButtonSocialMedia>
        </div>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import {
    Instagram,
    Facebook,
    Youtube,
} from '@eobuwie-ui/icons/v2/social-media';

import {
    ButtonSocialMedia,
    BUTTON_SOCIAL_MEDIA_VARIANTS,
} from '@eobuwie-ui/components/ButtonSocialMedia/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

export default {
    name: 'SocialIcons',

    components: {
        BaseHeading,
        ButtonSocialMedia,
        Icon,
    },

    props: {
        facebookUrl: {
            type: String,
            default: '',
        },

        instagramUrl: {
            type: String,
            default: '',
        },

        youtubeUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        socialMediaLinksMap() {
            return [
                {
                    link: this.facebookUrl,
                    icon: Facebook,
                },
                {
                    link: this.instagramUrl,
                    icon: Instagram,
                },
                {
                    link: this.youtubeUrl,
                    icon: Youtube,
                },
            ].filter(({ link }) => link);
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT =
            BUTTON_SOCIAL_MEDIA_VARIANTS.BASIC_ALTERNATIVE_REVERSED;
    },
};
</script>

<style lang="scss" scoped>
.social-icons {
    @apply text-center;

    .icons {
        @apply flex justify-center mt-3 gap-x-2;
    }

    @screen md {
        @apply text-left;

        .icons {
            @apply justify-start;
        }
    }
}
</style>
