import { HTTP_CLIENT_ENDPOINT_NAME_AVATAR } from '@configs/http-client';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_AVATAR };

export default httpClient => ({
    connectClient: avatarId =>
        httpClient.post('/guest/connect-client', {
            ...ENDPOINT_PARAMETER,
            payload: {
                anonymousUserId: avatarId,
            },
        }),

    getInviteFriendToken: () =>
        httpClient.get('/invite-friend/invitation', {
            ...ENDPOINT_PARAMETER,
        }),

    createInviteFriendToken: () =>
        httpClient.post('/invite-friend/invitation', {
            ...ENDPOINT_PARAMETER,
        }),

    checkInviteFriendToken: ({ inviteToken, storeContext, websiteContext }) =>
        httpClient.get(
            // eslint-disable-next-line max-len
            `/invite-friend/invitation/check-exists/${inviteToken}/${storeContext}/${websiteContext}`,
            {
                ...ENDPOINT_PARAMETER,
                context: {
                    useAuthorization: false,
                },
            }
        ),

    getVouchersHistory: () =>
        httpClient.get('/invite-friend/invitation/vouchers-history', {
            ...ENDPOINT_PARAMETER,
        }),

    sendMagicLink: payload =>
        httpClient.post('/magic-link/send', {
            ...ENDPOINT_PARAMETER,
            payload,
            context: {
                useAuthorization: false,
            },
        }),

    createAccountVerification: payload =>
        httpClient.post('/verification/verification', {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
            payload,
        }),

    confirmAccountVerification: token =>
        httpClient.get(`/verification/verification/confirm/token/${token}`, {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
        }),

    resendAccountVerification: payload =>
        httpClient.patch('/verification/verification/resend', {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
            payload,
        }),

    checkAccountVerificationStatus: payload =>
        httpClient.post('verification/verification/status', {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
            payload,
        }),

    checkEmailExist: payload =>
        httpClient.post('/client/client/check-email-exists', {
            ...ENDPOINT_PARAMETER,
            payload,
            context: {
                useAuthorization: false,
            },
        }),

    assignClientToOrder: payload =>
        httpClient.post('/order/assign-client', {
            ...ENDPOINT_PARAMETER,
            payload,
        }),
});
