import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    computed: {
        ...mapConfigGetters(['baseDomain']),

        titleBaseDomain() {
            return this.baseDomain;
        },
    },
};
