export default class ConsentsProvider {
    constructor({ cms_description, cms_title, code }) {
        this.cmsDescription = cms_description;
        this.cmsTitle = cms_title;
        this.code = code;
    }

    build() {
        return {
            cmsDescription: this.cmsDescription,
            cmsTitle: this.cmsTitle,
            code: this.code,
        };
    }
}
