import { getMissingError } from '../assets/errors.js';
import Filter from './Filter.js';
import { copyObjectDeep } from '../assets/object.js';

class FilterMultiselect extends Filter {
  options;
  constructor(filterAttributes, params, addFilter, removeFilter) {
    super(filterAttributes, addFilter, removeFilter);
    const { options } = filterAttributes;
    if (!options) {
      throw getMissingError("options");
    }
    this.options = options;
    if (Object.keys(params).length) {
      this.selectedFilterParams = copyObjectDeep(params);
      this.initialParamsKeys = Object.keys(params);
    }
  }
  toggleOption(option) {
    if (this.selectedFilterParams[option.code]) {
      this.removeSelectedOption(option);
      return;
    }
    this.addSelectedOption(option);
  }
  updateFilter(filterAttributes) {
    this.options = filterAttributes.options;
    const optionsCodes = this.options.map(({ code }) => code);
    this.selectedFilterParams = Object.values(this.selectedFilterParams).reduce(
      (acc, value) => {
        if (optionsCodes.includes(value.code)) {
          acc[value.code] = value;
        }
        return acc;
      },
      {}
    );
  }
  addSelectedOption(option) {
    const { code, label, url } = option;
    this.selectedFilterParams = {
      ...this.selectedFilterParams,
      [code]: {
        label,
        url,
        code
      }
    };
  }
  removeSelectedOption(option) {
    const { [option.code]: _unused, ...rest } = this.selectedFilterParams;
    this.selectedFilterParams = rest;
  }
}

export { FilterMultiselect as default };
