export default {
    isSortChanged(state) {
        const { code, order } = state.selectedSortOption;
        const { orderCode, orderDir } = state;

        return code !== orderCode || order !== orderDir;
    },

    isSortSelected(state) {
        const { code, order } = state.selectedSortOption;

        return !!(code && order);
    },
};
