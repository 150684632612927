import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';

import { sortCategoriesTreeAlphabetically } from '@search/utils';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import CategoryNotFoundException from '@exceptions/services/category/CategoryNotFoundException';

import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareSearchData = async ({
    searchRequestParams,
    $asyncServices,
    $errorHandler,
    fallbackFrom = '',
}) => {
    const { data, error, status, originalResponse } = await $asyncServices.use(
        CATALOG_SERVICE_NAME,
        CATALOG_SERVICE_METHODS.GET_SEARCH_DATA,
        searchRequestParams
    );

    if (!error && data) {
        sortCategoriesTreeAlphabetically(
            data.navigation,
            searchRequestParams.locale
        );

        const {
            total = null,
            totalFormatted = null,
            search_query: searchQuery = {},
            products: searchProducts = [],
            filters,
            navigation = {},
            sort_options: sortOptions = [],
            navigation: { bread_crumbs: { items: breadcrumbs = [] } = {} } = {},
        } = data;

        return {
            total,
            totalFormatted,
            searchQuery,
            searchProducts,
            filters,
            navigation,
            sortOptions,
            breadcrumbs,
            responseEngine: fallbackFrom,
        };
    }

    const errorToThrow = new Error(error?.message || 'UNKNOWN_ERROR', {
        cause: error,
    });

    $errorHandler.captureError(
        errorToThrow,
        {
            [ERROR_ACTION_TAG_NAME]: 'prepareCatalogSearchData',
        },
        {
            searchParams: searchRequestParams,
            data,
            error,
            status,
            originalResponse,
        }
    );

    if (status === NOT_FOUND_HTTP_CODE) {
        throw new CategoryNotFoundException(
            JSON.stringify(searchRequestParams)
        );
    }

    throw errorToThrow;
};
