import { LOGIN_PAGE } from '@router/paths';

export const checkCustomerAuthorization = async (
    { app, redirect, store },
    { shouldRedirectToLogin = true } = {}
) => {
    const customerEmail = await app.$services.customer.getCustomerEmail();

    if (customerEmail) {
        return;
    }

    if (store.getters['customer/isLoggedIn']) {
        await store.dispatch('customer/handleExpiredSession');
    }

    if (shouldRedirectToLogin && !app.$mobileApp.isWebView) {
        return redirect(`/${LOGIN_PAGE}`);
    }
};

export const isExpired = timeToExpire => {
    if (!timeToExpire) {
        return true;
    }

    return new Date(timeToExpire) < new Date();
};

export const shouldRefreshToken = (
    accessToken,
    refreshToken,
    accessTokenExpirationISO,
    refreshTokenExpirationISO
) =>
    ((!!accessToken && isExpired(accessTokenExpirationISO)) || !accessToken) &&
    !!refreshToken &&
    !isExpired(refreshTokenExpirationISO);
