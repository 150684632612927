import { getMissingError, getUnknownFilterTypeError } from '../assets/errors.js';
import { FilterTypes } from '../types/filter-types.js';
import FilterMultiselect from '../models/FilterMultiselect.js';
import FilterRange from '../models/FilterRange.js';
import FilterNestedMultiselect from '../models/FilterNestedMultiselect.js';
import FilterToggle from '../models/FilterToggle.js';
import FilterGroup from '../models/FilterGroup.js';
import Filter from '../models/Filter.js';
import filterModule from '../store/filters/index.js';
import { FILTERS_MODULE_NAME } from '../store/filters/meta.js';
import { FilterSeparators } from '../types/filter-separators.js';
import { copyObjectDeep } from '../assets/object.js';
import FilterEsizeme from '../models/FilterEsizeme.js';

class FilterManager {
  storeNamespacePath;
  storeNamespace;
  esizemeEnabled;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $store;
  namespaces;
  constructor({ store, namespace = "", esizemeEnabled = false }) {
    this.$store = store;
    if (namespace) {
      this.storeNamespace = `${namespace}/${FILTERS_MODULE_NAME}`;
      this.storeNamespacePath = [...namespace.split("/"), FILTERS_MODULE_NAME];
    } else {
      this.storeNamespace = FILTERS_MODULE_NAME;
      this.storeNamespacePath = FILTERS_MODULE_NAME;
    }
    this.namespaces = {
      SELECTED_FILTERS_NAME: this.getNameWithNamespace("selectedFilters"),
      SELECTED_EXTRA_FILTERS_NAME: this.getNameWithNamespace("selectedExtraFilters"),
      SELECTED_CODES_NAME: this.getNameWithNamespace("selectedCodes"),
      SELECTED_TAGS_NAME: this.getNameWithNamespace("selectedTags"),
      SELECTED_EXTRA_TAGS_NAME: this.getNameWithNamespace("selectedExtraTags"),
      SELECTED_PARAMS_NAME: this.getNameWithNamespace("selectedParams"),
      SELECTED_EXTRA_PARAMS_NAME: this.getNameWithNamespace("selectedExtraParams"),
      SELECTED_LABELS_NAME: this.getNameWithNamespace("selectedLabels"),
      SELECTED_EXTRA_LABELS_NAME: this.getNameWithNamespace("selectedExtraLabels"),
      SELECTED_ESIZEME_SCAN_NAME: this.getNameWithNamespace("selectedEsizemeScan"),
      ESIZEME_RANGE_BOUNDARIES_NAME: this.getNameWithNamespace("esizemeRangeBoundaries"),
      LOCALIZED_FILTERS_NAME: this.getNameWithNamespace("localizedFilters"),
      SELECTED_FILTERS_COUNT_NAME: this.getNameWithNamespace("selectedFiltersCount"),
      SELECTED_EXTRA_FILTERS_COUNT_NAME: this.getNameWithNamespace(
        "selectedExtraFiltersCount"
      ),
      LEFTOVER_FILTERS_NAME: this.getNameWithNamespace("leftoverFilters"),
      ARE_FILTERS_CHANGED_NAME: this.getNameWithNamespace("areFiltersChanged"),
      ARE_EXTRA_FILTERS_CHANGED_NAME: this.getNameWithNamespace("areExtraFiltersChanged"),
      ADD_SELECTED_FILTER_ACTION_NAME: this.getNameWithNamespace("addSelectedFilter"),
      REMOVE_SELECTED_FILTER_ACTION_NAME: this.getNameWithNamespace("removeSelectedFilter"),
      IS_ESIZEME_FILTER_CHANGED_NAME: this.getNameWithNamespace("isEsizemeFilterChanged"),
      FILTERS: this.getNameWithNamespace("filters"),
      AVAILABLE_FILTERS: this.getNameWithNamespace("availableFilters"),
      CLEAR_ALL_FILTERS: this.getNameWithNamespace("clearAllFilters"),
      CLEAR_EXTRA_FILTERS: this.getNameWithNamespace("clearExtraFilters"),
      SET_INITIAL_STATE: this.getNameWithNamespace("setInitialState"),
      SET_ESIZEME_STATE: this.getNameWithNamespace("setEsizemeState"),
      SET_INITIAL_ESIZEME_STATE: this.getNameWithNamespace("setInitialEsizemeState"),
      SET_SELECTED_PARAMS: this.getNameWithNamespace("setSelectedParams"),
      SEARCH_QUERY_FILTERS: this.getNameWithNamespace("searchQueryFilters"),
      AVAILABLE_CODES: this.getNameWithNamespace("availableCodes"),
      REMOVE_TAG: this.getNameWithNamespace("removeTag"),
      REMOVE_EXTRA_TAG: this.getNameWithNamespace("removeExtraTag"),
      RESTORE_FILTERS: this.getNameWithNamespace("RESTORE_FILTERS"),
      SET_AVAILABLE_FILTERS: this.getNameWithNamespace("SET_AVAILABLE_FILTERS"),
      SET_URL_FILTERS: this.getNameWithNamespace("SET_URL_FILTERS"),
      SET_SEARCH_QUERY: this.getNameWithNamespace("SET_SEARCH_QUERY"),
      SET_ESIZEME_RANGE_BOUNDARIES: this.getNameWithNamespace("SET_ESIZEME_RANGE_BOUNDARIES"),
      SET_SELECTED_EXTRA_PARAMS: this.getNameWithNamespace("setSelectedExtraParams"),
      SET_INITIAL_EXTRA_STATE: this.getNameWithNamespace("setInitialExtraState")
    };
    this.esizemeEnabled = esizemeEnabled;
  }
  get availableCodes() {
    return this.$store.getters[this.namespaces.AVAILABLE_CODES];
  }
  get selectedFilters() {
    return this.$store.getters[this.namespaces.SELECTED_FILTERS_NAME];
  }
  get selectedExtraFilters() {
    return this.$store.getters[this.namespaces.SELECTED_EXTRA_FILTERS_NAME];
  }
  get selectedFiltersCount() {
    return this.$store.getters[this.namespaces.SELECTED_FILTERS_COUNT_NAME];
  }
  get selectedExtraFiltersCount() {
    return this.$store.getters[this.namespaces.SELECTED_EXTRA_FILTERS_COUNT_NAME];
  }
  get selectedCodes() {
    return this.$store.getters[this.namespaces.SELECTED_CODES_NAME];
  }
  get selectedParams() {
    return this.$store.getters[this.namespaces.SELECTED_PARAMS_NAME];
  }
  get selectedExtraParams() {
    return this.$store.getters[this.namespaces.SELECTED_EXTRA_PARAMS_NAME];
  }
  get selectedLabels() {
    return this.$store.getters[this.namespaces.SELECTED_LABELS_NAME];
  }
  get selectedExtraLabels() {
    return this.$store.getters[this.namespaces.SELECTED_EXTRA_LABELS_NAME];
  }
  get selectedTags() {
    return this.$store.getters[this.namespaces.SELECTED_TAGS_NAME];
  }
  get selectedExtraTags() {
    return this.$store.getters[this.namespaces.SELECTED_EXTRA_TAGS_NAME];
  }
  get areFiltersChanged() {
    return this.$store.getters[this.namespaces.ARE_FILTERS_CHANGED_NAME];
  }
  get areExtraFiltersChanged() {
    return this.$store.getters[this.namespaces.ARE_EXTRA_FILTERS_CHANGED_NAME];
  }
  get filters() {
    return this.$store.getters[this.namespaces.FILTERS];
  }
  get availableFilters() {
    return this.$store.getters[this.namespaces.AVAILABLE_FILTERS];
  }
  get searchQueryFilters() {
    return this.$store.getters[this.namespaces.SEARCH_QUERY_FILTERS];
  }
  get localizedFilters() {
    return this.$store.getters[this.namespaces.LOCALIZED_FILTERS_NAME];
  }
  get leftoverFilters() {
    return this.$store.getters[this.namespaces.LEFTOVER_FILTERS_NAME];
  }
  get selectedEsizemeScan() {
    return this.$store.getters[this.namespaces.SELECTED_ESIZEME_SCAN_NAME];
  }
  get esizemeRangeBoundaries() {
    return this.$store.getters[this.namespaces.ESIZEME_RANGE_BOUNDARIES_NAME];
  }
  get isEsizemeFilterChanged() {
    return this.$store.getters[this.namespaces.IS_ESIZEME_FILTER_CHANGED_NAME];
  }
  addSelectedFilter(filter) {
    this.$store.dispatch(this.namespaces.ADD_SELECTED_FILTER_ACTION_NAME, filter);
  }
  removeSelectedFilter(filter) {
    this.$store.dispatch(this.namespaces.REMOVE_SELECTED_FILTER_ACTION_NAME, filter);
  }
  setEsizemeFilter(filter) {
    this.$store.dispatch(this.namespaces.SET_ESIZEME_STATE, filter.selectedScan);
  }
  clearAllFilters() {
    this.$store.dispatch(this.namespaces.CLEAR_ALL_FILTERS);
  }
  clearExtraFilters() {
    this.$store.dispatch(this.namespaces.CLEAR_EXTRA_FILTERS);
  }
  restoreAllFilters() {
    this.$store.commit(this.namespaces.RESTORE_FILTERS);
  }
  setUrlFilters(urlFilters) {
    this.$store.commit(this.namespaces.SET_URL_FILTERS, urlFilters);
  }
  initManager({
    filters,
    searchQueryFilters,
    searchQueryLocalized,
    searchQueryLabels = {},
    initial = false,
    rangeBoundaries = []
  }) {
    if (!this.$store.hasModule(this.storeNamespacePath)) {
      this.registerFilterStoreModule();
    }
    this.$store.commit(this.namespaces.SET_SEARCH_QUERY, {
      filters: searchQueryFilters,
      localized: searchQueryLocalized,
      labels: searchQueryLabels
    });
    this.initFilters(filters, searchQueryFilters, searchQueryLabels, initial);
    if (this.esizemeEnabled) {
      this.initEsizeme(rangeBoundaries, initial);
    }
  }
  initFilters(filters, searchQueryFilters, searchQueryLabels, initial) {
    if (!filters) {
      throw getMissingError("filters");
    }
    if (!searchQueryFilters) {
      throw getMissingError("searchQueryFilters");
    }
    const selectedParams = {};
    const unwrappedFilters = Object.values(filters).reduce(
      (acc, filter) => {
        if (filter.type === FilterTypes.GROUP) {
          filter.filters.forEach((nestedFilter) => {
            acc[nestedFilter.code] = nestedFilter;
          });
          return acc;
        }
        acc[filter.code] = filter;
        return acc;
      },
      {}
    );
    this.$store.commit(this.namespaces.SET_AVAILABLE_FILTERS, unwrappedFilters);
    Object.values(unwrappedFilters).forEach((filter) => {
      if (!filter.selected) {
        return;
      }
      selectedParams[filter.code] = {
        url: filter.url,
        code: filter.code,
        label: filter.label,
        selected: {}
      };
      switch (filter.type) {
        case FilterTypes.MULTISELECT: {
          const selectedOption = filter.options.filter(
            ({ selected }) => selected
          );
          selectedOption.forEach(({ code, url, label }) => {
            selectedParams[filter.code].selected[code] = { code, url, label };
          });
          break;
        }
        case FilterTypes.RANGE: {
          const selectedRange = searchQueryFilters[filter.code]?.range.split(
            FilterSeparators.FILTER_VALUE_SEPARATOR
          );
          if (!selectedRange) {
            delete selectedParams[filter.code];
            break;
          }
          const [selectedMin, selectedMax] = selectedRange;
          const rangeToApply = FilterRange.getAppliedRangeWithBoundaries({
            min: filter.min,
            max: filter.max,
            selectedMin: parseInt(selectedMin, 10),
            selectedMax: parseInt(selectedMax, 10)
          });
          if (!rangeToApply) {
            delete selectedParams[filter.code];
            break;
          }
          const [min, max] = rangeToApply;
          if (min !== filter.min) {
            selectedParams[filter.code].selected.min = {
              code: "min",
              url: min.toString(),
              label: min.toString()
            };
          }
          if (max !== filter.max) {
            selectedParams[filter.code].selected.max = {
              code: "max",
              url: max.toString(),
              label: max.toString()
            };
          }
          break;
        }
        case FilterTypes.NESTED_MULTISELECT: {
          const mutableOptions = copyObjectDeep(
            filter.options
          );
          mutableOptions.forEach(FilterNestedMultiselect.recurrentApplyParent());
          const getInitialState = (option) => {
            const { selected, parentOption, rootOption } = option;
            if (selected && parentOption && !selectedParams[filter.code].selected[parentOption.code]) {
              selectedParams[filter.code].selected[option.code] = {
                url: option.url,
                code: option.code,
                label: `${rootOption?.label}:${option.label}`
              };
            }
            option.options.forEach(getInitialState);
          };
          mutableOptions.forEach(getInitialState);
          break;
        }
        case FilterTypes.TOGGLE: {
          const { option } = filter;
          selectedParams[filter.code].selected[option.code] = {
            url: option.url,
            code: option.code,
            label: option.label
          };
          break;
        }
        default: {
          throw getUnknownFilterTypeError(filter);
        }
      }
    });
    if (initial) {
      this.$store.dispatch(this.namespaces.SET_INITIAL_STATE, selectedParams);
      this.setSelectedExtraParams(unwrappedFilters, searchQueryLabels);
    }
    this.$store.dispatch(this.namespaces.SET_SELECTED_PARAMS, { filters, selectedParams });
  }
  setSelectedExtraParams(filters, searchQueryLabels) {
    const extraParams = {};
    const urlFilters = this.$store.getters[this.getNameWithNamespace("urlFilters")];
    Object.entries(searchQueryLabels).forEach(([code, filter]) => {
      if (filters[code] || !urlFilters[filter.url] && !urlFilters[filter.code]) {
        return;
      }
      extraParams[filter.code] = {
        url: filter.url,
        code: filter.code,
        label: filter.label,
        selected: Array.isArray(filter.values) ? Object.fromEntries(filter.values.map((option) => [option.code, option])) : { range: { code: "range", url: filter.values, label: filter.values } }
      };
    });
    this.$store.dispatch(this.namespaces.SET_SELECTED_EXTRA_PARAMS, extraParams);
    this.$store.dispatch(this.namespaces.SET_INITIAL_EXTRA_STATE, extraParams);
  }
  initEsizeme(rangeBoundaries, initial) {
    const { below, above } = this.esizemeRangeBoundaries;
    if (rangeBoundaries.length && (rangeBoundaries[0] !== below || rangeBoundaries[1] !== above)) {
      this.$store.commit(this.namespaces.SET_ESIZEME_RANGE_BOUNDARIES, rangeBoundaries);
    }
    const esizemeValue = this.searchQueryFilters[FilterEsizeme.TRUE_SIZE_FEMALE]?.range || this.searchQueryFilters[FilterEsizeme.TRUE_SIZE_MALE]?.range;
    if (!esizemeValue) {
      if (initial) {
        this.$store.dispatch(this.namespaces.SET_INITIAL_ESIZEME_STATE, {});
      }
      return;
    }
    if (this.selectedEsizemeScan?.id && this.selectedEsizemeScan.size === esizemeValue) {
      if (initial) {
        this.$store.dispatch(
          this.namespaces.SET_INITIAL_ESIZEME_STATE,
          this.selectedEsizemeScan
        );
      }
      return;
    }
    const scan = {
      gender: this.searchQueryFilters[FilterEsizeme.TRUE_SIZE_MALE] ? FilterEsizeme.MALE : FilterEsizeme.FEMALE,
      name: FilterEsizeme.DEFAULT_LABEL,
      id: FilterEsizeme.DEFAULT_ID,
      size: esizemeValue
    };
    this.$store.dispatch(this.namespaces.SET_ESIZEME_STATE, scan);
    if (initial) {
      this.$store.dispatch(this.namespaces.SET_INITIAL_ESIZEME_STATE, scan);
    }
  }
  removeTag(filterCode, tagCode) {
    this.$store.dispatch(this.namespaces.REMOVE_TAG, { filterCode, tagCode });
  }
  removeExtraTag(filterCode, tagCode) {
    this.$store.dispatch(this.namespaces.REMOVE_EXTRA_TAG, { filterCode, tagCode });
  }
  useFilter(filter) {
    if (filter instanceof Filter) {
      return filter;
    }
    const addFilter = this.addSelectedFilter.bind(this);
    const removeFilter = this.removeSelectedFilter.bind(this);
    const params = this.selectedParams[filter.code]?.selected || {};
    switch (filter.type) {
      case FilterTypes.MULTISELECT: {
        return new FilterMultiselect(
          filter,
          params,
          addFilter,
          removeFilter
        );
      }
      case FilterTypes.RANGE: {
        return new FilterRange(
          filter,
          params,
          addFilter,
          removeFilter
        );
      }
      case FilterTypes.NESTED_MULTISELECT: {
        return new FilterNestedMultiselect(
          filter,
          params,
          addFilter,
          removeFilter
        );
      }
      case FilterTypes.TOGGLE: {
        return new FilterToggle(
          filter,
          params,
          addFilter,
          removeFilter
        );
      }
      case FilterTypes.GROUP: {
        const { filters, ...rest } = filter;
        const filtersModels = filters.map(
          (groupFilter) => this.useFilter(groupFilter)
        );
        return new FilterGroup(filtersModels, rest);
      }
      default: {
        throw getUnknownFilterTypeError(filter);
      }
    }
  }
  useEsizemeFilter() {
    const setFilter = this.setEsizemeFilter.bind(this);
    const selectedScan = this.selectedEsizemeScan;
    return new FilterEsizeme(selectedScan, this.esizemeRangeBoundaries, setFilter);
  }
  registerFilterStoreModule() {
    if (this.$store.hasModule(this.storeNamespacePath)) {
      return;
    }
    this.$store.registerModule(this.storeNamespacePath, filterModule);
  }
  getNameWithNamespace(name) {
    return `${this.storeNamespace}/${name}`;
  }
}

export { FilterManager as default };
