export const STREET_RULE_NAME = 'rmaStreet';

const STREET_LENGTH = 100;

export const validate = value => {
    if (value === '0') {
        return false;
    }

    if (value.length > STREET_LENGTH) {
        return false;
    }

    return true;
};

export const getStreetRuleName = i18n => {
    return {
        validate,
        message: (_, values) => {
            const { _value_ } = values;

            if (_value_ === '0') {
                return i18n.t('__validations.__rma.noZero');
            }

            return i18n.t('__validations.max', { length: STREET_LENGTH });
        },
    };
};

export default i18n => ({
    name: STREET_RULE_NAME,
    validation: getStreetRuleName(i18n),
});
