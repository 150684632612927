import { CUSTOMER_ACCOUNT_RETURN_NAME } from '@router/names';

export default async function ({ app, store, redirect }) {
    if (!(process.client && store.getters['customer/isLoggedIn'])) {
        return;
    }

    const {
        route: { path },
    } = app.router.resolve({
        name: CUSTOMER_ACCOUNT_RETURN_NAME,
    });

    return redirect(path);
}
