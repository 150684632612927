export const NAME = 'cmsBlocks';

export const GET_BLOCKS = 'getBlocks';
export const GET_AVAILABLE_BLOCKS = 'getAvailableBlocks';
export const GET_PAGE_BUILDER_BLOCKS = 'getPageBuilderBlocks';
export const GET_STATIC_CONTENT = 'getStaticContent';

export const ASYNC_CMS_BLOCKS_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_BLOCKS,
        GET_AVAILABLE_BLOCKS,
        GET_PAGE_BUILDER_BLOCKS,
        GET_STATIC_CONTENT,
    },
};
