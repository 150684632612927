export default ({ app }, inject) => {
    const { router } = app;
    let pageReferrer = document.referrer;
    let userNavigationOccured = false;

    performance.getEntriesByType('navigation').forEach(entry => {
        if (entry.type === 'reload') {
            pageReferrer = '';
            userNavigationOccured = true;
        }
    });

    window.addEventListener('popstate', () => {
        pageReferrer = '';
        userNavigationOccured = true;
    });

    router.beforeEach((to, from, next) => {
        const isFirstRequest = from.path === '/' && from.name === null;

        if (isFirstRequest) {
            next();

            return;
        }

        if (userNavigationOccured) {
            userNavigationOccured = false;
        } else {
            pageReferrer = `${window.location.origin}${from.fullPath}`;
        }

        next();
    });

    inject('getPageReferrer', () => {
        return pageReferrer;
    });
};
