import {
    FIRSTNAME,
    LASTNAME,
    COMPANY,
    STREET,
    HOUSE_NUMBER,
    CITY,
    POSTCODE,
    COUNTRY_CODE,
    TELEPHONE,
    DEFAULT_SHIPPING_ADDRESS,
    DEFAULT_BILLING_ADDRESS,
    ADDRESS_TITLE,
    ID,
    VAT_ID,
    ADDRESS_IS_ACTIVE,
    BUY_AS,
    BUY_AS_PRIVATE_PERSON,
} from '@configs/mage-customer-attributes';

import { ADDRESS_TYPE_BILLING, ADDRESS_TYPE_SHIPPING } from '@types/Checkout';

import { COMPANY_CUSTOMER, PRIVATE_PERSON_CUSTOMER } from '@types/Customer';
import AbstractAddress from '@models/Customer/AbstractAddress';

export default class CustomerAddress extends AbstractAddress {
    constructor(customerAddress = {}) {
        super(customerAddress);

        this.set(customerAddress);
    }

    set(customerAddress = {}) {
        super.set(customerAddress);

        const {
            id = null,
            title = '',
            isDefaultBilling = false,
            isDefaultShipping = false,
            isActive = false,
        } = customerAddress;

        this.id = id;
        this.title = title;
        this.isDefaultBilling = isDefaultBilling;
        this.isDefaultShipping = isDefaultShipping;
        this.isActive = isActive;
    }

    copyFromMagentoCustomerAddress(magentoCustomerAddress = {}) {
        if (!magentoCustomerAddress) {
            return;
        }

        const address = CustomerAddress.mapMagentoCustomerAddress(
            magentoCustomerAddress
        );

        Object.entries(address).forEach(([key, value]) => {
            if (typeof this[key] !== 'undefined') {
                this[key] = typeof value === 'boolean' ? value : value || '';
            }
        });
    }

    get isDefaultBillingOrShipping() {
        return this.isDefaultBilling || this.isDefaultShipping;
    }

    isDefault(addressType) {
        return addressType === ADDRESS_TYPE_BILLING
            ? this.isDefaultBilling
            : this.isDefaultShipping;
    }

    setIsDefault(addressType, isDefault) {
        if (addressType === ADDRESS_TYPE_BILLING) {
            this.isDefaultBilling = isDefault;
        } else if (addressType === ADDRESS_TYPE_SHIPPING) {
            this.isDefaultShipping = isDefault;
        }
    }

    static mapMagentoCustomerAddress(magentoCustomerAddress) {
        const {
            [ID]: id = '',
            [ADDRESS_TITLE]: title = '',
            [BUY_AS]: customerType = '',
            [FIRSTNAME]: firstName = '',
            [LASTNAME]: lastName = '',
            [COMPANY]: company = '',
            [TELEPHONE]: telephone = '',
            [STREET]: street = '',
            [HOUSE_NUMBER]: houseNumber = '',
            [POSTCODE]: postcode = '',
            [CITY]: city = '',
            [COUNTRY_CODE]: countryCode = '',
            [VAT_ID]: vatId = '',
            [DEFAULT_BILLING_ADDRESS]: isDefaultBilling = false,
            [DEFAULT_SHIPPING_ADDRESS]: isDefaultShipping = false,
            [ADDRESS_IS_ACTIVE]: isActive = false,
        } = magentoCustomerAddress;

        return {
            id,
            title,
            customerType: CustomerAddress.mapMagentoCustomerType(customerType),
            firstName,
            lastName,
            company: company || '',
            telephone,
            street: CustomerAddress.mapMagentoStreet(street),
            houseNumber,
            postcode,
            city,
            countryCode,
            vatId: vatId || '',
            isDefaultBilling,
            isDefaultShipping,
            isActive,
        };
    }

    static mapMagentoCustomerType(magentoCustomerType) {
        return !magentoCustomerType ||
            magentoCustomerType === BUY_AS_PRIVATE_PERSON
            ? PRIVATE_PERSON_CUSTOMER
            : COMPANY_CUSTOMER;
    }

    static mapMagentoStreet(magentoStreet = []) {
        const [street] = [magentoStreet].flat();

        return street || '';
    }
}
