import applePayService from '@services/modules/payments/applePay';
import giftCardService from '@services/modules/payments/giftCard';
import klarnaService from '@services/modules/payments/klarna';
import payPalService from '@services/modules/payments/payPal';
import { PAYMENTS_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME, UNKNOWN_ERROR } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.payments.${action}`,
});

export default ({ megatronClient, httpClient, $errorHandler }) => ({
    applePay: applePayService(httpClient),
    klarna: klarnaService(megatronClient, $errorHandler),
    giftCard: giftCardService(megatronClient, $errorHandler),
    payPal: payPalService(megatronClient, httpClient, $errorHandler),

    async getPaymentMethods() {
        const ERROR_TAGS = getErrorTags('getPaymentMethods');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getPaymentMethods',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                }
            );

            if (!error) {
                return data?.paymentMethods || [];
            }

            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                err,
                ERROR_TAGS
            );
        }

        return [];
    },
});
