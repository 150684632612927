export const addPrefixToIdAttributes = (htmlString, prefix) => {
    const ID_ATTRIBUTE_NAME = 'id';

    return (
        htmlString
            // replace attribute
            .replace(
                new RegExp(`${ID_ATTRIBUTE_NAME}="([^"]+)"`, 'g'),
                `${ID_ATTRIBUTE_NAME}="${prefix}_$1"`
            )
            // replace attribute value for all references
            .replace(/url\(#([^)]+)\)/g, `url(#${prefix}_$1)`)
            .replace(/href="#([^"]+)"/g, `href="#${prefix}_$1"`)
    );
};
