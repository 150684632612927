<template>
    <HeaderTooltip :is-enabled="true" class="minicart-tooltip">
        <HeaderActionButton
            :path="cartPath"
            :icon="Bag"
            :label="$t('Cart')"
            :digit="cartItemsCount"
        />

        <template #tooltipContent>
            <HeaderTooltipContent
                v-if="!cartItemsCount"
                :illustration="GreenBag"
                :heading="$t('Your shopping cart is empty')"
                :description="tooltipDescription"
                :primary-button-text="tooltipPrimaryButtonText"
                :secondary-button-text="tooltipSecondaryButtonText"
                @primary-button-click="onTooltipPrimaryButtonClick()"
                @secondary-button-click="onTooltipSecondaryButtonClick()"
            />
            <MinicartContent v-else class="minicart-content" />
        </template>
    </HeaderTooltip>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    MODAL_AUTHORIZATION_NAME,
    MODAL_AUTHORIZATION_MODAL_TYPE,
} from '@configs/modals';
import { LOGIN } from '@types/CustomerModal';

import { CHECKOUT_CART_PAGE } from '@router/paths';

import HeaderTooltip from '@header-atoms/HeaderTooltip/HeaderTooltip';
import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';

import { Bag } from '@eobuwie-ui/icons/v2/other';
import GreenBag from '@header/illustrations/green-bag.svg?inline';

const { mapGetters: mapCartGetters } = createNamespacedHelpers('cart');
const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    name: 'MinicartTooltip',

    components: {
        HeaderTooltip,
        HeaderActionButton,
        HeaderTooltipContent: () => ({
            component: import(
                /* webpackChunkName: "header-tooltip-content" */
                '@header-atoms/HeaderTooltipContent/HeaderTooltipContent'
            ),
        }),

        MinicartContent: () => ({
            component: import(
                /* webpackChunkName: "new-minicart-content" */
                '@header-molecules/MinicartContent/MinicartContent'
            ),
        }),
    },

    computed: {
        ...mapCartGetters(['cartItemsCount']),
        ...mapCustomerGetters(['isLoggedIn']),

        tooltipPrimaryButtonText() {
            return this.isLoggedIn ? '' : this.$t('Login');
        },

        tooltipSecondaryButtonText() {
            return this.isLoggedIn && this.$tPath('new')
                ? this.$t('Show novelties')
                : '';
        },

        tooltipDescription() {
            if (!this.isLoggedIn) {
                return this.$t(
                    // eslint-disable-next-line max-len
                    'Were there products in your cart? Log in to your account and check if they are still there.'
                );
            }

            return '';
        },
    },

    beforeCreate() {
        this.cartPath = `/${CHECKOUT_CART_PAGE}`;
        this.Bag = Bag;
        this.GreenBag = GreenBag;
    },

    methods: {
        async onTooltipPrimaryButtonClick() {
            await this.$modals.open(MODAL_AUTHORIZATION_NAME, {
                [MODAL_AUTHORIZATION_MODAL_TYPE]: LOGIN,
            });
        },

        onTooltipSecondaryButtonClick() {
            const noveltyPath = this.$tPath('new');

            this.$router.push(noveltyPath);
        },
    },
};
</script>
