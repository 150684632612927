import { getMissingError } from '../assets/errors.js';
import Filter from './Filter.js';

class FilterRange extends Filter {
  max;
  min;
  initialRange;
  constructor(filterAttributes, params, addFilter, removeFilter) {
    super(filterAttributes, addFilter, removeFilter);
    const { max, min } = filterAttributes;
    if (!max && max !== 0) {
      throw getMissingError("max");
    }
    if (!min && min !== 0) {
      throw getMissingError("min");
    }
    this.max = max;
    this.min = min;
    this.initialRange = [min, max];
    if (Object.keys(params).length) {
      this.selectedFilterParams = params;
      const { min: minParam, max: maxParam } = params;
      if (minParam) {
        this.initialRange[0] = parseInt(minParam.url, 10);
      }
      if (maxParam) {
        this.initialRange[1] = parseInt(maxParam.url, 10);
      }
    }
  }
  get range() {
    const { min: appliedMin, max: appliedMax } = this.selectedFilterParams;
    const min = appliedMin ? parseInt(appliedMin.url, 10) : this.min;
    const max = appliedMax ? parseInt(appliedMax.url, 10) : this.max;
    return [min, max];
  }
  get isFilterChanged() {
    return this.initialRange[0] !== this.range[0] || this.initialRange[1] !== this.range[1];
  }
  get selectedCount() {
    return Object.keys(this.selectedFilterParams).length ? 1 : 0;
  }
  setRange(range) {
    const [selectedMin, selectedMax] = range;
    const { min, max } = this;
    const rangeToApply = FilterRange.getAppliedRangeWithBoundaries({
      min,
      max,
      selectedMin,
      selectedMax
    });
    if (!rangeToApply) {
      this.clearFilter();
      return;
    }
    if (rangeToApply[0] !== min) {
      this.selectedFilterParams = {
        ...this.selectedFilterParams,
        min: {
          url: rangeToApply[0].toString(),
          code: "min",
          label: rangeToApply[0].toString()
        }
      };
    } else {
      const { min: _minToRemove, ...rest } = this.selectedFilterParams;
      this.selectedFilterParams = rest;
    }
    if (rangeToApply[1] !== max) {
      this.selectedFilterParams = {
        ...this.selectedFilterParams,
        max: {
          url: rangeToApply[1].toString(),
          code: "max",
          label: rangeToApply[1].toString()
        }
      };
    } else {
      const { max: _maxToRemove, ...rest } = this.selectedFilterParams;
      this.selectedFilterParams = rest;
    }
  }
  updateFilter(filterAttributes) {
    const { max, min } = filterAttributes;
    this.max = max;
    this.min = min;
    this.setRange(this.range);
  }
  static getAppliedRangeWithBoundaries({
    min,
    max,
    selectedMin,
    selectedMax
  }) {
    let finalMin = selectedMin;
    let finalMax = selectedMax;
    if (selectedMin < min) {
      finalMin = min;
    }
    if (selectedMax < min) {
      finalMax = min;
      finalMin = min;
    }
    if (selectedMax > max) {
      finalMax = max;
    }
    if (selectedMin > max) {
      finalMin = max;
      finalMax = max;
    }
    if (finalMax < finalMin) {
      finalMax = finalMin;
    }
    if (finalMin === min && finalMax === max) {
      return null;
    }
    return [finalMin, finalMax];
  }
}

export { FilterRange as default };
