<template>
    <div
        :class="{
            'side-modal-mask': isOpenFlag,
            'bg-filter': isBackgroundBlurred,
        }"
        @keyup.esc.stop="closeSideModal($event)"
    >
        <WithClickOutsideDetection
            :handler="isOpenFlag ? 'closeSideModal' : null"
        >
            <div
                ref="sideModal"
                :tabindex="tabindex"
                :class="mainModalClasses"
                class="side-modal"
            >
                <div class="wrapper">
                    <header :class="modalHeaderClass" class="header">
                        <slot name="header-left-icon" />

                        <slot name="header">
                            <div v-if="label" class="label">
                                <BaseHeading tag="h5" class="title">
                                    {{ label }}
                                </BaseHeading>
                            </div>
                            <ButtonIcon
                                :data-test-id="CLOSE_SIDE_MODAL"
                                :variant="BUTTON_ICON_VARIANT"
                                class="close"
                                @click.native="closeSideModal($event)"
                            >
                                <Icon :icon="Close" />
                            </ButtonIcon>
                        </slot>
                    </header>

                    <section :class="modalBodyClass" class="body-text">
                        <slot />
                    </section>
                    <footer
                        v-if="$slots.footer"
                        :class="modalFooterClass"
                        class="footer"
                    >
                        <slot name="footer" />
                    </footer>
                </div>
            </div>
        </WithClickOutsideDetection>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { PREVENT_SCROLL_CLASS_NAME } from '@configs/class-names';

import { SLIDE_FROM_TYPES } from '@types/SideModal';
import { CLOSE_SIDE_MODAL } from '@types/AutomaticTestIDs';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

import { Close } from '@eobuwie-ui/icons/v2/navigation';

const { mapActions: mapActionsLayout } = createNamespacedHelpers('layout');

export default {
    name: 'SideModal',

    components: {
        WithClickOutsideDetection,
        BaseHeading,
        ButtonIcon,
        Icon,
    },

    props: {
        label: {
            type: String,
            default: '',
        },

        isOpen: {
            type: Boolean,
            default: false,
        },

        applyOnlyOnMobile: {
            type: Boolean,
            default: false,
        },

        applyOnlyOnMobileAndTablet: {
            type: Boolean,
            default: false,
        },

        slideFrom: {
            type: String,
            default: 'left',
            validator: checkIfExistsInValuesMap(SLIDE_FROM_TYPES),
        },

        applyNoScroll: {
            type: Boolean,
            default: true,
        },

        deferOpenToMount: {
            type: Boolean,
            default: false,
        },

        fixedHeader: {
            type: Boolean,
            default: true,
        },

        absoluteHeader: {
            type: Boolean,
            default: false,
        },

        isNotFullScreenOnMobile: {
            type: Boolean,
            default: false,
        },

        modalWindowClass: {
            type: [String, Array],
            default: '',
        },

        modalHeaderClass: {
            type: [String, Array],
            default: '',
        },

        modalBodyClass: {
            type: [String, Array],
            default: '',
        },

        modalFooterClass: {
            type: [String, Array],
            default: '',
        },

        isBackgroundBlurred: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isMounted: false,
            isMountedAfterAnimationFrame: false,
        };
    },

    computed: {
        ...mapState(['isMobile']),

        isOpenFlag() {
            return this.deferOpenToMount
                ? this.isMounted && this.isOpen
                : this.isOpen;
        },

        isOpenedClass() {
            return this.deferOpenToMount
                ? this.isOpenFlag && this.isMountedAfterAnimationFrame
                : this.isOpenFlag;
        },

        mainModalClasses() {
            return {
                [this.slideFrom]: true,
                [this.modalWindowClass]: true,
                opened: this.isOpenedClass,
                'mobile-only': this.applyOnlyOnMobile,
                'mobile-and-tablet-only': this.applyOnlyOnMobileAndTablet,
                'fixed-header': this.fixedHeader && !this.absoluteHeader,
                'absolute-header': this.absoluteHeader,
                'is-not-full-screen-on-mobile': this.isNotFullScreenOnMobile,
                default:
                    !this.applyOnlyOnMobile && !this.applyOnlyOnMobileAndTablet,
            };
        },

        tabindex() {
            return this.isOpenFlag ? '-1' : null;
        },
    },

    watch: {
        isMobile() {
            if (this.isOpen) {
                this.closeSideModal();
            }
        },

        isOpenFlag(open) {
            if (open) {
                this.applyLockBodyScroll();

                return;
            }

            this.removeLockBodyScroll();
        },
    },

    mounted() {
        this.isMounted = true;

        if (this.isOpenFlag) {
            this.applyLockBodyScroll();
        }

        window.requestAnimationFrame(() => {
            this.isMountedAfterAnimationFrame = true;
        });
    },

    beforeCreate() {
        this.CLOSE_SIDE_MODAL = CLOSE_SIDE_MODAL;
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.Close = Close;
    },

    destroyed() {
        if (this.applyNoScroll && this.isOpenFlag) {
            this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        }
    },

    methods: {
        ...mapActionsLayout(['addHTMLClasses', 'removeHTMLClasses']),

        async applyLockBodyScroll() {
            if (!this.applyNoScroll) {
                return;
            }

            await this.$nextTick();

            this.addHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        },

        removeLockBodyScroll() {
            this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        },

        closeSideModal(ev) {
            this.$emit('close', ev);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/blurred-background';

@mixin horizontal-screen-slide() {
    @apply w-full;

    &.left,
    &.right {
        @apply top-0;

        max-width: theme('customVariables.sideModal.desktopWidth');

        &.opened {
            transform: translateX(0);
        }
    }

    &.left {
        @apply left-0;

        transform: translateX(-100%);
    }

    &.right {
        @apply right-0;

        transform: translateX(100%);
    }
}

@mixin vertical-screen-slide() {
    &.top,
    &.bottom {
        @apply left-0 w-full;

        &.opened {
            transform: translateY(0);
        }
    }

    &.top {
        @apply top-0;

        transform: translateY(-100%);
    }

    &.bottom {
        @apply bottom-0;

        transform: translateY(100%);
    }

    @screen lg {
        &.top,
        &.bottom {
            height: theme('customVariables.sideModal.desktopHeight');
        }
    }
}

@mixin slide-screen-styles() {
    @include horizontal-screen-slide();
    @include vertical-screen-slide();

    @apply bg-light fixed w-full h-full;

    transition: none;

    &.opened {
        @apply z-7;

        transition: transform 0.3s ease-out;
    }

    .header {
        @apply flex flex-row justify-between items-center w-full border-b-1 border-border relative;
        height: #{theme('customVariables.sideModal.headerHeight')};

        .close {
            @apply right-4 absolute;
        }

        @screen md {
            .close {
                @apply right-18;
            }
        }
    }

    .footer {
        @apply bg-light;
    }
}

.side-modal {
    @apply outline-none z-3 overflow-y-auto;

    &.fixed-header {
        @apply overflow-y-visible;

        .wrapper {
            @apply flex flex-col;
        }

        .header {
            @apply shrink-0;
        }

        .body-text {
            @apply overflow-y-auto;
        }

        .footer {
            @apply shrink-0;
        }
    }

    .wrapper {
        @apply w-full h-full;
    }

    .header {
        @apply hidden w-full;
    }

    .title {
        @apply font-bold;
    }

    &.absolute-header {
        .header {
            @apply absolute;
        }
    }

    &.default {
        @include slide-screen-styles();
    }

    @screen mobile-and-tablet-only {
        &.bottom,
        &.top {
            &.is-not-full-screen-on-mobile {
                @apply h-3/4 w-full max-w-full;

                @screen md {
                    @apply h-full rounded-t-0;
                    max-width: theme('customVariables.sideModal.desktopWidth');
                }
            }
        }

        &.bottom {
            @apply rounded-t-4;
        }

        &.top {
            @apply rounded-b-4;
        }
    }

    &.mobile-only {
        @screen mobile-only {
            @include slide-screen-styles();
        }
    }

    &.mobile-and-tablet-only {
        @screen mobile-and-tablet-only {
            @include slide-screen-styles();
        }
    }

    @screen lg {
        .title {
            @apply text-m leading-m;
        }
    }
}

.side-modal-mask {
    &:focus {
        @apply outline-none;
    }

    &::before {
        @apply fixed top-0 left-0 w-full h-full z-4;

        content: '';
    }

    &.bg-filter {
        &::before {
            @include blurred-background();
        }
    }
}
</style>
