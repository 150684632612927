export const chunkBySize = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (element, index) => {
        const arrStart = index * size;

        return arr.slice(arrStart, arrStart + size);
    });

export const hasCommonElement = (arr1, arr2) =>
    arr1.some(element => arr2.includes(element));

export const sortRandomly = arr => {
    const arrCopy = [...arr];

    for (let i = arrCopy.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));

        [arrCopy[i], arrCopy[j]] = [arrCopy[j], arrCopy[i]];
    }

    return arrCopy;
};
