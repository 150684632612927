import { NOT_FOUND_NAME } from '@router/names';

import {
    getHost,
    makeRedirect,
    includesWWWSubdomain,
} from '@modules/m1-migration/redirector/utils';

export default ({ req, route, redirect }) => {
    if (process.client || route.name === NOT_FOUND_NAME) {
        return;
    }

    if (includesWWWSubdomain(getHost(req))) {
        return makeRedirect(req, redirect, route.fullPath);
    }
};
