export const LOAD_CART = {
    startTag: 'checkoutCartLoadStart',
    endTag: 'checkoutCartLoadEnd',
    label: 'checkoutCartLoad',
};

export const REMOVE_ITEM_FROM_CART = {
    startTag: 'cartItemRemovedStart',
    endTag: 'cartItemRemovedEnd',
    label: 'cartItemRemovedLoad',
};

export const APPLY_PROMO_CODE = {
    startTag: 'applyPromoCodeStart',
    endTag: 'applyPromoCodeEnd',
    label: 'applyPromoCode',
};

export const LOAD_CHECKOUT = {
    startTag: 'checkoutLoadStart',
    endTag: 'checkoutLoadEnd',
    label: 'checkoutLoad',
};
