import Vue from 'vue'

let sentryReadyResolve
let loadInitiated = false
let loadCompleted = false

export default function SentryPlugin (ctx, inject) {
  const loadSentryHook = () => attemptLoadSentry(ctx, inject)

  inject('sentryLoad', loadSentryHook)
  ctx.$sentryLoad = loadSentryHook

  const sentryReadyPromise = new Promise((resolve) => {
    sentryReadyResolve = resolve
  })

  const sentryReady = () => sentryReadyPromise

  inject('sentryReady', sentryReady)
  ctx.$sentryReady = sentryReady
}

async function attemptLoadSentry (ctx, inject) {
  if (loadInitiated) {
    return
  }

  loadInitiated = true

  if (!window.$nuxt) {
    window.onNuxtReady(() => loadSentry(ctx, inject))
    return
  }

  await loadSentry(ctx, inject)
}

async function loadSentry (ctx, inject) {
  if (loadCompleted) {
    return
  }

  const { getConfig, init, SentrySdk } = await import(/* webpackChunkName: 'sentry' */ './sentry.client.shared')

  const config = await getConfig(ctx)
  init({ Vue, ...config })

  loadCompleted = true

  forceInject(ctx, inject, 'sentry', SentrySdk)
  sentryReadyResolve(SentrySdk)

  // help gc

  sentryReadyResolve = undefined
}

// Custom inject function that is able to overwrite previously injected values,
// which original inject doesn't allow to do.
// This method is adapted from the inject method in nuxt/vue-app/template/index.js
function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  window.$nuxt.$options[injectKey] = value
}
