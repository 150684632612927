import {
    USER_BAD_INPUT,
    NO_SUCH_ENTITY,
    FORBIDDEN,
    UNAUTHENTICATED,
    INTERNAL_SERVER_ERROR,
    ALREADY_EXISTS,
    NO_SUCH_PRODUCT,
    EXTERNAL_SERVICE_UNAVAILABLE,
    UNPROCESSABLE_ENTITY,
    PRODUCT_IS_OUT_OF_STOCK,
    PRODUCT_IS_LOW_STOCK,
} from '@types/GraphQLErrorCodes';

const EXTENSIONS_CATEGORY_TO_CODE = {
    'graphql-input': USER_BAD_INPUT,
    'graphql-no-such-entity': NO_SUCH_ENTITY,
    'graphql-authorization': FORBIDDEN,
    'graphql-authentication': UNAUTHENTICATED,
    'graphql-already-exist': ALREADY_EXISTS,
    'graphql-no-such-product': NO_SUCH_PRODUCT,
    'graphql-external-service-unavailable': EXTERNAL_SERVICE_UNAVAILABLE,
    'graphql-unprocessable-entity': UNPROCESSABLE_ENTITY,
    'graphql-product-is-out-of-stock': PRODUCT_IS_OUT_OF_STOCK,
    'graphql-product-is-low-stock': PRODUCT_IS_LOW_STOCK,
};

const mapExtensionsCategoryToCode = category => {
    return EXTENSIONS_CATEGORY_TO_CODE[category] || INTERNAL_SERVER_ERROR;
};

export const getErrorCodes = (
    graphqlErrors,
    { onlyFirstError = true, withCategory = false, withMessage = false } = {}
) => {
    const emptyValue = onlyFirstError ? null : [];

    try {
        if (!graphqlErrors.length) {
            return emptyValue;
        }

        if (onlyFirstError) {
            return mapExtensionsCategoryToCode(
                graphqlErrors[0].extensions.category
            );
        }

        return graphqlErrors.map(err => {
            const { category } = err?.extensions || {};
            const shouldExtendData = withCategory || withMessage;
            const code = mapExtensionsCategoryToCode(category);

            if (!shouldExtendData) {
                return code;
            }

            const errorData = {
                code,
            };

            if (withCategory) {
                errorData.category = category || '';
            }

            if (withMessage) {
                errorData.message = err.message || '';
            }

            return errorData;
        });
    } catch (err) {
        return emptyValue;
    }
};

export const getErrorMessages = (graphqlErrors, onlyFirstError = false) => {
    const emptyValue = onlyFirstError ? null : [];

    try {
        if (!graphqlErrors.length) {
            return emptyValue;
        }

        return onlyFirstError
            ? graphqlErrors[0].message
            : graphqlErrors.map(({ message }) => message);
    } catch (err) {
        return emptyValue;
    }
};

export const getFirstErrorCode = (graphqlErrors, options) =>
    getErrorCodes(graphqlErrors, options);
export const getFirstErrorMessage = graphqlErrors =>
    getErrorMessages(graphqlErrors, true);
