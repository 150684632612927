<template>
    <div
        :style="{
            backgroundColor,
            color,
        }"
        class="marketing-bar"
    >
        <component :is="textComponent" :path="textLink || null" class="text">
            <SvgIcon
                v-if="icon"
                :style="{
                    color: iconColor || 'currentColor',
                }"
                width="20px"
                height="20px"
                class="icon"
            >
                <component :is="icon" />
            </SvgIcon>
            <div class="text-mobile">
                {{ textMobile }}
            </div>
            <div class="text-desktop">
                {{ textDesktop }}
            </div>
        </component>
        <slot name="after-text" />
    </div>
</template>

<script>
import {
    MARKETING_BAR_ALLOWED_ICONS,
    MARKETING_BAR_DEFAULT_BACKGROUND,
    MARKETING_BAR_DEFAULT_COLOR,
} from '@configs/marketing-bar';

import { checkIfExistsInValuesMap } from '@assets/props';
import { isHttpLink } from '@assets/link';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import { Newsletter } from '@eobuwie-ui/icons/v2/other';

export default {
    name: 'MarketingBar',

    components: {
        SvgIcon,
        BaseLink,
        BaseLinkStatic,
        Newsletter,

        Sun: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-icon-sun" */
                '@marketing-bar-static/icons/regular/sun.svg?inline'
            ),
        }),

        Tag: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-icon-tag" */
                '@marketing-bar-static/icons/regular/tag.svg?inline'
            ),
        }),

        LockKey: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-icon-lock-key" */
                '@marketing-bar-static/icons/regular/lock-key.svg?inline'
            ),
        }),

        LockFilled: () => ({
            component: import(
                /* webpackChunkName: "icon-lock-filled" */
                '@static/icons/20px/lock-filled.svg?inline'
            ),
        }),
    },

    props: {
        icon: {
            type: String,
            default: null,
            validator: checkIfExistsInValuesMap(
                MARKETING_BAR_ALLOWED_ICONS,
                true
            ),
        },

        backgroundColor: {
            type: String,
            default: MARKETING_BAR_DEFAULT_BACKGROUND,
        },

        color: {
            type: String,
            default: MARKETING_BAR_DEFAULT_COLOR,
        },

        iconColor: {
            type: String,
            default: null,
        },

        textMobile: {
            type: String,
            default: null,
        },

        textDesktop: {
            type: String,
            default: null,
        },

        textLink: {
            type: String,
            default: null,
        },
    },

    computed: {
        // eslint-disable-next-line vue/no-unused-properties
        baseProps() {
            return {
                backgroundColor: this.backgroundColor,
                textMobile: this.textMobile,
                textDesktop: this.textDesktop,
                textLink: this.textLink,
                color: this.color,
                icon: this.icon,
                iconColor: this.iconColor,
            };
        },

        textComponent() {
            const { textLink } = this;
            const isLink = !!textLink;

            let component = 'div';

            if (isLink) {
                component = isHttpLink(textLink)
                    ? 'BaseLinkStatic'
                    : 'BaseLink';
            }

            return component;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-bar {
    @apply min-h-xxl flex justify-center items-center w-full px-3 py-2;
    @apply text-s font-bold leading-ui-18;

    .text {
        @apply flex text-s items-center justify-center gap-x-2 h-5;
        @apply no-underline text-inherit font-bold leading-ui-18;

        &:hover:not(.disabled) {
            @apply no-underline text-inherit;
        }
    }

    @screen mobile-and-tablet-only {
        .text-desktop {
            @apply hidden;
        }
    }

    @screen lg {
        @apply px-5;

        .text-mobile {
            @apply hidden;
        }
    }
}
</style>
