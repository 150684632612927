import { TYPES, THEMES, SIZES } from '@types/Button';

export default {
    props: {
        type: {
            type: String,
            default: TYPES.TYPE_BUTTON,
            validator: value => Object.values(TYPES).includes(value),
        },

        to: {
            type: [String, Object],
            default: null,
        },

        theme: {
            type: String,
            default: THEMES.THEME_SECONDARY,
            validator: value => Object.values(THEMES).includes(value),
        },

        size: {
            type: String,
            default: SIZES.SIZE_NORMAL,
            validator: value => Object.values(SIZES).includes(value),
        },
    },
};
