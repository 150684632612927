export const DOMAIN_WHITELIST = [
    'eobuwie.org',
    'eobuwie.local',
    'eobuwie-local.',
    'modivo.cloud',
];

export const isAllowed = url => {
    return DOMAIN_WHITELIST.some(domain => url.includes(domain));
};
