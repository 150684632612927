import { DEBUG_MODE_ENABLED } from '@modules/async-services/src/types/log';

import AsyncServicesManager from '@modules/async-services/src/models/AsyncServicesManager';

export default function createAsyncServices({
    servicesConfig,
    runtimeServicesHelpers,
    debugModeEnabled = false,
}) {
    if (debugModeEnabled) {
        AsyncServicesManager.log(DEBUG_MODE_ENABLED);
    }

    return new AsyncServicesManager(
        servicesConfig,
        runtimeServicesHelpers,
        debugModeEnabled
    );
}
