import { SearchQuery, Channel, ApiVersion, Endpoint } from '@eobuwie/search';

import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';
import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';
import { FINAL_PRICE_ATTRIBUTE_NAME } from '@configs/product-attribute-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { getSearchClientAndEndpointParameter } from '@assets/get-megatron-endpoint-parameter-search';

const CHECK_REDIRECTS_ERROR_TAGS = {
    [ERROR_ACTION_TAG_NAME]: 'services.category.checkRedirect',
};
const CHECK_REDIRECTS_FOR_PL_ERROR_TAGS = {
    [ERROR_ACTION_TAG_NAME]: 'services.category.checkRedirectForPL',
};

export default ({
    megatronClient,
    httpClient,
    $errorHandler,
    helpers: { abTests },
}) => ({
    async getNavCategories(rootCategoryId) {
        const { data } = await megatronClient.get('v1/navigationCategories', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                rootCategoryId,
            },
        });

        return data?.categoryList || [];
    },

    async checkRedirect({ locale, currency, categories = [] }) {
        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.SEARCH,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setLimit(0)
                .setCategories(categories)
                .setAggregations({
                    [FINAL_PRICE_ATTRIBUTE_NAME]: {
                        enabled: 0,
                    },
                })
                .build();

            const {
                client,
                endpointParameter,
            } = getSearchClientAndEndpointParameter(
                megatronClient,
                httpClient,
                abTests
            );

            const { data, error, status } = await client.get(searchQueryUrl, {
                ...endpointParameter,
            });

            if (!error) {
                return data;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                CHECK_REDIRECTS_ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            return null;
        } catch (err) {
            $errorHandler.captureError(err, CHECK_REDIRECTS_ERROR_TAGS);

            return null;
        }
    },

    async checkRedirectForPL({ slug, locale }) {
        try {
            const redirectQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.REDIRECT,
            });

            const redirectQueryUrl = redirectQuery
                .setChannel(Channel.EOBUWIE)
                .setLocale(locale)
                .setSlug(slug)
                .build();

            const {
                client,
                endpointParameter,
            } = getSearchClientAndEndpointParameter(
                megatronClient,
                httpClient,
                abTests
            );

            const { data, error, status } = await client.get(redirectQueryUrl, {
                ...endpointParameter,
            });

            if (!error) {
                return data;
            }

            if (status !== NOT_FOUND_HTTP_CODE) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    CHECK_REDIRECTS_FOR_PL_ERROR_TAGS,
                    {
                        redirectQueryUrl,
                        data,
                        error,
                        status,
                    }
                );
            }

            return {};
        } catch (err) {
            $errorHandler.captureError(err, CHECK_REDIRECTS_FOR_PL_ERROR_TAGS);

            return {};
        }
    },
});
