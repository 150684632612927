export const PSB_PRODUCT_CARD_ADDITIONAL = 'psb_product_card_additional';
export const PSB_BRAND_SHOP = 'psb_brand_shop';

export const PRODUCT_CMS_BLOCKS_IDS = [PSB_PRODUCT_CARD_ADDITIONAL];

export const MAX_BADGES_TO_DISPLAY = 3;

export const PRODUCT_SLIDER_SLIDE_ATTRIBUTE = 'data-product-slider-slide';

export const PRODUCT_ONE_SIZE_LABEL = 'One Size';

export const VIDEO_GALLERY_POSITION = 1;

export const GOOD_PRICE_ENABLED_LOCALES = [
    'pl_PL',
    'sk_SK',
    'it_IT',
    'de_DE',
    'lv_LV',
    'cs_CZ',
    'fr_FR',
    'de_AT',
    'es_ES',
];
