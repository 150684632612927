import {
    bg_BG,
    cs_CZ,
    de_AT,
    de_CH,
    de_DE,
    el_GR,
    en_GB,
    es_ES,
    fr_CH,
    fr_FR,
    hr_HR,
    hu_HU,
    it_CH,
    it_IT,
    lt_LT,
    lv_LV,
    pl_PL,
    ro_RO,
    ru_RU,
    ru_UA,
    sk_SK,
    sl_SI,
    sv_SE,
    uk_UA,
} from '@localeConfig/locales';
import { IS_HREFLANG_ALLOWED } from '@localeConfig/keys';

import { EOBUWIE_CATEGORY_ID } from '@types/Navigation';

export default {
    [IS_HREFLANG_ALLOWED]: {
        type: Object,
        value: {
            [EOBUWIE_CATEGORY_ID]: {
                [bg_BG]: true,
                [cs_CZ]: true,
                [de_AT]: true,
                [de_CH]: true,
                [de_DE]: true,
                [el_GR]: true,
                [en_GB]: false,
                [es_ES]: true,
                [fr_CH]: true,
                [fr_FR]: true,
                [hr_HR]: true,
                [hu_HU]: true,
                [it_CH]: true,
                [it_IT]: true,
                [lt_LT]: true,
                [lv_LV]: true,
                [pl_PL]: true,
                [ro_RO]: true,
                [ru_RU]: true,
                [ru_UA]: true,
                [sk_SK]: true,
                [sl_SI]: true,
                [sv_SE]: true,
                [uk_UA]: true,
            },
        },
    },
};
