import LOYALTY_CLUB_SERVICES_NAMESPACE from '@loyalty-club/async-services/namespace';

export const NAME = `${LOYALTY_CLUB_SERVICES_NAMESPACE}/points-estimator`;

export const GET_POINTS_ESTIMATOR_FOR_PRODUCTS =
    'GET_POINTS_ESTIMATOR_FOR_PRODUCTS';
export const GET_POINTS_ESTIMATOR_FOR_CART = 'GET_POINTS_ESTIMATOR_FOR_CART';
export const GET_POSSIBLE_POINTS = 'GET_POSSIBLE_POINTS';

export const ASYNC_LOYALTY_POINTS_ESTIMATOR_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_POINTS_ESTIMATOR_FOR_PRODUCTS,
        GET_POINTS_ESTIMATOR_FOR_CART,
        GET_POSSIBLE_POINTS,
    },
};
