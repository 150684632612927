import { v4 as uuid } from 'uuid';

// i.e. product page with param ?size=L or ?size=M is the same page,
// so it should get the same pageId for events
const QUERY_PARAMS_TO_OMIT_FOR_PAGE_ID = ['size'];

export default class EventContext {
    pageId = null;
    pageIdGeneratedOnUrl = null;

    appendContext(event) {
        if (!event) {
            return event;
        }

        if (!event.page) {
            event.page = {};
        }

        event.dataLayer_pushId = uuid();
        event.page.pageId = this.getPageId();

        return event;
    }

    refreshPageId() {
        this.pageId = uuid();

        return this.pageId;
    }

    getPageId() {
        const url = new URL(window.location.href);

        QUERY_PARAMS_TO_OMIT_FOR_PAGE_ID.forEach(param => {
            url.searchParams.delete(param);
        });

        const urlString = url.toString();

        if (!this.pageId || this.pageIdGeneratedOnUrl !== urlString) {
            this.refreshPageId();
            this.pageIdGeneratedOnUrl = urlString;
        }

        return this.pageId;
    }
}
