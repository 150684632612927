export const PLN = {
    symbol: 'zł',
    isPrefixed: false,
};

export const RON = {
    symbol: 'Lei',
    isPrefixed: false,
};

export const CZK = {
    symbol: 'Kč',
    isPrefixed: false,
};

export const EUR = {
    symbol: '€',
    isPrefixed: false,
};
export const BGN = {
    symbol: 'лв',
    isPrefixed: false,
};

export const HUF = {
    symbol: 'Ft',
    isPrefixed: false,
};

export const UAH = {
    symbol: 'грн',
    isPrefixed: false,
};

export const CHF = {
    symbol: 'CHF',
    isPrefixed: true,
};

export const HRK = {
    symbol: 'kn',
    isPrefixed: false,
};

export const SEK = {
    symbol: 'kr',
    isPrefixed: false,
};

export const CURRENCY_UNITS = {
    BGN,
    CZK,
    EUR,
    HUF,
    PLN,
    RON,
    UAH,
    CHF,
    HRK,
    SEK,
};
