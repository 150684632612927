export const SLIDE_FROM_TOP = 'top';
export const SLIDE_FROM_BOTTOM = 'bottom';
export const SLIDE_FROM_LEFT = 'left';
export const SLIDE_FROM_RIGHT = 'right';

export const SLIDE_FROM_TYPES = {
    SLIDE_FROM_TOP,
    SLIDE_FROM_BOTTOM,
    SLIDE_FROM_LEFT,
    SLIDE_FROM_RIGHT,
};
