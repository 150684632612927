const state = () => ({
    urlKey: '',
    isProductReviewsVisible: false,
    productsReviewsTotalCount: {},
    isModalAddProductReviewOpened: false,
    recommendedProducts: {},
    listingCategoryId: '',
    colorVariants: [],
});

export default state;
