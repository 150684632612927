import { REDIRECT_HTTP_CODE } from '@configs/http-codes';

const WWW_SUBDOMAIN_PREFIX = 'www.';

export const getHost = req =>
    process.server ? req.headers.host : window.location.host;

export const includesWWWSubdomain = host =>
    host.startsWith(WWW_SUBDOMAIN_PREFIX);

const removeWWWSubdomain = host => {
    let hostWithoutWWW = host;

    if (includesWWWSubdomain(host)) {
        hostWithoutWWW = host.replace(WWW_SUBDOMAIN_PREFIX, '');
    }

    return hostWithoutWWW;
};

export const makeRedirect = (req, redirect, url, query = {}) => {
    const host = removeWWWSubdomain(getHost(req));

    return redirect(REDIRECT_HTTP_CODE, `https://${host}${url}`, query);
};

export const makeAbsoluteRedirect = (req, redirect, url, query = {}) =>
    redirect(REDIRECT_HTTP_CODE, url, query);
