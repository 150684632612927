import { convertLocaleToIso } from '@assets/locale';

import { IS_LIVE_CHAT_ALLOWED } from '@localeConfig/keys';

export default {
    storeCode: state => state.storeView.code,

    locale: state => state.storeView.locale,

    localeHyphen: state => convertLocaleToIso(state.storeView.locale),

    countryCode: (_, getters) => getters.locale.split('_')[1],

    currency: state => state.storeView.base_currency_code,

    baseDomain: state => state.storeView.base_url,

    locales: state => {
        return state.storeViews.filter(
            ({ visible }) =>
                typeof visible === 'undefined' || parseInt(visible, 10) === 1
        );
    },

    localeConfigByKey: state => (key, defaultValue = null) => {
        const localeConfigValue = state.localeConfig[key];

        return typeof localeConfigValue !== 'undefined'
            ? localeConfigValue
            : defaultValue;
    },

    timezone: state => state.storeView.timezone,

    isZowieChatEnabled: state => state.globalConfig.isZowieChatEnabled,

    zowieChatEnabledPages: state => state.globalConfig.zowieChatEnabledPages,

    zowieScriptSrc: state => state.globalConfig.zowieScriptSrc,

    zowieChatInstanceId: state => state.globalConfig.zowieChatInstanceId,

    isLiveChatEnabled: (_, getters) =>
        getters.localeConfigByKey(IS_LIVE_CHAT_ALLOWED) &&
        !getters.isZowieChatEnabled,

    contactFormLink: state => state.helpCenterConfig?.contactFormLink,

    isMagicLinkEnabled: state => state.globalConfig.isMagicLinkEnabled,

    isAccountVerificationEnabled: state =>
        state.globalConfig.isAccountVerificationEnabled,

    facebookUrl: state => state.globalConfig.facebookUrl,

    instagramUrl: state => state.globalConfig.instagramUrl,

    youtubeUrl: state => state.globalConfig.youtubeUrl,

    synthroneScriptUrl: state => state.globalConfig.synthroneScriptUrl,

    synthroneBrands: state =>
        (state.globalConfig.synthroneBrands || '')
            .split(',')
            .map(brand => brand.trim())
            .filter(brand => !!brand),

    freeDeliveryText: state => state.globalConfig.freeDeliveryText,
};
