import { FETCH_PRIORITY } from '@types/FetchPriority';

import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    props: {
        fetchPriority: {
            type: String,
            default: FETCH_PRIORITY.AUTO,
            validator: checkIfExistsInValuesMap(FETCH_PRIORITY),
        },
    },

    methods: {
        shouldSetFetchPriority(fetchPriority) {
            return [FETCH_PRIORITY.LOW, FETCH_PRIORITY.HIGH].includes(
                fetchPriority
            );
        },
    },
};
