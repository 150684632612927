import { ITEM_SKU_PRODUCT_KEY } from '@configs/mage-order-based-on-cart-attributes';
import { ORDER_ITEM_SKU_PRODUCT_KEY } from '@configs/mage-order-attributes';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import {
    formatCreatedDate,
    getProductDataMapForOrderItems,
} from '@assets/order';

export const getFullName = (firstName = '', lastName = '') => {
    return `${firstName || ''} ${lastName || ''}`.trim();
};

export const loadOrders = async (
    app,
    { currentPage, pageSize, sortType, sortValue }
) => {
    const response = (hasError, orders, ordersCount) => ({
        loadingError: hasError || false,
        customerOrders: orders || [],
        customerOrdersCount: ordersCount || 0,
    });
    let customerOrders = [];

    const {
        hasError,
        orders,
        ordersCount,
    } = await app.$services.customer.getCustomerOrdersExtData(
        currentPage,
        pageSize,
        sortType,
        sortValue
    );

    if (hasError) {
        return response(true);
    }

    try {
        const searchProducts = await getProductDataMapForOrderItems(
            orders.flatMap(orderItem => orderItem.items),
            app
        );

        customerOrders = orders.map(order => {
            order.items.forEach(item => {
                let product = {
                    ...item.product,
                    name: item.name || item.product_parent?.name,
                    url: '',
                };

                const index =
                    item[ORDER_ITEM_SKU_PRODUCT_KEY]?.sku ||
                    item[ITEM_SKU_PRODUCT_KEY]?.sku.split('-')[0];

                const searchProduct = searchProducts[index];

                if (searchProduct) {
                    product = {
                        ...item.product,
                        ...searchProduct,
                    };
                }

                item.product = product;

                return item;
            });

            return {
                ...formatCreatedDate(order, app.locale),
            };
        });

        return response(false, customerOrders, ordersCount);
    } catch (err) {
        app.$errorHandler.captureError(err, {
            [ERROR_ACTION_TAG_NAME]: 'customer.getCustomerOrdersExtData',
        });

        return response(true);
    }
};

export default {
    getFullName,
    loadOrders,
};
