import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_LOYALTY_SERVICE_CONFIG } from '@loyalty-club/async-services/loyalty/meta';
import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import { UNAUTHORIZED_CODE } from '@configs/http-codes';
import { LOYALTY_TERMS, IS_SUBSCRIBED_CODE } from '@configs/gdpr';

import { types } from './mutations';

export default {
    setAgreementsData({ commit }, { forViewId, agreementData }) {
        commit(types.SET_AGREEMENTS_DATA, { forViewId, agreementData });
    },

    setFetching({ commit }, isFetching) {
        commit(types.SET_FETCHING, isFetching);
    },

    async getGdprData(
        { dispatch },
        { viewId, forViewId, customerEmail = null }
    ) {
        dispatch('setFetching', true);

        const agreementData = await this.app.$services.gdpr.getGdprAgreementsData(
            viewId,
            customerEmail
        );

        if (agreementData?.length) {
            dispatch('setAgreementsData', {
                forViewId,
                agreementData,
            });

            dispatch('setFetching', false);

            return true;
        }

        if (process.client) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Fetching GDPR data error occured'),
                },
                { root: true }
            );
        }

        dispatch('setFetching', false);

        return false;
    },

    async checkIfLoyaltyAgreementCanBeSend({ getters }, gdprData) {
        const { decisions = [] } = gdprData || {};

        const acceptedAgreementsIds = decisions.reduce(
            (acc, { agreement_id, is_accepted }) => {
                if (is_accepted) {
                    acc.push(agreement_id);
                }

                return acc;
            },
            []
        );

        const loyaltyAgreementId = getters.allFetchedAgreements.find(
            ({ code }) => code === LOYALTY_TERMS
        )?.id;

        const newsletterAgreementId = getters.allFetchedAgreements.find(
            ({ code }) => code === IS_SUBSCRIBED_CODE
        )?.id;

        if (
            !loyaltyAgreementId ||
            !acceptedAgreementsIds.includes(loyaltyAgreementId) ||
            acceptedAgreementsIds.includes(newsletterAgreementId)
        ) {
            return;
        }

        const { httpStatus } = await this.$asyncServices.use(
            ASYNC_LOYALTY_SERVICE_CONFIG.NAME,
            ASYNC_LOYALTY_SERVICE_CONFIG.METHODS.GET_MEMBER_DATA,
            {
                withHttpStatus: true,
            }
        );

        if (httpStatus === UNAUTHORIZED_CODE) {
            throw new Error('Unauthorized user trying to join loyalty club');
        }
    },

    async setGdprData({ dispatch, rootGetters }, gdprData) {
        const showErrorMessage = () => {
            dispatch(
                'messages/addErrorMessage',
                { text: this.app.i18n.t('Change of GDPR data failed') },
                { root: true }
            );
        };

        const isLoyaltyEnabled = isLoyaltyClubEnabled(
            this.$abTests,
            rootGetters['config/storeCode'],
            this.$cookies
        );

        try {
            if (isLoyaltyEnabled) {
                await dispatch('checkIfLoyaltyAgreementCanBeSend', gdprData);
            }

            const wasCreated = await this.app.$services.gdpr.createGdprAgreementDecision(
                gdprData
            );

            if (!wasCreated) {
                showErrorMessage();
            }

            return wasCreated;
        } catch (err) {
            showErrorMessage();

            const { decisions = [], layoutHandle = '', source = '' } =
                gdprData || {};

            this.$errorHandler.captureError(
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'gdpr.setGdprData',
                },
                {
                    decisions,
                    layoutHandle,
                    source,
                    isLoyaltyClubEnabled: isLoyaltyEnabled,
                }
            );

            return false;
        }
    },
};
