<script>
export default {
    name: 'WithClickOutsideDetection',

    props: {
        handler: {
            type: String,
            default: '',
        },

        exclude: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        handler(newHandler, oldHandler) {
            if (newHandler && newHandler !== oldHandler) {
                this.bindEventListeners();
            }

            if (newHandler === null) {
                this.unbindEventListeners();
            }
        },
    },

    mounted() {
        this.bindEventListeners();
    },

    beforeDestroy() {
        this.unbindEventListeners();
    },

    methods: {
        bindEventListeners() {
            if (this.handler) {
                document.addEventListener('mousedown', this.vueOutsideClick);
                document.addEventListener('touchstart', this.vueOutsideClick);
            }
        },

        unbindEventListeners() {
            document.removeEventListener('mousedown', this.vueOutsideClick);
            document.removeEventListener('touchstart', this.vueOutsideClick);
        },

        vueOutsideClick(event) {
            event.stopPropagation();

            const vnode = this.$slots.default[0];
            const clickedOnExcludedElement = this.exclude.some(refName =>
                vnode.context.$refs[refName].contains(event.target)
            );

            if (
                !vnode.elm.contains(event.target) &&
                !clickedOnExcludedElement &&
                this.handler
            ) {
                vnode.context[this.handler](event);
            }
        },
    },

    render() {
        return this.$slots.default;
    },
};
</script>
