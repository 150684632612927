export const capitalizeFirstLetter = text => {
    if (typeof text !== 'string') {
        return text;
    }

    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const kebabToPascalCaseTransform = name =>
    name.split('-').map(capitalizeFirstLetter).join('');

export const snakeCase = string => {
    const parts =
        string?.split(/[.\-\s_]/).map(part => part.toLowerCase()) || [];

    return parts.join('_');
};
