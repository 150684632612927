import { HTTP_CLIENT_ENDPOINT_NAME_SMAUG } from '@configs/http-client';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_SMAUG };

export default httpClient => ({
    validateMerchant: payload =>
        httpClient.post('/apple-pay/session', {
            ...ENDPOINT_PARAMETER,
            payload,
        }),
});
