export const EMAIL = 'email';
export const ADDRESSES = 'addresses';
export const TELEPHONE = 'telephone';
export const FIRSTNAME = 'firstname';
export const LASTNAME = 'lastname';
export const POSTCODE = 'postcode';
export const CITY = 'city';
export const COMPANY = 'company';
export const VAT_ID = 'vat_id';
export const STREET = 'street';
export const ADDRESS_TITLE = 'address_title';
export const ADDRESS_IS_ACTIVE = 'address_is_active';
export const CUSTOMER_ADDRESS_TYPE = 'customer_address_type';
export const BUY_AS = 'buy_as';
export const COUNTRY = 'country';

export const DEFAULT_BILLING_ADDRESS = 'default_billing';
export const DEFAULT_SHIPPING_ADDRESS = 'default_shipping';

export const BILLING_ADDRESS_TYPE = '1';
export const SHIPPING_ADDRESS_TYPE = '2';

export const BUY_AS_PRIVATE_PERSON = '1';
export const BUY_AS_COMPANY = '2';

export const CUSTOMER_ADDRESS_NOT_ACTIVE = '0';
export const CUSTOMER_ADDRESS_ACTIVE = '1';

export const SAVE_IN_ADDRESS_BOOK = 'save_in_address_book';
export const COUNTRY_CODE = 'country_code';

export const HOUSE_NUMBER = 'address_house_number';

export const PASSWORD = 'password';

export const ID = 'id';
