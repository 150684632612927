export const ABTEST_COOKIE_NAME = 'abtest';

export const AB_TEST_COOKIE_VALUE_SEPARATOR = ':';
export const AB_TEST_COOKIE_VARIANT_SEPARATOR = '=';

export const AB_TEST_SEARCH_COOKIE_SUFFIX = '_SBE';

export const X_SEARCH_ABTEST_HEADER_NAME = 'x-search-abtest';

export const OCCASION_FILTER_COOKIE_NAME = 'eob_all_plp_occasion_filter';
