import {
    GUEST_ESIZEME_SCANS_KEY,
    SELECTED_GUEST_ESIZEME_SCAN_KEY,
    SELECTED_CUSTOMER_ESIZEME_SCAN_KEY,
} from '@configs/storage';

import { isScanDateValid, mergeScanLists, sortScans } from '@assets/esizeme';

import { types } from './mutations';

export default {
    setEsizemeStoreConfig({ commit }, storeConfig) {
        commit(types.SET_ESIZEME_STORE_CONFIG, storeConfig);
    },

    saveSelectedGuestEsizemeScan({ commit }, scan) {
        commit(types.SET_SELECTED_GUEST_SCAN, scan);

        this.app.$storage.setItem(SELECTED_GUEST_ESIZEME_SCAN_KEY, scan);
    },

    saveSelectedCustomerEsizemeScan({ commit }, scan) {
        commit(types.SET_SELECTED_CUSTOMER_SCAN, scan);

        this.app.$storage.setItem(SELECTED_CUSTOMER_ESIZEME_SCAN_KEY, scan);
    },

    saveSelectedEsizemeScan({ rootGetters, dispatch }, scan) {
        const action = rootGetters['customer/isLoggedIn']
            ? 'saveSelectedCustomerEsizemeScan'
            : 'saveSelectedGuestEsizemeScan';

        dispatch(action, scan);
    },

    saveGuestEsizemeScans({ commit }, scans) {
        commit(types.SET_GUEST_SCANS, scans);

        this.app.$storage.setItem(GUEST_ESIZEME_SCANS_KEY, scans);
    },

    saveCustomerEsizemeScans({ commit }, scans) {
        commit(types.SET_CUSTOMER_SCANS, scans);
    },

    clearGuestEsizemeScans({ dispatch }) {
        dispatch('saveGuestEsizemeScans', []);
    },

    clearSelectedGuestEsizemeScan({ dispatch }) {
        dispatch('saveSelectedGuestEsizemeScan', null);
    },

    clearCustomerEsizemeScans({ dispatch }) {
        dispatch('saveCustomerEsizemeScans', []);
    },

    async getStoreConfig({ dispatch }) {
        const {
            config,
            isValid,
        } = await this.app.$services.esizeme.getStoreConfig();

        dispatch('setEsizemeStoreConfig', config);

        return isValid;
    },

    setFirstScanAsSelected({ rootGetters, dispatch, state }, scans) {
        const isAnyScanSelected =
            state[
                rootGetters['customer/isLoggedIn']
                    ? 'selectedCustomerScan'
                    : 'selectedGuestScan'
            ];

        if (isAnyScanSelected) {
            return;
        }

        const validScans = scans.filter(scan => isScanDateValid(scan));

        if (!validScans.length) {
            return;
        }

        const sortedScans = sortScans(validScans);

        dispatch('saveSelectedEsizemeScan', sortedScans[0]);
    },

    setGuestEsizemeScans(
        { dispatch },
        { scans, shouldSetSelectedScan = false }
    ) {
        const currentScans =
            this.app.$storage.getItem(GUEST_ESIZEME_SCANS_KEY) || [];

        const newScans = mergeScanLists(currentScans, scans);

        dispatch('saveGuestEsizemeScans', newScans);

        if (shouldSetSelectedScan) {
            dispatch('setFirstScanAsSelected', newScans);
        }
    },

    setCustomerEsizemeScans(
        { state, dispatch },
        { scans, shouldSetSelectedScan = false, shouldRemoveScan = false }
    ) {
        const currentScans = state.customerScans || [];

        const newScans = shouldRemoveScan
            ? scans
            : mergeScanLists(currentScans, scans);

        dispatch('saveCustomerEsizemeScans', newScans);

        if (shouldSetSelectedScan) {
            dispatch('setFirstScanAsSelected', newScans);
        }
    },

    loadEsizemeGuestScans({ dispatch }) {
        const scans = this.app.$storage.getItem(GUEST_ESIZEME_SCANS_KEY);

        if (!scans?.length) {
            dispatch('saveSelectedEsizemeScan', null);

            return;
        }

        dispatch('setGuestEsizemeScans', { scans });
        dispatch('chooseSelectedEsizemeScan');
    },

    async loadEsizemeCustomerScans({ dispatch }, shouldRemoveScan) {
        dispatch('setAreCustomerScansLoading', true);

        const scans = await this.app.$services.esizeme.getCustomerScans();

        if (!scans.length) {
            dispatch('saveSelectedEsizemeScan', null);
            dispatch('clearCustomerEsizemeScans');
            dispatch('setAreCustomerScansLoading', false);

            return;
        }

        dispatch('setCustomerEsizemeScans', { scans, shouldRemoveScan });
        dispatch('chooseSelectedEsizemeScan');

        dispatch('setAreCustomerScansLoading', false);
    },

    chooseSelectedEsizemeScan({ dispatch, rootGetters, state }) {
        const isLoggedIn = rootGetters['customer/isLoggedIn'];

        const scans = state[isLoggedIn ? 'customerScans' : 'guestScans'];

        const selectedScan = this.app.$storage.getItem(
            isLoggedIn
                ? SELECTED_CUSTOMER_ESIZEME_SCAN_KEY
                : SELECTED_GUEST_ESIZEME_SCAN_KEY
        );

        if (!selectedScan) {
            return;
        }

        const scanFromList = scans.find(scan => scan.id === selectedScan.id);

        if (!scanFromList) {
            dispatch('saveSelectedEsizemeScan', null);

            return;
        }

        const isValid = isScanDateValid(scanFromList);

        dispatch('saveSelectedEsizemeScan', isValid ? scanFromList : null);
    },

    setAreCustomerScansLoading({ commit }, areCustomerScansLoading) {
        commit(types.SET_ARE_CUSTOMER_SCANS_LOADING, areCustomerScansLoading);
    },
};
