export default {
    flatCategoryTree: state => {
        const maxDisplayLevel = 1;
        let trees = [];
        let maxLevel = 1;

        const flattenTree = ({ children, ...root }, level = 1) => {
            maxLevel = Math.max(maxLevel, level);

            trees.push({
                ...root,
                level,
                productsCount: root.products_count,
            });

            children?.forEach(child => flattenTree(child, level + 1));
        };

        try {
            const {
                navigation: { tree: { items = [] } = {} },
            } = state;

            items.forEach(item => flattenTree(item));

            if (maxLevel > maxDisplayLevel) {
                const levelDiff = maxLevel - maxDisplayLevel;

                trees = trees.filter(category => {
                    if (category.level > levelDiff) {
                        category.levelOriginal = category.level;
                        category.level -= levelDiff;

                        return true;
                    }

                    return false;
                });
            } else if (maxLevel > 1) {
                trees = trees.filter(category => {
                    if (category.level > 1) {
                        category.levelOriginal = category.level;
                        category.level -= 1;

                        return true;
                    }

                    return false;
                });
            }
        } catch (err) {
            this.app.$errorHandler.captureError(err);
        }

        return trees;
    },
};
