import { OMNIBUS_OCCASION_TRESHOLD } from '@configs/discount';

import { TYPE_DISCOUNT, TYPE_OCCASION } from '@types/Badge';

import { formatPrice } from '@assets/price';
import { round } from '@assets/round';

export const isDiscounted = (regularPrice, finalPrice) => {
    return !(regularPrice === finalPrice || !regularPrice || !finalPrice);
};

export const getDiscount = (regularPrice, finalPrice) => {
    if (!isDiscounted(regularPrice, finalPrice)) {
        return null;
    }

    return `${Math.ceil(100 - (100 * finalPrice) / regularPrice)}%`;
};

export const getDiscountAmountAndFormatted = (
    regularPrice,
    finalPrice,
    locale,
    currency
) => {
    if (!isDiscounted(regularPrice, finalPrice)) {
        return {
            amount: null,
            formatted: null,
        };
    }

    const amount = round(regularPrice - finalPrice, 2);
    const formatted = formatPrice(amount, locale, currency);

    return {
        amount,
        formatted,
    };
};

export const getDiscountBadge = ({
    isGoodPriceBadgeEnabled,
    isOccasionBadgeEnabled,
    isOmnibusStrict,
    omnibusAmount,
    regularAmount,
    promotionalAmount,
    discount,
    $t,
}) => {
    let badgeType = null;
    let badgeContent = null;

    const discountAmount = parseInt(discount, 10);

    if (discountAmount >= OMNIBUS_OCCASION_TRESHOLD) {
        badgeType = TYPE_DISCOUNT;
        badgeContent = `-${discountAmount}%`;
    }

    if (!isOmnibusStrict || discountAmount >= OMNIBUS_OCCASION_TRESHOLD) {
        return { badgeType, badgeContent };
    }

    if (discountAmount > 0 && isOccasionBadgeEnabled) {
        badgeType = TYPE_OCCASION;
        badgeContent = $t('Occasion');
    } else if (
        isGoodPriceBadgeEnabled &&
        omnibusAmount &&
        omnibusAmount <= promotionalAmount &&
        promotionalAmount < regularAmount
    ) {
        badgeType = 'Good price';
        badgeContent = $t('Good price');
    }

    return { badgeType, badgeContent };
};

export default {
    getDiscount,
    getDiscountAmountAndFormatted,
    getDiscountBadge,
};
