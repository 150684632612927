export const NAME = 'newsletterSms';

export const UNSUBSCRIBE = 'unsubscribe';

export const ASYNC_NEWSLETTER_SMS_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        UNSUBSCRIBE,
    },
};
