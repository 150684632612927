import { DEFAULT_STORE_CODE } from '@configs/defaults/store-codes';

const DEFAULT_STORE_CODE_RMA_REPLACEMENT = 'pl';

const BRAND_NAME = 'eobuwie';

const X_MARKET = 'X-Market';
const X_BRAND = 'X-Brand';
const ACCEPT_LANGUAGE = 'Accept-Language';

export const getRmaHeaders = storeCode => {
    const marketCodeToPass =
        storeCode === DEFAULT_STORE_CODE
            ? DEFAULT_STORE_CODE_RMA_REPLACEMENT
            : storeCode;

    return {
        [ACCEPT_LANGUAGE]: marketCodeToPass,
        [X_MARKET]: marketCodeToPass,
        [X_BRAND]: BRAND_NAME,
    };
};
