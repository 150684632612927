import RMA_SERVICES_NAMESPACE from '@rma/async-services/namespace';

export const NAME = `${RMA_SERVICES_NAMESPACE}/guest`;

export const GET_GUEST_TOKEN = 'GET_GUEST_TOKEN';

export const ASYNC_GUEST_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_GUEST_TOKEN,
    },
};
