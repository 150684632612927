export const TYPE_BUTTON = 'button';
export const TYPE_SUBMIT = 'submit';
export const TYPE_RESET = 'reset';

export const THEME_PRIMARY = 'primary';
export const THEME_SECONDARY = 'secondary';
export const THEME_TERTIARY = 'tertiary';
export const THEME_INITIAL = 'initial';
export const THEME_PURE = 'pure';

export const SIZE_BIG = 'big';
export const SIZE_NORMAL = 'normal';
export const SIZE_SMALL = 'small';

export const TYPES = { TYPE_BUTTON, TYPE_SUBMIT, TYPE_RESET };

export const THEMES = {
    THEME_PRIMARY,
    THEME_SECONDARY,
    THEME_TERTIARY,
    THEME_INITIAL,
    THEME_PURE,
};

export const ICON_POSITION_LEFT = 'left';
export const ICON_POSITION_RIGHT = 'right';

export const ICON_POSITIONS = {
    ICON_POSITION_LEFT,
    ICON_POSITION_RIGHT,
};

export const SIZES = { SIZE_BIG, SIZE_NORMAL, SIZE_SMALL };
export const COLOR_GREEN = 'green';
export const COLOR_BLACK = 'black';
export const COLOR_WHITE = 'white';
export const COLOR_BLUE = 'blue';
export const COLOR_BLUE3 = 'blue3';
export const COLOR_RED = 'red';
export const COLOR_WHITE_BLUE = 'white-blue';
export const COLOR_GRAY = 'gray';

export const COLORS = {
    COLOR_GREEN,
    COLOR_BLACK,
    COLOR_WHITE,
    COLOR_RED,
    COLOR_BLUE,
    COLOR_BLUE3,
    COLOR_WHITE_BLUE,
    COLOR_GRAY,
};
