<template>
    <footer class="checkout-footer">
        <CheckoutFooterSupportLinks />
        <CheckoutFooterLinks />
    </footer>
</template>

<script>
import CheckoutFooterLinks from '@molecules/CheckoutFooterLinks/CheckoutFooterLinks';
import CheckoutFooterSupportLinks from '@molecules/CheckoutFooterSupportLinks/CheckoutFooterSupportLinks';

export default {
    name: 'CheckoutFooter',
    components: {
        CheckoutFooterLinks,
        CheckoutFooterSupportLinks,
    },
};
</script>

<style lang="scss" scoped>
.checkout-footer {
    @apply flex flex-col gap-y-3 pb-4 pt-12p bg-gray8;

    @screen lg {
        @apply gap-y-4 pt-5 pb-56p;
    }
}
</style>
