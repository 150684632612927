import {
    STORAGE_PREFIX,
    STORAGE_EXPIRES_KEY,
    STORAGE_EXPIRES_DATA_KEY,
    STORAGE_TYPES,
    STORAGE_TYPE_DEFAULT,
} from '@configs/storage';

function chooseStorage(type) {
    return Object.values(STORAGE_TYPES).includes(type)
        ? type
        : STORAGE_TYPE_DEFAULT;
}

function checkIfBrowserStorageIsSupported() {
    let canUseStorage = true;

    try {
        const key = `${STORAGE_PREFIX}TEST`;

        window.localStorage.setItem(key, 'test');

        const testItem = window.localStorage.getItem(key);

        if (testItem) {
            window.localStorage.removeItem(key);
        }
    } catch {
        canUseStorage = false;
    }

    return canUseStorage;
}

export default function createStorage(errorHandler) {
    const isSupported = checkIfBrowserStorageIsSupported();

    return {
        isSupported,
        storage: {
            createKeyWithPrefix(key) {
                return `${STORAGE_PREFIX}${key}`;
            },
            getItem(
                key,
                storageType = STORAGE_TYPE_DEFAULT,
                withPrefix = true
            ) {
                if (!isSupported) {
                    return null;
                }

                const storageKey = withPrefix
                    ? this.createKeyWithPrefix(key)
                    : key;
                const storage = window[chooseStorage(storageType)];

                try {
                    const item = storage.getItem(storageKey);
                    const parsed = JSON.parse(item);

                    if (parsed && parsed instanceof Object) {
                        const { [STORAGE_EXPIRES_KEY]: expires } = parsed;

                        if (expires && !Number.isNaN(expires)) {
                            const expired = this.isItemExpired(expires);

                            if (expired) {
                                storage.removeItem(storageKey);

                                return null;
                            }
                        }
                    }

                    return parsed;
                } catch (error) {
                    errorHandler.captureError(
                        error,
                        {},
                        {
                            key,
                            storageType,
                        }
                    );

                    return null;
                }
            },
            setItem(
                key,
                value,
                expires = null,
                storageType = STORAGE_TYPE_DEFAULT,
                withPrefix = true,
                withStringify = true
            ) {
                if (!isSupported) {
                    return;
                }

                const storageKey = withPrefix
                    ? this.createKeyWithPrefix(key)
                    : key;
                const storage = window[chooseStorage(storageType)];

                try {
                    if (expires && !Number.isNaN(Number(expires))) {
                        storage.setItem(
                            storageKey,
                            JSON.stringify({
                                [STORAGE_EXPIRES_KEY]: expires,
                                [STORAGE_EXPIRES_DATA_KEY]: value,
                            })
                        );
                    } else {
                        const valueToSave = withStringify
                            ? JSON.stringify(value)
                            : value;

                        storage.setItem(storageKey, valueToSave);
                    }

                    return value;
                } catch (error) {
                    errorHandler.captureError(
                        error,
                        {},
                        {
                            key,
                            storageType,
                        }
                    );

                    return null;
                }
            },
            removeItem(
                key,
                storageType = STORAGE_TYPE_DEFAULT,
                withPrefix = true
            ) {
                if (!isSupported) {
                    return;
                }

                const storageKey = withPrefix
                    ? this.createKeyWithPrefix(key)
                    : key;
                const itemExist =
                    this.getItem(key, storageType, withPrefix) !== null;

                if (itemExist) {
                    try {
                        window[chooseStorage(storageType)].removeItem(
                            storageKey
                        );
                    } catch (error) {
                        errorHandler.captureError(
                            error,
                            {},
                            {
                                key,
                                storageType,
                            }
                        );
                    }
                }
            },
            isItemExpired(expires) {
                const current = new Date().getTime();

                return current > expires;
            },
        },
    };
}
