export const types = {
    SET_AGREEMENTS_DATA: 'SET_AGREEMENTS_DATA',
    SET_FETCHING: 'SET_FETCHING',
};

const mutations = {
    [types.SET_AGREEMENTS_DATA](state, { forViewId, agreementData }) {
        state[forViewId] = agreementData;
    },
    [types.SET_FETCHING](state, isFetching) {
        state.isFetching = isFetching;
    },
};

export default mutations;
