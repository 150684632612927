<script>
import cookies from '@services/cookies';

export default {
    name: 'CheckCookieExist',

    props: {
        /**
         * Name of cookie to check.
         */
        cookieName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isCookieExist: null,
        };
    },

    mounted() {
        this.isCookieExist = !!this.$cookies.get(
            cookies.createCookieWithPrefix(this.cookieName)
        );
    },

    render() {
        return this.$scopedSlots.default({
            isCookieExist: this.isCookieExist,
        });
    },
};
</script>
