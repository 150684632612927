export const types = {
    SET_NAVIGATION_CATEGORIES: 'SET_NAVIGATION_CATEGORIES',
    SET_CURRENT_MENU_NAV_PATH: 'SET_CURRENT_MENU_NAV_PATH',
    TOGGLE_DESKTOP_MENU: 'TOGGLE_DESKTOP_MENU',
    SET_IS_SEARCH_INPUT_ACTIVE: 'SET_IS_SEARCH_INPUT_ACTIVE',
    SET_IS_SORT_FILTER_PANEL_ACTIVE: 'SET_IS_SORT_FILTER_PANEL_ACTIVE',
};

const mutations = {
    [types.SET_NAVIGATION_CATEGORIES](
        state,
        { rootCategoryChildren, rootCategoryPimSlug }
    ) {
        state.categories = rootCategoryChildren;
        state.rootCategoryPimSlug = rootCategoryPimSlug;
    },

    [types.SET_IS_SEARCH_INPUT_ACTIVE](state, isSearchInputActive) {
        state.isSearchInputActive = isSearchInputActive;
    },

    [types.TOGGLE_DESKTOP_MENU](state, isMenuOpen) {
        state.isDesktopMenuOpen = isMenuOpen;
    },
};

export default mutations;
