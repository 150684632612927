<template>
    <div class="header-top-bar">
        <div class="top-bar-content">
            <BaseLink
                v-if="isLoyaltyClubEnabled && loyaltyClubLink.path"
                :path="loyaltyClubLink.path"
                :title="loyaltyClubLink.title"
                class="link loyalty-link"
            >
                <div class="link-content">
                    <span>{{ loyaltyClubLink.content }}</span>
                    <Badge class="link-badge" :variant="BADGE_NEW">
                        {{ $t('New') }}
                    </Badge>
                </div>
            </BaseLink>

            <ul class="header-helpful-links">
                <li
                    v-for="{
                        title,
                        path,
                        content,
                        component,
                        isOpenedInNewTab,
                    } in links"
                    :key="path"
                    class="link-item"
                >
                    <component
                        :is="component"
                        :path="path"
                        :title="title"
                        :target="isOpenedInNewTab ? '_blank' : null"
                        class="link"
                    >
                        {{ content }}
                    </component>
                </li>
                <LanguageSwitcher :is-dropdown="true" />
            </ul>
        </div>

        <Divider class="top-bar-divider" />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    STATIONARY_SHOP,
    MOBILE_APP_LINK_HEADER,
    BLOG_LINK,
} from '@localeConfig/keys';

import {
    CUSTOMER_LOYALTY_CLUB_NAME,
    GUEST_LOYALTY_CLUB_NAME,
} from '@router/names';

import { isHttpLink } from '@assets/link';
import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import { Divider } from '@eobuwie-ui/components/Divider/v1';
import { Badge, BADGE_VARIANTS } from '@eobuwie-ui/components/Badge/v1';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    name: 'HeaderTopBar',

    components: {
        Badge,
        Divider,
        BaseLink,
        BaseLinkStatic,
        LanguageSwitcher: () => ({
            component: import(
                /* webpackChunkName: "language-switcher" */
                '@molecules/LanguageSwitcher/LanguageSwitcher'
            ),
        }),
    },

    computed: {
        ...mapCustomerGetters(['isLoggedIn']),
        ...mapConfigGetters(['localeConfigByKey', 'storeCode']),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        loyaltyClubLink() {
            return {
                path:
                    this.$router.resolve({
                        name: this.isLoggedIn
                            ? CUSTOMER_LOYALTY_CLUB_NAME
                            : GUEST_LOYALTY_CLUB_NAME,
                    })?.route?.path || '',

                content: this.$tLoyalty('Eobuwie club'),
                title: this.$tLoyalty('Eobuwie club'),
            };
        },

        links() {
            const blogPath = this.localeConfigByKey(BLOG_LINK);
            const stationaryShopPath = this.localeConfigByKey(STATIONARY_SHOP);
            const mobileAppPath = this.localeConfigByKey(
                MOBILE_APP_LINK_HEADER
            );

            return [
                {
                    path: blogPath,
                    content: this.$t('Blog'),
                    title: this.$t('Blog'),
                },
                {
                    path: mobileAppPath,
                    content: this.$t('Download the app'),
                    title: this.$t('Download the app'),
                },
                {
                    path: stationaryShopPath,
                    content: this.$t('Find the store'),
                    title: this.$t('Find the store'),
                },
                {
                    path: this.$tPath('help-center'),
                    content: this.$t('Help'),
                    title: this.$t('Help'),
                },
            ]
                .filter(({ path }) => path)
                .map(link => {
                    const isStaticLink = isHttpLink(link.path);

                    return {
                        ...link,
                        component: isStaticLink ? BaseLinkStatic : BaseLink,
                        isOpenedInNewTab: isStaticLink,
                    };
                });
        },
    },

    beforeCreate() {
        this.BADGE_NEW = BADGE_VARIANTS.NEW;
    },
};
</script>

<style scoped lang="scss">
.header-top-bar {
    @apply h-ui-11;

    .top-bar-content {
        @apply px-ui-7 mx-auto flex justify-end h-ui-11 items-center;
        max-width: theme('customVariables.containerContent.maxWidth');

        .link {
            @apply font-ui-body-s;

            &:hover {
                @apply no-underline;
            }

            .link-content {
                @apply flex items-center;

                .link-badge {
                    @apply ml-ui-2;
                }
            }
        }

        .loyalty-link {
            @apply mr-ui-auto;
        }
    }

    .top-bar-divider {
        @apply relative top-[-2px];
    }

    .header-helpful-links {
        @apply flex items-center;

        .link-item {
            @apply flex items-center;

            &:not(:last-child) {
                &:after {
                    @apply content-['·'] mx-ui-2;
                }
            }
        }
    }
}
</style>
