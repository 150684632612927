<template>
    <div>
        <MarketingBarCart v-if="showMarketingBarCart" />

        <MarketingBarTimer
            v-else-if="isMarketingBarTimerVisible"
            v-bind="marketingBarConfigToUse"
            @expired="closeMarketingBarTimer()"
        />

        <MarketingBarDiscountCode
            v-else-if="marketingBarDiscountCodeConfig"
            v-bind="marketingBarConfigToUse"
        />

        <MarketingBarTextAndLinks
            v-else-if="marketingBarTextAndLinksConfig && marketingBarLinks"
            v-bind="marketingBarConfigToUse"
        >
            <MarketingBarLink
                v-for="(linkProps, index) in marketingBarLinks"
                v-bind="linkProps"
                :key="index"
            />
        </MarketingBarTextAndLinks>

        <MarketingBarDefault
            v-else-if="marketingBarDefaultConfig"
            v-bind="marketingBarConfigToUse"
        />
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { MARKETING_BAR_VIEW } from '@analytics-types/Events';
import { LABELS } from '@analytics-types/Labels';

import {
    MARKETING_BAR_CART_WHITELIST,
    CMS_MARKETING_BAR_GLOBAL_ID,
} from '@configs/marketing-bar';

import {
    CMS_MARKETING_BAR_DEFAULT,
    CMS_MARKETING_BAR_DISCOUNT_CODE,
    CMS_MARKETING_BAR_TEXT_AND_LINKS,
    CMS_MARKETING_BAR_TIMER,
} from '@modules/page-builder/page-builder.config';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import DateHelper from '@models/DateHelper/DateHelper';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'MarketingBarDataProvider',

    components: {
        MarketingBarLink: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-link" */
                '@marketing-bar-components/molecules/MarketingBarLink/MarketingBarLink'
            ),
        }),

        MarketingBarCart: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-cart" */
                '@marketing-bar-components/organisms/MarketingBarCart/MarketingBarCart'
            ),
        }),

        MarketingBarDefault: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-default" */
                '@marketing-bar-components/organisms/MarketingBarDefault/MarketingBarDefault'
            ),
        }),

        MarketingBarDiscountCode: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-discount" */
                // eslint-disable-next-line max-len
                '@marketing-bar-components/organisms/MarketingBarDiscountCode/MarketingBarDiscountCode'
            ),
        }),

        MarketingBarTextAndLinks: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-text-and-links" */
                // eslint-disable-next-line max-len
                '@marketing-bar-components/organisms/MarketingBarTextAndLinks/MarketingBarTextAndLinks'
            ),
        }),

        MarketingBarTimer: () => ({
            component: import(
                /* webpackChunkName: "marketing-bar-timer" */
                '@marketing-bar-components/organisms/MarketingBarTimer/MarketingBarTimer'
            ),
        }),
    },

    data() {
        return {
            isMarketingBarTimerVisible: false,
        };
    },

    computed: {
        ...mapState(['isMobile', 'isMobileEvaluated']),
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapConfigGetters(['timezone']),

        showMarketingBarCart() {
            return MARKETING_BAR_CART_WHITELIST.includes(this.$route.name);
        },

        marketingBarConfig() {
            return this.getMarketingBarConfig();
        },

        marketingBarDefaultConfig() {
            return this.getMarketingBarComponentConfig(
                this.MARKETING_BAR_DEFAULT,
                this.marketingBarConfig
            );
        },

        marketingBarDiscountCodeConfig() {
            return this.getMarketingBarComponentConfig(
                this.MARKETING_BAR_DISCOUNT_CODE,
                this.marketingBarConfig
            );
        },

        marketingBarTextAndLinksConfig() {
            return this.getMarketingBarComponentConfig(
                this.MARKETING_BAR_TEXT_AND_LINKS,
                this.marketingBarConfig,
                true
            );
        },

        marketingBarTimerConfig() {
            return this.getMarketingBarComponentConfig(
                this.MARKETING_BAR_TIMER,
                this.marketingBarConfig
            );
        },

        marketingBarConfigToUse() {
            if (this.isMarketingBarTimerVisible) {
                return this.marketingBarTimerConfig;
            }

            if (this.marketingBarDiscountCodeConfig) {
                return this.marketingBarDiscountCodeConfig;
            }

            if (this.marketingBarTextAndLinksConfig && this.marketingBarLinks) {
                return this.marketingBarTextAndLinksConfig.props;
            }

            if (this.marketingBarDefaultConfig) {
                return this.marketingBarDefaultConfig;
            }

            return null;
        },

        marketingBarText() {
            const { textMobile, textDesktop } = this.marketingBarConfigToUse;

            return this.isMobile ? textMobile : textDesktop;
        },

        marketingBarLinks() {
            const {
                default: links,
            } = this.marketingBarTextAndLinksConfig.slots;

            if (!links?.length) {
                return null;
            }

            return links
                .map(link => link.props)
                .filter(({ path, label }) => path && label);
        },

        marketingBarLinksLabels() {
            return Object.values(this.marketingBarLinks)
                .map(({ label }) => label)
                .join(',');
        },
    },

    mounted() {
        this.onWindowLoad = () => {
            this.emitMarketingBarViewEvents();
        };

        window.addEventListener('load', this.onWindowLoad);
    },

    beforeDestroy() {
        window.removeEventListener('load', this.onWindowLoad);
    },

    beforeCreate() {
        this.MARKETING_BAR_DEFAULT = CMS_MARKETING_BAR_DEFAULT;
        this.MARKETING_BAR_DISCOUNT_CODE = CMS_MARKETING_BAR_DISCOUNT_CODE;
        this.MARKETING_BAR_TEXT_AND_LINKS = CMS_MARKETING_BAR_TEXT_AND_LINKS;
        this.MARKETING_BAR_TIMER = CMS_MARKETING_BAR_TIMER;
        this.LABELS = LABELS;
    },

    created() {
        const { date } = this.marketingBarTimerConfig || {};

        if (!date) {
            return;
        }

        const dateNormalized = date
            .replace('T', ' ')
            .slice(0, FORMAT_YYYYMMDD_HHMM.length);

        const dateHelper = new DateHelper(this.timezone, FORMAT_YYYYMMDD_HHMM);

        const currentDate = dateHelper.createCurrentDateInTimezone();
        const expireDate = DateHelper.getDateFromFormat(
            dateNormalized,
            FORMAT_YYYYMMDD_HHMM,
            this.timezone
        );

        this.isMarketingBarTimerVisible =
            !expireDate.invalid &&
            !DateHelper.isCurrentDateAfterPassed(expireDate, currentDate);
    },

    methods: {
        getMarketingBarConfig() {
            const marketingBlock = this.staticBlockById(
                CMS_MARKETING_BAR_GLOBAL_ID
            );

            if (!marketingBlock || Object.keys(marketingBlock).length === 0) {
                return [];
            }

            try {
                const { content_json: contentJSON } = marketingBlock;

                const [
                    {
                        slots: { default: config },
                    },
                ] = JSON.parse(contentJSON);

                return config;
            } catch (err) {
                this.$errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'cms.getMarketingBarConfig',
                    },
                    { CMS_MARKETING_BAR_GLOBAL_ID }
                );

                return [];
            }
        },

        getMarketingBarComponentConfig(component, config, withSlots = false) {
            const componentConfig = config.filter(
                item => item?.name === component
            );

            if (!componentConfig.length) {
                return null;
            }

            const [{ props = {}, slots = {} }] = componentConfig;

            if (!withSlots) {
                return props;
            }

            return {
                props,
                slots,
            };
        },

        closeMarketingBarTimer() {
            this.isMarketingBarTimerVisible = false;
        },

        emitMarketingBarViewEvent(barName, targetValue) {
            this.$analytics.emit(MARKETING_BAR_VIEW, {
                barName,
                barText: this.marketingBarText,
                targetValue,
            });
        },

        emitMarketingBarViewEvents() {
            let barName = '';
            let targetValue = '';

            if (this.isMarketingBarTimerVisible) {
                barName = LABELS.TIMER;
                targetValue = LABELS.NULL;
            } else if (this.marketingBarDiscountCodeConfig) {
                barName = LABELS.PROMO_CODE;
                targetValue = this.marketingBarDiscountCodeConfig.code;
            } else if (
                this.marketingBarTextAndLinksConfig &&
                this.marketingBarLinks
            ) {
                barName = LABELS.TEXT_LINK;
                targetValue = this.marketingBarLinksLabels;
            }

            if (barName && targetValue) {
                this.emitMarketingBarViewEvent(barName, targetValue);
            }
        },
    },
};
</script>
