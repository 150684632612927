import {
    SERVICE_INVALID_TYPE_MESSAGE,
    SERVICE_METHOD_INVALID_TYPE_MESSAGE,
    CREATE_MESSAGE_INVALID_ARGUMENTS,
    ERROR_MESSAGE_PARAM_NOT_EXISTS_MESSAGE,
    SERVICE_NOT_EXISTS,
    CANNOT_USE_METHOD_ON_SERVICE_MESSAGE,
    INVALID_SERVICE_CONFIG,
    INVALID_RUNTIME_SERVICES_HELPERS,
    INVALID_USE_METHOD_ARGUMENTS,
    CREATE_SERVICE_FUNCTION_IS_NOT_DEFAULT_EXPORT,
    SERVICE_IS_ALREADY_REGISTERED,
    INVALID_SERVICE_REGISTRATION_INPUT,
    INVALID_SERVICE_NAME,
} from '@modules/async-services/src/types/error-messages';
import {
    LOG_DEBUG_MODE_ENABLED,
    LOG_SERVICE_IS_LOADING,
    LOG_SERVICE_INITIALIZED,
    LOG_REQUEST_MESSAGE,
    LOG_SERVICE_REGISTRATION,
} from '@modules/async-services/src/types/log-messages';
import {
    DEBUG_MODE_ENABLED,
    SERVICE_LOADING,
    SERVICE_INITIALIZED,
    METHOD_CALLED,
    SERVICE_CONFIG_ADDED,
} from '@modules/async-services/src/types/log';

import { isObject } from '@modules/async-services/src/assets/object';
import { validateServicesConfig } from '@modules/async-services/src/assets/validate-services-config';

export default class AsyncServicesManager {
    static NAMESPACED_SERVICE_METHOD_STRING_SPLIT_SIGN = '/';
    static LOG_REQUEST_STYLES = 'color: blue; font-weight: bold';
    static ERROR_PARAMS_REPLACE_REG_EXP = /(%[0-9]*%)/gm;
    static MESSAGE_PREFIX = '[AsyncServicesManager]';

    constructor(
        servicesConfig,
        runtimeServicesHelpers = {},
        debugModeEnabled = false
    ) {
        if (!validateServicesConfig(servicesConfig)) {
            throw AsyncServicesManager.createError(INVALID_SERVICE_CONFIG);
        }

        if (!isObject(runtimeServicesHelpers)) {
            throw AsyncServicesManager.createError(
                INVALID_RUNTIME_SERVICES_HELPERS
            );
        }

        this.servicesConfig = { ...servicesConfig };
        this.runtimeServicesHelpers = runtimeServicesHelpers;

        this.debugModeEnabled = debugModeEnabled;

        this.loadedServices = new Map();
        this.pendingServices = new Map();
    }

    static canUseServiceMethod(service, methodName) {
        if (!isObject(service)) {
            throw AsyncServicesManager.createError(
                SERVICE_INVALID_TYPE_MESSAGE
            );
        }

        if (!methodName || typeof methodName !== 'string') {
            throw AsyncServicesManager.createError(
                SERVICE_METHOD_INVALID_TYPE_MESSAGE
            );
        }

        return typeof service[methodName] === 'function';
    }

    static createMessage(message, params = []) {
        if (!message || typeof message !== 'string' || !Array.isArray(params)) {
            throw AsyncServicesManager.createError(
                CREATE_MESSAGE_INVALID_ARGUMENTS
            );
        }

        let msg = message;

        if (params.length) {
            let currentParamIndex = 0;

            msg = msg.replace(
                AsyncServicesManager.ERROR_PARAMS_REPLACE_REG_EXP,
                () => {
                    const param = params[currentParamIndex];

                    if (!param) {
                        throw AsyncServicesManager.createError(
                            ERROR_MESSAGE_PARAM_NOT_EXISTS_MESSAGE
                        );
                    }

                    currentParamIndex += 1;

                    return param;
                }
            );
        }

        return `${AsyncServicesManager.MESSAGE_PREFIX}: ${msg}`;
    }

    static createError(message, params = []) {
        return new Error(AsyncServicesManager.createMessage(message, params));
    }

    async getService(serviceName) {
        if (this.loadedServices.has(serviceName)) {
            return this.loadedServices.get(serviceName);
        }

        const loadService = this.servicesConfig[serviceName];

        if (typeof loadService !== 'function') {
            throw AsyncServicesManager.createError(SERVICE_NOT_EXISTS, [
                serviceName,
            ]);
        }

        let loadingServicePromise = null;

        const isServiceCurrentlyLoading = this.pendingServices.has(serviceName);

        if (isServiceCurrentlyLoading) {
            loadingServicePromise = this.pendingServices.get(serviceName);
        } else {
            loadingServicePromise = loadService();

            if (this.debugModeEnabled) {
                AsyncServicesManager.log(SERVICE_LOADING, { serviceName });
            }

            this.pendingServices.set(serviceName, loadingServicePromise);
        }

        try {
            const { default: loadedService } = await loadingServicePromise;

            if (!loadedService) {
                throw AsyncServicesManager.createError(
                    CREATE_SERVICE_FUNCTION_IS_NOT_DEFAULT_EXPORT
                );
            }

            const isAlreadyInitializedByPreviousRequest = this.loadedServices.has(
                serviceName
            );

            if (isAlreadyInitializedByPreviousRequest) {
                return this.loadedServices.get(serviceName);
            }

            const initializedService = loadedService(
                this.runtimeServicesHelpers
            );

            this.loadedServices.set(serviceName, initializedService);

            if (this.debugModeEnabled) {
                AsyncServicesManager.log(SERVICE_INITIALIZED, { serviceName });
            }

            return initializedService;
        } catch (error) {
            this.pendingServices.delete(serviceName);

            throw error;
        }
    }

    static log(
        type,
        { serviceName = '', serviceMethodName = '', payload } = {}
    ) {
        let message = '';

        const { LOG_REQUEST_STYLES, createMessage } = AsyncServicesManager;

        switch (type) {
            case DEBUG_MODE_ENABLED:
                message = createMessage(LOG_DEBUG_MODE_ENABLED);
                break;

            case SERVICE_LOADING:
                message = createMessage(LOG_SERVICE_IS_LOADING, [serviceName]);
                break;

            case SERVICE_INITIALIZED:
                message = createMessage(LOG_SERVICE_INITIALIZED, [serviceName]);
                break;

            case METHOD_CALLED:
                message = createMessage(LOG_REQUEST_MESSAGE, [
                    serviceName,
                    serviceMethodName,
                ]);
                break;
            case SERVICE_CONFIG_ADDED:
                message = createMessage(LOG_SERVICE_REGISTRATION, [
                    serviceName,
                ]);
                break;
            default:
                break;
        }

        const messageWithStyles = `%c${message}`;

        if (typeof payload === 'undefined') {
            console.log(messageWithStyles, LOG_REQUEST_STYLES);

            return;
        }

        console.log(messageWithStyles, LOG_REQUEST_STYLES, payload);
    }

    async use(serviceName, serviceMethodName, payload) {
        if (
            !serviceName ||
            typeof serviceName !== 'string' ||
            !serviceMethodName ||
            typeof serviceMethodName !== 'string'
        ) {
            throw AsyncServicesManager.createError(
                INVALID_USE_METHOD_ARGUMENTS
            );
        }

        let service = null;

        if (!this.loadedServices.has(serviceName)) {
            service = await this.getService(serviceName);
        } else {
            service = this.loadedServices.get(serviceName);
        }

        if (
            AsyncServicesManager.canUseServiceMethod(service, serviceMethodName)
        ) {
            if (this.debugModeEnabled) {
                AsyncServicesManager.log(METHOD_CALLED, {
                    serviceName,
                    serviceMethodName,
                    payload,
                });
            }

            return service[serviceMethodName](payload);
        }

        throw AsyncServicesManager.createError(
            CANNOT_USE_METHOD_ON_SERVICE_MESSAGE,
            [serviceMethodName, serviceName]
        );
    }

    registerService(serviceName, serviceLoadFn) {
        if (!(typeof serviceName === 'string' && serviceName.length > 0)) {
            throw AsyncServicesManager.createError(INVALID_SERVICE_NAME);
        }

        if (this.servicesConfig[serviceName]) {
            throw AsyncServicesManager.createError(
                SERVICE_IS_ALREADY_REGISTERED
            );
        }

        if (typeof serviceLoadFn !== 'function') {
            throw AsyncServicesManager.createError(
                INVALID_SERVICE_REGISTRATION_INPUT
            );
        }

        this.servicesConfig = {
            ...this.servicesConfig,
            [serviceName]: serviceLoadFn,
        };

        if (this.debugModeEnabled) {
            AsyncServicesManager.log(SERVICE_CONFIG_ADDED, { serviceName });
        }
    }
}
