export const ADDRESS_TYPE_BILLING = 'billing';
export const ADDRESS_TYPE_SHIPPING = 'shipping';

export const ADDRESS_TYPES = [ADDRESS_TYPE_BILLING, ADDRESS_TYPE_SHIPPING];

export const BILLING = 'billing';
export const SHIPPING = 'shipping';
export const CREATE_ACCOUNT = 'create-account';

export const CHECKOUT_CUSTOMER_FORMS = {
    BILLING,
    SHIPPING,
    CREATE_ACCOUNT,
};

export default {
    ADDRESS_TYPE_BILLING,
    ADDRESS_TYPE_SHIPPING,
    ADDRESS_TYPES,
};
