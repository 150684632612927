export const NAME = 'seomatic';

export const GET_SEO = 'getSeo';

export const ASYNC_SEOMATIC_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_SEO,
    },
};
