import { SHOW_LAST_ITEMS_INFO_QUANTITY } from '@configs/checkout';

export const isVariantRunningOutOfStock = (
    { stock_quantity } = { stock_quantity: null }
) => {
    if (!stock_quantity || typeof stock_quantity !== 'number') {
        return false;
    }

    return stock_quantity <= SHOW_LAST_ITEMS_INFO_QUANTITY;
};

export const sortProductVariantsBySortOrder = (variant1, variant2) => {
    const sortOrder1 = Number.isInteger(variant1.sort_order)
        ? variant1.sort_order
        : 99999;
    const sortOrder2 = Number.isInteger(variant2.sort_order)
        ? variant2.sort_order
        : 99999;

    return sortOrder1 - sortOrder2;
};

export const getProductVariantsBySortOrder = (productVariants = {}) => {
    return Object.entries(productVariants)
        .map(([sizeKey, variant]) => {
            return { ...variant, sizeKey };
        })
        .sort(sortProductVariantsBySortOrder);
};

export const getProductSizes = (
    variants,
    { showNotifyAboutAvailability, chosenSize }
) => {
    return Object.values(variants).map(variant => {
        const isOutOfStock = variant.stock_quantity === 0;

        return {
            ...variant,
            isOutOfStock,
            hasNotifyAboutAvailability:
                isOutOfStock && showNotifyAboutAvailability,
            isSelected: chosenSize === variant.size,
            isRunningOutOfStock: isVariantRunningOutOfStock(variant),
        };
    });
};
