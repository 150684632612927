import {
    INSIDER_PARTNER_NAME,
    INSIDER_PARTNER_ID,
} from '@modules/insider/configs/insider';

const insiderObjectScriptId = 'insider-object-script';
const insiderScriptId = 'insider-init-script';

const removeElementById = id => {
    const element = document.getElementById(id);

    if (element) {
        element.remove();
    }
};

export const insiderScripts = (isCheckoutSuccessPage = false) => {
    if (isCheckoutSuccessPage) {
        removeElementById(insiderObjectScriptId);

        const insiderObjectScript = document.createElement('script');

        insiderObjectScript.id = insiderObjectScriptId;
        insiderObjectScript.innerHTML = `
                window.insider_object = window.insider_object || {};
                window.insider_object.page = {
                    type: 'Confirmation',
                };
            `;

        document.head.appendChild(insiderObjectScript);
    }

    removeElementById(insiderScriptId);

    const insiderScript = document.createElement('script');

    insiderScript.async = true;
    insiderScript.id = insiderScriptId;
    // eslint-disable-next-line max-len
    insiderScript.src = `https://${INSIDER_PARTNER_NAME}.api.useinsider.com/ins.js?id=${INSIDER_PARTNER_ID}`;

    document.head.appendChild(insiderScript);
};
