import { RMA_DOMAIN_TAG_VALUE } from '@rma/errors/config';

import { createErrorHandlerWithDomainContext } from '@errors/plugin/create-domain-error-handler';

export default function ({ $errorHandler }, inject) {
    inject(
        'rmaErrorHandler',
        createErrorHandlerWithDomainContext($errorHandler, RMA_DOMAIN_TAG_VALUE)
    );
}
