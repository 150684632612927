import NotFoundException from '@exceptions/NotFoundException';

export default class CategoryNotFoundException extends NotFoundException {
    name = 'CategoryNotFoundException';

    constructor(params) {
        super(`Category not found. Request params: ${params}`);

        this.params = params;
    }
}
