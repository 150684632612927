import {
    DEFAULT_CURRENT_PAGE,
    DEFAULT_PAGE_SIZE,
    POSSIBLE_PAGE_SIZES,
} from '@configs/defaults/query/pagination';
import { types } from './mutations';

const actions = {
    setPagination({ commit }, { currentPage, totalPages }) {
        commit(types.SET_CURRENT_PAGE, currentPage);
        commit(types.SET_TOTAL_PAGES, totalPages);
    },

    setPaginationFromQuery({ dispatch }, { currentPage, pageSize }) {
        dispatch('setCurrentPage', currentPage);
        dispatch('setPageSize', pageSize);
    },

    setCurrentPage({ commit }, currentPage) {
        const currentPageNumber = parseInt(currentPage, 10);
        const isCurrentPageValid =
            !Number.isNaN(currentPageNumber) && currentPageNumber > 0;

        if (isCurrentPageValid) {
            commit(types.SET_CURRENT_PAGE, currentPage);

            return;
        }

        commit(types.SET_CURRENT_PAGE, DEFAULT_CURRENT_PAGE);
    },

    setPageSize({ commit }, pageSize) {
        const pageSizeNumber = parseInt(pageSize, 10);

        const isPageSizeValid =
            !Number.isNaN(pageSizeNumber) &&
            POSSIBLE_PAGE_SIZES.indexOf(pageSizeNumber) !== -1;

        if (isPageSizeValid) {
            commit(types.SET_PAGE_SIZE, pageSizeNumber);

            return;
        }

        commit(types.SET_PAGE_SIZE, DEFAULT_PAGE_SIZE);
    },
};

export default actions;
