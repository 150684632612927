import { MEGATRON_SEARCH_GET_FILTERS_OPERATION_NAME } from '@configs/megatron-client';

import normalizeFilters from '@normalizers/models/filters';

const normalizer = async (response, config) => {
    if (response.data && !response.data.filters) {
        response.data = { filters: response.data };
    }

    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    response.data.filters = normalizeFilters(data.filters, config);

    return response;
};

export const getFilters = {
    operationName: MEGATRON_SEARCH_GET_FILTERS_OPERATION_NAME,
    normalizer,
};
