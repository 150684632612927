<template>
    <div class="header-wrapper">
        <header class="header">
            <HeaderTopBar v-if="!isMobile" class="desktop-top-bar" />

            <HeaderMainBar class="main-bar" />

            <div class="search-button-wrapper">
                <HeaderSearchButton />
            </div>

            <HeaderNavigationBar v-if="!isMobile" class="nav-bar" />

            <Divider class="header-divider" />
        </header>

        <Scrim v-show="isDesktopMenuOpen" class="backdrop" />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import HeaderTopBar from '@header-organisms/HeaderTopBar/HeaderTopBar';
import HeaderMainBar from '@header-organisms/HeaderMainBar/HeaderMainBar';
import HeaderSearchButton from '@header-organisms/HeaderSearchButton/HeaderSearchButton';
import HeaderNavigationBar from '@header-organisms/HeaderNavigationBar/HeaderNavigationBar';

import { Divider } from '@eobuwie-ui/components/Divider/v1';
import { Scrim } from '@eobuwie-ui/components/Scrim/v1';

const { mapState: mapNavigationState } = createNamespacedHelpers('navigation');
const { mapActions: mapAutocompleteActions } = createNamespacedHelpers(
    'header/autocomplete'
);

export default {
    name: 'HeaderWrapper',

    components: {
        HeaderTopBar,
        HeaderMainBar,
        HeaderSearchButton,
        HeaderNavigationBar,
        Divider,
        Scrim,
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationState(['isDesktopMenuOpen']),
    },

    mounted() {
        this.checkIfIsNewAutocomplete();
    },

    methods: {
        ...mapAutocompleteActions(['checkIfIsNewAutocomplete']),
    },
};
</script>

<style scoped lang="scss">
.header-wrapper {
    transition: 0.3s;
    @apply sticky top-0 z-4;

    .hidden-state {
        @apply opacity-0;
        @apply pointer-events-none;
    }

    .header {
        @apply relative bg-ui-container-default-default;
        @apply z-4;
        @apply max-h-[160px];
    }

    .desktop-top-bar {
        @apply hidden;
    }

    .search-button-wrapper {
        @apply px-ui-4;
    }

    .main-bar,
    .nav-bar {
        transition: 0.3s;
        transition-delay: opacity 0.2s;
    }

    .nav-bar {
        @apply hidden;
    }

    .header-divider {
        @apply mt-ui-4;
    }

    .backdrop {
        @apply z-2 fixed;
    }

    @screen lg {
        @apply top-[-48px];

        .header {
            @apply max-h-none;
        }

        .desktop-top-bar {
            @apply block;
        }

        .search-button-wrapper {
            @apply hidden;
        }

        .nav-bar {
            @apply block;

            &.hidden-state {
                @apply h-ui-6;
            }
        }

        .header-divider {
            @apply mt-0;
        }
    }
}
</style>
