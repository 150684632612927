import { PREVENT_SCROLL_CLASSES } from '@configs/class-names';
import { hasCommonElement } from '@assets/array';
import { types } from './mutations';

export default {
    addHTMLClasses({ commit }, classNames = []) {
        commit(types.ADD_HTML_CLASSES, classNames);

        const htmlStyle = document.documentElement.style;

        if (
            hasCommonElement(classNames, PREVENT_SCROLL_CLASSES) &&
            htmlStyle.top === ''
        ) {
            htmlStyle.top = `${-window.pageYOffset}px`;
        }
    },

    removeHTMLClasses({ commit, dispatch }, classNames = []) {
        commit(types.REMOVE_HTML_CLASSES, classNames);

        if (hasCommonElement(classNames, PREVENT_SCROLL_CLASSES)) {
            dispatch('blockScrollToTop', classNames);
        }
    },

    blockScrollToTop(_, classNames = []) {
        const html = document.documentElement;
        const scrollY = html.style.top;

        html.classList.remove(...classNames);

        if (!hasCommonElement([...html.classList], PREVENT_SCROLL_CLASSES)) {
            html.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        }
    },
};
