export const PADDING_DIRECTIONS = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
    'bottom-left': 'top',
    'bottom-right': 'top',
    'top-left': 'bottom',
    'top-right': 'bottom',
};
