/* eslint-disable max-len */
export const INVALID_SERVICE_CONFIG =
    '"servicesConfig" passed to $createAsyncServices has invalid type (object with only functions as values needed)';
export const INVALID_RUNTIME_SERVICES_HELPERS =
    '"runtimeServicesHelpers" passed to $createAsyncServices has invalid type (object needed)';
export const SERVICE_INVALID_TYPE_MESSAGE =
    'Service is invalid type (object needed).';
export const SERVICE_METHOD_INVALID_TYPE_MESSAGE =
    'Service method name is invalid type (string needed).';
export const CREATE_MESSAGE_INVALID_ARGUMENTS =
    '"createError" - passed arguments are invalid';
export const ERROR_MESSAGE_PARAM_NOT_EXISTS_MESSAGE =
    'Error message param of given index does not exists.';
export const SERVICE_NOT_EXISTS =
    'Given service name - "%1%" does not exists in configuration.';
export const CANNOT_USE_METHOD_ON_SERVICE_MESSAGE =
    'Method "%1%" cannot be used on "%2%" service. Method doesn\'t exist or has invalid type';
export const INVALID_USE_METHOD_ARGUMENTS =
    'Arguments passed to "use" methods are invalid (strings required)';
export const CREATE_SERVICE_FUNCTION_IS_NOT_DEFAULT_EXPORT =
    'Given create service function is not default export';
export const SERVICE_IS_ALREADY_REGISTERED =
    'Given service is already registered in services config';
export const INVALID_SERVICE_REGISTRATION_INPUT =
    'Service load function passed to registration has invalid type (function needed)';
export const INVALID_SERVICE_NAME =
    'Service name passed to registration is missing or has invalid type (string needed)';
