export const PREVENT_SCROLL_CLASS_NAME = '_no-scroll';
export const PREVENT_SCROLL_MODAL_CLASS_NAME = '_no-scroll-modal';
export const HIDE_RECAPTCHA_CLASS_NAME = '_hide-recaptcha';

export const PRODUCT_SECTION_REVIEWS_ID = 'section-reviews';
export const NEWSLETTER_SECTION_ID = 'newsletter-section';

export const PREVENT_SCROLL_CLASSES = [
    PREVENT_SCROLL_CLASS_NAME,
    PREVENT_SCROLL_MODAL_CLASS_NAME,
];
