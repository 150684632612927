<template>
    <div class="newsletter-section-wrapper">
        <Observer
            v-if="displayContainer"
            :id="NEWSLETTER_SECTION_ID"
            :options="OBSERVER_OPTIONS"
            :observe-once="true"
            class="content"
            @intersect="displayContent($event)"
        >
            <template v-if="isDisplayed">
                <Loader v-if="!newsletterSectionComponent" />
                <component
                    :is="newsletterSectionComponent"
                    v-if="!!newsletterSectionComponent"
                    @submit="handleNewsletterSubmit($event)"
                />
            </template>
        </Observer>
    </div>
</template>

<script>
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { NEWSLETTER_SECTION_ID } from '@configs/class-names';
import { NEWSLETTER_REGISTRATION } from '@configs/gdpr';
import {
    MODAL_NEWSLETTER_NAME,
    SHOULD_VALIDATE_ON_OPEN,
    SHOULD_OPEN_MODAL_ON_CLICK,
    EMAIL_VALUE,
    MODAL_NEWSLETTER_RELEASER,
    NEWSLETTER_RELEASER_FOOTER,
} from '@configs/modals';

import Observer from '@atoms/Observer/Observer';
import Loader from '@atoms/Loader/Loader';

const OBSERVER_OPTIONS = {
    root: null,
    threshold: 0,
    rootMargin: '0px 0px 200px 0px',
};

export default {
    name: 'NewsletterSectionWrapper',

    components: {
        Loader,
        Observer,
        ModalNewsletter: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "modal-newsletter" */ '@organisms/ModalNewsletter/ModalNewsletter'
            ),
        }),
    },

    data() {
        return {
            isDisplayed: false,
            displayContainer: true,
            newsletterSectionComponent: null,
        };
    },

    beforeCreate() {
        this.NEWSLETTER_REGISTRATION = NEWSLETTER_REGISTRATION;
        this.NEWSLETTER_SECTION_ID = NEWSLETTER_SECTION_ID;
        this.OBSERVER_OPTIONS = OBSERVER_OPTIONS;
    },

    methods: {
        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
                this.loadNewsletterSectionComponent();
            }
        },

        loadNewsletterSectionComponent() {
            import(
                /* webpackChunkName: "newsletter-section-footer" */
                '@organisms/NewsletterSectionFooter/NewsletterSectionFooter'
            )
                .then(component => {
                    this.newsletterSectionComponent = component.default;
                })
                .catch(err => {
                    this.handleError();
                    this.$errorHandler.captureError(err, {
                        [ERROR_ACTION_TAG_NAME]: 'lazyload',
                    });
                });
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
        },

        handleNewsletterSubmit({ email }) {
            this.$modals.open(MODAL_NEWSLETTER_NAME, {
                [SHOULD_VALIDATE_ON_OPEN]: true,
                [SHOULD_OPEN_MODAL_ON_CLICK]: true,
                [EMAIL_VALUE]: email,
                [MODAL_NEWSLETTER_RELEASER]: NEWSLETTER_RELEASER_FOOTER,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$wrapper-height: (
    'default': 224px,
    'xs': 176px,
    'lg': 212px,
);

.newsletter-section-wrapper {
    .content {
        @apply flex items-center bg-green5;
        min-height: map-get($wrapper-height, 'default');

        @screen xs {
            min-height: map-get($wrapper-height, 'xs');
        }

        @screen lg {
            min-height: map-get($wrapper-height, 'lg');
        }
    }
}
</style>
