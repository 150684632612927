<template>
    <ContentContainer class="checkout-footer-support-links">
        <Grid cols="12">
            <Column cols="12">
                <BaseLinkStatic
                    v-if="isHelpCenterLinkVisible"
                    :path="$tPath('help-center')"
                    class="link"
                    target="_blank"
                >
                    <template #icon>
                        <SvgIcon :width="ICON_SIZE" :height="ICON_SIZE">
                            <QuestionMarkCircled />
                        </SvgIcon>
                    </template>
                    {{ $t('Help center') }}
                </BaseLinkStatic>
                <BaseLinkStatic
                    :path="$tPath('contact')"
                    class="link"
                    target="_blank"
                >
                    <template #icon>
                        <SvgIcon :width="ICON_SIZE" :height="ICON_SIZE">
                            <HelpCenter />
                        </SvgIcon>
                    </template>
                    {{ $t('Contact') }}
                </BaseLinkStatic>
            </Column>
        </Grid>
    </ContentContainer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { LOCALES_WITH_HIDDEN_HELP_CENTER_LINK } from '@configs/checkout';

import { ContentContainer } from '@eobuwie-ui/components/ContentContainer/v1';
import { Column, Grid } from '@eobuwie-ui/components/Grid/v1';

import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import HelpCenter from '@static/icons/24px/help-center.svg?inline';
import QuestionMarkCircled from '@static/icons/24px/question-mark-circled.svg?inline';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'CheckoutFooterSupportLinks',

    components: {
        Column,
        ContentContainer,
        Grid,
        BaseLinkStatic,
        SvgIcon,
        HelpCenter,
        QuestionMarkCircled,
    },

    computed: {
        ...mapConfigGetters(['locale']),

        isHelpCenterLinkVisible() {
            return !LOCALES_WITH_HIDDEN_HELP_CENTER_LINK.includes(this.locale);
        },
    },

    beforeCreate() {
        this.ICON_SIZE = '24px';
    },
};
</script>

<style lang="scss" scoped>
.checkout-footer-support-links {
    .grid {
        @apply px-ui-4;
    }

    .column {
        @apply flex flex-wrap items-center justify-center gap-x-ui-6;
    }

    .link {
        @apply h-ui-11;
    }

    @screen ui-desktop {
        .grid {
            @apply px-0;
        }

        .link {
            @apply h-auto;
        }
    }
}
</style>
