export const ESIZEME_PATH = 'b/esizeme';
export const ESIZEME_SCANNING_POINTS_MAP_ID = '#4';

export const STEP_PROVIDE_PHONE_NUMBER = 1;
export const STEP_PROVIDE_CODE = 2;
export const STEP_SCANS_ADDED = 3;

export const TRUE_SIZE_TYPE_MALE = 'true_size_male';
export const TRUE_SIZE_TYPE_FEMALE = 'true_size_female';
export const TRUE_SIZE_TYPE_CHILD = 'true_size_child';

export const ESIZEME_LABEL_NONE = 'none';
export const ESIZEME_LABEL_RECOMMENDED = 'recommended';
export const ESIZEME_LABEL_ACCEPTABLE = 'acceptable';

export const TRUE_SIZE_TYPES = [
    TRUE_SIZE_TYPE_MALE,
    TRUE_SIZE_TYPE_FEMALE,
    TRUE_SIZE_TYPE_CHILD,
];
