export const TOGGLE = 'toggle';
export const MULTISELECT = 'multiselect';
export const RANGE = 'range';
export const SPECIAL = 'special';

export const PRICE_FILTER_CODE = 'final_price';
export const SIZE_FILTER_CODE = 'grouped_size';
export const BRAND_FILTER_CODE = 'manufacturer_with_collection';
export const OCCASION_FILTER_CODE = 'occasion';
export const PRODUCT_BADGE_FILTER_CODE = 'product_badge';
export const SPECIAL_ACTION_CODE = 'action_label';
export const HOT_DEAL_ACTION_CODE = 'hot_deal';
export const ESIZEME_FILTER_CODE = 'esizeme';
export const COLOR_FILTER_CODE = 'kolor';
export const NEW_OPTION_CODE = 'nowosc';
export const OCCASION_OPTION_CODE = 'okazja';

export const RANGE_FILTER_MIN_KEY = 'min';
export const RANGE_FILTER_MAX_KEY = 'max';

export const URL = 'url';
export const CODE = 'code';

export const EMPTY = 'empty';

export const FILTER_TYPES = {
    TOGGLE,
    MULTISELECT,
    RANGE,
    SPECIAL,
};
