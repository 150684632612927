export const ORDER_ITEMS_KEY = 'items';
export const ORDER_GRAND_TOTAL_KEY = 'grand_total';
export const ORDER_GRAND_TOTAL_WITHOUT_SHIPPING_KEY =
    'grand_total_without_shipping';
export const ORDER_SHIPPING_INCLUDING_TAX_KEY = 'shipping_incl_tax';
export const ORDER_SHIPPING_ADDRESS_KEY = 'shipping_address';
export const ORDER_STATUS_KEY = 'status';
export const ORDER_CREATED_AT_KEY = 'created_at';

export const ORDER_SELECTED_SHIPPING_METHOD_CODE_KEY = 'code';
export const ORDER_SELECTED_SHIPPING_METHOD_TITLE_KEY = 'title';

export const ORDER_ITEM_PRICE_DISCOUNT_PERCENT_KEY = 'discount_percent';
export const ORDER_ITEM_PRICE_ROW_SUBTOTAL_EXT_KEY = 'row_subtotal_ext';
export const ORDER_ITEM_PRICE_FINAL_PRICE_EXT_KEY = 'final_price_ext';
export const ORDER_ITEM_PRICE_ITEM_FINAL_PRICE_EXT_KEY = 'final_price_ext';
export const ORDER_ITEM_PRICE_ORIGINAL_PRICE_KEY = 'original_price';
export const ORDER_ITEM_PRICE_DISCOUNT_PERCENT_BY_SPECIAL_PRICE_EXT_KEY =
    'discount_percent_by_special_price_ext';
export const ORDER_ITEM_SKU_PRODUCT_KEY = 'product_parent';
export const ORDER_CAN_BE_REPAID = 'can_be_repaid';
export const ORDER_HASH = 'order_hash';
