<template>
    <a :href="modivoUrl" target="_blank" class="logo-modivo-by-eobuwie">
        <div class="logo">
            <SvgIcon width="94px" height="24px">
                <ModivoLogo />
            </SvgIcon>
            <BaseParagraph class="by-caption">
                {{ $t('by eobuwie.pl') }}
            </BaseParagraph>
        </div>

        <div class="description">
            <BaseParagraph>
                {{ $t('Your premium fashion store!') }}
            </BaseParagraph>
            <BaseParagraph class="go-outside-icon-container">
                <SvgIcon width="20px" height="20px" class="go-outside-icon">
                    <ExternalLink />
                </SvgIcon>
                {{ $t('Go to modivo.pl') }}
            </BaseParagraph>
        </div>
    </a>
</template>

<script>
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import ModivoLogo from '@static/logo/modivo-logo.svg?inline';
import ExternalLink from '@static/icons/20px/external-link.svg?inline';

export default {
    name: 'LogoModivoByEobuwie',

    components: {
        BaseParagraph,
        SvgIcon,
        ModivoLogo,
        ExternalLink,
    },

    props: {
        modivoUrl: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.logo-modivo-by-eobuwie {
    @apply text-center inline-flex justify-center items-center;

    .by-caption {
        @apply text-xs leading-s text-center;
    }

    .description {
        @apply text-s leading-s ml-5;
    }

    .go-outside-icon-container {
        @apply flex justify-center items-center leading-r font-bold mt-2;
    }

    .go-outside-icon {
        @apply inline mr-10p;
    }

    @screen md {
        .go-outside-icon-container {
            @apply justify-end;
        }
    }
}
</style>
