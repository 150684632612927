import NotFoundException from '@exceptions/NotFoundException';

export default class CmsPageNotFoundException extends NotFoundException {
    name = 'CmsPageNotFoundException';

    constructor(identifier, error) {
        super(`CMS page with identifier ${identifier} not found`);

        this.identifier = identifier;
        this.error = error;
    }
}
