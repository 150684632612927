import { SCROLL_BEHAVIOR_CHANGED_KEY } from '@configs/storage';

export default async function ({ app: { $storage, router } }) {
    const baseScrollBehavior = router.options.scrollBehavior;

    router.options.scrollBehavior = function (toPage, fromPage, savedPosition) {
        if ($storage?.getItem(SCROLL_BEHAVIOR_CHANGED_KEY)) {
            $storage.removeItem(SCROLL_BEHAVIOR_CHANGED_KEY);

            return false;
        }

        return baseScrollBehavior(toPage, fromPage, savedPosition);
    };
}
