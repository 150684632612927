<template>
    <div class="checkout-stepper">
        <ol class="step-list">
            <li
                v-for="({ name, path }, index) in steps"
                :key="name"
                class="step"
                :class="{ current: isCurrentOrCompleted(index) }"
            >
                <div class="step-circle-wrapper">
                    <div class="step-circle">
                        <Icon
                            v-if="isCompleted(index)"
                            :icon="Tick"
                            :width="ICON_SIZE"
                            :height="ICON_SIZE"
                            class="step-icon"
                        />
                        <span v-else>{{ index + 1 }}</span>
                    </div>
                </div>
                <div class="step-circle-background" />
                <BaseLink
                    v-if="path"
                    :path="path"
                    :label="name"
                    :title="name"
                    class="step-label"
                />
                <span v-else class="step-label">{{ name }}</span>
            </li>
        </ol>
        <div class="line" />
    </div>
</template>

<script>
import { CHECKOUT_CART_PAGE_NAME } from '@router/names';

import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { Tick } from '@eobuwie-ui/icons/v1/other';

import BaseLink from '@atoms/BaseLink/BaseLink';

export default {
    name: 'CheckoutStepper',

    components: {
        Icon,
        BaseLink,
    },

    props: {
        currentStep: {
            type: Number,
            required: true,
        },
    },

    computed: {
        steps() {
            return [
                {
                    name: this.CART_NAME,
                    path: this.currentStep !== 3 ? this.CART_PATH : '',
                },
                { name: this.DELIVERY_AND_PAYMENT_NAME },
                { name: this.READY_NAME },
            ];
        },
    },

    beforeCreate() {
        this.ICON_SIZE = 20;
        this.Tick = Tick;
        this.CART_NAME = this.$t('Cart');
        this.DELIVERY_AND_PAYMENT_NAME = this.$t('Delivery and payment');
        this.READY_NAME = this.$t('Ready');
        this.CART_PATH = this.$createPagePath({
            name: CHECKOUT_CART_PAGE_NAME,
        });
    },

    methods: {
        isCompleted(index) {
            return index < this.currentStep - 1;
        },

        isCurrentOrCompleted(index) {
            return index <= this.currentStep - 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkout-stepper {
    @apply relative z-ui-0 w-full;

    .step-list {
        @apply flex justify-between w-full;
    }

    .step {
        @apply relative flex flex-col items-center gap-y-ui-2;
    }

    .step:first-child,
    .step:last-child {
        @apply min-w-[96px];
    }

    .step-circle-wrapper {
        @apply px-ui-2 z-ui-1 bg-ui-swap-white;
    }

    .step-circle {
        @apply flex items-center justify-center h-ui-9 w-ui-9 font-ui-body-m-bold;
        @apply rounded-50 border-ui-1 border-ui-swap-gray200 text-ui-swap-gray200;
    }

    .step.current .step-circle {
        @apply border-transparent bg-ui-swap-gray600 text-ui-swap-white;
    }

    .step-circle-background {
        @apply hidden;
    }

    .step:first-child .step-circle-background {
        @apply absolute top-ui-0 left-ui-0 block h-full w-ui-percent-50 bg-ui-swap-white;
    }

    .step:last-child .step-circle-background {
        @apply absolute top-ui-0 right-ui-0 block h-full w-ui-percent-50 bg-ui-swap-white;
    }

    .step-icon {
        @apply text-ui-swap-white;
    }

    .step-label {
        @apply z-ui-1 text-center font-ui-component-bottom-nav text-ui-swap-gray600;
    }

    .line {
        @apply absolute top-ui-4 left-ui-0 w-full h-ui-plus-1p bg-ui-swap-gray200 -z-ui-1;
    }
}
</style>
