import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const ERROR_TAGS = {
    [ERROR_ACTION_TAG_NAME]: 'services.orders.getCustomerOrdersData',
};

export default ({ megatronClient, $errorHandler }) => ({
    async getCustomerOrdersData() {
        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getCustomerOrders',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.customerOrders?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return [];
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return [];
        }
    },
});
