<template>
    <div
        class="container-content"
        :class="{
            _full: fullWidth,
            _nogutters: noGutters,
            _alignleft: alignLeft,
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ContainerContent',

    props: {
        fullWidth: {
            type: Boolean,
            default: false,
        },

        noGutters: {
            type: Boolean,
            default: false,
        },

        alignLeft: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.container-content {
    @apply flex flex-wrap justify-center w-full mx-auto px-3;
    max-width: theme('customVariables.containerContent.maxWidth');

    &._nogutters {
        @apply -mx-3;
    }

    &._full {
        @apply px-0 max-w-full;
    }

    &._alignleft {
        @apply justify-start;
    }

    @screen lg {
        @apply px-5;
    }
}
</style>
