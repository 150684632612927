import RMA_SERVICES_NAMESPACE from '@rma/async-services/namespace';

export const NAME = `${RMA_SERVICES_NAMESPACE}/return-intent`;

export const SET_REFUND_METHOD = 'SET_REFUND_METHOD';
export const CREATE_RETURN_INTENT = 'CREATE_RETURN_INTENT';
export const UPDATE_RETURN_INTENT_ITEMS = 'UPDATE_RETURN_INTENT_ITEMS';
export const GET_REFUND_METHODS = 'GET_REFUND_METHODS';
export const GET_RETURN_SHIPPING_METHODS = 'GET_RETURN_SHIPPING_METHODS';
export const SET_RETURN_SHIPPING_METHOD = 'SET_RETURN_SHIPPING_METHOD';
export const SUBMIT_RETURN = 'SUBMIT_RETURN';
export const GET_RETURN_STATUS = 'GET_RETURN_STATUS';

export const ASYNC_RETURN_INTENT_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        SET_REFUND_METHOD,
        CREATE_RETURN_INTENT,
        UPDATE_RETURN_INTENT_ITEMS,
        GET_REFUND_METHODS,
        GET_RETURN_SHIPPING_METHODS,
        SET_RETURN_SHIPPING_METHOD,
        SUBMIT_RETURN,
        GET_RETURN_STATUS,
    },
};
