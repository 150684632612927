import { INPUT_TYPE_EMPTY } from '@configs/gdpr';

export default {
    getInfoAgreementsDecisionForViewId: state => forViewId => {
        return (
            state[forViewId]
                ?.filter(agreement => agreement.input_type === INPUT_TYPE_EMPTY)
                .map(({ id }) => {
                    return {
                        agreement_id: id,
                        is_accepted: true,
                    };
                }) || []
        );
    },

    allFetchedAgreements: state => {
        return Object.keys(state).reduce((acc, key) => {
            if (Array.isArray(state[key])) {
                acc.push(...state[key]);
            }

            return acc;
        }, []);
    },
};
