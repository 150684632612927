<template>
    <ContainerContent
        v-if="Object.keys(staticBlocks).length"
        class="footer-top"
    >
        <div class="column-menus">
            <div v-for="menu in menus" :key="menu" class="column-single-menu">
                <template v-if="isBlockAvailable(menu)">
                    <ExpandCollapse
                        class="menu-expand-collapse"
                        :is-expanded-on-desktop="true"
                    >
                        <template #header>
                            {{ staticBlocks[menu].title }}
                        </template>
                        <template #icon-plus>
                            <SvgIcon width="16px" height="16px" class="icon">
                                <Down />
                            </SvgIcon>
                        </template>
                        <template #icon-minus>
                            <SvgIcon width="16px" height="16px" class="icon">
                                <Up />
                            </SvgIcon>
                        </template>
                        <HtmlLinkHandler
                            #default="{ clickHandler }"
                            @click="setIsCookieSettingsModalOpen(true)"
                        >
                            <StaticBlock
                                :content="staticBlocks[menu].content"
                                @click.native="clickHandler($event)"
                            />
                        </HtmlLinkHandler>
                    </ExpandCollapse>
                </template>
            </div>
        </div>

        <div class="column-additionals">
            <div class="column-our-prices">
                <BaseHeading>
                    {{ $t('Our Awards') }}
                </BaseHeading>

                <div
                    class="awards-image-container"
                    :style="{ maxWidth: AWARDS_IMAGE_WIDTH_PX }"
                >
                    <BasePicture
                        class="awards-image"
                        :src="require('~static/awards.png')"
                        :is-lazy="true"
                        :alt="$t('Our Awards')"
                        :width="AWARDS_IMAGE_WIDTH"
                        :height="AWARDS_IMAGE_HEIGHT"
                    />
                </div>
            </div>
            <div
                v-if="facebookUrl || instagramUrl || youtubeUrl"
                class="column-social-icons"
            >
                <SocialIcons
                    :facebook-url="facebookUrl"
                    :instagram-url="instagramUrl"
                    :youtube-url="youtubeUrl"
                />
            </div>

            <MobileAppsLinksProvider #default="{ androidAppUrl, iosAppUrl }">
                <div v-if="androidAppUrl || iosAppUrl" class="column-apps">
                    <DownloadApp
                        :android-app-url="androidAppUrl"
                        :ios-app-url="iosAppUrl"
                    />
                </div>
            </MobileAppsLinksProvider>
        </div>
    </ContainerContent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import Up from '@static/icons/24px/up.svg?inline';
import Down from '@static/icons/24px/down.svg?inline';

import HtmlLinkHandler from '@functionals/HtmlLinkHandler/HtmlLinkHandler';
import MobileAppsLinksProvider from '@functionals/MobileAppsLinksProvider/MobileAppsLinksProvider';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';
import ExpandCollapse from '@atoms/ExpandCollapse/ExpandCollapse';

import DownloadApp from '@molecules/DownloadApp/DownloadApp';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SocialIcons from '@molecules/SocialIcons/SocialIcons';

const { mapActions: mapConsentsActions } = createNamespacedHelpers('consents');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'FooterTop',

    components: {
        BaseHeading,
        BasePicture,
        ExpandCollapse,
        DownloadApp,
        ContainerContent,
        StaticBlock,
        MobileAppsLinksProvider,
        SvgIcon,
        Up,
        Down,
        HtmlLinkHandler,
        SocialIcons,
    },

    props: {
        staticBlocks: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            menus: [
                'customerServiceMenu',
                'regulationsMenu',
                'companyMenu',
                'guidesMenu',
            ],
        };
    },

    computed: {
        ...mapConfigGetters(['facebookUrl', 'instagramUrl', 'youtubeUrl']),
    },

    beforeCreate() {
        this.AWARDS_IMAGE_WIDTH = 224;
        this.AWARDS_IMAGE_WIDTH_PX = `${this.AWARDS_IMAGE_WIDTH}px`;
        this.AWARDS_IMAGE_HEIGHT = 32;
    },

    methods: {
        ...mapConsentsActions(['setIsCookieSettingsModalOpen']),

        isBlockAvailable(name) {
            return (
                !!this.staticBlocks[name] &&
                Object.keys(this.staticBlocks[name]).length
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-top {
    .column-menus {
        @apply w-full flex flex-col;
    }

    .column-single-menu {
        @apply py-3 border-b-1 border-solid border-gray5;
    }

    .column-additionals {
        @apply w-full flex flex-col;
    }

    .column-our-prices {
        @apply w-full flex flex-col items-center mt-5;
    }

    .column-social-icons {
        @apply w-full mt-5;
    }

    .column-apps {
        @apply w-full mt-5;
    }

    &:deep() {
        .menu-expand-collapse {
            .heading-button {
                @apply text-left;
            }

            .svg {
                @apply text-gray3;
            }
        }

        .footer-menu-item {
            &:first-child {
                @apply mt-1;
            }

            &:last-child {
                .text-link {
                    @apply pb-0;
                }
            }

            .text-link {
                @apply inline-block py-12p;

                // @todo remove after PWA-126
                &:hover {
                    @apply underline;
                }
            }
        }

        .awards-image-container {
            @apply w-full;
        }

        .awards-image {
            @apply mt-3;

            img {
                @apply mx-auto;
            }
        }
    }

    @screen md {
        .column-additionals {
            @apply flex-row flex-wrap;
        }

        .column-our-prices {
            @apply w-1/2 items-start;
        }

        .column-social-icons {
            @apply w-1/2;
        }

        &:deep() {
            .awards-image {
                img {
                    @apply mx-0;
                }
            }
        }
    }

    @screen lg {
        .column-menus {
            @apply flex-row;
        }

        .column-single-menu {
            @apply w-1/4 p-0 pr-3 mt-5 border-none;
        }

        .column-additionals {
            @apply flex-nowrap;
        }

        .column-our-prices {
            @apply w-1/4;
        }

        .column-social-icons {
            @apply w-1/4;
        }

        .column-apps {
            @apply w-1/2;
        }

        &:deep() {
            .menu-expand-collapse {
                .footer-menu-item {
                    &:first-child {
                        @apply mt-0;
                    }

                    .text-link {
                        @apply py-1 leading-s text-s;
                    }
                }
            }
        }
    }

    @screen xl {
        .column-menus {
            @apply w-2/3;
        }

        .column-additionals {
            @apply w-1/3 flex-col;
        }

        .column-our-prices {
            @apply w-full;
        }

        .column-social-icons {
            @apply w-full;
        }

        .column-apps {
            @apply w-full;
        }
    }
}
</style>
