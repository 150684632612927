import RMA_SERVICES_NAMESPACE from '@rma/async-services/namespace';

export const NAME = `${RMA_SERVICES_NAMESPACE}/orders`;

export const GET_ORDERS = 'GET_ORDERS';
export const SET_SUBORDER = 'SET_SUBORDER';

export const ASYNC_ORDERS_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_ORDERS,
        SET_SUBORDER,
    },
};
