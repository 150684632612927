export const isMobileOperatingSystemAndroid = (userAgent = '') => {
    return /android/i.test(userAgent);
};

export const isMobileOperatingSystemIOS = (userAgent = '') => {
    if (typeof window === 'undefined') {
        return false;
    }

    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

export const isMobileOperatingSystemHuawei = (userAgent = '') => {
    return (
        /huawei/i.test(userAgent) && !isMobileOperatingSystemAndroid(userAgent)
    );
};
