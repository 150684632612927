import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';
import { HTTP_CLIENT_ENDPOINT_NAME_SMAUG } from '@configs/http-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { PAYMENTS_ERROR_DOMAIN } from '@errors/feature-domain-names';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_SMAUG };

export default (megatronClient, httpClient, $errorHandler) => ({
    async preauthorize(cartId) {
        const payload = {
            cartId,
        };

        try {
            return await megatronClient.post('v1/preauthorizePayment', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
                context: {
                    useAuthorization: true,
                },
            });
        } catch (err) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]:
                        'services.payments.paypal.preauthorize',
                },
                {
                    payload,
                }
            );
        }
    },
    async authorize(paymentId) {
        const payload = {
            paymentId,
        };

        try {
            return httpClient.post('/payments/authorize', {
                ...ENDPOINT_PARAMETER,
                payload,
            });
        } catch (err) {
            $errorHandler.captureDomainError(
                PAYMENTS_ERROR_DOMAIN,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]:
                        'services.payments.paypal.authorize',
                },
                {
                    payload,
                }
            );
        }
    },
});
