export const checkIfExistsInValuesMap = (
    valuesMapObj,
    canBeEmptyString = false
) => value => {
    const isInValuesMap = Object.values(valuesMapObj).includes(value);

    return isInValuesMap || (canBeEmptyString && value === '');
};

export const checkIfExistsInKeysMap = (
    keysMapObj,
    canBeEmptyString = false
) => value => {
    const isInKeysMap = Object.keys(keysMapObj).includes(value);

    return isInKeysMap || (canBeEmptyString && value === '');
};
