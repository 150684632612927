import mainMutations, {
    CMS_BLOCKS,
    NAVIGATION,
    ESIZEME,
    MARKETING_BAR_CONFIG,
} from './mutations';
import mainActions from './actions';
import mainGetters from './getters';

export const state = () => ({
    asyncModulesRegistered: [],
    isMobile: false,
    isMobileEvaluated: false,
    isTabletOrDesktop: false,
    isTabletOrDesktopEvaluated: false,
    dataLoaded: {
        [NAVIGATION]: false,
        [CMS_BLOCKS]: false,
        [ESIZEME]: false,
        [MARKETING_BAR_CONFIG]: false,
    },
    isChatWidgetLoaded: false,
    isChatWidgetLoading: false,
    performance: [],
    routeRenderStart: {},
    stanleyPrefix: null,
});

export const mutations = mainMutations;
export const actions = mainActions;
export const getters = mainGetters;
