import getBankAccountValidations from '@rma/assets/validations/bank-account-number-validation';
import getIbanValidation from '@rma/assets/validations/iban-validation';
import getHouseNumberValidation from '@rma/assets/validations/house-number-validation';
import getCityValidation from '@rma/assets/validations/city-validation';
import getFirstAndLastNameRuleValidation from '@rma/assets/validations/first-and-last-name-validation';
import getStreetRuleValidation from '@rma/assets/validations/street-validation';

export default i18n => [
    ...getBankAccountValidations(i18n),
    getIbanValidation(i18n),
    getHouseNumberValidation(i18n),
    getCityValidation(i18n),
    getFirstAndLastNameRuleValidation(i18n),
    getStreetRuleValidation(i18n),
];
