import { LOCAL_WISHLIST_KEY } from '@configs/storage';

export default $storage => ({
    get() {
        return $storage.getItem(LOCAL_WISHLIST_KEY) || [];
    },

    set(wishlist = []) {
        return $storage.setItem(LOCAL_WISHLIST_KEY, wishlist);
    },

    clear() {
        return $storage.removeItem(LOCAL_WISHLIST_KEY);
    },
});
