import { REDIRECT_HTTP_CODE } from '@configs/http-codes';
import { QUERY_CURRENT_PAGE } from '@configs/query-keys/pagination';

import { removeTrailingSlashesFromEnd } from '@assets/url';

const HOMEPAGE_PATH = '/';

export default ({ route, redirect }) => {
    const { query, path } = route;
    const { [QUERY_CURRENT_PAGE]: page, ...restQuery } = query;
    const isFirstPageWithQuery = page === '1';

    const pathNormalized = path.replace(/(%2F)+|\/+/g, '/');

    if (pathNormalized === HOMEPAGE_PATH) {
        return;
    }

    const decodedPath = removeTrailingSlashesFromEnd(pathNormalized);

    if (path !== decodedPath) {
        return redirect(
            REDIRECT_HTTP_CODE,
            decodedPath,
            isFirstPageWithQuery ? restQuery : query
        );
    }

    if (isFirstPageWithQuery) {
        return redirect(REDIRECT_HTTP_CODE, path, restQuery);
    }
};
