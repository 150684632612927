export const WHITE_COLOR_CODE = 'bialy';
export const EMPTY_COLOR_CODE = 'empty';
export const TRANSPARENT_COLOR_CODE = 'przezroczysty';

export const LIGHT_COLOR_CODES = [
    'szary',
    'zolty',
    'zloty',
    'kolorowy',
    'bezowy',
    'srebrny',
    'bialy',
    'przezroczysty',
    'empty',
    'biale_zloto',
    'rozowe_zlocenie',
    'rozowe_zloto',
    'srebrne_rodowanie',
    'zolte_zloto',
    'zolte_zlocenie',
    'ecru',
];

export const COLORS = {
    granatowy: '#0a0094',
    czarny: '#000000',
    brazowy: '#783404',
    czerwony: '#ea0000',
    szary: '#aeaeae',
    niebieski: '#0730ee',
    rozowy: '#e624bf',
    zielony: '#469705',
    pomaranczowy: '#ce6e08',
    fioletowy: '#8a08cd',
    zolty: '#edea00',
    zloty: '#e7b025',
    kolorowy: 'linear-gradient(45deg, #00d8ff 0%, #fff830 50%, #ff3232 100%)',
    bezowy: '#decea5',
    bordowy: '#8a1a30',
    srebrny: '#c5c4c3',
    bialy: '#ffffff',
    empty: '#e8e8e8',
    biale_zloto: '#cbcbcb',
    czarne_rodowanie: '#3a3a3a',
    rozowe_zlocenie: '#cda3a9',
    rozowe_zloto: '#b88078',
    srebrne_rodowanie: '#a2a2a1',
    zloto_bicolour:
        'linear-gradient(45deg, #e5c768 0%, #c4b77d 50%, #adb1b6 100%)',
    zolte_zloto: '#e2bf36',
    zolte_zlocenie: '#e0cd67',
    khaki: '#898952',
    turkusowy: '#48d1cc',
    koralowy: '#f08080',
    miedziany: '#b74434',
    przezroczysty: '#ffffff',
    ecru: '#f5f5dc',
    blekitny: '#89CFF0',
};

export default {
    COLORS,
    LIGHT_COLOR_CODES,
    WHITE_COLOR_CODE,
    EMPTY_COLOR_CODE,
    TRANSPARENT_COLOR_CODE,
};
