import { NAME } from './meta';

export default {
    name: NAME,
    load: () =>
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "catalog-search-service" */ './service'
        ),
};
