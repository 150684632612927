<template>
    <div class="header-search-button">
        <button class="search-button" @click="openAutocomplete()">
            <Icon :icon="Search" />

            <span class="button-text">{{ $t('Search') }}</span>
        </button>

        <HeaderSideModal
            v-if="isAutocompleteOpen && isMobile"
            :is-open="isAutocompleteOpen"
        >
            <template #header>
                <div class="autocomplete-header">
                    <HeaderSearchInput
                        :focus-on-mount="true"
                        class="search-input"
                    />

                    <Divider />
                </div>
            </template>

            <HeaderAutocomplete />
        </HeaderSideModal>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import HeaderSearchInput from '@header-atoms/HeaderSearchInput/HeaderSearchInput';

import { Search } from '@eobuwie-ui/icons/v1/action';

import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { Divider } from '@eobuwie-ui/components/Divider/v1';

const { mapActions: mapAutocompleteActions } = createNamespacedHelpers(
    'header/autocomplete'
);

export default {
    name: 'HeaderSearchButton',

    components: {
        Icon,
        Divider,
        HeaderSearchInput,
        HeaderSideModal: () => ({
            component: import(
                /* webpackChunkName: "header-side-modal" */
                '@header-molecules/HeaderSideModal/HeaderSideModal'
            ),
        }),

        HeaderAutocomplete: () => ({
            component: import(
                /* webpackChunkName: "header-autocomplete" */
                '@header-organisms/HeaderAutocomplete/HeaderAutocomplete'
            ),
        }),
    },

    computed: {
        ...mapState(['isMobile']),

        isAutocompleteOpen() {
            return this.$modals.isOpen('autocomplete');
        },
    },

    watch: {
        $route() {
            if (this.isAutocompleteOpen) {
                this.closeAutocomplete();
            }
        },
    },

    beforeCreate() {
        this.Search = Search;
    },

    methods: {
        ...mapAutocompleteActions(['openAutocomplete']),

        closeAutocomplete() {
            this.$modals.close('autocomplete');
        },
    },
};
</script>

<style scoped lang="scss">
.header-search-button {
    .search-button {
        @apply flex items-center w-full h-ui-11 px-ui-3 gap-x-ui-2;
        @apply bg-ui-container-secondary-default;
        @apply text-ui-text-primary-default;
    }

    .search-input {
        @apply m-ui-4;
    }

    .button-text {
        @apply text-ui-text-secondary-default;
    }
}
</style>
