/* eslint-disable prefer-const */
export default function loadScript(src, options = {}) {
    return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
            resolve();

            return;
        }

        const el = document.createElement('script');

        el.type = 'text/javascript';
        el.async = true;
        el.src = src;

        if (options.dataset?.length) {
            options.dataset.forEach(({ key, value }) => {
                el.dataset[key] = value;
            });
        }

        let loadHandler;
        let errorHandler;
        let abortHandler;
        let removeEventListeners;

        loadHandler = ev => {
            removeEventListeners();
            resolve(ev);
        };

        errorHandler = ev => {
            removeEventListeners();
            reject(ev);
        };

        abortHandler = ev => {
            removeEventListeners();
            reject(ev);
        };

        removeEventListeners = () => {
            el.removeEventListener('load', loadHandler);
            el.removeEventListener('error', errorHandler);
            el.removeEventListener('abort', abortHandler);
        };

        el.addEventListener('load', loadHandler);
        el.addEventListener('error', errorHandler);
        el.addEventListener('abort', abortHandler);

        document.head.appendChild(el);
    });
}
