export const ADD_RETURN_PAGE = 'add-return';
export const AFTER_ADD_RETURN_PAGE = 'after-add-return';
export const BRANDS_PAGE = 'brands';
export const CHECKOUT_CART_PAGE = 'checkout/cart';
export const CHECKOUT_PAGE = 'checkout';
export const CHECKOUT_REPAYMENT = 'checkout/repayment';
export const CHECKOUT_SUCCESS_PAGE = 'checkout/success';
export const CHECKOUT_ACCOUNT_PAGE = 'checkout/account';
export const CMS_PAGE = 'b';
export const CONTACT_PAGE = 'contact';
export const CUSTOMER_ESIZEME_PAGE = 'esizeme';
export const CUSTOMER_ACCOUNT_AGREEMENTS_PAGE = 'agreements';
export const CUSTOMER_ACCOUNT_ORDER_VIEW_PAGE = 'orders/:orderId';
export const CUSTOMER_ACCOUNT_ORDERS_PAGE = 'orders';
export const CUSTOMER_ACCOUNT_RETURN_PAGE = 'return';
export const CUSTOMER_ACCOUNT_RETURNS_PAGE = 'returns';
export const CUSTOMER_ACCOUNT_REVIEWS_PAGE = 'reviews';
export const CUSTOMER_ADDRESSES_PAGE = 'addresses';
export const CUSTOMER_DETAILS_PAGE = 'details';
export const LOYALTY_CLUB_PAGE = 'loyalty-club';
export const CUSTOMER_PAGE = 'customer';
export const HOME_PAGE = '/';
export const LOGIN_PAGE = 'login';
export const NEW_PASSWORD_PAGE = 'new-password';
export const NEWSLETTER_PAGE = 'newsletter';
export const NEWSLETTER_SMS_UNSUBSCRIBE_PAGE = 'us';
export const NEWSLETTER_SUBSCRIBE_PAGE_URL_KEY = 'subscribe';
export const NEWSLETTER_UNSUBSCRIBE_BY_CODE_PAGE_URL_KEY =
    'unsubscribe-by-code';
export const NEWSLETTER_UNSUBSCRIBE_PAGE_URL_KEY = 'unsubscribe';
export const NOT_SUPPORTED_PAGE = 'not-supported';
export const ORDER_PAGE = 'order';
export const ORDER_STATUS = 'order-status';
export const PACKAGE_TRACKING_PAGE = 'package-tracking';
export const PAGE_BUILDER_PAGE = 'pb';
export const REGISTER_PAGE = 'register';
export const RESET_PASSWORD_PAGE = 'reset-password';
export const WISHLIST_PAGE = 'wishlist';
export const INVITE_FRIEND_PAGE = 'invite-friend';
export const INVITE_FRIEND_REF_LINK_PAGE = 'ref';
export const INVITE_FRIEND_INACTIVE_REF_LINK_PAGE = 'inactive-ref';
export const WEB_LINKS_PAGE = 'web-links';
export const MOBILE_TOKEN_EXPIRED_PAGE = 'token-expired';
