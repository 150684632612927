import {
    GATEWAY_PAYU_CODE,
    GATEWAY_PAYU_CARD_CODE,
    GATEWAY_PAYMENTS_METHODS,
    GATEWAY_PAYPAL_EXPRESS_CODE,
    CARD_PAYMENT_CODE,
    PAYMENT_METHOD_CODES_WITH_DEDICATED_COMPONENT,
} from '@configs/checkout-payment-methods';

import {
    PAYMENT_PAYPAL_CANCELED_URL_KEY,
    PAYMENT_PAYPAL_CANCELED_QUERY_URL_KEY,
    PAYMENT_GATEWAY_REDIRECT_FAILED_QUERY_URL_KEY,
    PAYMENT_PAYU_STATUS_WARNING_CONTINUE_CVV,
    PAYMENT_PAYU_STATUS_CODE_QUERY_KEY,
} from '@configs/gateway-payments';

import { PAYMENTS_GIFT_CARD_KEY } from '@configs/storage';

export const isPayuMethodSelected = paymentMethodCode => {
    return (
        paymentMethodCode === GATEWAY_PAYU_CODE ||
        paymentMethodCode === GATEWAY_PAYU_CARD_CODE
    );
};

export const isGatewayPayment = code => GATEWAY_PAYMENTS_METHODS.includes(code);

export const isCardPayment = code =>
    [CARD_PAYMENT_CODE, GATEWAY_PAYU_CARD_CODE].includes(code);

export const isPayUCardPaymentNeedsCVV = query => {
    if (!Array.isArray(query) && typeof query === 'object' && query !== null) {
        const { [PAYMENT_PAYU_STATUS_CODE_QUERY_KEY]: statusCode } = query;

        return statusCode === PAYMENT_PAYU_STATUS_WARNING_CONTINUE_CVV;
    }

    return false;
};

export const checkForPaymentFailure = (methodCode, query, params) => {
    if (!isGatewayPayment(methodCode)) {
        return false;
    }

    const {
        [PAYMENT_GATEWAY_REDIRECT_FAILED_QUERY_URL_KEY]: gatewayRedirectError,
    } = query;

    if (gatewayRedirectError) {
        return true;
    }

    if (methodCode === GATEWAY_PAYU_CARD_CODE) {
        return isPayUCardPaymentNeedsCVV(query);
    }

    if (methodCode === GATEWAY_PAYPAL_EXPRESS_CODE) {
        const {
            [PAYMENT_PAYPAL_CANCELED_QUERY_URL_KEY]: isPaypalCancelToken,
        } = query;

        const { urlKey } = params;

        const isPaypalCancelPath = urlKey === PAYMENT_PAYPAL_CANCELED_URL_KEY;

        return !!(isPaypalCancelToken && isPaypalCancelPath);
    }

    return false;
};

export const getPayUEmbeddedFormConfigFromMethod = payUCardMethod => {
    if (!payUCardMethod) {
        return null;
    }

    const { code, additionalInfo } = payUCardMethod;

    if (
        !Object.values(PAYMENT_METHOD_CODES_WITH_DEDICATED_COMPONENT).includes(
            code
        ) ||
        !additionalInfo
    ) {
        return null;
    }

    const { posId, embeddedFormEnabled: isEnabled, sdkUrl } = additionalInfo;

    return {
        isEnabled,
        posId,
        sdkUrl,
    };
};

export const setGiftCardPaymentStore = async (app, dispatch) => {
    const cardDataItem = app.$storage.getItem(PAYMENTS_GIFT_CARD_KEY);

    if (cardDataItem) {
        const { cardData } = cardDataItem;

        if (cardData) {
            await dispatch('cart/setGiftCardData', { ...cardData });
        }
    }
};
