export const types = {
    SET_AVAILABILITY_NOTIFICATION_MODAL: 'SET_ENABLED_HEADER_PROMOTION_BLOCK',
    SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL:
        'SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL',
    SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL:
        'SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL',
    SET_SHOULD_OPEN_SNIPER_LINK_MODAL: 'SET_SHOULD_OPEN_SNIPER_LINK_MODAL',
    SET_IS_SIZE_MODAL_OPEN: 'SET_IS_SIZE_MODAL_OPEN',
};

const mutations = {
    [types.SET_AVAILABILITY_NOTIFICATION_MODAL](state, isOpen) {
        state.isOpenAvailabilityNotificationModal = isOpen;
    },

    [types.SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL](state, cartItemData) {
        state.cartItemData = cartItemData;
        state.isOpenAddToCartErrorModal = true;
    },

    [types.SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL](state) {
        state.isOpenAddToCartErrorModal = false;
        state.cartItemData = null;
    },

    [types.SET_SHOULD_OPEN_SNIPER_LINK_MODAL](
        state,
        shouldOpenSniperLinkModal
    ) {
        state.shouldOpenSniperLinkModal = shouldOpenSniperLinkModal;
    },

    [types.SET_IS_SIZE_MODAL_OPEN](state, isSizeModalOpen) {
        state.isSizeModalOpen = isSizeModalOpen;
    },
};

export default mutations;
