<template>
    <div class="footer-simple-wrapper">
        <FooterFreeDelivery class="free-delivery" />
        <FooterOrderQuestion />
        <FooterSimple />
    </div>
</template>

<script>
import FooterSimple from '@molecules/FooterSimple/FooterSimple';
import FooterOrderQuestion from '@molecules/FooterOrderQuestion/FooterOrderQuestion';
import FooterFreeDelivery from '@molecules/FooterFreeDelivery/FooterFreeDelivery';

export default {
    name: 'FooterSimpleWrapper',
    components: {
        FooterOrderQuestion,
        FooterFreeDelivery,
        FooterSimple,
    },
};
</script>

<style lang="scss" scoped>
.footer-simple-wrapper {
    .free-delivery {
        @apply pt-7;
    }
}
</style>
