<script>
import { CLICK_ACTION_EMIT, CLICK_ACTION_NAVIGATE } from '@types/ClickAction';

export default {
    name: 'HtmlLinkHandler',

    methods: {
        clickHandler(event) {
            const { clickAction } = event.target.dataset;

            switch (clickAction) {
                case CLICK_ACTION_EMIT:
                    this.emit(event);
                    break;
                case CLICK_ACTION_NAVIGATE:
                    this.navigate(event);
                    break;
                default:
                    break;
            }
        },

        navigate(event) {
            this.$emit(CLICK_ACTION_NAVIGATE, event);

            const href = event.target.getAttribute('href');

            if (href?.startsWith('/')) {
                event.preventDefault();
                this.$router.push(href);
            }
        },

        emit(event) {
            event.preventDefault();
            this.$emit('click', event);
        },
    },

    render() {
        return this.$scopedSlots.default({
            clickHandler: this.clickHandler,
        });
    },
};
</script>
