import {
    HOME_PAGE,
    LOGIN_PAGE,
    REGISTER_PAGE,
    RESET_PASSWORD_PAGE,
    NEW_PASSWORD_PAGE,
    CUSTOMER_PAGE,
    INVITE_FRIEND_INACTIVE_REF_LINK_PAGE,
} from '@router/paths';

export const getPathBackToShopping = path => {
    const PAGES_NOT_TO_REDIRECT = [
        `/${LOGIN_PAGE}`,
        `/${REGISTER_PAGE}`,
        `/${RESET_PASSWORD_PAGE}`,
        `/${NEW_PASSWORD_PAGE}`,
        `/${INVITE_FRIEND_INACTIVE_REF_LINK_PAGE}`,
    ];

    return !path ||
        PAGES_NOT_TO_REDIRECT.includes(path) ||
        path.includes(`/${CUSTOMER_PAGE}`)
        ? HOME_PAGE
        : path;
};
