import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { registerMediaQueryWatcher } from '@assets/media-query-watcher';

import { md as BREAKPOINT_MD } from '@theme/resources/variables/responsiveBreakpoints.json';

const TABLET_BREAKPOINT = `(min-width: ${BREAKPOINT_MD})`;

export default ({ app, store }) => {
    window.onNuxtReady(() => {
        try {
            registerMediaQueryWatcher(
                isTabletOrDesktop =>
                    store.dispatch('setIsTabletOrDesktop', isTabletOrDesktop),
                TABLET_BREAKPOINT
            );
        } catch (err) {
            app.$errorHandler.captureDomainError(CORE_ERROR_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'plugin.is-tablet-or-desktop-watcher',
            });
        }
    });
};
