import createStorage from '@services/storage';

export default ({ app, store }, inject) => {
    const { isSupported, storage } = createStorage(app.$errorHandler);

    inject('storage', storage);

    if (!isSupported) {
        store.dispatch('messages/addWarningMessage', {
            text: app.i18n.t(
                // eslint-disable-next-line max-len
                'The browser operating in the current mode does not allow our website to function properly. Go to the default mode or use a different browser'
            ),
        });
    }
};
