import { createNamespacedHelpers } from 'vuex';

import registerHeaderStoreModule from '@header/store/helpers/registerHeaderStoreModule';

import { CATALOG_PAGE_NAME } from '@search/routing/names';

import { USER_FIRST_VISIT_KEY } from '@configs/storage';

import { OG_IMAGE_IMPORT_NAME } from '@localeConfig/keys';
import { DEFAULT_OG_IMAGE, OG_IMAGES } from '@localeConfig/og-images';

import { formatDate } from '@assets/date';

import TitleBaseDomain from '@mixins/TitleBaseDomain';
import WithCanonicalUrl from '@mixins/WithCanonicalUrl';

import WithCustomer from '@layouts/mixins/WithCustomer';

const {
    mapState: mapConfigState,
    mapGetters: mapConfigGetters,
} = createNamespacedHelpers('config');

const { mapState: mapStateLayout } = createNamespacedHelpers('layout');

export default {
    mixins: [WithCanonicalUrl, WithCustomer, TitleBaseDomain],

    middleware: [registerHeaderStoreModule, 'global/normalized-path-redirect'],

    computed: {
        ...mapStateLayout(['htmlClasses']),
        ...mapConfigState(['baseUrl']),
        ...mapConfigGetters(['locale']),

        pageUrl() {
            return this.$absoluteUrl(this.$route.fullPath);
        },

        ogLogoPath() {
            const image = this.$getLocaleConfigByKey(OG_IMAGE_IMPORT_NAME);

            if (OG_IMAGES[image]) {
                return this.baseUrl + OG_IMAGES[image];
            }

            this.$errorHandler.captureError(
                new Error('No og image for given locale'),
                {},
                {
                    component: image,
                }
            );

            return this.baseUrl + DEFAULT_OG_IMAGE;
        },

        languageCode() {
            return this.locale.slice(0, 2);
        },
    },

    methods: {
        captureFirstVisitData() {
            const firstVisitDate = this.$storage.getItem(USER_FIRST_VISIT_KEY);

            if (!firstVisitDate) {
                this.$storage.setItem(
                    USER_FIRST_VISIT_KEY,
                    formatDate(new Date())
                );
            }
        },
    },

    beforeCreate() {
        registerHeaderStoreModule({ store: this.$store });
    },

    beforeMount() {
        this.captureFirstVisitData();
    },

    head() {
        const { baseUrl, titleBaseDomain } = this;

        let canonicalLink = [
            {
                hid: 'canonical',
                rel: 'canonical',
                href: this.canonicalUrl,
            },
        ];

        if (this.$route.name === CATALOG_PAGE_NAME) {
            canonicalLink = [];
        }

        return {
            titleTemplate: titleChunk =>
                titleChunk
                    ? `${titleChunk} | ${titleBaseDomain}`
                    : titleBaseDomain,
            link: [
                ...canonicalLink,
                {
                    hid: 'manifest',
                    rel: 'manifest',
                    href: `${process.env.assetsPath}manifest.${this.locale}.json`,
                },
            ],
            meta: [
                {
                    hid: 'og:url',
                    property: 'og:url',
                    content: this.pageUrl,
                },
                {
                    hid: 'og:site_name',
                    property: 'og:site_name',
                    content: titleBaseDomain,
                },
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: this.ogLogoPath,
                },
                {
                    hid: 'og:image:secure_url',
                    property: 'og:image:secure_url',
                    content: this.ogLogoPath,
                },
                {
                    hid: 'og:image:width',
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    hid: 'og:image:height',
                    property: 'og:image:height',
                    content: '630',
                },
                {
                    hid: 'og:image:alt',
                    property: 'og:image:alt',
                    content: titleBaseDomain,
                },
                {
                    hid: 'og:image:type',
                    property: 'og:image:type',
                    content: 'image/jpg',
                },
                {
                    hid: 'og:locale',
                    property: 'og:locale',
                    content: this.locale,
                },
            ],
            htmlAttrs: {
                class: this.htmlClasses.join(' '),
                lang: this.languageCode,
            },
            script: [
                {
                    hid: 'ldjson-schema-organization',
                    innerHTML: JSON.stringify({
                        '@context': 'http://schema.org/',
                        '@type': 'WebSite',
                        name: titleBaseDomain,
                        url: baseUrl,
                        sameAs: [
                            'https://www.youtube.com/channel/UCbmw7rr86pVh6197n0upy2g',
                            'https://www.facebook.com/modivopl/',
                            'https://www.instagram.com/modivo/',
                            'https://www.linkedin.com/company/eobuwie-pl-sa/',
                        ],
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: `${baseUrl}${decodeURIComponent(
                                this.$createSearchPath('{q}')
                            )}`,
                            'query-input': 'required name=q',
                        },
                    }),
                    type: 'application/ld+json',
                },
            ],
            __dangerouslyDisableSanitizersByTagID: {
                'ldjson-schema-organization': ['innerHTML'],
            },
        };
    },
};
