<template>
    <div class="legal-information-links">
        <BaseLinkStatic
            v-for="{ link, image, linkText } in links"
            :key="link"
            :path="link"
            target="_blank"
            class="image"
        >
            <BasePicture
                alt=""
                :src="image"
                :is-lazy="true"
                :width="144"
                :height="36"
            />
            <span class="sr-only">{{ linkText }}</span>
        </BaseLinkStatic>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    LEGAL_INFORMATION_FIRST,
    LEGAL_INFORMATION_SECOND,
} from '@localeConfig/keys';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'LegalInformationLinks',

    components: {
        BaseLinkStatic,
        BasePicture,
    },

    computed: {
        ...mapConfigGetters(['localeConfigByKey']),

        links() {
            return [
                this.localeConfigByKey(LEGAL_INFORMATION_FIRST),
                this.localeConfigByKey(LEGAL_INFORMATION_SECOND),
            ].filter(legalInformation => !!legalInformation);
        },
    },
};
</script>

<style lang="scss" scoped>
$image-width: 144px;
$image-height: 36px;

.legal-information-links {
    @apply flex gap-2;

    .image {
        width: $image-width;
        height: $image-height;
    }
}
</style>
