<template>
    <div class="navigation-bar">
        <nav class="navigation">
            <NavigationItem
                v-for="category in tree"
                :key="category.id"
                :category="category"
                :is-active="
                    currentlyHoveredCategory === category.id &&
                    isDesktopMenuOpen
                "
                @hover="handleMouseenter(category.id)"
                @mouseleave.native="handleMouseleave()"
            />
        </nav>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import NavigationItem from '@header-atoms/NavigationItem/NavigationItem';

const CURRENTLY_HOVERED_CATEGORY_TIMEOUT_VALUE = 300;
const LEAVE_CATEGORY_TIMEOUT_VALUE = CURRENTLY_HOVERED_CATEGORY_TIMEOUT_VALUE;
const UNSELECTED_CATEGORY_ID_VALUE = -1;

const {
    mapState: mapNavigationState,
    mapGetters: mapNavigationGetters,
    mapActions: mapNavigationActions,
} = createNamespacedHelpers('navigation');

export default {
    name: 'HeaderNavigationBar',

    components: {
        NavigationItem,
    },

    data() {
        return {
            currentlyHoveredCategory: -1,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationState(['isDesktopMenuOpen']),
        ...mapNavigationGetters(['mainCategories']),

        tree() {
            return this.mainCategories?.children || [];
        },
    },

    watch: {
        isMobile() {
            this.resetAllHoverConfiguration();
        },

        $route() {
            this.setMenuDesktopIsOpen(false);
        },
    },

    methods: {
        ...mapNavigationActions(['setMenuDesktopIsOpen']),

        handleMouseenter(categoryId) {
            const timeoutCallback = () => {
                this.currentlyHoveredCategory = categoryId;
                this.setMenuDesktopIsOpen(true);
            };

            if (
                this.currentlyHoveredCategory !== UNSELECTED_CATEGORY_ID_VALUE
            ) {
                this.clearLeaveCategoryTimeout();
                this.clearCurrentlyHoveredCategoryTimeout();
            }

            this.currentlyHoveredCategoryTimeout = setTimeout(
                timeoutCallback,
                CURRENTLY_HOVERED_CATEGORY_TIMEOUT_VALUE
            );
        },

        handleMouseleave() {
            this.clearCurrentlyHoveredCategoryTimeout();

            this.leaveCategoryTimeout = setTimeout(() => {
                this.currentlyHoveredCategory = UNSELECTED_CATEGORY_ID_VALUE;
                this.setMenuDesktopIsOpen(false);
            }, LEAVE_CATEGORY_TIMEOUT_VALUE);
        },

        resetAllHoverConfiguration() {
            this.clearCurrentlyHoveredCategoryTimeout();

            this.clearLeaveCategoryTimeout();

            this.currentlyHoveredCategory = UNSELECTED_CATEGORY_ID_VALUE;
        },

        clearCurrentlyHoveredCategoryTimeout() {
            clearTimeout(this.currentlyHoveredCategoryTimeout);

            this.currentlyHoveredCategoryTimeout = null;
        },

        clearLeaveCategoryTimeout() {
            clearTimeout(this.leaveCategoryTimeout);

            this.leaveCategoryTimeout = null;
        },
    },
};
</script>

<style scoped lang="scss">
.navigation-bar {
    .navigation {
        @apply flex items-center gap-x-ui-5 h-full whitespace-nowrap;
    }

    @screen lg {
        @apply px-ui-7 max-w-[1440px] mx-auto h-ui-12;
    }

    @media (min-width: 1280px) {
        .navigation {
            @apply gap-x-ui-7;
        }
    }
}
</style>
