import { isValidIban, getIbanExample } from '@assets/iban';

export const IBAN_WITH_CODE_PARAM_RULE_NAME = 'ibanWithCodeParam';

const IBAN_CODE_LENGTH = 2;

const ibanValidationRule = i18n => {
    let iban = '';

    return {
        validate: (value, [ibanCode]) => {
            iban = `${ibanCode}${value}`;

            return isValidIban(iban);
        },
        message: () => {
            const ibanExample = getIbanExample(iban);

            if (!ibanExample) {
                return i18n.t(
                    '__validations.__rma.isNotValidBankAccountNumber'
                );
            }

            const numberWithoutCountryCode = ibanExample.slice(
                IBAN_CODE_LENGTH
            );

            return `${i18n.t(
                '__validations.__rma.checkIfBankAccountNumberIsCorrect'
            )} ${numberWithoutCountryCode}.`;
        },
    };
};

export default i18n => ({
    name: IBAN_WITH_CODE_PARAM_RULE_NAME,
    validation: ibanValidationRule(i18n),
});
