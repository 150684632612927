import { parseCategoryUrl } from '@assets/path';
import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

export default async function ({
    store,
    params,
    route,
    query: { category },
    $filterManager,
    $fetchInParallel,
    error,
}) {
    const { url } = params;
    const { categoryUrl, filters } = parseCategoryUrl(url);
    const isSearchPage = route.name === SEARCH_RESULTS_PAGE_NAME;

    if (!isSearchPage && !categoryUrl) {
        await $fetchInParallel();

        return error({
            statusCode: 404,
        });
    }

    store.dispatch('category/setUrl', isSearchPage ? category : categoryUrl);

    store.dispatch('category/setQuery', isSearchPage ? url.split('/')[0] : '');

    $filterManager.setUrlFilters(filters);
}
