import { sortScans } from '@assets/esizeme';

export default {
    isEsizemeApiEnabled: state =>
        state.esizemeStoreConfig?.isEsizemeApiEnabled || false,

    isEsizemeProductPageEnabled: (state, getters) =>
        (getters.isEsizemeApiEnabled &&
            state.esizemeStoreConfig?.isEsizemeProductPageEnabled) ||
        false,

    isEsizemeCategoryPagesEnabled: (state, getters) =>
        (getters.isEsizemeApiEnabled &&
            state.esizemeStoreConfig?.isEsizemeCategoryPagesEnabled) ||
        false,

    esizemeFilterRangeBoundaries: state => [
        state.esizemeStoreConfig?.esizemeCategoryPagesRangeBelow,
        state.esizemeStoreConfig?.esizemeCategoryPagesRangeAbove,
    ],

    guestScansSorted: ({ guestScans }) => sortScans(guestScans),

    customerScansSorted: ({ customerScans }) => sortScans(customerScans),

    userScansSorted: (
        _,
        { guestScansSorted, customerScansSorted },
        __,
        rootGetters
    ) =>
        rootGetters['customer/isLoggedIn']
            ? customerScansSorted
            : guestScansSorted,

    selectedScan: (state, _, __, rootGetters) => {
        const { selectedGuestScan, selectedCustomerScan } = state;

        return rootGetters['customer/isLoggedIn']
            ? selectedCustomerScan
            : selectedGuestScan;
    },
};
