export const types = {
    SET_SEO_DATA: 'SET_SEO_DATA',
};

const mutations = {
    [types.SET_SEO_DATA](state, seoData) {
        state.seoData = seoData;
    },
};

export default mutations;
