import errorHandler from '@errors/plugin/errorHandler';
import featureDomainNames from '@errors/feature-domain-names';

export default async (
    {
        app: { $sentry, $sentryLoad, $sentryReady, $correlation },
        env,
        route: { name: routeName },
        store,
    },
    inject
) => {
    const errorHandlerInstance = errorHandler({
        env,
        $sentry,
        $sentryLoad,
        $sentryReady,
        routeName,
        locale: store.getters['config/locale'],
        correlationId: $correlation.correlationId,
    });

    inject('errorHandler', errorHandlerInstance);

    featureDomainNames.forEach(featureDomainName => {
        errorHandlerInstance.registerFeatureDomain(featureDomainName);
    });
};
