<template>
    <nuxt-link
        v-if="path"
        :class="extendedLinkClasses"
        :to="path"
        :title="title"
        :event="disabled ? '' : 'click'"
    >
        <template v-if="hasIcon">
            <span>
                <slot name="icon" />
            </span>
            <span v-if="showIconWithLabel" class="link-label">
                <slot>{{ label }}</slot>
            </span>
        </template>
        <template v-else>
            <slot>{{ label }}</slot>
        </template>
    </nuxt-link>
</template>

<script>
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import Link from '@mixins/Link';

export default {
    name: 'BaseLink',

    mixins: [Link],

    props: {
        path: {
            type: [String, Object],
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        showIconWithLabel: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        extendedLinkClasses() {
            return {
                ...this.linkClasses,
                disabled: this.disabled,
                'nuxt-link-exact-active': this.$route.path === this.path,
            };
        },
    },

    created() {
        // Temporary logging invalid NuxtLink usage
        if (!this.path) {
            this.$errorHandler.captureError(
                new Error('Invalid NuxtLink'),
                {
                    [ERROR_ACTION_TAG_NAME]: 'NuxtLink',
                },
                {
                    ...this.$props,
                }
            );
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/components/links';
</style>
