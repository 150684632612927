import { LOCAL_WISHLIST_LIMIT } from '@configs/wishlist';
import { LOGIN_METHOD } from '@configs/storage';
import {
    AVAILABLE_SOCIAL_PROVIDERS,
    FACEBOOK_PROVIDER,
    GOOGLE_PROVIDER,
} from '@configs/auth-social-providers';

import Deferred from '@core-assets/deferred';

import { ERROR_ANALYTICS_TAG_NAME } from '@analytics-types/Errors';
import {
    USER_LOGIN_LABEL_EMAIL,
    USER_LOGIN_LABEL_FACEBOOK,
    USER_LOGIN_LABEL_GOOGLE,
} from '@analytics-types/Analytics';
import {
    WISHLIST_ADD,
    WISHLIST_REMOVE,
    INACTIVE_TOKEN_AUTO_LOGOUT,
    THRUD_ERROR_AUTO_LOGOUT,
} from '@analytics-types/Events';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { UNAUTHENTICATED } from '@types/GraphQLErrorCodes';

import { STORE_MODULE_NAME as LOYALTY_STORE_MODULE_NAME } from '@loyalty-club/store/meta';

import { LOGIN_PAGE } from '@router/paths';

import CustomerAddress from '@models/Customer/CustomerAddress';
import DataLayerProduct from '@models/Analytics/DataLayerProduct';
import CheckoutLocalStorage from '@models/Checkout/CheckoutLocalStorage';

import { shouldRefreshToken } from '@assets/auth';
import { saveUserIdForGTM } from '@assets/gtm-native-enhanced-conversion';
import { formatBirthdate } from '@assets/birthdate';

import { types } from './mutations';

const MODULE_NAME = 'customer';

const emitWishlistEvent = async (eventName, { app, rootGetters, product }) => {
    const totalCountGetter = rootGetters['product/getProductReviewTotalCount'];
    const averageRating = rootGetters['product/getProductReviewAverageRating'];

    const productReviewTotalCount = totalCountGetter(product.sku);

    const productData = {
        product,
        variantId: product.variants?.[product.chosenSize]?.id || '',
    };

    if (productReviewTotalCount && averageRating) {
        productData.reviews = {
            totalCountAll: productReviewTotalCount,
            averageRatings: [{ value: averageRating }],
        };
    }

    const dataLayerProduct = new DataLayerProduct(productData).build();

    if (!dataLayerProduct) {
        return;
    }

    try {
        app.$analytics.emit(eventName, {
            sku: product.sku,
            currency: product.currency,
            product: dataLayerProduct,
            pageType: product.pageType,
            actionFieldList: product.actionField,
        });
    } catch (err) {
        app.$errorHandler.captureStoreError(
            MODULE_NAME,
            err,
            {
                [ERROR_ACTION_TAG_NAME]: 'analytics',
                [ERROR_ANALYTICS_TAG_NAME]: eventName,
            },
            {
                product,
            }
        );
    }
};

const emitAddToWishlistEvent = async context => {
    await emitWishlistEvent(WISHLIST_ADD, context);
};

const emitRemoveFromWishlistEvent = async context => {
    await emitWishlistEvent(WISHLIST_REMOVE, context);
};

let refreshTokenDeferred = null;

export default {
    async mergeLocalWishlist({ state, commit }) {
        const { wishlist } = state;

        if (wishlist.length === 0) {
            return;
        }

        const {
            isAdded,
        } = await this.app.$services.customer.addWishlistProducts(wishlist);

        if (!isAdded) {
            return;
        }

        this.app.$localWishlist.clear();

        const wishlistCount = await this.app.$services.customer.getCustomerWishlistCount();

        commit(types.SET_CUSTOMER_WISHLIST_COUNT, wishlistCount);
    },

    initLocalWishlist({ commit }) {
        commit(types.SET_WISHLIST, this.app.$localWishlist.get());
    },

    syncLocalWishlist({ state }) {
        this.app.$localWishlist.set(
            state.wishlist.slice(0, LOCAL_WISHLIST_LIMIT)
        );
    },

    async setWishlist({ commit }, wishlist = []) {
        commit(types.SET_WISHLIST, wishlist);
    },

    async checkWishlist({ commit, getters }, skus = []) {
        if (!getters.isLoggedIn) {
            return;
        }

        const wishlistProducts = await this.app.$services.customer.wishlistProducts(
            skus
        );

        commit(types.SET_WISHLIST, wishlistProducts);
    },

    async addToWishlist({ commit, dispatch, rootGetters, getters }, product) {
        const { sku } = product;

        const emitAddToWishlist = emitAddToWishlistEvent.bind(this, {
            app: this.app,
            rootGetters,
            product,
        });

        if (!getters.isLoggedIn) {
            commit(types.ADD_TO_WISHLIST, [sku]);
            dispatch('syncLocalWishlist');
            emitAddToWishlist();
        } else {
            const {
                isAdded,
            } = await this.app.$services.customer.addWishlistProducts([sku]);

            if (!isAdded) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Adding to wishlist failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return;
            }

            commit(types.ADD_TO_WISHLIST, [sku]);
            commit(types.INCREMENT_CUSTOMER_WISHLIST_COUNT);
            emitAddToWishlist();
        }
    },

    async removeFromWishlist(
        { commit, dispatch, rootGetters, getters },
        product
    ) {
        const { sku } = product;

        const emitRemoveFromWishlist = emitRemoveFromWishlistEvent.bind(this, {
            app: this.app,
            rootGetters,
            product,
        });

        if (!getters.isLoggedIn) {
            commit(types.REMOVE_FROM_WISHLIST, sku);
            dispatch('syncLocalWishlist');
            emitRemoveFromWishlist();
        } else {
            const {
                isDeleted,
            } = await this.app.$services.customer.deleteWishlistProducts([sku]);

            if (!isDeleted) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Removal from wishlist failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return;
            }

            commit(types.REMOVE_FROM_WISHLIST, sku);
            commit(types.DECREMENT_CUSTOMER_WISHLIST_COUNT);
            emitRemoveFromWishlist();
        }
    },

    async handleLoggedCustomer({ dispatch, rootGetters, getters }, type) {
        const { $storage, $errorHandler } = this.app;

        saveUserIdForGTM({
            userId: getters.customerUuid,
            $errorHandler,
            $storage,
        });

        dispatch('esizeme/loadEsizemeCustomerScans', null, { root: true });

        if (rootGetters['config/isAccountVerificationEnabled']) {
            await dispatch('avatar/checkAccountVerificationStatus', null, {
                root: true,
            });
        }

        await dispatch('mergeLocalWishlist');

        await dispatch('cart/handleCustomerCart', null, {
            root: true,
        });

        this.$storage.setItem(LOGIN_METHOD, type);

        if (!this.$avatar.avatarId) {
            this.$avatar.createAvatarId();
        }

        dispatch('avatar/connectClient', this.$avatar.avatarId, { root: true });
    },

    async setCustomerBirthdate({ dispatch }, { day, month, year }) {
        if (!day || !month || !year) {
            return false;
        }

        const data = await this.$services.customer.updateCustomerNonSensitiveData(
            {
                dateOfBirth: formatBirthdate(day, month, year),
            }
        );

        const { date_of_birth: dateOfBirth } = data || {};

        if (!dateOfBirth) {
            return false;
        }

        dispatch(
            'setCustomerData',
            await this.app.$services.customer.getCustomer()
        );

        return true;
    },

    async handleAuthTokens({ dispatch }) {
        const {
            accessToken = null,
            refreshToken = null,
            accessTokenExpirationISO = null,
            refreshTokenExpirationISO = null,
        } = await this.$services.auth.readAuthTokens();

        if (
            shouldRefreshToken(
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO
            ) &&
            process.client
        ) {
            await dispatch('refreshAuthToken', refreshToken);

            return;
        }

        await dispatch('setAuthorizationData', {
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        });
    },

    async login({ dispatch, getters }, { email, password }) {
        let result = true;

        dispatch('setFetchingCustomerInProgressFlag', true);

        const showErrorMessage = () => {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'Some error occured during logging in'
                    ),
                    timeout: 10000,
                },
                { root: true }
            );
        };

        try {
            const {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            } = await this.app.$services.customer.generateAuthToken(
                email,
                password
            );

            if (
                !accessToken ||
                !refreshToken ||
                !accessTokenExpirationISO ||
                !refreshTokenExpirationISO
            ) {
                result = false;

                showErrorMessage();

                dispatch('setFetchingCustomerInProgressFlag', false);

                return result;
            }

            await dispatch('saveAuthTokens', {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            });

            dispatch(
                'setCustomerData',
                await this.app.$services.customer.getCustomer()
            );

            if (!getters.isLoggedIn) {
                throw new Error('Unsuccessful get customer data in login');
            }

            dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Successful login.') },
                { root: true }
            );

            await dispatch('handleLoggedCustomer', USER_LOGIN_LABEL_EMAIL);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'customer.login',
            });

            showErrorMessage();

            result = false;
        }

        dispatch('setFetchingCustomerInProgressFlag', false);

        return result;
    },

    async socialLogin(
        { dispatch, getters },
        { token, provider, referrerToken = undefined }
    ) {
        let result = {
            loginSuccessful: false,
            isRegistration: false,
        };

        if (
            !provider ||
            !token ||
            !AVAILABLE_SOCIAL_PROVIDERS.includes(provider)
        ) {
            return result;
        }

        const {
            generateAuthTokenWithFacebook,
            generateAuthTokenWithGoogle,
        } = this.app.$services.customer;
        const { i18n } = this.app;

        const AUTH = {
            [FACEBOOK_PROVIDER]: generateAuthTokenWithFacebook,
            [GOOGLE_PROVIDER]: generateAuthTokenWithGoogle,
        };
        const PROVIDERS_DATA = {
            [FACEBOOK_PROVIDER]: {
                errorMessage: i18n.t(
                    'Some error occured during logging in with Facebook'
                ),
                loginMethod: USER_LOGIN_LABEL_FACEBOOK,
                successMessage: i18n.t('Successful Facebook login.'),
            },
            [GOOGLE_PROVIDER]: {
                errorMessage: i18n.t(
                    'Some error occured during logging in with Google'
                ),
                loginMethod: USER_LOGIN_LABEL_GOOGLE,
                successMessage: i18n.t('Successful Google login.'),
            },
        };

        const providerData = PROVIDERS_DATA[provider];

        dispatch('setFetchingCustomerInProgressFlag', true);

        try {
            const {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                isRegistration,
            } = await AUTH[provider](token, referrerToken);

            result = {
                loginSuccessful:
                    !!accessToken &&
                    !!refreshToken &&
                    !!accessTokenExpirationISO &&
                    !!refreshTokenExpirationISO,
                isRegistration,
            };

            if (result.loginSuccessful) {
                await dispatch('saveAuthTokens', {
                    accessToken,
                    refreshToken,
                    accessTokenExpirationISO,
                    refreshTokenExpirationISO,
                });

                dispatch(
                    'setCustomerData',
                    await this.app.$services.customer.getCustomer()
                );

                if (!getters.isLoggedIn) {
                    throw new Error(
                        `Unsuccessful get customer data in login with ${provider}`
                    );
                }

                await dispatch(
                    'handleLoggedCustomer',
                    providerData.loginMethod
                );

                dispatch(
                    'messages/addSuccessMessage',
                    { text: providerData.successMessage },
                    { root: true }
                );
            } else {
                throw new Error(`Unsuccessful ${provider} login.`);
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'customer.socialLogin',
                },
                {
                    provider,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: providerData.errorMessage,
                },
                { root: true }
            );

            result = {
                loginSuccessful: false,
                isRegistration: false,
            };
        }

        dispatch('setFetchingCustomerInProgressFlag', false);

        return result;
    },

    async loginWithMagicLink({ dispatch, getters }, magicLink) {
        let result = true;

        dispatch('setFetchingCustomerInProgressFlag', true);

        try {
            const {
                success,
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            } = await this.app.$services.customer.generateAuthTokenWithMagicLink(
                magicLink
            );

            if (!success) {
                throw new Error('Unsuccessful login with magicLink');
            }

            await dispatch('saveAuthTokens', {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            });

            await dispatch(
                'setCustomerData',
                await this.app.$services.customer.getCustomer()
            );

            if (!getters.isLoggedIn) {
                throw new Error('Unsuccessful login with magicLink');
            }

            await dispatch('handleLoggedCustomer', USER_LOGIN_LABEL_EMAIL);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]:
                        'customer.generateAuthTokenWithMagicLink',
                },
                {
                    error: JSON.stringify(err),
                }
            );

            result = false;
        }

        dispatch('setFetchingCustomerInProgressFlag', false);

        return result;
    },

    async saveAuthTokens(
        { dispatch },
        {
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        }
    ) {
        if (!accessToken) {
            return;
        }

        await this.$services.auth.saveAuthTokens({
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        });

        await dispatch('setAuthorizationData', {
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        });
    },

    async removeAuthTokens({ dispatch }) {
        await this.$services.auth.removeAuthTokens();

        await dispatch('setAuthorizationData', {
            accessToken: null,
            refreshToken: null,
            accessTokenExpirationISO: null,
            refreshTokenExpirationISO: null,
        });
    },

    async refreshAuthToken({ dispatch }, refreshTokenValue) {
        if (refreshTokenDeferred?.promise) {
            return refreshTokenDeferred.promise;
        }

        refreshTokenDeferred = new Deferred();

        const {
            success,
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        } = await this.app.$services.customer.refreshAuthToken(
            refreshTokenValue
        );

        if (!success) {
            this.$analytics.emit(THRUD_ERROR_AUTO_LOGOUT);

            await dispatch('handleExpiredSession', false);
        } else {
            await dispatch('saveAuthTokens', {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            });
        }

        refreshTokenDeferred.resolve();

        refreshTokenDeferred = null;
    },

    async registerCustomer({ dispatch }, registerData) {
        const result = await this.app.$services.customer.createCustomer(
            registerData
        );

        if (!result) {
            dispatch(
                'messages/addErrorMessage',
                { text: this.app.i18n.t('Customer register error occured') },
                { root: true }
            );

            return false;
        }

        dispatch(
            'messages/addSuccessMessage',
            { text: this.app.i18n.t('Successful register.') },
            { root: true }
        );

        return true;
    },

    async logout({ dispatch, rootGetters }, params) {
        const { showSuccessMessage = true, withRevokeTokenRequest = true } =
            params || {};

        try {
            new CheckoutLocalStorage(this.app.$storage).clear();

            const {
                isWebView,
                hasAuthorizationTokensFromMobileApp,
            } = this.$mobileApp;
            const isMobileToken =
                isWebView && hasAuthorizationTokensFromMobileApp;

            if (withRevokeTokenRequest && !isMobileToken) {
                await this.app.$services.customer.revokeAuthToken();
            }

            await dispatch('removeAuthTokens');
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'customer.revokeAuthToken',
            });
            dispatch('messages/addErrorMessage', { text: err }, { root: true });
        }

        dispatch('setCustomerData', null);

        if (this.app.store.hasModule(LOYALTY_STORE_MODULE_NAME)) {
            dispatch(
                `${LOYALTY_STORE_MODULE_NAME}/clearLoyaltyMemberData`,
                null,
                { root: true }
            );
        }

        dispatch('esizeme/clearCustomerEsizemeScans', null, { root: true });

        if (rootGetters['config/isAccountVerificationEnabled']) {
            dispatch('avatar/clearAccountVerificationData', null, {
                root: true,
            });
        }

        this.$storage.removeItem(LOGIN_METHOD);

        await dispatch('cart/handleCartAfterLogout', null, {
            root: true,
        });

        if (showSuccessMessage) {
            dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Successful logout.') },
                { root: true }
            );
        }

        // redirect to nuxt loggin page if user is not in nuxt app
        if (!this.app.nuxt) {
            window.location.href = `${window.location.origin}/${LOGIN_PAGE}`;
        }
    },

    async getCustomerData({ dispatch, state, rootGetters, getters }) {
        try {
            if (!state.accessToken) {
                await dispatch('handleAuthTokens');
            }

            const customerData = await this.app.$services.customer.getCustomer();

            await dispatch('setCustomerData', customerData);

            if (getters.customerUuid) {
                const { $storage, $errorHandler } = this.app;

                saveUserIdForGTM({
                    userId: getters.customerUuid,
                    $errorHandler,
                    $storage,
                });
            }

            if (
                rootGetters['config/isAccountVerificationEnabled'] &&
                getters.isLoggedIn
            ) {
                await dispatch('avatar/checkAccountVerificationStatus', null, {
                    root: true,
                });
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'customer.getCustomer',
            });

            dispatch(
                'messages/addErrorMessage',
                { text: this.app.i18n.t('Fetching customer error occured') },
                { root: true }
            );
        }
    },

    async setCustomerData({ dispatch, commit }, customerData = null) {
        commit(types.SET_CUSTOMER_DATA, customerData);
        await dispatch('setIsCustomerSubscribedToNewsletter');
    },

    setFetchingCustomerInProgressFlag({ commit }, fetchingInProgress) {
        commit(
            types.SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS,
            fetchingInProgress
        );
    },

    async setIsCustomerSubscribedToNewsletter({ commit, getters }) {
        let isCustomerSubscribedToNewsletter = false;

        if (getters.isLoggedIn) {
            // eslint-disable-next-line max-len
            isCustomerSubscribedToNewsletter = await this.app.$services.customer.getCustomerIsSubscribedToNewsletter();
        }

        commit(
            types.SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER,
            isCustomerSubscribedToNewsletter
        );
    },

    async setAuthorizationData(
        { commit },
        {
            accessToken = null,
            refreshToken = null,
            accessTokenExpirationISO = null,
            refreshTokenExpirationISO = null,
        } = {}
    ) {
        if (!process.client) {
            return;
        }

        commit(types.SET_ACCESS_TOKEN, accessToken);
        commit(types.SET_REFRESH_TOKEN, refreshToken);
        commit(types.SET_ACCESS_TOKEN_EXPIRATION_ISO, accessTokenExpirationISO);
        commit(
            types.SET_REFRESH_TOKEN_EXPIRATION_ISO,
            refreshTokenExpirationISO
        );
    },

    async updateCustomer(
        { dispatch, commit },
        { formData, updatedFirstname, updatedLastname }
    ) {
        const {
            customer,
            errorCode,
            errorMessages,
        } = await this.app.$services.customer.updateCustomer(formData);

        if (!customer) {
            if (errorCode === UNAUTHENTICATED) {
                return { success: false, invalidPassword: true };
            }

            if (!errorMessages.length) {
                errorMessages.push(
                    this.app.i18n.t(
                        'An unexpected error has occurred. Please try again.'
                    )
                );
            }

            errorMessages.forEach(errorMessage => {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: errorMessage,
                        hasIcon: false,
                    },
                    { root: true }
                );
            });

            return { success: false, invalidPassword: false };
        }

        commit(types.UPDATE_CUSTOMER_DATA, customer);

        let successMessageText = '';

        if (updatedFirstname && updatedLastname) {
            successMessageText = 'Firstname and lastname has been changed.';
        } else if (updatedFirstname && !updatedLastname) {
            successMessageText = 'Firstname has been changed.';
        } else if (!updatedFirstname && updatedLastname) {
            successMessageText = 'Lastname has been changed.';
        } else {
            successMessageText = 'Successful details update';
        }

        dispatch(
            'messages/addSuccessMessage',
            {
                text: this.app.i18n.t(successMessageText),
                hasIcon: false,
            },
            { root: true }
        );

        return { success: true, invalidPassword: false };
    },

    async changeCustomerPassword(
        { dispatch },
        { currentPassword, newPassword }
    ) {
        const {
            result,
            errorCode,
        } = await this.app.$services.customer.changeCustomerPassword(
            currentPassword,
            newPassword
        );

        if (!result) {
            if (errorCode === UNAUTHENTICATED) {
                return { success: false, invalidPassword: true };
            }

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        'An unexpected error has occurred. Please try again.'
                    ),
                    hasIcon: false,
                },
                { root: true }
            );

            return { success: false, invalidPassword: false };
        }

        dispatch(
            'messages/addSuccessMessage',
            {
                text: this.app.i18n.t('Password changed successfuly'),
                hasIcon: false,
            },
            { root: true }
        );

        return { success: true, invalidPassword: false };
    },

    async createCustomerAddress(
        { dispatch },
        {
            customerAddressInput,
            showSuccessMessage = true,
            showErrorMessage = true,
        }
    ) {
        const magentoAddedCustomerAddress = await this.app.$services.customer.createCustomerAddress(
            customerAddressInput
        );

        if (!magentoAddedCustomerAddress) {
            if (showErrorMessage) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Creating address failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );
            }

            return null;
        }

        if (showSuccessMessage) {
            dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Address has been added.') },
                { root: true }
            );
        }

        await dispatch('getCustomerData');

        const addedCustomerAddress = new CustomerAddress();

        addedCustomerAddress.copyFromMagentoCustomerAddress(
            magentoAddedCustomerAddress
        );

        return addedCustomerAddress;
    },

    async deleteCustomerAddress(
        { dispatch },
        {
            customerAddressId,
            showSuccessMessage = true,
            showErrorMessage = true,
        }
    ) {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const confirmDelete = confirm(
            this.app.i18n.t('Are you sure you want to delete this address?')
        );

        if (!confirmDelete) {
            return { wasDeleted: false, confirmDelete: false };
        }

        const result = await this.app.$services.customer.deleteCustomerAddress(
            customerAddressId
        );

        if (!result) {
            if (showErrorMessage) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Removing address failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );
            }

            return { wasDeleted: false, confirmDelete: true };
        }

        if (showSuccessMessage) {
            dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Address has been removed.') },
                { root: true }
            );
        }

        dispatch('getCustomerData');

        return { wasDeleted: true, confirmDelete: true };
    },

    async updateCustomerAddress(
        { dispatch },
        {
            customerAddressInput,
            customerAddressId,
            showSuccessMessage = true,
            showErrorMessage = true,
        }
    ) {
        const result = await this.app.$services.customer.updateCustomerAddress(
            customerAddressInput,
            customerAddressId
        );

        if (!result) {
            if (showErrorMessage) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Changing address failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );
            }

            return false;
        }

        if (showSuccessMessage) {
            dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Address has been changed.') },
                { root: true }
            );
        }

        dispatch('getCustomerData');

        return true;
    },

    async handleExpiredSession({ dispatch }, withRevokeTokenRequest = true) {
        if (withRevokeTokenRequest) {
            this.$analytics.emit(INACTIVE_TOKEN_AUTO_LOGOUT);
        }

        await dispatch('logout', {
            showSuccessMessage: false,
            withRevokeTokenRequest,
        });

        dispatch(
            'messages/addErrorMessage',
            {
                text: this.app.i18n.t(
                    'Your session has expired. Please log in again.'
                ),
            },
            { root: true }
        );
    },

    setShouldOpenModalNewsletterConfirmation(
        { commit },
        shouldOpenModalNewsletterConfirmation
    ) {
        commit(
            types.SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION,
            shouldOpenModalNewsletterConfirmation
        );
    },

    setShouldShowNewsletterConfirmationSection(
        { commit },
        shouldShowNewsletterConfirmationSection
    ) {
        commit(
            types.SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION,
            shouldShowNewsletterConfirmationSection
        );
    },
};
