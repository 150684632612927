<template>
    <a :class="linkClasses" :href="path" :title="title" :target="target">
        <template v-if="hasIcon">
            <span>
                <slot name="icon" />
            </span>
            <span class="link-label">
                <slot>{{ label }}</slot>
            </span>
        </template>
        <template v-else>
            <slot>{{ label }}</slot>
        </template>
    </a>
</template>

<script>
import Link from '@mixins/Link';

export default {
    name: 'BaseLinkStatic',

    mixins: [Link],

    props: {
        path: {
            type: String,
            required: true,
        },

        target: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/components/links';
</style>
