import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { createProductsRequestParams } from '@assets/request/create-products-request-params';

import { prepareSearchDataFromCorrectEngine } from '@search/utils/prepare/prepare-search-data-from-correct-engine';
import { prepareSearchData } from '@search/utils/prepare/prepare-search-data';

import { types } from './mutations';

export default {
    setUrl({ commit }, url) {
        commit(types.SET_URL, url);
    },

    setCategory({ commit }, { navigation, skus, total, productsAnalytics }) {
        commit(types.SET_CATEGORY_NAVIGATION, navigation);
        commit(types.SET_CATEGORY_PRODUCTS_SKUS, skus);
        commit(types.SET_FILTERED_PRODUCTS_COUNT, total);
        commit(types.SET_PRODUCTS_ANALYTICS, productsAnalytics);
    },

    setQuery({ commit }, query) {
        commit(types.SET_QUERY, query);
    },

    setCurrentProduct({ commit }, product) {
        commit(types.SET_CURRENT_PRODUCT, product);
    },

    setLastUrl({ commit }, url) {
        commit(types.SET_LAST_URL, url);
    },

    setLoading({ commit }, loading) {
        commit(types.SET_LOADING, loading);
    },

    async reloadFilters({ rootState, commit }) {
        const {
            pagination,
            sort,
            category: { query, url },
            config: {
                storeView: { base_currency_code: currency, locale },
            },
        } = rootState;

        let pageData = null;

        const { leftoverFilters, selectedFilters } = this.app.$filterManager;

        const filtersToApply = {
            ...leftoverFilters,
            ...selectedFilters,
        };

        try {
            const productsRequestParams = createProductsRequestParams({
                pagination,
                sort,
                filters: filtersToApply,
                categoryUrl: url,
                locale,
                currency,
            });

            let promise;

            if (
                this.app.router.currentRoute.name === SEARCH_RESULTS_PAGE_NAME
            ) {
                promise = prepareSearchDataFromCorrectEngine({
                    searchRequestParams: {
                        ...productsRequestParams,
                        query,
                        limit: 0,
                    },
                    store: this.app.store,
                    $abTests: this.app.$abTests,
                    $asyncServices: this.app.$asyncServices,
                    $errorHandler: this.app.$errorHandler,
                });
            } else {
                // todo after SEARCH-3613 in future it will be prepareFiltersData
                promise = prepareSearchData({
                    $asyncServices: this.app.$asyncServices,
                    $errorHandler: this.app.$errorHandler,
                    searchRequestParams: {
                        ...productsRequestParams,
                        query,
                        limit: 0,
                    },
                });
            }

            pageData = await promise;
        } catch (err) {
            this.app.$errorHandler.captureStoreError('category', err, {
                [ERROR_ACTION_TAG_NAME]: 'categoryReloadFilters',
            });

            return;
        }

        const { filters, searchQuery, total } = pageData;

        commit(types.SET_FILTERED_PRODUCTS_COUNT, total);

        this.app.$filterManager.initManager({
            filters,
            searchQueryFilters: searchQuery.filters || {},
            searchQueryLocalized: searchQuery.localized_filters || {},
        });
    },
};
