import RMA_SERVICES_NAMESPACE from '@rma/async-services/namespace';

export const NAME = `${RMA_SERVICES_NAMESPACE}/returns`;

export const GET_RETURNS_LIST = 'GET_RETURNS_LIST';
export const GET_RETURN_DETAILS = 'GET_RETURN_DETAILS';

export const ASYNC_RETURNS_LIST_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_RETURNS_LIST,
        GET_RETURN_DETAILS,
    },
};
