import {
    QUERY_CURRENT_PAGE,
    QUERY_PAGE_SIZE,
} from '@configs/query-keys/pagination';
import {
    DEFAULT_CURRENT_PAGE,
    DEFAULT_PAGE_SIZE,
} from '@configs/defaults/query/pagination';
import { VALID_ORDERS } from '@configs/defaults/query/sort';
import { QUERY_ORDER, QUERY_ORDER_DIR } from '@configs/query-keys/sort';
import queryParser from '@assets/query/parser';

export default async function ({ store, query }) {
    const { pagination, sort } = queryParser.groupQueries(query);

    const {
        [QUERY_CURRENT_PAGE]: currentPage,
        [QUERY_PAGE_SIZE]: pageSize,
    } = pagination;

    const parsedPage = parseInt(currentPage, 10);
    const page = Number.isNaN(parsedPage) ? DEFAULT_CURRENT_PAGE : parsedPage;

    const parsedPageSize = parseInt(pageSize, 10);
    const size = Number.isNaN(pageSize) ? DEFAULT_PAGE_SIZE : parsedPageSize;

    const {
        currentPage: stateCurrentPage,
        pageSize: statePageSize,
    } = store.state.pagination;

    if (page !== stateCurrentPage || size !== statePageSize) {
        store.dispatch('pagination/setPaginationFromQuery', {
            currentPage: page,
            pageSize: size,
        });
    }

    const {
        [QUERY_ORDER]: orderCode = '',
        [QUERY_ORDER_DIR]: orderDir = '',
    } = sort;

    if (orderCode && !VALID_ORDERS.includes(orderCode)) {
        store.dispatch('sort/resetSort');

        return;
    }

    const { order: stateOrder, orderDir: stateOrderDir } = store.state.sort;

    if (orderCode !== stateOrder || orderDir !== stateOrderDir) {
        store.dispatch('sort/setSortFromQuery', { orderCode, orderDir });
    }
}
