export const types = {
    SET_IS_CONNECTING_CLIENT: 'SET_IS_CONNECTING_CLIENT',
    SET_IS_INVITE_FRIEND_FEATURE_ENABLED:
        'SET_IS_INVITE_FRIEND_FEATURE_ENABLED',
    SET_IS_INVITE_FRIEND_FEATURE_PAUSED: 'SET_IS_INVITE_FRIEND_FEATURE_PAUSED',
    SET_IS_INVITE_FRIEND_REGISTER_PAGE_MOUNTED:
        'SET_IS_INVITE_FRIEND_REGISTER_PAGE_MOUNTED',
    SET_ACCOUNT_VERIFICATION_STATUS: 'SET_ACCOUNT_VERIFICATION_STATUS',
};

export default {
    [types.SET_IS_CONNECTING_CLIENT](state, isConnecting) {
        state.isConnectingClient = isConnecting;
    },

    [types.SET_IS_INVITE_FRIEND_FEATURE_ENABLED](state, isEnabled) {
        state.isInviteFriendFeatureEnabled = isEnabled;
    },

    [types.SET_IS_INVITE_FRIEND_FEATURE_PAUSED](state, isPaused) {
        state.isInviteFriendFeaturePaused = isPaused;
    },

    [types.SET_IS_INVITE_FRIEND_REGISTER_PAGE_MOUNTED](state, isMounted) {
        state.isInviteFriendRegisterPageMounted = isMounted;
    },

    [types.SET_ACCOUNT_VERIFICATION_STATUS](state, status) {
        state.accountVerificationStatus = status;
    },
};
