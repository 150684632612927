import { COLORS, SIZES } from '@types/Links';

import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    props: {
        label: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        color: {
            type: String,
            default: '',
            validator: checkIfExistsInValuesMap(COLORS, true),
        },

        size: {
            type: String,
            default: '',
            validator: checkIfExistsInValuesMap(SIZES, true),
        },

        bold: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasIcon() {
            return !!this.$slots.icon;
        },

        linkClasses() {
            const { color, size, bold, hasIcon } = this;

            return {
                [color]: color,
                [size]: size,
                bold,
                'with-icon': hasIcon,
                'text-link': true,
            };
        },
    },
};
