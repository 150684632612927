import RMA_SERVICES_NAMESPACE from '@rma/async-services/namespace';

export const NAME = `${RMA_SERVICES_NAMESPACE}/config`;

export const GET_COUNTRY_CODES = 'GET_COUNTRY_CODES';
export const GET_RETURN_REASONS = 'GET_RETURN_REASONS';

export const ASYNC_CONFIG_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_COUNTRY_CODES,
        GET_RETURN_REASONS,
    },
};
