import { LOCALE_LT, LOCALE_LV } from '@rma/configs/locales-codes';
import { getLocaleISOCode } from '@rma/assets/get-locale-iso-code';

export const HOUSE_NUMBER_RULE_NAME = 'rmaHouseNumber';

export const STANDARD_HOUSE_NUMBER_VALIDATION_RULES = {
    pattern: /^(?!0$)\d.*/,
    length: 20,
};

export const LOCALIZED_HOUSE_NUMBER_VALIDATION_RULES = {
    [LOCALE_LT]: {
        pattern: /^(?!0$)\d.*/,
        length: 8,
    },
    [LOCALE_LV]: {
        pattern: /^(?!0$)\d.*/,
        length: 8,
    },
};

export const getHouseNumberValidationRule = i18n => {
    const localeIso = getLocaleISOCode(i18n.locales, i18n.locale);

    const { pattern, length } =
        LOCALIZED_HOUSE_NUMBER_VALIDATION_RULES[localeIso] ||
        STANDARD_HOUSE_NUMBER_VALIDATION_RULES;

    return {
        validate: rmaHouseNumber => {
            if (rmaHouseNumber.length > length) {
                return false;
            }

            return pattern ? pattern.test(rmaHouseNumber) : true;
        },
        message: (_, values) => {
            const { _value_: rmaHouseNumber } = values;

            if (rmaHouseNumber.length > length) {
                return i18n.t('__validations.max', { length });
            }

            if (pattern && !pattern.test(rmaHouseNumber)) {
                return i18n.t('__validations.__rma.checkIfNumberIsCorrect');
            }
        },
    };
};

export default i18n => ({
    name: HOUSE_NUMBER_RULE_NAME,
    validation: getHouseNumberValidationRule(i18n),
});
