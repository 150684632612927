import {
    EVENT_USER_LOGGED_OUT,
    EVENT_USER_LOGGED_IN,
} from '@analytics-types/Analytics';

import Customer from '@models/Customer/Customer';

export default {
    customer: (state, getters) =>
        getters.isLoggedIn ? new Customer(state.customerData) : null,

    customerUuid: state => state.customerData?.ext_uuid || '',

    customerHashedEmail: state => async () => {
        if (state?.customerData?.email) {
            const { default: md5 } = await import(
                /* webpackChunkName: 'blueimp-md5' */
                'blueimp-md5'
            );

            return md5(state.customerData.email);
        }

        return '';
    },

    isLoggedIn: state => !!state.customerData,

    customerBirthdate: state => state.customerData?.date_of_birth || '',

    userType: (state, getters) => {
        if (state.fetchingCustomerInProgress) {
            return '';
        }

        return getters.isLoggedIn
            ? EVENT_USER_LOGGED_IN
            : EVENT_USER_LOGGED_OUT;
    },

    wishlistCount: (state, getters) => {
        if (!getters.isLoggedIn) {
            return state.wishlist.length;
        }

        return state.customerData.paginatedWishlist.items_count;
    },

    isOnWishlist: state => sku => state.wishlist.includes(sku),
};
