import { REQUIRED_BLOCKS } from '@configs/required-blocks';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_CMS_BLOCKS_SERVICE_CONFIG } from '@async-services/cmsBlocks/meta';

import { types } from './mutations';

const { NAME, METHODS } = ASYNC_CMS_BLOCKS_SERVICE_CONFIG;

const MODULE_NAME = 'cmsBlock';

const TYPE_CMS_BLOCK = 'cmsBlock';
const TYPE_PAGE_BUILDER_BLOCK = 'pageBuilderBlocks';

const MISSING_REQUIRED_BLOCKS_ERROR_MESSAGE =
    'Not all required blocks have been loaded.';

const sendErrorToSentry = (
    err,
    blocks,
    $errorHandler,
    blockType = TYPE_CMS_BLOCK
) => {
    $errorHandler.captureStoreError(
        MODULE_NAME,
        err,
        {
            [ERROR_ACTION_TAG_NAME]: `${MODULE_NAME}/${blockType}`,
        },
        {
            blocks,
            blockType,
        }
    );
};

const checkAllRequiredBlocksHaveBeenLoaded = (
    loadedBlocks,
    blocksToLoad,
    app,
    blockType = TYPE_CMS_BLOCK
) => {
    const loadedBlocksIds = loadedBlocks.flatMap(
        ({ identifier }) => identifier
    );

    const missingRequiredBlocks = blocksToLoad.filter(
        id => REQUIRED_BLOCKS.includes(id) && !loadedBlocksIds.includes(id)
    );

    if (missingRequiredBlocks.length) {
        sendErrorToSentry(
            new Error(MISSING_REQUIRED_BLOCKS_ERROR_MESSAGE),
            missingRequiredBlocks,
            app.$errorHandler,
            blockType
        );
    }
};

const getBlocks = async (
    dispatch,
    state,
    blocksRequested,
    app,
    blockType = TYPE_CMS_BLOCK
) => {
    let loadedBlocks = [];

    const blocksToLoad = [blocksRequested]
        .flat()
        .filter(id => Object.keys(state[id] || {}).length === 0);

    if (blocksToLoad.length === 0) {
        return true;
    }

    const requiredBlocksToLoad = blocksToLoad.filter(id =>
        REQUIRED_BLOCKS.includes(id)
    );

    const method =
        blockType === TYPE_CMS_BLOCK
            ? METHODS.GET_BLOCKS
            : METHODS.GET_PAGE_BUILDER_BLOCKS;

    try {
        loadedBlocks = await app.$asyncServices.use(NAME, method, {
            ids: blocksToLoad,
        });
    } catch (err) {
        sendErrorToSentry(err, blocksToLoad, app.$errorHandler, blockType);
    }

    if (loadedBlocks?.length) {
        dispatch('setCmsBlocks', loadedBlocks);

        checkAllRequiredBlocksHaveBeenLoaded(
            loadedBlocks,
            blocksToLoad,
            app,
            blockType
        );

        return true;
    }

    if (requiredBlocksToLoad.length) {
        sendErrorToSentry(
            new Error(MISSING_REQUIRED_BLOCKS_ERROR_MESSAGE),
            requiredBlocksToLoad,
            app.$errorHandler,
            blockType
        );
    }

    return false;
};

export default {
    setCmsBlocks({ commit }, cmsBlocks) {
        commit(types.SET_CMS_BLOCKS, cmsBlocks);
    },

    async getCmsBlocks({ dispatch, state }, { cmsBlocksToLoad }) {
        return getBlocks(
            dispatch,
            state,
            cmsBlocksToLoad,
            this.app,
            TYPE_CMS_BLOCK
        );
    },

    async getPageBuilderBlocks(
        { dispatch, state },
        { pageBuilderBlocksToLoad }
    ) {
        return getBlocks(
            dispatch,
            state,
            pageBuilderBlocksToLoad,
            this.app,
            TYPE_PAGE_BUILDER_BLOCK
        );
    },
};
