import avatarMutations from './mutations';
import avatarActions from './actions';
import avatarGetters from './getters';

export const state = () => ({
    isConnectingClient: false,
    isInviteFriendFeatureEnabled: false,
    isInviteFriendRegisterPageMounted: false,
    isInviteFriendFeaturePaused: false,
    accountVerificationStatus: null,
});

export const mutations = avatarMutations;
export const actions = avatarActions;
export const getters = avatarGetters;
