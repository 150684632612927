export default {
    staticBlockById: state => id => state?.[id],

    pageBuilderBlockById: state => id => {
        const contentJSON = state[id]?.content_json;

        if (!contentJSON) {
            return [];
        }

        try {
            const rawData = JSON.parse(contentJSON);

            const [
                {
                    slots: { default: CmsPage },
                },
            ] = rawData;

            return CmsPage;
        } catch {
            return [];
        }
    },

    getComponentFromPageBuilderBlock: (_, getters) => (
        pageBuilderId,
        componentId
    ) => {
        const pageBuilderBlock = getters.pageBuilderBlockById(pageBuilderId);

        if (!pageBuilderBlock.length) {
            return {};
        }

        const component = pageBuilderBlock.filter(
            ({ name }) => name === componentId
        );

        return component[0] || {};
    },
};
