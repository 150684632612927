import {
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    FASON_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    MODEL_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    PRODUCER_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    SERIES_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    PREMIUM_ATTRIBUTE_NAME,
    HOT_DEAL_ATTRIBUTE_NAME,
    NEW_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';
import { TRUE_SIZE_TYPES } from '@configs/esizeme';
import { COLORS } from '@configs/filter-main-colors';
import { GOOD_PRICE_ENABLED_LOCALES } from '@configs/product';

import {
    DEFAULT_SEARCH_CATEGORY_NAME,
    IS_CUT_OFF_THE_CENTS_ENABLED,
    IS_OMNIBUS_STRICT_ENABLED,
    IS_CURRENCY_BEFORE_ENABLED,
} from '@localeConfig/keys';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';
import {
    IMAGE_TYPE_PRODUCT_512w_512h,
    IMAGE_TYPE_PRODUCT_256w_256h,
    DEFAULT_IMAGE_FORMAT,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import {
    TYPE_OCCASION,
    TYPE_GOOD_PRICE,
    TYPE_NEW,
    TYPE_DISCOUNT,
    BADGE_VARIANTS_MAP,
    PRICING_DISCOUNT_LABEL_TYPE,
    PRICING_GOOD_PRICE_LABEL_TYPE,
    PRICING_SPECIAL_OFFER_LABEL_TYPE,
} from '@types/Badge';
import { PRODUCT_IMAGE_SEX_TYPES } from '@types/ProductImage';

import {
    getPriceFromPricingData,
    getPriceFromPricingDataButLocalConfig,
} from '@assets/pricing';
import { getChosenSizeLabelFromVariant } from '@assets/product';
import { sortProductVariantsBySortOrder } from '@assets/product-variants';
import { getProductImage } from '@assets/images';
import {
    getValidPromoSticker,
    getHotDealPromoSticker,
} from '@assets/product-promo-stickers';

export default class CatalogProduct {
    constructor(
        {
            id,
            values,
            variants,
            categories,
            family,
            attributes,
            pricing_data: pricingData = {},
            sponsorship_details: sponsorshipDetails = {},
        },
        locale,
        currency,
        $createProductPath,
        $createCategoryPath,
        $imaginator,
        $getLocaleConfigByKey,
        $t,
        timezone = ''
    ) {
        this.id = id;
        this.values = values;
        this.attributes = attributes;
        this.variants = variants;
        this.categories = categories;
        this.family = family;
        this.locale = locale;
        this.currency = currency;
        this.pricingData = pricingData;
        this.sponsorshipDetails = sponsorshipDetails;
        this.$createProductPath = $createProductPath;
        this.$createCategoryPath = $createCategoryPath;
        this.$imaginator = $imaginator;
        this.storeViewTimezone = timezone;
        this.$getLocaleConfigByKey = $getLocaleConfigByKey;
        this.$t = $t;

        this.isPricingFlow = ['lt_LT', 'hu_HU', 'pl_PL', 'sk_SK'].includes(
            locale
        );

        this.isOmnibusEnabled = this.isPricingFlow
            ? pricingData.market_type.startsWith('OMNIBUS')
            : $getLocaleConfigByKey(IS_OMNIBUS_STRICT_ENABLED);
        this.isCentsEnabled = !$getLocaleConfigByKey(
            IS_CUT_OFF_THE_CENTS_ENABLED
        );
        this.isCurrencyBefore = $getLocaleConfigByKey(
            IS_CURRENCY_BEFORE_ENABLED
        );
    }

    buildDetails() {
        const { locale } = this;

        const product = {
            sku: this.getSku(),
            brand: this.getBrandName(),
            color: this.getColor(),
            colorVariantsLink: this.getColorVariantsLink(),
            colorVariantsCount: this.getColorVariantsCount(),
            images: this.getImages([
                IMAGE_TYPE_PRODUCT_256w_256h,
                IMAGE_TYPE_PRODUCT_512w_512h,
            ]),
            category: CatalogProduct.getCategory(this.locale, this.categories),
            mainCategoryUrl: CatalogProduct.getMainCategoryUrl(
                this.locale,
                this.$getLocaleConfigByKey,
                this.categories
            ),
            nameDisplay: this.getNameDisplay(),
            shortName: this.getShortName(),
            currency: this.currency,
            price: this.getPrice(),
            url: this.getUrl(),
            analytics: this.buildAnalytics(),
            adTechEvents: this.getAdTechEvents(),
            badges: this.getProductBadges(locale),
            promoSticker: this.getPromoSticker(),
            isSponsored: this.getIsSponsored(),
        };

        product.designSystem = this.buildDesignSystem(product);

        return product;
    }

    buildAnalytics() {
        return {
            sku: this.getSku(),
            name: this.getName(DEFAULT_LOCALE),
            price: this.getPrice(),
            brandName: this.getBrandName(DEFAULT_LOCALE),
            categories: this.categories,
            color: this.getColor(DEFAULT_LOCALE),
            fason: this.getFason(DEFAULT_LOCALE),
            variants: this.variants,
            model: this.getModel(DEFAULT_LOCALE),
            seriesName: this.getSeriesName(DEFAULT_LOCALE),
            badges: this.getProductBadges(DEFAULT_LOCALE, true, true),
            localeDefault: DEFAULT_LOCALE,
            purpose: [],
            style: [],
            isPremium: this.getIsPremium(DEFAULT_LOCALE),
            isSponsored: this.getIsSponsored(),
            hasTrueSize: this.getHasTrueSize(),
            mainImageType: this.getMainImageType(),
        };
    }

    buildDesignSystem(product) {
        const { promotional, regular, omnibus, isOnSale } = product.price;

        const priceRegular = regular.show ? regular.formatted : null;
        const priceMinimal = omnibus.formatted || '';

        const showPrevious = this.isOmnibusEnabled ? false : regular.show;

        const { variant_dimension: variantDimension = null } = this.family;

        return {
            id: product.sku,
            images: product.images.map((_, index) => {
                const image = getProductImage(
                    product,
                    index,
                    [
                        IMAGE_TYPE_PRODUCT_256w_256h,
                        IMAGE_TYPE_PRODUCT_512w_512h,
                    ],
                    index > 0 ? null : IMAGE_TYPE_PRODUCT_256w_256h
                );

                image.src = image.url;
                delete image.url;

                return image;
            }),
            brand: product.brand,
            name: product.shortName || product.nameDisplay,
            url: product.url,
            color: COLORS[this.values.main_color?.value.code],
            price: {
                final: promotional.formatted,
                regular: priceRegular,
                minimal: priceMinimal,
                isDiscounted: isOnSale,
                isCentsEnabled: this.isCentsEnabled,
                isCurrencyBefore: this.isCurrencyBefore,
                showOmnibus: omnibus.show,
                showPrevious,
            },
            badges: product.badges.map(({ type, label }) => ({
                type: BADGE_VARIANTS_MAP[type],
                label,
            })),
            sizes: Object.values(this.variants || {})
                .filter(({ stock_quantity }) => stock_quantity > 0)
                .sort(sortProductVariantsBySortOrder)
                .map(variant =>
                    String(
                        getChosenSizeLabelFromVariant(variant, variantDimension)
                    )
                )
                .filter(
                    label => !['One Size', '00', 'OS', 'NOSIZE'].includes(label)
                ),
            promoBadge: product.promoSticker && {
                color: product.promoSticker.textColor,
                backgroundColor: product.promoSticker.bgColor,
                label: product.promoSticker.formattedContent,
            },
        };
    }

    getProductBadges() {
        const noveltyBadge = this.getNoveltyBadge();
        const discountBadges = this.getDiscountBadges();

        return [...discountBadges, noveltyBadge].filter(Boolean);
    }

    getNoveltyBadge() {
        const { [NEW_ATTRIBUTE_NAME]: novelty } = this.getBadges(this.locale);

        if (!novelty) {
            return null;
        }

        return {
            type: TYPE_NEW,
            label: novelty.label,
        };
    }

    getDiscountBadges() {
        if (this.isPricingFlow) {
            const { labels } = this.pricingData;

            return labels.reduce((acc, curr) => {
                if (curr.type === PRICING_DISCOUNT_LABEL_TYPE) {
                    acc.push({
                        type: TYPE_DISCOUNT,
                        label: `-${curr.value}%`,
                    });

                    return acc;
                }

                if (curr.type === PRICING_SPECIAL_OFFER_LABEL_TYPE) {
                    acc.push({
                        type: TYPE_OCCASION,
                        label: this.$t('Occasion'),
                    });

                    return acc;
                }

                if (curr.type === PRICING_GOOD_PRICE_LABEL_TYPE) {
                    acc.push({
                        type: TYPE_GOOD_PRICE,
                        label: this.$t('Good price'),
                    });

                    return acc;
                }

                return acc;
            }, []);
        }

        const {
            discount,
            omnibus_discount: omnibusDiscount,
        } = this.pricingData;

        const discountToUse = this.isOmnibusEnabled
            ? omnibusDiscount
            : discount;

        if (discountToUse?.amount >= 5) {
            return [
                {
                    type: TYPE_DISCOUNT,
                    label: `-${discountToUse.amount}%`,
                },
            ];
        }

        return [];
    }

    getPrice() {
        const promoSticker = this.getPromoSticker() || {};
        const hasMarketingBadge = !!Object.keys(promoSticker).length;

        if (this.isPricingFlow) {
            return getPriceFromPricingData(this.pricingData, hasMarketingBadge);
        }

        const showOmnibusOnGoodPrice = GOOD_PRICE_ENABLED_LOCALES.includes(
            this.locale
        );

        return getPriceFromPricingDataButLocalConfig(
            this.pricingData,
            this.$getLocaleConfigByKey,
            hasMarketingBadge,
            showOmnibusOnGoodPrice
        );
    }

    getBadges(locale) {
        return this.values[BADGES_ATTRIBUTE_NAME]?.value[locale] || {};
    }

    getBrandName() {
        return this.values[BRAND_ATTRIBUTE_NAME]?.value.label || '';
    }

    getColor() {
        return this.values[COLOR_ATTRIBUTE_NAME]?.value.label || '';
    }

    getColorVariantsLink() {
        return {
            value:
                this.values[PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME]?.value ||
                '',
            url:
                this.attributes[PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME]?.url ||
                '',
        };
    }

    getColorVariantsCount() {
        return (
            this.values[PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME]?.value || 0
        );
    }

    getImages(types, limit) {
        let images = this.values[IMAGES_ATTRIBUTE_NAME]?.value || [];

        if (limit) {
            images = images.slice(0, limit);
        }

        const categorySex = CatalogProduct.getMainCategoryUrl(
            DEFAULT_LOCALE,
            this.$getLocaleConfigByKey,
            this.categories
        );

        if (categorySex) {
            images = images.filter(({ values: { plec } }) => {
                if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                    return true;
                }

                return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(
                    plec.toLowerCase()
                );
            });
        }

        return images.map(image => {
            const result = {
                family: image.family,
            };

            types.forEach(imageType => {
                result[imageType] = this.$imaginator.getImage(
                    image.url,
                    this.getUrlKey(),
                    this.getImageAlt(),
                    imageType,
                    DEFAULT_IMAGE_FORMAT,
                    SOURCE_IMAGE_FORMATS
                );
            });

            return result;
        });
    }

    getNameDisplay() {
        const name = this.values[NAME_DISPLAY_ATTRIBUTE_NAME]?.value[
            this.locale
        ];

        return (
            name || this.values[NAME_ATTRIBUTE_NAME]?.value[this.locale] || ''
        );
    }

    getName(locale = this.locale) {
        return this.values[NAME_ATTRIBUTE_NAME]?.value?.[locale] || '';
    }

    getShortName() {
        return this.values[SHORT_NAME_ATTRIBUTE_NAME]?.value[this.locale];
    }

    getUrl() {
        return this.$createProductPath(this.getUrlKey());
    }

    getUrlKey() {
        return this.values[URL_KEY_ATTRIBUTE_NAME]?.value[this.locale] || '';
    }

    getIsSponsored() {
        return !!Object.keys(this.sponsorshipDetails).length;
    }

    getAdTechEvents() {
        const {
            click_event_urls: onClickBeacons = [],
            view_event_urls: onViewBeacons = [],
            load_event_urls: onLoadBeacons = [],
        } = this.sponsorshipDetails;

        return {
            onClickBeacons,
            onViewBeacons,
            onLoadBeacons,
        };
    }

    getFason() {
        return this.values[FASON_ATTRIBUTE_NAME]?.value?.label || '';
    }

    getModel() {
        return this.values[MODEL_ATTRIBUTE_NAME]?.value || '';
    }

    getSku() {
        return this.values[SKU_ATTRIBUTE_NAME]?.value || '';
    }

    getSeriesName() {
        return this.values[SERIES_NAME_ATTRIBUTE_NAME]?.value.label || '';
    }

    getIsPremium() {
        const producer = this.values[PRODUCER_ATTRIBUTE_NAME]?.values;

        return !!producer?.[PREMIUM_ATTRIBUTE_NAME];
    }

    getMainImageType() {
        return this.getImages([IMAGE_TYPE_PRODUCT_256w_256h], 1)[0].family;
    }

    getImageAlt() {
        return `${this.getBrandName()} ${this.getName()}`.trim();
    }

    getHasTrueSize() {
        return Object.values(this.variants || {}).some(({ values = {} }) =>
            TRUE_SIZE_TYPES.every(type => Object.keys(values).includes(type))
        );
    }

    getPromoSticker() {
        if (typeof this.$$promoStickerMemoize !== 'undefined') {
            return this.$$promoStickerMemoize;
        }

        const omnibusAmount = this.pricingData?.omnibus_price?.amount;

        if (this.isOmnibusEnabled && !omnibusAmount) {
            this.$$promoStickerMemoize = null;

            return null;
        }

        const { ...originalStickers } = this.getPromoAction();
        const hotDeal = this.getHotDeal();

        const priorityStickers = {};

        if (hotDeal) {
            priorityStickers[HOT_DEAL_ATTRIBUTE_NAME] = hotDeal;
        }

        const sticker = {
            ...priorityStickers,
            ...originalStickers,
        };

        if (Object.keys(sticker).length === 0) {
            this.$$promoStickerMemoize = null;

            return null;
        }

        const promoSticker = getValidPromoSticker({
            sticker,
            sku: this.getSku(),
            locale: this.locale,
            storeViewTimezone: this.storeViewTimezone,
        });

        this.$$promoStickerMemoize = promoSticker;

        return promoSticker;
    }

    getHotDeal() {
        const { locale, storeViewTimezone } = this;

        const hotDealAttributeValue =
            this.values[HOT_DEAL_ATTRIBUTE_NAME]?.value[locale] || {};

        return getHotDealPromoSticker({
            hotDealAttributeValue,
            locale,
            storeViewTimezone,
        });
    }

    getPromoAction() {
        return this.values[STICKER_ATTRIBUTE_NAME]?.value[this.locale] || {};
    }

    static getMainCategoryUrl(locale, $getLocaleConfigByKey, categories = []) {
        return (
            categories[0]?.[1]?.translations?.[locale]?.url ||
            $getLocaleConfigByKey(DEFAULT_SEARCH_CATEGORY_NAME)
        );
    }

    static getCategory(locale, categories = []) {
        if (!categories.length) {
            return null;
        }

        const firstCategories = categories[0] || [];
        const category = firstCategories[firstCategories.length - 1] || null;

        return category?.translations?.[locale] || null;
    }

    static extendProductWithCategoriesData(
        product,
        categories,
        locale,
        $getLocaleConfigByKey
    ) {
        const categorySex = CatalogProduct.getMainCategoryUrl(
            DEFAULT_LOCALE,
            $getLocaleConfigByKey,
            categories
        );

        const filteredImages = product.images.filter(
            ({ values: { plec } = {} }) => {
                if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                    return true;
                }

                return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(
                    plec.toLowerCase()
                );
            }
        );

        return {
            ...product,
            images: categorySex ? filteredImages : product.images,
            analytics: {
                ...product.analytics,
                categories,
            },
            category: CatalogProduct.getCategory(locale, categories),
            mainCategoryUrl: CatalogProduct.getMainCategoryUrl(
                locale,
                $getLocaleConfigByKey,
                categories
            ),
            categories,
        };
    }
}
