import handlerState from './state.js';
import handlerActions from './actions.js';
import handlerMutations from './mutations.js';
import handlerGetters from './getters.js';

var filterModule = {
  namespaced: true,
  state: handlerState,
  getters: handlerGetters,
  actions: handlerActions,
  mutations: handlerMutations
};

export { filterModule as default };
