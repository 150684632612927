import { createDefaultSyneriseConfig } from '@store/synerise/state';

export const types = {
    SET_SYNERISE_CONFIG: 'SET_SYNERISE_CONFIG',
    SET_SYNERISE_LOADED: 'SET_SYNERISE_LOADED',
};

const mutations = {
    [types.SET_SYNERISE_CONFIG](state, config) {
        state.syneriseConfig = {
            ...createDefaultSyneriseConfig(),
            ...config,
        };
    },

    [types.SET_SYNERISE_LOADED](state, isLoaded) {
        state.syneriseLoaded = isLoaded;
    },
};

export default mutations;
