export const complexPasswordRegexRules = {
    minLength: /.{8,}/,
    lowerCaseAndUpperCase: [/[a-z]+/, /[A-Z]+/],
    specialOrNumber: /[^a-zA-Z]+/,
};

export const complexPasswordTranslationsKeyPathsByRule = {
    minLength: ['__validations.Min {counter} characters', { counter: 8 }],
    lowerCaseAndUpperCase: ['__validations.Lower and upper case letter'],
    specialOrNumber: ['__validations.Special character or number'],
};
