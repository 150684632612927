<template>
    <ChatButton v-if="isChatAllowed" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'ChatWrapper',

    components: {
        ChatButton: () => ({
            component: import(
                /* webpackChunkName: "chat-button" */
                '@molecules/ChatButton/ChatButton'
            ),
        }),
    },

    computed: {
        ...mapConfigGetters([
            'isZowieChatEnabled',
            'zowieChatEnabledPages',
            'isLiveChatEnabled',
        ]),

        isZowieChatAllowed() {
            return (
                this.isZowieChatEnabled &&
                this.zowieChatEnabledPages.includes(this.$route.name)
            );
        },

        isChatAllowed() {
            return this.isLiveChatEnabled || this.isZowieChatAllowed;
        },
    },
};
</script>
