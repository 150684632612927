<template>
    <div class="main">
        <template v-if="isWebViewMobile">
            <main>
                <nuxt />
            </main>
        </template>

        <template v-else>
            <MarketingBarDataProvider />
            <HeaderWrapper />
            <main>
                <nuxt />
            </main>
            <Messages />
            <BaseFooter />
            <ChatWrapper />
            <ModalNewsletter v-if="isOpen" :is-open="isOpen" @close="close()" />
            <GlobalModals />
        </template>
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { MODAL_NEWSLETTER_NAME } from '@configs/modals';

import Messages from '@molecules/Messages/Messages';
import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';
import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

import BaseLayout from '@layouts/mixins/BaseLayout';
import MobileWebView from '@layouts/mixins/MobileWebView';

export default {
    name: 'DefaultLayoutWithoutNewsletter',

    components: {
        Messages,
        HeaderWrapper,
        BaseFooter,
        ChatWrapper,
        GlobalModals,
        MarketingBarDataProvider,
        SmartBannerWrapper,

        ModalNewsletter: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "modal-newsletter" */ '@organisms/ModalNewsletter/ModalNewsletter'
            ),
        }),
    },

    mixins: [BaseLayout, MobileWebView],

    computed: {
        isOpen() {
            return this.$modals.isOpen(MODAL_NEWSLETTER_NAME);
        },
    },

    methods: {
        close() {
            this.$modals.close(MODAL_NEWSLETTER_NAME);
        },
    },
};
</script>
