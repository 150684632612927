import { DEFAULT_SEARCH_CATEGORY_NAME } from '@localeConfig/keys';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { types } from './mutations';

const MODULE_NAME = 'navigation';

export default {
    setNavigationCategories({ commit }, defaultCategory) {
        const defaultSearchCategory = this.app.$getLocaleConfigByKey(
            DEFAULT_SEARCH_CATEGORY_NAME
        );
        const { children: rootCategoryChildren, pim } = defaultCategory || {};
        const { slug: rootCategoryPimSlug = defaultSearchCategory } = pim || {};

        commit(types.SET_NAVIGATION_CATEGORIES, {
            rootCategoryChildren,
            rootCategoryPimSlug,
        });
    },

    setMenuDesktopIsOpen({ commit }, isMenuOpen) {
        commit(types.TOGGLE_DESKTOP_MENU, isMenuOpen);
    },

    async getNavCategoriesData({ dispatch, state }) {
        const { defaultCategoryId } = state;
        let defaultCategory = [];

        try {
            defaultCategory = await this.app.$services.category.getNavCategories(
                defaultCategoryId
            );
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'category.getNavCategories',
                },
                {
                    defaultCategoryId,
                    action: 'category.getNavCategories',
                }
            );
        }

        if (defaultCategory && defaultCategory.length) {
            const rootCategoriesWrapper = defaultCategory[0];

            dispatch('setNavigationCategories', rootCategoriesWrapper);

            return true;
        }

        this.app.$errorHandler.captureStoreMessage(
            MODULE_NAME,
            'Error getting navigation categories',
            {
                [ERROR_ACTION_TAG_NAME]: 'setNavigationCategories',
            },
            {
                defaultCategory,
            }
        );

        return false;
    },

    setIsSearchInputActive({ commit }, isSearchInputActive) {
        commit(types.SET_IS_SEARCH_INPUT_ACTIVE, isSearchInputActive);
    },
};
