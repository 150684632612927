<template>
    <div class="simple-layout">
        <BaseSimpleHeader
            v-if="!isWebViewMobile"
            :show-marketing-bar="showMarketingBar"
            class="header"
        />
        <Divider :variant="S_VARIANT" />
        <main class="layout">
            <nuxt />
        </main>

        <template v-if="!isWebViewMobile">
            <Messages />
            <ChatWrapper />
        </template>
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { CHECKOUT_ACCOUNT_PAGE_NAME } from '@router/names';

import Messages from '@molecules/Messages/Messages';
import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseSimpleHeader from '@organisms/BaseSimpleHeader/BaseSimpleHeader';

import { Divider, DIVIDER_VARIANTS } from '@eobuwie-ui/components/Divider/v1';

import BaseLayout from '@layouts/mixins/BaseLayout';
import MobileWebView from '@layouts/mixins/MobileWebView';

export default {
    name: 'LoginRegisterSimpleLayout',

    components: {
        Messages,
        ChatWrapper,
        BaseSimpleHeader,
        Divider,
        SmartBannerWrapper,
    },

    mixins: [BaseLayout, MobileWebView],

    computed: {
        showMarketingBar() {
            return ![CHECKOUT_ACCOUNT_PAGE_NAME].includes(this.$route.name);
        },
    },

    beforeCreate() {
        this.S_VARIANT = DIVIDER_VARIANTS.S;
    },
};
</script>

<style lang="scss" scoped>
.simple-layout {
    @apply flex flex-col;
    min-height: 100vh;

    .header {
        @apply border-none;

        &:deep(.top-info) {
            @apply p-0;
        }
    }

    .layout {
        @apply grow;
    }

    @screen lg {
        .layout {
            @apply flex items-center flex-col bg-gray8;
        }
    }
}
</style>
