import { formatPrice } from '@core-assets/price';

export const buildProductPriceObject = (
    { amount: regularAmount, formatted: regularFormatted },
    { amount: promotionalAmount, formatted: promotionalFormatted },
    { amount: omnibusAmount, formatted: omnibusFormatted },
    {
        discount,
        discountRegular,
        amount: discountAmount,
        formatted: discountFormatted,
    }
) => {
    return {
        discount,
        discountRegular,
        discountFormatted,
        discountAmount,
        regular: {
            amount: regularAmount,
            formatted: regularFormatted,
        },
        promotional: {
            amount: promotionalAmount,
            formatted: promotionalFormatted,
        },
        omnibus: {
            amount: omnibusAmount,
            formatted: omnibusFormatted,
        },
    };
};

export { formatPrice } from '@core-assets/price';
export default {
    formatPrice,
};
