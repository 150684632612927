export const CATALOG_NAMESPACE = {
    ASYNC_DATA: 'async-data',
    REBUILD_PRODUCTS: 'rebuild-products',
    BUILD_PRODUCTS: 'build-products',
    BUILD_SEARCH_DATA: 'build-search-data',
    BUILD_SEARCH_SERVICE_DATA: 'build-search-service-data',
    BUILD_SPONSORED_PRODUCTS: 'build-sponsored-products',
    GET_REQUESTS: 'get-requests',
    GET_SEO_DATA: 'get-seo-data',
};

export default class PerformanceRecorder {
    constructor(abTests, store) {
        this.shouldMeasurePerformance = abTests.getVariant('dev_ttfb') === 'on';
        this.store = store;

        if (process.server) {
            // eslint-disable-next-line global-require
            const { performance } = require('perf_hooks');

            this.performance = performance;
        } else {
            this.performance = window.performance;
        }
    }

    start(name) {
        if (!this.shouldMeasurePerformance) {
            return;
        }

        this.performance.mark(`${name}-start`, {
            detail: `${PerformanceRecorder.getNamespace(
                name
            )} ${name} start mark`,
        });
    }

    end(name) {
        if (!this.shouldMeasurePerformance) {
            return;
        }

        this.performance.mark(`${name}-end`, {
            detail: `${PerformanceRecorder.getNamespace(
                name
            )} ${name} end mark`,
        });
        const entry = this.performance.measure(
            `${PerformanceRecorder.getNamespace(name)}-${name}`,
            `${name}-start`,
            `${name}-end`
        );

        this.store.dispatch('addPerformanceEntry', {
            client: 'internal',
            duration: Math.floor(entry.duration),
            name: entry.name,
        });
    }

    static getNamespace(name) {
        if (
            Object.entries(CATALOG_NAMESPACE).some(
                ([, value]) => value === name
            )
        ) {
            return 'catalog';
        }
    }
}
