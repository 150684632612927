export const types = {
    SET_CATEGORY_NAVIGATION: 'SET_CATEGORY_NAVIGATION',
    SET_CATEGORY_PRODUCTS_SKUS: 'SET_CATEGORY_PRODUCTS_SKUS',
    SET_URL: 'SET_URL',
    SET_QUERY: 'SET_QUERY',
    SET_CURRENT_PRODUCT: 'SET_CURRENT_PRODUCT',
    SET_LAST_URL: 'SET_LAST_URL',
    SET_LOADING: 'SET_LOADING',
    SET_FILTERED_PRODUCTS_COUNT: 'SET_FILTERED_PRODUCTS_COUNT',
    SET_SELECTED_SORT_OPTION: 'SET_SELECTED_SORT_OPTION',
    SET_INITIAL_SORT_OPTION: 'SET_INITIAL_SORT_OPTION',
    SET_PRODUCTS_ANALYTICS: 'SET_PRODUCTS_ANALYTICS',
};

const mutations = {
    [types.SET_URL](state, url) {
        state.url = url;
    },

    [types.SET_CATEGORY_NAVIGATION](state, navigation) {
        state.navigation = navigation;
    },

    [types.SET_CATEGORY_PRODUCTS_SKUS](state, skus) {
        state.productsSkus = skus;
    },

    [types.SET_QUERY](state, query) {
        state.query = query;
    },

    [types.SET_CURRENT_PRODUCT](state, product) {
        state.currentProduct = product;
    },

    [types.SET_LAST_URL](state, url) {
        state.lastUrl = url;
    },

    [types.SET_FILTERED_PRODUCTS_COUNT](state, productCount) {
        state.filteredProductsCount = productCount;
    },

    [types.SET_LOADING](state, loading) {
        state.isContentLoading = loading;
    },

    [types.SET_SELECTED_SORT_OPTION](state, sortOption) {
        state.selectedSortOption = sortOption;
    },

    [types.SET_INITIAL_SORT_OPTION](state, sortOption) {
        state.initialSortOption = sortOption;
    },

    [types.SET_PRODUCTS_ANALYTICS](state, productsAnalytics) {
        state.productsAnalytics = productsAnalytics;
    },
};

export default mutations;
