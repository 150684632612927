import { types } from './mutations.js';

var handlerActions = {
  addSelectedFilter({ commit, state }, { url, code, label, selectedFilterParams }) {
    commit(types.SET_SELECTED_PARAMS, {
      ...state.selectedParams,
      [code]: {
        url,
        code,
        label,
        selected: selectedFilterParams
      }
    });
  },
  removeSelectedFilter({ commit, state }, { code }) {
    const { [code]: _paramToRemove, ...restParams } = state.selectedParams;
    commit(types.SET_SELECTED_PARAMS, restParams);
  },
  setEsizemeState({ commit }, selectedScan) {
    commit(types.SET_SELECTED_ESIZEME_SCAN, selectedScan);
  },
  setInitialEsizemeState({ commit }, selectedScan) {
    commit(types.SET_INITIAL_ESIZEME_SCAN, selectedScan);
  },
  clearAllFilters({ commit }) {
    commit(types.SET_SELECTED_PARAMS, {});
    commit(types.SET_SELECTED_ESIZEME_SCAN, {});
  },
  clearExtraFilters({ commit }) {
    commit(types.SET_SELECTED_EXTRA_PARAMS, {});
  },
  setSelectedParams({ commit }, { filters, selectedParams }) {
    commit(types.SET_FILTERS, filters);
    commit(types.SET_SELECTED_PARAMS, selectedParams);
  },
  setSelectedExtraParams({ commit }, extraParams) {
    commit(types.SET_SELECTED_EXTRA_PARAMS, extraParams);
  },
  setInitialState({ commit }, selectedParams) {
    commit(types.SET_INITIAL_STATE, selectedParams);
  },
  setInitialExtraState({ commit }, selectedExtraParams) {
    commit(types.SET_INITIAL_EXTRA_STATE, selectedExtraParams);
  },
  removeTag({ commit, state, dispatch }, { filterCode, tagCode }) {
    if (filterCode === "esizeme") {
      dispatch("setEsizemeState", {});
      return;
    }
    const { [tagCode]: _paramToRemove, ...restParams } = state.selectedParams[filterCode]?.selected || {};
    if (Object.keys(restParams).length) {
      const selectedParams = {
        ...state.selectedParams,
        [filterCode]: {
          ...state.selectedParams[filterCode],
          selected: restParams
        }
      };
      commit(types.SET_SELECTED_PARAMS, selectedParams);
    } else {
      dispatch("removeSelectedFilter", { code: filterCode });
    }
  },
  removeExtraTag({ commit, state }, { filterCode, tagCode }) {
    const { [tagCode]: _paramToRemove, ...restParams } = state.selectedExtraParams[filterCode]?.selected || {};
    const extraParams = { ...state.selectedExtraParams };
    if (Object.keys(restParams).length) {
      extraParams[filterCode] = {
        ...state.selectedExtraParams[filterCode],
        selected: restParams
      };
    } else {
      delete extraParams[filterCode];
    }
    commit(types.SET_SELECTED_EXTRA_PARAMS, extraParams);
  }
};

export { handlerActions as default };
