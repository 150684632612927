import {
    DEFAULT_CURRENT_PAGE,
    DEFAULT_PAGE_SIZE,
} from '@configs/defaults/query/pagination';

const state = () => ({
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: DEFAULT_CURRENT_PAGE,
    totalPages: null,
});

export default state;
