const { resolve } = require('path');

const LOYALTY_I18N_NAMESPACE = '__loyalty';

const { GENERAL_TRANSLATIONS_KEY } = require('../../../../server/trans/config');

module.exports = {
    namespace: LOYALTY_I18N_NAMESPACE,

    generateFilePaths: lang => ({
        [GENERAL_TRANSLATIONS_KEY]: resolve(
            __dirname,
            `./translations/${lang}.json`
        ),
    }),
};
