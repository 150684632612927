export const TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
    NOTIFICATION: 'notification',
};

export const PARAMS = {
    DISPLAY_LIMIT: 3,
    DEFAULT_TIMEOUT_MS: 5000,
};
