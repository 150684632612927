const gtmTestVariant = abTests => abTests.getVariant('dev_gtm');

const gtmRegExp = /^GTM-[A-Z0-9]{1,7}$/;

export default function ({ app, $gtm, $config }) {
    const {
        gtm: { id },
    } = $config;
    const { $abTests } = app;

    const gtmVariant = gtmTestVariant($abTests);
    const isGTMAbTestOn = gtmRegExp.test(gtmVariant);

    if (isGTMAbTestOn) {
        $gtm.init(gtmVariant);
    } else {
        $gtm.init(id);
    }
}
