<script>
import { ANDROID_APP_LINK_NAME, IOS_APP_LINK_NAME } from '@localeConfig/keys';

export default {
    name: 'MobileAppsLinksProvider',

    computed: {
        androidAppUrl() {
            return this.$getLocaleConfigByKey(ANDROID_APP_LINK_NAME) ?? '';
        },

        iosAppUrl() {
            return this.$getLocaleConfigByKey(IOS_APP_LINK_NAME) ?? '';
        },
    },

    render() {
        return this.$scopedSlots.default({
            androidAppUrl: this.androidAppUrl,
            iosAppUrl: this.iosAppUrl,
        });
    },
};
</script>
