import { STORE_MODULE_NAME as LOYALTY_STORE_MODULE_NAME } from '@loyalty-club/store/meta';

const getLoyaltyStoreModuleImport = () =>
    import(
        /* webpackChunkName: "loyalty-club-store-async-module" */
        '@loyalty-club/store'
    );

export const hasLoyaltyClubStoreModule = store => {
    if (!store) {
        return null;
    }

    return store.hasModule(LOYALTY_STORE_MODULE_NAME);
};

export const registerLoyaltyStoreModule = async store => {
    if (!store) {
        return;
    }

    if (hasLoyaltyClubStoreModule(store)) {
        return;
    }

    const { default: module } = await getLoyaltyStoreModuleImport();

    store.registerModule(LOYALTY_STORE_MODULE_NAME, module);
};
