import { SearchQuery, Channel, ApiVersion, Endpoint } from '@eobuwie/search';

import { DEFAULT_SEARCH_SELECT_FIELDS } from '@configs/defaults/search';
import {
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';
import {
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_OMNIBUS,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
    MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME,
    MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME,
    MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME,
} from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import ProductNotFoundException from '@exceptions/services/product/ProductNotFoundException';

import { getSearchClientAndEndpointParameter } from '@assets/get-megatron-endpoint-parameter-search';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.product.${action}`,
});

const GET_SPONSORED_PRODUCTS_ERROR_TAGS = getErrorTags('getSponsoredByIndexes');
const GET_SPONSORED_BY_URL_KEY_ERROR_TAGS = getErrorTags(
    'getSponsoredByUrlKey'
);
const GET_HOMEPAGE_SPONSORED_PRODUCTS_ERROR_TAGS = getErrorTags(
    'getHomepageSponsoredProducts'
);

const isNewSearchTestOn = abTests =>
    abTests.getVariant('eob_all_pdp_NewSearch') === 'on';

const emptyGetByIndexesResponse = (page, limit) => ({
    items: [],
    total: 0,
    page,
    per_page: limit,
    attributes: {},
});

const getEmptyGetSponsoredProducts = () => ({
    products: [],
    sponsorshipDetails: {},
});

export default ({
    megatronClient,
    httpClient,
    normalizeMegatronResponse,
    $errorHandler,
    helpers: { abTests, $avatar },
}) => ({
    async getColorVariants(
        colorVariantsUrl,
        colorVariantValues,
        locale,
        currency,
        selectLocales = [],
        limit = 250
    ) {
        const ERROR_TAGS = getErrorTags('getColorVariants');
        let response = null;

        try {
            const apiVersion = isNewSearchTestOn(abTests)
                ? ApiVersion.VERSION_1
                : ApiVersion.VERSION_5;

            const searchQuery = new SearchQuery({
                apiVersion,
                endpoint: Endpoint.PRODUCTS,
            });

            const colorVariantsFilter = isNewSearchTestOn(abTests)
                ? PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME
                : colorVariantsUrl;

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setFilters({
                    [colorVariantsFilter]: colorVariantValues,
                })
                .setSelectLocales(selectLocales)
                .setSelectFields(DEFAULT_SEARCH_SELECT_FIELDS)
                .setLimit(limit)
                .setWithStocks(true)
                .build();

            if (isNewSearchTestOn(abTests)) {
                response = await megatronClient.get(searchQueryUrl, {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
                });
            } else {
                const {
                    client,
                    endpointParameter,
                } = getSearchClientAndEndpointParameter(
                    megatronClient,
                    httpClient,
                    abTests
                );

                response = await client.get(searchQueryUrl, {
                    ...endpointParameter,
                });
            }

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME
            );

            if (!error) {
                return data?.items || [];
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            return [];
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                ERROR_TAGS,
                {
                    ...response,
                }
            );

            return [];
        }
    },

    async getByIndexes({
        indexes = [],
        page = 1,
        limit = 10,
        locale,
        selectLocales = [],
        currency,
    }) {
        const ERROR_TAGS = getErrorTags('getByIndexes');

        try {
            const apiVersion = isNewSearchTestOn(abTests)
                ? ApiVersion.VERSION_1
                : ApiVersion.VERSION_5;

            const searchQuery = new SearchQuery({
                apiVersion,
                endpoint: Endpoint.PRODUCTS,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setIndexes(indexes)
                .setSelectLocales(selectLocales)
                .setLimit(limit)
                .setPage(page)
                .build();

            let response = null;

            if (isNewSearchTestOn(abTests)) {
                response = await megatronClient.get(searchQueryUrl, {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
                });
            } else {
                const {
                    client,
                    endpointParameter,
                } = getSearchClientAndEndpointParameter(
                    megatronClient,
                    httpClient,
                    abTests
                );

                response = await client.get(searchQueryUrl, {
                    ...endpointParameter,
                });
            }

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME
            );

            if (!error) {
                return data;
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            return emptyGetByIndexesResponse(page, limit);
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                ERROR_TAGS
            );

            return emptyGetByIndexesResponse(page, limit);
        }
    },

    async checkRedirect({ urlKey, locale, currency }) {
        const ERROR_TAGS = getErrorTags('checkRedirect');

        try {
            const apiVersion = isNewSearchTestOn(abTests)
                ? ApiVersion.VERSION_1
                : ApiVersion.VERSION_5;

            const searchQuery = new SearchQuery({
                apiVersion,
                endpoint: Endpoint.PRODUCTS,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setUrlKey(urlKey)
                .setSelectFields([URL_KEY_ATTRIBUTE_NAME])
                .build();

            let response = null;

            if (isNewSearchTestOn(abTests)) {
                response = await megatronClient.get(searchQueryUrl, {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
                });
            } else {
                const {
                    client,
                    endpointParameter,
                } = getSearchClientAndEndpointParameter(
                    megatronClient,
                    httpClient,
                    abTests
                );

                response = await client.get(searchQueryUrl, {
                    ...endpointParameter,
                });
            }

            const { data, error, status } = response || {};

            if (!error) {
                return data;
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            return null;
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                ERROR_TAGS
            );

            return null;
        }
    },

    async getByUrlKey({ urlKey, locale, currency, selectLocales = [] }) {
        const ERROR_TAGS = getErrorTags('getByUrlKey');

        try {
            const apiVersion = isNewSearchTestOn(abTests)
                ? ApiVersion.VERSION_1
                : ApiVersion.VERSION_5;

            const searchQuery = new SearchQuery({
                apiVersion,
                endpoint: Endpoint.PRODUCTS,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setSelectLocales(selectLocales)
                .setUrlKey(urlKey)
                .build();

            let response = null;

            if (isNewSearchTestOn(abTests)) {
                response = await megatronClient.get(searchQueryUrl, {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH,
                });
            } else {
                const {
                    client,
                    endpointParameter,
                } = getSearchClientAndEndpointParameter(
                    megatronClient,
                    httpClient,
                    abTests
                );

                response = await client.get(searchQueryUrl, {
                    ...endpointParameter,
                });
            }

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME
            );

            if (data === null) {
                throw new ProductNotFoundException(urlKey);
            }

            if (!error) {
                return data;
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );

            throw error;
        } catch (err) {
            if (!(err instanceof ProductNotFoundException)) {
                $errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    err,
                    ERROR_TAGS
                );
            }

            throw err;
        }
    },

    async getSponsoredProductsBase({
        indexes = [],
        urlKey,
        locale,
        currency,
        selectLocales = [],
        fields = [],
    }) {
        let ERROR_TAGS = GET_HOMEPAGE_SPONSORED_PRODUCTS_ERROR_TAGS;
        let searchEndpoint = Endpoint.HOMEPAGE;

        if (indexes.length > 0) {
            ERROR_TAGS = GET_SPONSORED_PRODUCTS_ERROR_TAGS;
            searchEndpoint = Endpoint.BASKET;
        } else if (urlKey) {
            ERROR_TAGS = GET_SPONSORED_BY_URL_KEY_ERROR_TAGS;
            searchEndpoint = Endpoint.PRODUCTS;
        }

        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: searchEndpoint,
            });

            const searchQueryBuilder = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setSelectLocales(selectLocales)
                .setSelectFields(fields)
                .setSponsorship();

            if (indexes.length > 0) {
                searchQueryBuilder.setIndexes(indexes);
            } else if (urlKey) {
                searchQueryBuilder.setUrlKey(urlKey);
            }

            const searchQueryUrl = searchQueryBuilder.build();

            const {
                client,
                endpointParameter,
            } = getSearchClientAndEndpointParameter(
                megatronClient,
                httpClient,
                abTests
            );

            const response = await client.get(searchQueryUrl, {
                ...endpointParameter,
                headers: {
                    ...$avatar.getInstanceIdHeader(),
                },
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME
            );

            if (!error) {
                const {
                    products = [],
                    sponsorship_details: sponsorshipDetails = {},
                } = data || {};

                return { products, sponsorshipDetails };
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                GET_SPONSORED_PRODUCTS_ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                ERROR_TAGS
            );
        }

        return getEmptyGetSponsoredProducts();
    },

    async getProductsReviewsTotalCount({
        skus = [],
        pageSize = 10000,
        currentPage = 1,
    }) {
        const { data } = await megatronClient.get(
            'v1/productsReviewsTotalCount',
            {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    skus,
                    pageSize,
                    currentPage,
                },
            }
        );

        const { reviews = [], errors = [] } = data?.productReviews || {};

        return {
            errors,
            reviews,
        };
    },

    async getProductReviews({ sku, currentPage, pageSize, dateSort }) {
        const { data } = await megatronClient.get('v1/productReviews', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                skus: [sku],
                currentPage,
                pageSize,
                dateSort,
            },
        });

        const { reviews = [], errors = [] } = data?.productReviews || {};
        const itemReviews = reviews.find(item => item.sku === sku);

        return {
            errors,
            reviews: itemReviews,
        };
    },

    async addProductReview(reviewData) {
        const ERROR_TAGS = getErrorTags('addProductReview');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/addProductReview',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: reviewData,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.addProductReview || false;
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    reviewData,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                ERROR_TAGS,
                {
                    reviewData,
                }
            );

            return false;
        }
    },

    async updateProductReview(review) {
        const ERROR_TAGS = getErrorTags('updateProductReview');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/updateProductReview',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        review,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.updateProductReview || false;
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    review,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                ERROR_TAGS,
                {
                    review,
                }
            );

            return false;
        }
    },

    async addProductAvailabilityNotification(availabilityNotificationData) {
        const ERROR_TAGS = getErrorTags('addProductAvailabilityNotification');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/availabilityNotificationSignUp',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: availabilityNotificationData,
                }
            );

            if (!error) {
                return data?.availabilityNotificationSignUp?.success || false;
            }

            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    availabilityNotificationData,
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureDomainError(
                PRODUCT_ERROR_DOMAIN,
                err,
                ERROR_TAGS,
                {
                    availabilityNotificationData,
                }
            );

            return false;
        }
    },

    // here locale is in 2 letter format i.e. PL, CZ
    async getMinimalPrice({ skus, locale }) {
        const { data } = await megatronClient.get('v1/minimalPrice', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_OMNIBUS,
            payload: {
                skus: [skus].flat(),
                locale,
                channel: Channel.EOBUWIE,
            },
        });

        return data?.minimalPrices || {};
    },
});
