import { RMA_API_VERSION_V2, RMA_API_VERSION_V3 } from './version';

export const MEGATRON_CLIENT_RMA_ENDPOINT_V2 = 'rma_v2';
export const MEGATRON_CLIENT_RMA_ENDPOINT_V3 = 'rma_v3';
export const MEGATRON_CLIENT_RMA_ENDPOINT_V3_PUBLIC = 'rma_v3_public';

export const MEGATRON_CLIENT_RMA_ENDPOINT_V2_PARAMS = {
    endpoint: MEGATRON_CLIENT_RMA_ENDPOINT_V2,
};

export const MEGATRON_CLIENT_RMA_ENDPOINT_V3_PARAMS = {
    endpoint: MEGATRON_CLIENT_RMA_ENDPOINT_V3,
};

export const MEGATRON_CLIENT_RMA_ENDPOINT_V3_PUBLIC_PARAMS = {
    endpoint: MEGATRON_CLIENT_RMA_ENDPOINT_V3_PUBLIC,
};

const createRmaSuffix = (version, suffix = 'external') =>
    `rest/rma/${version}/${suffix}`;

export const endpointToSuffixMap = new Map([
    [MEGATRON_CLIENT_RMA_ENDPOINT_V2, createRmaSuffix(RMA_API_VERSION_V2)],
    [MEGATRON_CLIENT_RMA_ENDPOINT_V3, createRmaSuffix(RMA_API_VERSION_V3)],
    [
        MEGATRON_CLIENT_RMA_ENDPOINT_V3_PUBLIC,
        createRmaSuffix(RMA_API_VERSION_V3, 'public'),
    ],
]);
