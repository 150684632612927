import ordersServiceConfig from './orders/config';
import configServiceConfig from './config/config';
import returnIntentServiceConfig from './return-intent/config';
import carriersServiceConfig from './carriers/config';
import returnsServiceConfig from './returns/config';
import guestServiceConfig from './guest/config';

export default function ({ $asyncServices }) {
    [
        ordersServiceConfig,
        configServiceConfig,
        returnIntentServiceConfig,
        carriersServiceConfig,
        returnsServiceConfig,
        guestServiceConfig,
    ].forEach(({ name: serviceName, load: serviceLoadFn }) => {
        $asyncServices.registerService(serviceName, serviceLoadFn);
    });
}
