export default {
    isMan: (_, __, ___, rootGetters) => (fullPath, menUrl) => {
        if (typeof menUrl !== 'string') {
            return false;
        }

        const expression = `([/=]${menUrl})(/.*)?$`;

        const regexpQuery = new RegExp(expression, 'g');
        const isManPath = regexpQuery.test(fullPath);

        if (isManPath) {
            return true;
        }

        const { slug: selectedRootCategorySlug = '' } =
            rootGetters['navigation/selectedRootCategory'] || {};

        return selectedRootCategorySlug === menUrl;
    },
};
