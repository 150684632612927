const { namespace } = require('./index');

function tLoyalty(loyaltyModuleTranslation, variables) {
    return this.t(`${namespace}.${loyaltyModuleTranslation}`, variables);
}

export default function ({ app }, inject) {
    app.i18n.tLoyalty = tLoyalty.bind(app.i18n);

    app.$tLoyalty = app.i18n.tLoyalty.bind(app.i18n);

    inject('tLoyalty', app.$tLoyalty);
}
