<template>
    <picture
        :key="index"
        :class="{ 'background-filter': isBackgroundFilterOn }"
        class="base-picture"
        v-on="$listeners"
    >
        <template v-if="sourcesWithMedia.length">
            <source
                v-for="(
                    { media, srcset: sourceSrcset, type }, sourceIndex
                ) in sourcesWithMedia"
                :key="sourceIndex"
                :srcset="sourceSrcset"
                :type="`image/${type}`"
                :media="media"
            />
        </template>
        <template v-else>
            <source
                v-for="(source, type) in sources"
                :key="type"
                :srcset="srcset[type]"
                :type="`image/${type}`"
                :sizes="sizes"
            />
        </template>
        <img
            ref="img"
            :width="width"
            :height="height"
            :src="src"
            :alt="alt"
            :fetchpriority="fetchPriorityValue"
            :loading="isLazy ? 'lazy' : null"
            :decoding="isLazy ? 'async' : 'sync'"
            :class="{ loaded: isLoaded }"
            class="image"
            @load="onLoad($event)"
            @error="onError($event)"
        />
    </picture>
</template>

<script>
import FetchPriority from '@mixins/FetchPriority';

export default {
    name: 'BasePicture',

    mixins: [FetchPriority],

    props: {
        width: {
            type: Number,
            required: true,
        },

        height: {
            type: Number,
            required: true,
        },

        alt: {
            type: String,
            required: true,
        },

        src: {
            type: String,
            required: true,
        },

        index: {
            type: String,
            default: '',
        },

        sources: {
            type: Object,
            default: () => ({}),
        },

        sourcesWithMedia: {
            type: Array,
            default: () => [],
        },

        sizes: {
            type: String,
            default: null,
        },

        isLazy: {
            type: Boolean,
            default: false,
        },

        isBackgroundFilterOn: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoaded: false,
        };
    },

    computed: {
        srcset() {
            return Object.entries(this.sources).reduce(
                (acc, [type, typeSources]) => {
                    acc[type] = typeSources
                        .map(item => `${item.src} ${item.width}w`)
                        .join(', ');

                    return acc;
                },
                {}
            );
        },

        fetchPriorityValue() {
            return this.shouldSetFetchPriority(this.fetchPriority)
                ? this.fetchPriority
                : null;
        },
    },

    watch: {
        index() {
            this.isLoaded = false;
        },
    },

    methods: {
        onLoad(event) {
            this.isLoaded = true;
            this.$emit('load', { event, src: this.src });
        },

        onError(event) {
            this.isLoaded = false;
            this.$emit('error', { event, src: this.src });
        },
    },
};
</script>

<style lang="scss" scoped>
$backgroundFilterColor: theme('customVariables.backgroundFilterColor');

.base-picture {
    @apply flex;

    &.background-filter {
        background-color: $backgroundFilterColor;

        .image {
            @apply mix-blend-multiply brightness-[1.02];
        }
    }

    .image {
        @apply w-full relative;

        &:not(.loaded) {
            @apply bg-gray5;

            &::before {
                @apply w-full h-full bg-gray5 absolute top-0 left-0 content-[''];
            }
        }
    }
}
</style>
