<template>
    <div class="header-action-button">
        <ButtonIcon
            :variant="BUTTON_ICON_VARIANT"
            :to="{ path }"
            class="button-small"
            @click.native="emitAnalyticsClick()"
        >
            <Icon :icon="icon" />
            <template v-if="digit" #circle-digit>
                <CircleDigit :digit="digit" />
            </template>
        </ButtonIcon>

        <ButtonComponent
            :to="{ path }"
            :variant="BUTTON_COMPONENT_VARIANT"
            class="button-with-label"
            @click.native="emitAnalyticsClick()"
        >
            <template #icon>
                <Icon :icon="icon" />
            </template>
            <div class="button-label">
                <span>{{ label }}</span>
                <CircleDigit v-if="digit" :digit="digit" />
            </div>
        </ButtonComponent>
    </div>
</template>

<script>
import { interactionResponse } from '@assets/performance';

import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@eobuwie-ui/components/ButtonComponent/v1';
import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';
import { CircleDigit } from '@eobuwie-ui/components/CircleDigit/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

export default {
    name: 'HeaderActionButton',

    components: {
        CircleDigit,
        Icon,
        ButtonIcon,
        ButtonComponent,
    },

    props: {
        icon: {
            type: Object,
            required: true,
        },

        path: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        digit: {
            type: Number,
            default: 0,
        },

        analyticsClickEvent: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.BUTTON_COMPONENT_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY;
    },

    methods: {
        async emitAnalyticsClick() {
            if (this.analyticsClickEvent) {
                await interactionResponse();

                this.$analytics.emit(this.analyticsClickEvent);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.header-action-button {
    .button-with-label {
        @apply hidden;
    }

    @screen ui-desktopPlus {
        .button-label {
            @apply flex gap-x-ui-2;
        }

        .button-small {
            @apply hidden;
        }

        .button-with-label {
            @apply inline-flex;

            &:deep(.label) {
                @apply block;
            }
        }
    }
}
</style>
