<template>
    <button :type="type" :to="to" class="text-button">
        <slot />
    </button>
</template>

<script>
import Button from '@mixins/Button';

export default {
    name: 'TextButton',

    mixins: [Button],
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/focus-visible.scss';

.text-button {
    &:hover,
    &:focus {
        @apply underline;
    }

    &[disabled] {
        @apply pointer-events-none;
    }

    @include focusOutlineNone();
}
</style>
