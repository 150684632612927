import { getAutocomplete } from '@normalizers/megatron/search/get-autocomplete';
import { getProduct } from '@normalizers/megatron/search/get-product';
import { getProducts } from '@normalizers/megatron/search/get-products';
import { getCatalogProducts } from '@normalizers/megatron/search/get-catalog-products';
import { getSponsoredProducts } from '@normalizers/megatron/search/get-sponsored-products';
import { getSearch } from '@normalizers/megatron/search/get-search';
import { getFilters } from '@normalizers/megatron/search/get-filters';
import { getCatalogColorVariants } from '@normalizers/megatron/search/get-catalog-color-variants';

export const megatronNormalizersRegistry = [
    getAutocomplete,
    getProduct,
    getProducts,
    getCatalogProducts,
    getSponsoredProducts,
    getSearch,
    getFilters,
    getCatalogColorVariants,
].reduce((config, { operationName, normalizer }) => {
    config[operationName] = normalizer;

    return config;
}, {});
