<template>
    <div class="sidebar-menu">
        <BaseButton class="open-btn" type="button" @click="open()">
            {{ $t('Menu') }}
        </BaseButton>
        <SideModal
            class="sidebar-menu-modal"
            :is-open="isOpened"
            :apply-only-on-mobile-and-tablet="true"
            modal-body-class="sidebar-menu-modal-body"
            modal-header-class="sidebar-menu-modal-header"
            :label="$t('Menu')"
            @close="close()"
        >
            <slot />
        </SideModal>
    </div>
</template>

<script>
import BaseButton from '@atoms/BaseButton/BaseButton';
import SideModal from '@molecules/SideModal/SideModal';

export default {
    name: 'SidebarMenu',
    components: {
        BaseButton,
        SideModal,
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isOpened: false,
        };
    },

    watch: {
        isMobile(toValue, fromValue) {
            const changedFromMobileToDesktop = !!(fromValue && !toValue);

            if (changedFromMobileToDesktop && this.isOpened) {
                this.close();
            }
        },

        $route() {
            if (this.isOpened && this.isMobile) {
                this.close();
            }
        },
    },

    methods: {
        close() {
            this.isOpened = false;
        },

        open() {
            this.isOpened = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.sidebar-menu {
    .open-btn {
        @apply w-full text-center my-4 flex justify-center items-center;
    }

    &:deep() {
        .sidebar-menu-modal-body {
            @apply p-3;
        }

        .sidebar-menu-modal-header {
            @apply pl-3;
        }
    }

    @screen lg {
        .open-btn {
            @apply hidden;
        }
    }
}
</style>
