import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH } from '@configs/megatron-client';
import { HTTP_CLIENT_ENDPOINT_NAME_SEARCH } from '@configs/http-client';

const HTTP_CLIENT_ENDPOINT_PARAMETER_CLIENT = {
    endpoint: HTTP_CLIENT_ENDPOINT_NAME_SEARCH,
};

export const getSearchClientAndEndpointParameter = (
    megatronClient,
    httpClient,
    abTests
) => {
    if (abTests.getVariant('eob_all_plp_searchOvh') === 'on') {
        return {
            client: megatronClient,
            endpointParameter: MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH,
        };
    }

    return {
        client: httpClient,
        endpointParameter: HTTP_CLIENT_ENDPOINT_PARAMETER_CLIENT,
    };
};
