export const LOCALE = 'pl_PL';
export const CURRENCY = 'PLN';
export const IMAGINATOR_TEST_URL = 'https://img-eob.stage.modivo.io';
export const BASE_URL = 'https://eobuwie.com.pl';
export const VISIBLE = 1;

export const STORE_VIEW = {
    locale: LOCALE,
    base_url: BASE_URL,
    visible: VISIBLE,
};
