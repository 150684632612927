import { MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME } from '@configs/megatron-client';

import normalizeProduct from '@normalizers/models/product';

const normalizer = async (response, config, helpers) => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    response.data = normalizeProduct(data, helpers, config);

    return response;
};

export const getProduct = {
    operationName: MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME,
    normalizer,
};
