<template>
    <header class="base-simple-header">
        <MarketingBarCart v-if="showMarketingBar" />
        <ContainerContent class="content">
            <div class="content-heading">
                <BaseLink :path="HOME_PAGE" class="logo">
                    <MainLogo :name="eobuwieLogo" />
                </BaseLink>

                <BaseHeading theme="h5" class="header-simple-heading">
                    {{ heading }}
                </BaseHeading>
            </div>

            <div class="info-boxes">
                <HeaderInfoBox
                    v-for="{ id, content, icon, text } in infoBoxes"
                    :key="id"
                    class="info-box"
                    :is-mobile="isMobile"
                    :tooltip-content="content"
                >
                    <template #icon>
                        <SvgIcon width="24px" height="24px">
                            <component :is="icon" />
                        </SvgIcon>
                    </template>
                    {{ text }}
                </HeaderInfoBox>
            </div>
        </ContainerContent>
    </header>
</template>

<script>
import { mapState } from 'vuex';

import { LOGO_EOB_IMPORT_NAME } from '@localeConfig/keys';

import { HOME_PAGE } from '@router/paths';

import DeliveryReturnInfo from '@mixins/DeliveryReturnInfo';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import MainLogo from '@molecules/MainLogo/MainLogo';
import HeaderInfoBox from '@molecules/HeaderInfoBox/HeaderInfoBox';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import MarketingBarCart from '@marketing-bar-components/organisms/MarketingBarCart/MarketingBarCart';

export default {
    name: 'BaseSimpleHeader',

    components: {
        HeaderInfoBox,
        MainLogo,
        ContainerContent,
        SvgIcon,
        BaseLink,
        BaseHeading,
        MarketingBarCart,
    },

    mixins: [DeliveryReturnInfo],

    props: {
        heading: {
            type: String,
            default: '',
        },

        showMarketingBar: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapState(['isMobile']),

        eobuwieLogo() {
            return this.$getLocaleConfigByKey(LOGO_EOB_IMPORT_NAME);
        },
    },

    beforeCreate() {
        this.HOME_PAGE = HOME_PAGE;
    },
};
</script>

<style lang="scss" scoped>
.base-simple-header {
    @apply border-border border-b-1;

    .content {
        @apply w-full flex justify-center items-center h-56p;
    }

    .content-heading {
        @apply flex items-center;
    }

    .header-simple-heading {
        @apply text-gray3 text-center;
    }

    .info-boxes {
        @apply hidden;
    }

    @screen mobile-and-tablet-only {
        .header-simple-heading {
            @apply order-1 absolute left-0;
        }

        .logo {
            @apply order-2;
        }

        .content-heading {
            @apply w-full relative justify-center;
        }
    }

    @screen md {
        .header-simple-heading {
            @apply leading-l text-l;
        }
    }

    @screen lg {
        .content {
            @apply justify-between h-8;
        }

        .header-simple-heading {
            @apply pl-5;
        }

        .info-boxes {
            @apply flex justify-between items-center;
        }

        .info-box {
            width: theme('customVariables.header.infoBoxMaxWidth');

            &:not(:first-child) {
                @apply ml-3;
            }
        }
    }
}
</style>
