const { namespace } = require('./index');

function tRma(rmaModuleTranslation, variables) {
    return this.t(`${namespace}.${rmaModuleTranslation}`, variables);
}

export default function ({ app }, inject) {
    app.i18n.tRma = tRma.bind(app.i18n);

    app.$tRma = app.i18n.tRma.bind(app.i18n);

    inject('tRma', app.$tRma);
}
