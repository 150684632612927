export const LOGIN = 'login';
export const REGISTER = 'register';
export const FORGOT_PASSWORD = 'forgot-password';

export const LOGIN_COMPONENT_NAME = 'CustomerLoginPanel';
export const REGISTER_COMPONENT_NAME = 'CustomerRegisterPanel';

export const AUTHORIZATION_FORMS_IDS = {
    LOGIN,
    REGISTER,
    FORGOT_PASSWORD,
};

export default {
    LOGIN,
    REGISTER,
    LOGIN_COMPONENT_NAME,
    REGISTER_COMPONENT_NAME,
};
