import { sha256 } from 'js-sha256';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { STORAGE_TYPE_LOCAL } from '@configs/storage';

const GTM_USER_ID_KEY = '_eob_woh_uid';
const GTM_USER_ID_SHA256_KEY = '_eob_h_uid';
const GTM_COUNTRY_CODE_SHA256_KEY = '_eob_h_uI_cc';

export const saveUserIdForGTM = ({ userId, $errorHandler, $storage }) => {
    if (typeof window === 'undefined' || !userId) {
        $errorHandler.captureError(
            new Error('window or userId is undefined'),
            {
                [ERROR_ACTION_TAG_NAME]: 'saveUserIdForGTM',
            },
            {
                typeofWindow: typeof window,
                isUserId: !!userId,
            }
        );

        return;
    }

    try {
        const hashedUserId = sha256(userId);

        $storage.setItem(
            GTM_USER_ID_SHA256_KEY,
            hashedUserId,
            null,
            STORAGE_TYPE_LOCAL,
            false,
            false
        );
        $storage.setItem(
            GTM_USER_ID_KEY,
            userId,
            null,
            STORAGE_TYPE_LOCAL,
            false,
            false
        );
    } catch (err) {
        $errorHandler.captureError(err, {
            [ERROR_ACTION_TAG_NAME]: 'saveUserIdForGTM',
        });
    }
};

export const saveCountryCodeForGTM = ({
    countryCode,
    $errorHandler,
    $storage,
}) => {
    if (typeof window === 'undefined' || !countryCode) {
        $errorHandler.captureError(
            new Error('window or countryCode is undefined'),
            {
                [ERROR_ACTION_TAG_NAME]: 'saveCountryCodeForGTM',
            },
            {
                typeofWindow: typeof window,
                isCountryCode: !!countryCode,
            }
        );

        return;
    }

    try {
        const hashedCountryCode = sha256(countryCode);

        $storage.setItem(
            GTM_COUNTRY_CODE_SHA256_KEY,
            hashedCountryCode,
            null,
            STORAGE_TYPE_LOCAL,
            false,
            false
        );
    } catch (err) {
        $errorHandler.captureError(err, {
            [ERROR_ACTION_TAG_NAME]: 'saveCountryCodeForGTM',
        });
    }
};
