import {
    DEFAULT_DATE_FORMAT,
    FORMAT_DDMM_DOT,
    FORMAT_DDMMYYYY_DASH,
} from '@types/DateFormat';

import DateHelper from '@models/DateHelper/DateHelper';

export const HOUR_IN_MILISECONDS = 3600 * 1000;
export const DAY_IN_MILISECONDS = 24 * HOUR_IN_MILISECONDS;
export const MONDAY_DAY_NUMBER = 1;

export const formatDate = (
    date,
    format = DEFAULT_DATE_FORMAT,
    withTime = false
) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const separator = format === FORMAT_DDMMYYYY_DASH ? '-' : '.';
    const dataToJoin = [day, month];

    if (format !== FORMAT_DDMM_DOT) {
        dataToJoin.push(date.getFullYear());
    }

    const formattedDate = dataToJoin.join(separator);

    if (!withTime) {
        return formattedDate;
    }

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const time = [hours, minutes, seconds]
        .map(item => item.toString().padStart(2, '0'))
        .join(':');

    return formattedDate.concat(', ', time);
};

export const normalizeDateString = dateString => {
    let dateToNormalize = dateString;

    if (typeof dateToNormalize !== 'string') {
        return dateToNormalize;
    }

    // @PWA-268 check if necessary after this task
    const isDateStartWithDays = dateToNormalize.split('.')[0].length === 2;

    dateToNormalize = isDateStartWithDays
        ? dateToNormalize.replace(/(\d+[.])(\d+[.])/, '$2$1')
        : dateToNormalize;

    return dateToNormalize
        .replace(/-/g, '/')
        .replace(/T/g, ' ')
        .replace(/(\+[0-9]{2}:[0-9]{2}$|(\.0{3})?Z$)/g, '');
};

export const formatDateFromString = (
    dateString,
    format = DEFAULT_DATE_FORMAT,
    withTime = false
) => formatDate(new Date(dateString), format, withTime);

export const formatFullDate = (date, locale, fallbackFormat) => {
    const formatedLocaleCode = locale?.replace('_', '-');
    const normalizeDate = normalizeDateString(date);
    const options = {
        dateStyle: 'long',
    };

    try {
        return new Date(normalizeDate).toLocaleDateString(
            formatedLocaleCode,
            options
        );
    } catch {
        return formatDateFromString(normalizeDate, fallbackFormat);
    }
};

export const isCurrentDateBetweenDates = ({
    dateHelper,
    startDate,
    endDate,
}) => {
    const start = dateHelper.createDateFromStringInTimezone(startDate);

    const end = dateHelper.createDateFromStringInTimezone(endDate);

    const currentDeviceTimeInStoreTimezone = dateHelper.createCurrentDateInTimezone();

    return DateHelper.isCurrentDateBetweenPassed(
        start,
        end,
        currentDeviceTimeInStoreTimezone
    );
};

export const getTodayFormattedDate = () => {
    return formatDate(new Date());
};

export const addHours = (date, hours) => {
    return new Date(date.getTime() + hours * HOUR_IN_MILISECONDS);
};

export default {
    formatDate,
    normalizeDateString,
};
