<template>
    <ContainerContent class="footer-bottom">
        <div
            class="wrapper"
            :class="{ 'has-legal-info-links': hasLegalInfoLinks }"
        >
            <div class="column-domains">
                <WithClickOutsideDetection
                    :handler="
                        isLocaleDropdownOpen ? 'closeLocaleDropdown' : null
                    "
                >
                    <div class="locales">
                        <button
                            type="button"
                            class="locales-toggle"
                            :class="{ focus: isLocaleDropdownOpen }"
                            @click="
                                isLocaleDropdownOpen = !isLocaleDropdownOpen
                            "
                        >
                            <Icon
                                :icon="currentLocale.icon"
                                class="domain-flag"
                            />
                            <span class="domain-name">
                                {{ currentLocale.label }}
                            </span>
                            <Icon :icon="localeDropdownIcon" class="arrow" />
                        </button>
                        <DropdownList
                            :options="availableLocales"
                            :show="isLocaleDropdownOpen"
                            :label="currentLocale.label"
                            class="locales-list"
                            @select="changeSiteLangVersion($event)"
                            @close="closeLocaleDropdown()"
                        />
                    </div>
                </WithClickOutsideDetection>
            </div>

            <div v-if="showModivoLogo" class="column-logo">
                <LogoModivoByEobuwie
                    :modivo-url="modivoUrl"
                    class="logo-modivo"
                />
            </div>
            <LegalInformationLinks
                v-if="hasLegalInfoLinks"
                class="column-links"
            />
        </div>
    </ContainerContent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    LEGAL_INFORMATION_FIRST,
    LEGAL_INFORMATION_SECOND,
    MODIVO_DOMAIN_NAME,
} from '@localeConfig/keys';

import { getFooterModivoUrl } from '@assets/modivo';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import LogoModivoByEobuwie from '@molecules/LogoModivoByEobuwie/LogoModivoByEobuwie';
import LegalInformationLinks from '@molecules/LegalInformationLinks/LegalInformationLinks';
import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

import { DropdownList } from '@eobuwie-ui/components/DropdownList/v1';

import {
    Austra as FlagAT,
    Bulgaria as FlagBG,
    Croatia as FlagHR,
    CzechRepublic as FlagCZ,
    France as FlagFR,
    Germany as FlagDE,
    Greece as FlagGR,
    Hungary as FlagHU,
    Italy as FlagIT,
    Latvia as FlagLV,
    Lithuania as FlagLT,
    Poland as FlagPL,
    Romania as FlagRO,
    Slovakia as FlagSK,
    Slovenia as FlagSI,
    Spain as FlagES,
    Sweden as FlagSE,
    Switzerland as FlagCH,
    Ukraine as FlagUA,
    UnitedKingdom as FlagGB,
} from '@eobuwie-ui/icons/v1/flags';
import { ChevronDown, ChevronUp } from '@eobuwie-ui/icons/v2/navigation';

import { Icon } from '@eobuwie-ui/components/Icon/v1';

const {
    mapState: mapConfigState,
    mapGetters: mapConfigGetters,
} = createNamespacedHelpers('config');

export default {
    name: 'FooterBottom',

    components: {
        ContainerContent,
        LogoModivoByEobuwie,
        DropdownList,
        Icon,
        LegalInformationLinks,
        WithClickOutsideDetection,
    },

    data() {
        return {
            isLocaleDropdownOpen: false,
        };
    },

    computed: {
        ...mapConfigGetters(['locale', 'localeConfigByKey']),
        ...mapConfigState(['storeView', 'storeViews']),

        modivoUrl() {
            return getFooterModivoUrl(
                this.$getLocaleConfigByKey(MODIVO_DOMAIN_NAME)
            );
        },

        showModivoLogo() {
            return this.modivoUrl !== '';
        },

        currentLocale() {
            return this.formatOption(this.storeView);
        },

        availableLocales() {
            return this.storeViews.map(storeView =>
                this.formatOption(storeView)
            );
        },

        hasLegalInfoLinks() {
            return (
                [
                    this.localeConfigByKey(LEGAL_INFORMATION_FIRST),
                    this.localeConfigByKey(LEGAL_INFORMATION_SECOND),
                ].filter(legalInformation => !!legalInformation).length > 0
            );
        },

        localeDropdownIcon() {
            return this.isLocaleDropdownOpen
                ? this.chevronUp
                : this.chevronDown;
        },
    },

    beforeCreate() {
        this.chevronDown = ChevronDown;
        this.chevronUp = ChevronUp;

        this.flagIcons = {
            FlagPL,
            FlagSK,
            FlagCZ,
            FlagRO,
            FlagHU,
            FlagBG,
            FlagGR,
            FlagDE,
            FlagFR,
            FlagLT,
            FlagLV,
            FlagIT,
            FlagSI,
            FlagUA,
            FlagHR,
            FlagES,
            FlagSE,
            FlagRU: FlagUA,
            FlagCH,
            FlagGB,
            FlagAT,
        };
    },

    methods: {
        changeSiteLangVersion(selected) {
            this.closeLocaleDropdown();

            if (window.location.origin !== selected) {
                window.location.href = selected;
            }
        },

        formatOption({ base_url, locale }) {
            const label = locale.split('_')[1];

            return {
                value: `https://${base_url}`,
                label: `${base_url}`,
                icon: this.flagIcons[`Flag${label}`],
            };
        },

        closeLocaleDropdown() {
            this.isLocaleDropdownOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-bottom {
    @apply pb-5;

    .wrapper {
        @apply w-full flex flex-col items-center pt-5 mt-5 border-t-1 border-solid border-gray5;
    }

    .column-logo,
    .column-domains {
        @apply w-full flex justify-center;
    }

    .focus {
        @apply border-dark border-1;
    }

    .column-logo {
        @apply mt-5;
    }

    .dropdown-item {
        @apply flex items-center min-w-xxl;
    }

    .domain-flag,
    .arrow {
        @apply flex-shrink-0;
    }

    .domain-name {
        @apply truncate text-left flex-1;
    }

    .column-links {
        @apply mt-5;
    }

    .locales {
        @apply border-solid border-ui-1 border-ui-container-stroke-100-default;
        @apply w-full relative bg-ui-container-default-default cursor-pointer h-ui-11;

        max-width: theme(
            'customVariables.footerDomainsDropdownMaxWidth.default'
        );
    }

    .locales-toggle {
        @apply w-full h-full flex items-center px-ui-4 py-ui-plus-14p bg-light gap-x-3;
        @apply font-ui-body-m;
    }

    .locales-list {
        @apply absolute left-ui-minus-1p bottom-ui-percent-100-plus-4p z-5;
        @apply w-ui-percent-100-plus-2p;

        &:deep(.content) {
            @apply w-ui-percent-100;
        }

        &:deep(.container) {
            @apply max-w-none;
        }
    }

    @screen md {
        .wrapper {
            @apply flex-row gap-x-2 justify-between;

            &.has-legal-info-links {
                .column-logo {
                    @apply justify-center;
                }
            }
        }

        .column-domains {
            @apply justify-start w-auto flex-shrink-0;
        }

        .column-logo {
            @apply justify-end mt-0 w-full;
        }

        .logo-modivo {
            @apply justify-start;
        }

        .column-links {
            @apply mt-0 flex-wrap;
        }
    }

    @screen lg {
        .column-links {
            @apply flex-nowrap;
        }
    }

    @screen xl {
        .wrapper {
            @apply justify-start;
        }

        .column-domains,
        .column-logo,
        .column-links {
            @apply w-1/3 flex-shrink;
        }

        .column-logo {
            @apply justify-center;
        }

        .column-links {
            @apply justify-end;
        }
    }
}
</style>
