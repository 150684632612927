export const ACCOUNT_VERIFICATION_STATES = {
    SEND: 'verification-send',
    PENDING: 'verification-pending',
    COMPLETE: 'verification-complete',
    EXPIRED: 'verification-expired',
    ERROR: 'verification-error',
    NOT_EXIST: 'verification-not-exist',
};

export const CHANNEL_TYPE_EMAIL = 'email';
