import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

export default megatronClient => ({
    async getConsentData(withProviders = true) {
        const { data } = await megatronClient.get('v1/consentData', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                withProviders,
            },
        });

        const customerConsentCategories = data?.customerConsentCategories || [];
        const customerConsentProviders = data?.customerConsentProviders || [];

        return { customerConsentCategories, customerConsentProviders };
    },
});
