import loyaltyServiceConfig from './loyalty/config';
import pointsEstimatorServiceConfig from './points-estimator/config';

export default function ({ $asyncServices }) {
    [loyaltyServiceConfig, pointsEstimatorServiceConfig].forEach(
        ({ name: serviceName, load: serviceLoadFn }) => {
            $asyncServices.registerService(serviceName, serviceLoadFn);
        }
    );
}
