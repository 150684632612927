<template>
    <component :is="tag" :class="headingClasses">
        <slot />
    </component>
</template>

<script>
import colors from '@theme/resources/variables/colors.json';

import { VALID_HEADING_TYPES } from '@configs/headings';

import { checkIfExistsInKeysMap } from '@assets/props';

const propsHeadingType = {
    type: String,
    default: 'strong',
    validator: value => VALID_HEADING_TYPES.includes(value),
};

export default {
    name: 'BaseHeading',

    props: {
        tag: propsHeadingType,

        theme: propsHeadingType,

        color: {
            type: String,
            default: '',
            validator: checkIfExistsInKeysMap(colors, true),
        },
    },

    computed: {
        headingClasses() {
            const classes = [`_heading-${this.theme}`];

            if (this.color) {
                classes.push(`text-${this.color}`);
            }

            return classes;
        },
    },
};
</script>
