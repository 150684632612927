export const types = {
    SET_PAGE_SIZE: 'SET_PAGE_SIZE',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
};

export default {
    [types.SET_PAGE_SIZE](state, pageSize) {
        state.pageSize = pageSize;
    },
    [types.SET_CURRENT_PAGE](state, currentPage) {
        state.currentPage = currentPage;
    },
    [types.SET_TOTAL_PAGES](state, totalPages) {
        state.totalPages = totalPages;
    },
};
