import {
    PRIVATE_PERSON_CUSTOMER_TYPE,
    COMPANY_CUSTOMER_TYPE,
} from '@analytics-modules/checkout/types/Analytics';

import { PRIVATE_PERSON_CUSTOMER, COMPANY_CUSTOMER } from '@types/Customer';
import DataLayerProducts from '@models/Analytics/DataLayerProducts';
import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { isCodPayment } from '@assets/pad';

const getAnalyticsCustomerTypeFromCustomerType = customerType => {
    switch (customerType) {
        case PRIVATE_PERSON_CUSTOMER:
            return PRIVATE_PERSON_CUSTOMER_TYPE;
        case COMPANY_CUSTOMER:
            return COMPANY_CUSTOMER_TYPE;
        default:
            return '';
    }
};

export const buildViewCartEventPayload = ({
    currency,
    grandTotalNumber,
    cartItems,
    productsReviewsTotalCount,
    selectedShippingMethodCode,
    selectedPaymentMethodCode,
    selectedPaymentType,
    elapsedTimeMs,
    route,
}) => {
    const products = new DataLayerProducts({
        products: cartItems,
        productsReviewsTotalCount,
        selectedShippingMethod: selectedShippingMethodCode,
        selectedPaymentMethod: selectedPaymentMethodCode,
        selectedPaymentType,
    }).build();

    return {
        currency,
        grandTotalNumber,
        products,
        elapsedTimeMs,
        route,
    };
};

export const buildBeginCheckoutPayload = ({
    currency,
    discountCode,
    grandTotalNumber,
    cartItems,
    productsReviewsTotalCount,
    selectedShippingMethodCode,
    selectedPaymentMethodCode,
    selectedPaymentType,
    customerType,
    autocomplete,
    elapsedTimeMs,
    route,
}) => {
    const products = new DataLayerProducts({
        products: cartItems,
        productsReviewsTotalCount,
        selectedShippingMethod: selectedShippingMethodCode,
        selectedPaymentMethod: selectedPaymentMethodCode,
        selectedPaymentType,
    }).build();

    return {
        currency,
        discountCode,
        grandTotalNumber,
        products,
        customerType: getAnalyticsCustomerTypeFromCustomerType(customerType),
        isPrefilled: autocomplete,
        isCodPayment: isCodPayment(selectedPaymentType),
        elapsedTimeMs,
        route,
    };
};

export const buildAddShippingInfoPayload = ({
    currency,
    discountCode,
    grandTotalNumber,
    cartItems,
    productsReviewsTotalCount,
    selectedShippingMethodCode,
    selectedPaymentMethodCode,
    selectedPaymentType,
    autocomplete,
    route,
}) => {
    const products = new DataLayerProducts({
        products: cartItems,
        productsReviewsTotalCount,
        selectedShippingMethod: selectedShippingMethodCode,
        selectedPaymentMethod: selectedPaymentMethodCode,
        selectedPaymentType,
    }).build();

    return {
        currency,
        discountCode,
        grandTotalNumber,
        products,
        isPrefilled: autocomplete,
        isCodPayment: isCodPayment(selectedPaymentType),
        shippingMethods: [selectedShippingMethodCode],
        route,
    };
};

export const buildAddPaymentInfoPayload = ({
    currency,
    discountCode,
    grandTotalNumber,
    cartItems,
    productsReviewsTotalCount,
    selectedShippingMethodCode,
    selectedPaymentMethodCode,
    selectedPaymentType,
    autocomplete,
    route,
}) => {
    const products = new DataLayerProducts({
        products: cartItems,
        productsReviewsTotalCount,
        selectedShippingMethod: selectedShippingMethodCode,
        selectedPaymentMethod: selectedPaymentMethodCode,
        selectedPaymentType,
    }).build();

    return {
        currency,
        discountCode,
        grandTotalNumber,
        products,
        isPrefilled: autocomplete,
        isCodPayment: isCodPayment(selectedPaymentType),
        paymentMethod: selectedPaymentMethodCode,
        route,
    };
};

export const buildRemoveFromCartPayload = ({
    currency,
    cartItems,
    cartItem,
    quantity = 0,
    productReviewTotalCount,
    elapsedTimeMs,
    selectedPaymentType,
    selectedPaymentMethodCode,
    selectedShippingMethodCode,
    route,
}) => {
    const {
        product: cartItemProduct,
        quantity: cartItemQuantity,
        price: { promotional: { amount: promotionalPrice } = {} } = {},
        prices: { final_item_price: finalItemPrice } = {},
        sku: cartItemSku,
    } = cartItem;

    const cartItemIndex = cartItems.map(({ sku }) => sku).indexOf(cartItemSku);

    const product = new DataLayerProduct({
        product: cartItemProduct,
        reviews: { totalCount: productReviewTotalCount },
        position: cartItemIndex + 1,
        quantity: quantity || cartItemQuantity,
        discountedPrice: promotionalPrice || finalItemPrice,
        variantId: cartItemSku,
        selectedPaymentType,
        selectedPaymentMethod: selectedPaymentMethodCode,
        shippingMethod: selectedShippingMethodCode,
    }).build();

    return {
        currency,
        product,
        elapsedTimeMs,
        route,
    };
};
