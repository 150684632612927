import {
    ERROR_FEATURE_DOMAIN_TAG_NAME,
    ERROR_ACTION_TAG_NAME,
} from '@types/Errors';

import { isObjectType } from '@assets/types';

export const ERROR_HANDLER_INVALID_ARGUMENT_MESSAGE =
    '"errorHandler" argument is missing or has invalid type (Object is required)';
export const DOMAIN_TAG_VALUE_INVALID_ARGUMENT_MESSAGE =
    '"domainTagValue" argument is missing or has invalid type (String is required)';

export const createError = msg =>
    new Error(`[createErrorHandlerWithDomainContext]: ${msg}`);

function applyDomainCustomTags(domainTagValue, tags = {}) {
    const domainTags = {
        [ERROR_FEATURE_DOMAIN_TAG_NAME]: domainTagValue,
    };

    if (typeof tags === 'string') {
        return {
            ...domainTags,
            [ERROR_ACTION_TAG_NAME]: tags,
        };
    }

    return {
        ...domainTags,
        ...tags,
    };
}

export function createErrorHandlerWithDomainContext(
    errorHandler,
    domainTagValue
) {
    if (
        !errorHandler ||
        !isObjectType(errorHandler, Object) ||
        typeof errorHandler.captureError !== 'function' ||
        typeof errorHandler.captureStoreError !== 'function'
    ) {
        throw createError(ERROR_HANDLER_INVALID_ARGUMENT_MESSAGE);
    }

    if (!domainTagValue || typeof domainTagValue !== 'string') {
        throw createError(DOMAIN_TAG_VALUE_INVALID_ARGUMENT_MESSAGE);
    }

    return {
        captureError: (error, tags, ...args) =>
            errorHandler.captureError(
                error,
                applyDomainCustomTags(domainTagValue, tags),
                ...args
            ),

        captureStoreError: (moduleName, error, tags, ...args) =>
            errorHandler.captureStoreError(
                moduleName,
                error,
                applyDomainCustomTags(domainTagValue, tags),
                ...args
            ),
    };
}
