<template>
    <div
        class="header-tooltip"
        v-on="$listeners"
        @mouseenter="onMouseEnter()"
        @mouseleave="hover = false"
    >
        <slot />
        <div v-if="hasTooltipRendered" v-show="hover" class="tooltip-container">
            <div class="tooltip-content">
                <slot name="tooltipContent" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderTooltip',

    props: {
        isEnabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hover: false,
            hasTooltipRendered: false,
        };
    },

    methods: {
        onMouseEnter() {
            if (!this.isEnabled) {
                return;
            }

            if (!this.hasTooltipRendered) {
                this.hasTooltipRendered = true;
            }

            this.hover = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.header-tooltip {
    @apply relative;

    .tooltip-container {
        @apply hidden;
    }

    @screen lg {
        .tooltip-container {
            @apply block right-0 absolute z-3 pt-ui-2;
        }

        .tooltip-content {
            @apply w-[400px];
            @apply bg-ui-container-primary-alternative;
            @apply border-ui-1 border-ui-container-stroke-100-default;
        }

        .tooltip-content:empty {
            @apply hidden;
        }
    }
}
</style>
