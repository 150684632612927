import { LOCALE_LT, LOCALE_LV } from '@rma/configs/locales-codes';
import { getLocaleISOCode } from '@rma/assets/get-locale-iso-code';

export const CITY_RULE_NAME = 'rmaCity';

export const STANDARD_CITY_VALIDATION_RULES = {
    length: 100,
};

export const LOCALIZED_CITY_VALIDATION_RULES = {
    [LOCALE_LT]: {
        length: 35,
    },
    [LOCALE_LV]: {
        length: 35,
    },
};

export const getCityValidationRule = i18n => {
    const localeIso = getLocaleISOCode(i18n.locales, i18n.locale);

    const { length } =
        LOCALIZED_CITY_VALIDATION_RULES[localeIso] ||
        STANDARD_CITY_VALIDATION_RULES;

    return {
        validate: rmaCity => rmaCity.length <= length,

        message: (_, values) => {
            const { _value_: rmaCity } = values;

            if (rmaCity.length > length) {
                return i18n.t('__validations.max', { length });
            }
        },
    };
};

export default i18n => ({
    name: CITY_RULE_NAME,
    validation: getCityValidationRule(i18n),
});
