import orderBy from 'lodash.orderby';

import { PAYMENT_TYPES_CONFIG } from '@configs/payment-types';

import { PAYMENT_TYPES } from '@types/PaymentTypes';

export function getDeliveryMethodPrice(methodPadOptions) {
    const prices = [...new Set(methodPadOptions.map(({ price }) => price))];

    return { value: Math.min(...prices), isSingle: prices.length === 1 };
}

export const checkoutDeliveryMethodFromPadDeliveryMethod = (
    padDeliveryMethod,
    storeId,
    paymentType,
    allPadOptions
) => {
    const { id: methodId } = padDeliveryMethod;

    const padOptions = allPadOptions
        .filter(option => option.deliveryMethodId === methodId)
        .filter(option => option.storeId === storeId)
        .filter(option => option.paymentType === paymentType);

    const price = getDeliveryMethodPrice(padOptions);

    return {
        ...padDeliveryMethod,
        padOptions,
        price,
    };
};

export const sortDeliveryMethods = deliveryMethods => {
    return deliveryMethods.sort((method1, method2) => {
        if (method1.disabled === method2.disabled) {
            return 0;
        }

        return method1.disabled ? 1 : -1;
    });
};

function sortPaymentMethods(methods, selectedPaymentType) {
    return orderBy(
        methods,
        [
            ({ type }) => type === selectedPaymentType,
            ({ disabled }) => disabled,
            method => methods.indexOf(method),
        ],
        ['desc', 'asc', 'asc']
    );
}

function findMaxProductsLimit(padOptions) {
    if (!padOptions?.length) {
        return null;
    }

    return Math.max(
        ...padOptions.map(
            ({ maximumAmountOfProducts }) => maximumAmountOfProducts || 1000
        )
    );
}

function countCartItemsQuantity(items) {
    return items.reduce((acc, { quantity }) => acc + quantity, 0);
}

function getMethodNotAvailableText(paymentType) {
    return PAYMENT_TYPES_CONFIG?.[paymentType]?.methodNotAvailableText || '';
}

function getPaymentLimitReachedText(number) {
    return {
        key:
            'Payment method available for the maximum number of products: {number}',
        values: { number },
    };
}

function getPaymentMethodDisabilityReasons(
    method,
    methodOptions,
    paymentType,
    selectedPaymentType,
    selectedDeliveryMethod,
    cartItems
) {
    const reasons = [];

    const isSameTypeAsPaymentType = paymentType === method.type;

    if (!isSameTypeAsPaymentType) {
        const methodNotAvailableText = getMethodNotAvailableText(paymentType);

        reasons.push({ key: methodNotAvailableText });
    }

    const isSameTypeAsSelectedPaymentType =
        selectedPaymentType && paymentType === selectedPaymentType;

    let existsAnyOptionForSelectedDeliveryMethod = true;

    if (selectedDeliveryMethod?.code && isSameTypeAsSelectedPaymentType) {
        existsAnyOptionForSelectedDeliveryMethod = methodOptions.some(
            ({ deliveryMethodId }) =>
                deliveryMethodId === selectedDeliveryMethod?.id
        );
    }

    if (!existsAnyOptionForSelectedDeliveryMethod) {
        reasons.push({
            key:
                'This payment method is not available for the selected delivery method.',
        });
    }

    const productsLimit = findMaxProductsLimit(methodOptions);

    if (productsLimit && countCartItemsQuantity(cartItems) > productsLimit) {
        reasons.push(getPaymentLimitReachedText(productsLimit));
    }

    return reasons;
}

export function getPaymentMethodsForPaymentType(
    paymentType,
    paymentMethods,
    allPadOptions,
    selectedDeliveryMethod,
    selectedPaymentType,
    storeId,
    cartItems
) {
    const methods = paymentMethods.map(method => {
        const options = allPadOptions
            .filter(option => option.paymentMethodId === method.id)
            .filter(option => option.storeId === storeId);

        const disabilityReasons = getPaymentMethodDisabilityReasons(
            method,
            options,
            paymentType,
            selectedPaymentType,
            selectedDeliveryMethod,
            cartItems
        );

        return {
            ...method,
            options,
            disabilityReasons,
            disabled: disabilityReasons.length !== 0,
        };
    });

    return sortPaymentMethods(methods, selectedPaymentType);
}

export function mapSort(methods = []) {
    return orderBy(
        methods.map(({ code, disabled }) => ({ code, disabled })),
        ['code', 'disabled']
    );
}

export function checkMethodsChanged(oldMethods, newMethods) {
    const { stringify } = JSON;

    return stringify(mapSort(oldMethods)) !== stringify(mapSort(newMethods));
}

export const isCodPayment = paymentType => paymentType === PAYMENT_TYPES.COD;
