import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO } from '@configs/megatron-client';

import CmsPageException from '@exceptions/services/cms/CmsPageException';
import CmsPageNotFoundException from '@exceptions/services/cms/CmsPageNotFoundException';

export default megatronClient => ({
    async getPageBuilderPage(identifier) {
        const { data, error: megatronError } = await megatronClient.get(
            'v1/pageBuilderCmsPage',
            {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    identifier,
                },
            }
        );

        const { pageBuilderPage, errors } = data || {};

        const error = megatronError || errors?.[0];

        if (error) {
            if (!pageBuilderPage) {
                throw new CmsPageNotFoundException(identifier, error);
            }

            throw new CmsPageException(identifier, error);
        }

        return pageBuilderPage;
    },
});
