export const types = {
    SET_ORDER_CODE: 'SET_ORDER_CODE',
    SET_ORDER_DIR: 'SET_ORDER_DIR',
    SET_ORDER_OPTIONS: 'SET_ORDER_OPTIONS',
    SET_DEFAULT_ORDER_CODE: 'SET_DEFAULT_ORDER_VALUE',
    SET_SELECTED_SORT: 'SET_SELECTED_SORT',
};

const mutations = {
    [types.SET_ORDER_CODE](state, orderCode) {
        state.orderCode = orderCode;
    },
    [types.SET_ORDER_DIR](state, orderDir) {
        state.orderDir = orderDir;
    },
    [types.SET_ORDER_OPTIONS](state, orderOptions) {
        state.orderOptions = orderOptions;
    },
    [types.SET_DEFAULT_ORDER_CODE](state, defaultOrderCode) {
        state.defaultOrderCode = defaultOrderCode;
    },
    [types.SET_SELECTED_SORT](state, selectedSort) {
        state.selectedSortOption = selectedSort;
    },
};

export default mutations;
