import { BRANDS_PAGE } from '@router/paths';
import { CATALOG_PAGE } from '@search/routing/paths';

import { createPagePath } from '@assets/path';

const ITEMS_PER_COLUMN = 10;

export default class Navigation {
    constructor(navigationItem) {
        const { children } = navigationItem;

        this.navigationItem = navigationItem;
        this.children = children;
    }

    getNormalizedChildren() {
        return this.children.map(child => ({
            hasChildren: child.children?.length > 0,
            path: Navigation.getCategoryPath(child),
            name: child.display_name,
            content: this.getNormalizedContent(child.children),
            specialTextColor: Navigation.getSpecialTextColor(child),
            cmsBlock: child.cms_block,
            id: child.id,
        }));
    }

    getNormalizedContent(categories) {
        let allColumns = 0;

        return categories.map((category, index) => {
            let categoryLinks = this.getNavigationLinks(category);

            const isBrandsList = categoryLinks.some(
                brand => brand.path === `/${BRANDS_PAGE}`
            );

            if (category.pim) {
                categoryLinks.sort((a, b) => a.name.localeCompare(b.name));
            }

            let columns = Math.ceil(categoryLinks.length / ITEMS_PER_COLUMN);

            if (index === 0 && columns > 3) {
                categoryLinks = [...categoryLinks.slice(0, 30)];
                columns = Math.ceil(categoryLinks.length / ITEMS_PER_COLUMN);
            }

            if (index >= 1 && columns > 2) {
                categoryLinks = [...categoryLinks.slice(0, 20)];
                columns = Math.ceil(categoryLinks.length / ITEMS_PER_COLUMN);
            }

            allColumns += columns;

            if (isBrandsList && allColumns > 5) {
                categoryLinks = [
                    ...categoryLinks.slice(0, 9),
                    categoryLinks[categoryLinks.length - 1],
                ];
                columns = Math.ceil(categoryLinks.length / ITEMS_PER_COLUMN);
            }

            return {
                id: category.id,
                title: category.display_name,
                categoryLinks,
                columns,
                isBrandsList,
                path: Navigation.getCategoryPath(category),
                showAll: Navigation.getShowAll(category) || null,
            };
        });
    }

    getNavigationLinks(category) {
        if (
            category.children.length === 1 &&
            category.children[0].hasChildren
        ) {
            return this.getNavigationLinks(category.children[0]);
        }

        return category.children.reduce((acc, curr) => {
            if (curr.display_name && curr.children?.length === 0) {
                acc.push({
                    name: curr.display_name,
                    path: Navigation.getCategoryPath(curr),
                    icon: curr.icon,
                });
            }

            if (curr.children?.length) {
                curr.children.forEach(child =>
                    acc.push({
                        name: child.display_name,
                        path: Navigation.getCategoryPath(child),
                        icon: child.icon,
                    })
                );
            }

            return acc;
        }, []);
    }

    static getShowAll(category) {
        const first = category.children[0];

        if (
            category.children.length === 1 &&
            (!!first.custom_url_path || !!first.pim?.slug)
        ) {
            return {
                name: first.display_name,
                path: Navigation.getCategoryPath(first),
            };
        }

        const showAll = category.children.find(
            child =>
                !!child.children.length &&
                (child.custom_url_path || child.pim?.slug)
        );

        if (!showAll) {
            return;
        }

        return {
            name: showAll.display_name,
            path: Navigation.getCategoryPath(showAll),
        };
    }

    static getSpecialTextColor(category) {
        if (
            category.custom_differentiators.some(
                differentiator => differentiator === 'text-red'
            )
        ) {
            return {
                color: '#E02424',
            };
        }

        return undefined;
    }

    static getCategoryPath(category) {
        const { custom_url_path, pim } = category;

        if (custom_url_path) {
            return custom_url_path;
        }

        const { slug = null } = pim || {};

        return slug ? createPagePath(slug, CATALOG_PAGE) : '';
    }

    getLimitedChildren() {
        const children = this.getNormalizedChildren();

        children.forEach(child => {
            if (child.content.length) {
                child.content = child.content.slice(0, 5);
            }

            child.content.forEach(content => {
                content.categoryLinks = content.categoryLinks.filter(
                    ({ path }) => path
                );
            });
        });

        return children;
    }

    normalize() {
        return {
            ...this.navigationItem,
            children: this.children?.length ? this.getLimitedChildren() : [],
            hasChildren: !!this.children?.length,
        };
    }
}
