import Navigation from '@header/models/Navigation';

export default {
    activeRootCategories: state => {
        return state.categories.filter(
            rootCategory =>
                (rootCategory.path && rootCategory.url_path) ||
                rootCategory.custom_url_path
        );
    },

    mainCategories: state => {
        return new Navigation({
            children: state.categories.filter(
                mainCategory =>
                    (mainCategory.path && mainCategory.url_path) ||
                    mainCategory.custom_url_path
            ),
            isActive: true,
        }).normalize();
    },
};
