<template>
    <ContainerContent class="footer-free-delivery">
        <BaseTooltip
            v-for="({ id, content, icon, text }, index) in infoBoxes"
            :key="id"
            class="box"
            tooltip-class="box-tooltip"
            tooltip-container-class="box-tooltip-container"
            :is-mobile="isMobile"
            :is-enabled="isMobile && !!content"
            :position="getPosition(index)"
            padding="11px"
            tabindex="0"
        >
            <span class="circle">
                <SvgIcon width="24px" height="24px" class="icon">
                    <component :is="icon" />
                </SvgIcon>
            </span>
            <span class="text">
                {{ text }}
            </span>
            <template #tooltipContent>
                <StaticBlock :content="content" />
            </template>
        </BaseTooltip>
    </ContainerContent>
</template>

<script>
import { mapState } from 'vuex';

import { POSITION_TOP_LEFT, POSITION_TOP_RIGHT } from '@types/Tooltip';

import DeliveryReturnInfo from '@mixins/DeliveryReturnInfo';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'FooterFreeDelivery',

    components: {
        ContainerContent,
        SvgIcon,
        BaseTooltip,
        StaticBlock,
    },

    mixins: [DeliveryReturnInfo],

    computed: {
        ...mapState(['isMobile']),
    },

    methods: {
        getPosition(index) {
            return index === 0 ? POSITION_TOP_RIGHT : POSITION_TOP_LEFT;
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-free-delivery {
    @screen mobile-and-tablet-only {
        &:deep() {
            .box-tooltip-container {
                width: theme('customVariables.tooltip.width');
            }

            .box-tooltip {
                @apply flex flex-col items-center justify-start p-3 w-full;
            }

            .text-link {
                @apply text-center;
            }
        }

        .box {
            @apply flex bg-gray8 rounded-4;

            width: calc(50% - #{theme('spacing.3') / 2});
            max-width: theme('customVariables.footerFreeDelivery.boxMaxWidth');

            &:not(:first-child) {
                @apply ml-3;
            }
        }

        .circle {
            @apply w-7 h-7 bg-light rounded-50 mb-3 flex justify-center items-center;
        }

        .icon {
            @apply text-green2;
        }

        .text {
            @apply leading-s text-s text-text flex text-center items-center;
        }
    }

    @screen lg {
        @apply hidden;
    }
}
</style>
