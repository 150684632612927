import { v4 as uuidv4 } from 'uuid';

import { STORAGE_TYPE_SESSION, PAGE_VIEWS_KEY } from '@configs/storage';

export const getSessionIdWithUUID = (app, key) => {
    const id = app.$storage.getItem(key, STORAGE_TYPE_SESSION);

    if (id) {
        return id;
    }

    const newId = uuidv4();

    app.$storage.setItem(key, newId, null, STORAGE_TYPE_SESSION);

    return newId;
};

export const incrementPagesViewsCountInStorage = storage => {
    const pageViews =
        storage.getItem(PAGE_VIEWS_KEY, STORAGE_TYPE_SESSION) || 0;

    storage.setItem(PAGE_VIEWS_KEY, pageViews + 1, null, STORAGE_TYPE_SESSION);
};
