import ConsentsProvider from '@models/ConsentsData/ConsentsProvider';
import ConsentsCategory from '@models/ConsentsData/ConsentsCategory';

export const types = {
    SET_CONSENT_DATA: 'SET_CONSENT_DATA',
    SET_IS_MODAL_OPEN: 'SET_IS_MODAL_OPEN',
    SET_IS_TRUSTED_PARTNERS_MODAL_OPEN: 'SET_IS_TRUSTED_PARTNERS_MODAL_OPEN',
    SET_IS_COOKIE_SETTINGS_MODAL_OPEN: 'SET_IS_COOKIE_SETTINGS_MODAL_OPEN',
    SET_ARE_ACTIVE_CONSENTS_EMPTY: 'SET_ARE_ACTIVE_CONSENTS_EMPTY',
    SET_IS_CONSENTS_MODAL_CONFIGURED: 'SET_IS_CONSENTS_MODAL_CONFIGURED',
};

export default {
    [types.SET_CONSENT_DATA](
        state,
        { customerConsentCategories, customerConsentProviders }
    ) {
        state.categories = customerConsentCategories?.map(category =>
            new ConsentsCategory(category).build()
        );

        state.providers = customerConsentProviders?.map(provider =>
            new ConsentsProvider(provider).build()
        );
    },

    [types.SET_IS_MODAL_OPEN](state, isModalOpen) {
        state.isModalOpen = isModalOpen;
    },

    [types.SET_IS_TRUSTED_PARTNERS_MODAL_OPEN](
        state,
        isTrustedPartnersModalOpen
    ) {
        state.isTrustedPartnersModalOpen = isTrustedPartnersModalOpen;
    },

    [types.SET_IS_COOKIE_SETTINGS_MODAL_OPEN](
        state,
        isCookieSettingsModalOpen
    ) {
        state.isCookieSettingsModalOpen = isCookieSettingsModalOpen;
    },

    [types.SET_ARE_ACTIVE_CONSENTS_EMPTY](state, areEmpty) {
        state.areActiveConsentsEmpty = areEmpty;
    },

    [types.SET_IS_CONSENTS_MODAL_CONFIGURED](state, isConsentsModalConfigured) {
        state.isConsentsModalConfigured = isConsentsModalConfigured;
    },
};
